import React from 'react';
import PropTypes from 'prop-types';

import BannerForm from './BannerForm';

import { DragSortTableProvider } from '~/providers/DragSortTable';

export const BlockBanner = ({ block, isEdit }) => (
  <DragSortTableProvider>
    <BannerForm block={block} isEdit={isEdit} />
  </DragSortTableProvider>
);

BlockBanner.propTypes = {
  isEdit: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    weightPosition: PropTypes.number.isRequired,
  }),
};

BlockBanner.defaultProps = {
  isEdit: false,
  block: {
    id: 0,
    kind: 'banner',
    title: '',
    published: false,
    weightPosition: 0,
    blockBanners: [],
  },
};
