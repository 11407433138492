import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

import { PROMOTION_CATEGORIES } from '~/apollo/marketing/promotion';

/*
 * setter = Função de 'set' do hook (onde vai guardar os dados)
 * onError = Função personalizada para tratar o erro
 */
function useCategory({ getter, setter, onError }) {
  const [parentId, setParentId] = useState(null);

  const [promotionCategories, { data, loading }] = useLazyQuery(
    PROMOTION_CATEGORIES,
    {
      context: {
        newBackend: true,
      },
      onError,
    }
  );

  const callQuery = (variables, insertInChildren) => {
    setParentId(insertInChildren);

    promotionCategories({
      variables,
    });
  };

  useEffect(() => {
    if (data) {
      const {
        categoriesProductSearchCategories: { nodes },
      } = data;
      const items = [];

      nodes.forEach(element => {
        items.push({
          ...element,
          id: element.id,
          title: element.name,
          value: element.id,
          key: element.id,
          color: [],
          quantity: element.productCount,
          children: [],
          isLeaf: !!parentId,
        });
      });

      if (parentId) {
        const newGetter = getter.map(item => {
          if (item.id === parentId) {
            if (items.length > 0) {
              item.children = items;
            } else {
              item.isLeaf = true;
            }
          }
          return item;
        });
        setter(newGetter);
        // Adiciona nos filhos do id (insertInChildren)
      } else {
        setter(items);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return [callQuery, { data, loading }];
}

useCategory.propTypes = {
  getter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setter: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default useCategory;
