import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, form } = THEME_BEHAVIOR;

export const AntPagination = css`
  body {
    .ant-pagination .ant-pagination {
      &-item,
      &-item-link {
        box-sizing: border-box;
        border-radius: ${theme.borderRadius};
        width: ${form.field.height};
        height: ${form.field.height};
        line-height: ${form.field.height};
        &-active,
        &:hover {
          border-color: ${theme.color};
          a,
          svg {
            color: ${theme.color};
          }
        }
      }
    }
  }
`;
