import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, Typography } from 'antd';
import useUppyUpload from '~/components/Upload/uppy';
import Image from '~/components/Image';

const { Dragger } = Upload;
const { Text } = Typography;

function BannerPromotion({ thumb, imageUrl, form, formFieldId, isDisabled }) {
  const [uppyImage, uppyUpload, uppyError] = useUppyUpload('promotion');

  const [image, setImage] = useState({
    imageUrl,
    loading: false,
    thumb,
  });

  useEffect(() => {
    setImage(prevState => {
      return {
        ...prevState,
        imageUrl: uppyImage,
      };
    });
    form.setFieldsValue({
      [formFieldId]: uppyImage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uppyImage]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = file => {
    uppyUpload(file);

    getBase64(file, base64 =>
      setImage(prevState => {
        return {
          ...prevState,
          thumb: base64,
          loading: false,
        };
      })
    );

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const fails = [];
    if (!isJpgOrPng) {
      fails.push('type');
    }
    const isLt2M = file.size / 1024 / 1024 / 1024 < 2;
    if (!isLt2M) {
      fails.push('size');
    }

    return fails;
  };

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setImage(prevState => {
        return {
          ...prevState,
          loading: true,
        };
      });
    }
  };

  return (
    <div data-testid="uploadDragger">
      <Dragger
        name="bannerImg"
        listType="picture-card"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        onChange={handleChange}
        disabled={isDisabled}
      >
        {image.thumb ? (
          <Image
            src={image.thumb}
            alt="banner"
            style={{ width: '100%', maxHeight: 300, objectFit: 'contain' }}
          />
        ) : (
          <>
            <Text>
              Clique ou arraste aqui para fazer o upload do banner da promoção
            </Text>
            <Text type="danger">{uppyError}</Text>
          </>
        )}
      </Dragger>
    </div>
  );
}

BannerPromotion.propTypes = {
  thumb: PropTypes.string,
  imageUrl: PropTypes.string,
  form: PropTypes.shape().isRequired,
  formFieldId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

BannerPromotion.defaultProps = {
  thumb: undefined,
  imageUrl: undefined,
};

export default BannerPromotion;
