import React from 'react';
import { Modal, Select, Typography, notification } from 'antd';
import history from '../../../../services/history';

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

export function deleteItem(key, { deleteBrand, setTable, newBrandId }) {
  deleteBrand({
    variables: {
      input: {
        id: key,
        newBrandId,
      },
    },
  })
    .then(() => {
      setTable(table => {
        return {
          ...table,
          loading: true,
        };
      });

      notification.success({
        message: `Marca deletada`,
        description: 'Tabela atualizada com sucesso',
        placement: 'topRight',
      });
    })
    .catch(errorDelete => {
      notification.error({
        message: `Erro ao deletar a marca.`,
        description: errorDelete.errors
          ? errorDelete.errors.map(e => e.message).join(';')
          : errorDelete.message,
        placement: 'topRight',
      });
    });
}

function handleChangeNewBrand(value, { setNewBrandId }) {
  setNewBrandId(value);
}

export function handleDelete(
  key,
  { brand, newBrandId, setNewBrandId, ...restOfHooks }
) {
  confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content: (
      <>
        <Text>
          Selecione uma nova marca para onde os produtos serão movidos.
        </Text>
        {brand.length > 0 ? (
          <Select
            style={{ width: '100%' }}
            defaultValue={brand[0].id === key ? brand[1].name : brand[0].name}
            onChange={value => handleChangeNewBrand(value, { setNewBrandId })}
          >
            {brand.map(
              item =>
                item.id !== key && (
                  <Option key={item.id} value={`${item.id}-${item.name}`}>
                    {item.name}
                  </Option>
                )
            )}
          </Select>
        ) : (
          ''
        )}
      </>
    ),
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteItem(key, { brand, newBrandId, setNewBrandId, ...restOfHooks });
    },
    onCancel() {
      // Cancel
    },
  });
}

export function handleAddBrand() {
  history.push('/manage/content/brand/create');
}

export function handleSearch(searchText, { setTable }) {
  setTable(table => {
    return {
      ...table,
      currentPage: 1,
      skip: 0,
      totalCount: 0,
      searchText,
      loading: true,
    };
  });
}
