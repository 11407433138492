import arrayMove from 'array-move';

export const onSortEnd = ({
  oldIndex,
  newIndex,
  sortedData,
  setSortedData,
  setTableData,
}) => {
  const { dataSource } = sortedData;
  if (oldIndex !== newIndex) {
    const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(
      el => !!el
    );

    setSortedData({ dataSource: newData });
    setTableData(newData);
  }
};
