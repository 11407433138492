import React, { useState, useEffect } from 'react';
import { Card, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import Button from '~/components/Button';
import LoadingBar from '~/components/LoadingBar';

import Filter from './Filter';
import TablePartner from './TablePartner';

import { getTodayISODate, formatCnpj, clearCnpjMask } from '~/util/Functions';
import { cnpjRegex, emailRegex } from '~/constants/GeneralForms';

import { LIST_USERS } from '~/apollo/service/users';

function PartnerList() {
  const [partners, setPartners] = useState([]);
  const [table, setTable] = useState({
    pageSize: 10,
    currentPage: 1,
    skip: 0,
    totalCount: 0,
    searchText: undefined,
    status: 'active',
    searchDate: getTodayISODate({
      seconds: false,
      minutes: false,
      hours: false,
    }),
    loading: false,
    refetch: false,
  });

  const [error, setError] = useState({
    flag: false,
    message: '',
    obj: {},
  });
  const [loading, setLoading] = useState(true);

  function updateHooks(searchUsers, totalCount) {
    setPartners(searchUsers);
    setTable({
      ...table,
      loading: false,
      refetch: false,
      totalCount,
    });
    setLoading(false);
  }

  const [listUsers] = useLazyQuery(LIST_USERS, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ userSearchUsers: { nodes: searchUsers, totalCount } }) => {
      updateHooks(searchUsers, totalCount);
    },
    onError: err => {
      setError({
        flag: true,
        message: 'Erro ao buscar parceiros',
        obj: err,
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    let document;
    let email;

    if (table.searchText) {
      if (emailRegex.test(table.searchText)) {
        email = table.searchText;
      }
      if (cnpjRegex.test(formatCnpj(table.searchText))) {
        document = clearCnpjMask(table.searchText);
      }
    }

    listUsers({
      variables: {
        size: table.pageSize,
        kind: 'partner',
        skip: table.skip,
        email,
        document,
        status: table.status,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.loading]);

  if (loading) return <LoadingBar />;
  if (error.flag) return `Error! ${error.message}`;

  return (
    <Card
      data-testid="partnerCard"
      title="PARCEIROS"
      extra={
        <>
          <Link to="/manage/service/partner/create">
            <Tooltip placement="bottom" title="Adicionar novo parceiro">
              <Button
                data-testid="addBtn"
                type="primary"
                icon={<PlusOutlined />}
                size="default"
                style={{ marginBottom: '0px' }}
              >
                Adicionar parceiro
              </Button>
            </Tooltip>
          </Link>
        </>
      }
    >
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter setTable={setTable} />
      </Card.Grid>
      <Card.Grid style={{ width: '100%' }}>
        <TablePartner
          partners={partners}
          setPartners={setPartners}
          table={table}
          setTable={setTable}
        />
      </Card.Grid>
    </Card>
  );
}

export default PartnerList;
