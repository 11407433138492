import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ToggleMenuContext = createContext();

const ToggleMenuProvider = ({ children }) => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <ToggleMenuContext.Provider value={{ menuActive, setMenuActive }}>
      {children}
    </ToggleMenuContext.Provider>
  );
};

const useToggleMenuContext = () => {
  const context = useContext(ToggleMenuContext);
  if (!context) {
    throw new Error(
      'useToggleMenuContext must be used within a ToggleMenuContext'
    );
  }
  return context;
};

ToggleMenuProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useToggleMenuContext, ToggleMenuProvider };
