import React, { useEffect } from 'react';
import { Form, Row, Col, Select, Typography, Radio } from 'antd';
import PropTypes from 'prop-types';
import {
  COUPON_KIND_BAG_ORDER,
  COUPON_KIND_CART,
  COUPON_RULE_PRODUCT,
  COUPON_RULE_USER,
  COUPON_RULE_FIRST_ORDER,
  COUPON_RULE_ORDER_TOTAL,
  COUPON_RULE_QUANTITY,
} from '~/constants/Coupon';
import ItemTotalRule from './rules/ItemTotal';
import ProductsRule from './rules/Products';
import UsersRule from './rules/Users';
import FirstOrderRule from './rules/FirstOrder';
import QuantityRule from './rules/Quantity';

function RulesForm({
  form,
  rules,
  ruleFlags,
  setRuleFlags,
  couponType,
  errors,
}) {
  const { Option } = Select;
  const { Text } = Typography;

  function handleChangeRules(values) {
    const aux = { ...ruleFlags };
    Object.keys(aux).forEach(key => {
      aux[key] = values.includes(key);
    });
    setRuleFlags(aux);
  }

  useEffect(() => {
    if (rules && rules?.initialRules)
      handleChangeRules(Object.keys(rules.initialRules));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newRules = Object.keys(ruleFlags).filter(
      ruleKey => ruleFlags[ruleKey] && ruleKey
    );

    if (
      newRules.includes(COUPON_RULE_PRODUCT) &&
      couponType === COUPON_KIND_BAG_ORDER
    )
      newRules = newRules.filter(rule => rule !== COUPON_RULE_PRODUCT);

    const formRules = form.getFieldsValue(true)?.rules;

    if (formRules && newRules.length) {
      Object.keys(formRules).forEach(
        rule => !newRules.includes(rule) && delete formRules[rule]
      );
      form.setFieldsValue({ rules: formRules });
    }
    form.setFieldsValue({ Regras: newRules });
  }, [ruleFlags, couponType]);

  function removeSelectedRule(rule) {
    setRuleFlags({ ...ruleFlags, [rule]: false });
  }

  return (
    <div data-testid="couponRules">
      <Row gutter={16} type="flex" align="top" justify="start">
        <Col span={24}>
          <Form.Item
            name={['ruleOption']}
            initialValue={
              rules && rules?.initialOperator ? rules.initialOperator : '_and'
            }
            rules={[
              {
                required: true,
                message: 'Selecione uma das opções',
                whitespace: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio data-testid="_and" value="_and">
                O pedido deve cumprir todas as regras
              </Radio>
              <Radio data-testid="_or" value="_or">
                O pedido deve cumprir pelo menos uma das regras
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginBottom: '10px' }}>
          <Text>Seleciona as regras do cupom</Text>
        </Col>
        <Col span={24} style={{ marginBottom: '10px' }}>
          <Form.Item
            name="Regras"
            rules={[
              {
                required: true,
                message: 'Selecione uma ou mais regras',
              },
            ]}
          >
            <Select
              data-testid="couponRulesSelector"
              mode="multiple"
              onChange={values => handleChangeRules(values)}
              style={{ width: '100%' }}
            >
              {/* <Option value="categories">Categorias</Option> */}
              <Option value={COUPON_RULE_ORDER_TOTAL}>Valor do Pedido</Option>
              {couponType === COUPON_KIND_CART && (
                <Option value={COUPON_RULE_PRODUCT}>Produtos</Option>
              )}
              <Option value="user">Usuários</Option>
              <Option
                value={COUPON_RULE_FIRST_ORDER}
                data-testid={COUPON_RULE_FIRST_ORDER}
              >
                Primeiro pedido
              </Option>
              <Option value={COUPON_RULE_QUANTITY}>Quantidade</Option>
              {/* <Option value="loggedIn">Usuário logado</Option> */}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {ruleFlags.orderTotal ? (
          <Col span={24}>
            <ItemTotalRule
              initialRules={rules?.initialRules?.orderTotal}
              error={errors.includes('order_total')}
              form={form}
              deleteHandler={() => {
                removeSelectedRule(COUPON_RULE_ORDER_TOTAL);
              }}
            />
          </Col>
        ) : null}
        {ruleFlags.product && couponType === COUPON_KIND_CART ? (
          <Col span={24}>
            <ProductsRule
              initialRules={rules?.initialRules?.product}
              error={errors.includes(COUPON_RULE_PRODUCT)}
              form={form}
              deleteHandler={() => {
                removeSelectedRule(COUPON_RULE_PRODUCT);
              }}
            />
          </Col>
        ) : null}
        {ruleFlags.user ? (
          <Col span={24}>
            <UsersRule
              initialRules={rules?.initialRules?.user}
              error={errors.includes(COUPON_RULE_USER)}
              form={form}
              deleteHandler={() => {
                removeSelectedRule(COUPON_RULE_USER);
              }}
            />
          </Col>
        ) : null}
        {ruleFlags.firstOrder ? (
          <Col span={24}>
            <FirstOrderRule
              initialRules={rules?.initialRules?.firstOrder}
              error={errors.includes('first_order')}
              form={form}
              deleteHandler={() => {
                removeSelectedRule(COUPON_RULE_FIRST_ORDER);
              }}
            />
          </Col>
        ) : null}
        {ruleFlags.quantityItems ? (
          <Col span={24}>
            <QuantityRule
              initialRules={rules?.initialRules?.quantityItems}
              error={errors.includes(COUPON_RULE_QUANTITY)}
              form={form}
              deleteHandler={() => {
                removeSelectedRule(COUPON_RULE_QUANTITY);
              }}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

RulesForm.defaultProps = {
  rules: {},
  errors: [],
};

RulesForm.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  rules: PropTypes.instanceOf(Object),
  ruleFlags: PropTypes.instanceOf(Object).isRequired,
  setRuleFlags: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  couponType: PropTypes.string.isRequired,
};

export default RulesForm;
