import React from 'react';
import { notification } from 'antd';

const updateWeightPosition = (
  tableData,
  _setTableData,
  updateProductFromBlock
) => {
  tableData.forEach((item, index) => {
    updateProductFromBlock({
      variables: {
        input: {
          id: item.id,
          weightPosition: index,
        },
      },
    }).catch(errorUpt => {
      notification.error({
        message: `Erro ao atualizar a ordem dos produtos`,
        description: errorUpt.errors
          ? errorUpt.errors.map(obj => obj.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
    return false;
  });
};

export function handleSubmit(
  values,
  tableData,
  setTableData,
  {
    isEdit,
    blockProduct,
    setBlockProduct,
    createBlockProduct,
    updateBlockProduct,
    updateProductFromBlock,
    setRedirectToEdit,
  }
) {
  if (!isEdit) {
    createBlockProduct({
      variables: {
        input: {
          subtitle: values.subtitle,
          title: values.title,
          urls: values.urls,
          kind: 'product',
          published: false,
          weightPosition: 0,
        },
      },
    })
      .then(successUpt => {
        setBlockProduct(successUpt.data.homeBlocksCreateBlock);
        notification.success({
          message: `Bloco de produtos criado com sucesso`,
          description: (
            <div>
              O bloco de produtos{' '}
              <b>{successUpt.data.homeBlocksCreateBlock.title}</b> foi criado
              com sucesso!
            </div>
          ),
          placement: 'topRight',
        });
        setRedirectToEdit(true);
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao criar bloco de produtos`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  } else {
    updateBlockProduct({
      variables: {
        input: {
          id: blockProduct.id,
          subtitle: values.subtitle,
          title: values.title,
          kind: 'product',
        },
      },
    })
      .then(successUpt => {
        setBlockProduct(successUpt.data.homeBlocksUpdateBlock);

        if (tableData.length) {
          updateWeightPosition(tableData, setTableData, updateProductFromBlock);
        }

        notification.success({
          message: `Bloco de produtos atualizado`,
          description: (
            <div>O bloco de produtos foi atualizado com sucesso!</div>
          ),
          placement: 'topRight',
        });
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao atualizar o bloco de produtos`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  }
}

export const loadBlockProduct = ({ data, setBlockProduct, setLoading }) => {
  if (data) {
    const {
      homeBlocksSearchBlocks: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [BlockProduct] = nodes;
      setBlockProduct(prevState => {
        return {
          ...prevState,
          id: BlockProduct.id,
          subtitle: BlockProduct.subtitle,
          title: BlockProduct.title,
          urls: BlockProduct.urls,
        };
      });
    }
    setLoading(false);
  }
};
