import React from 'react';
import { Link } from 'react-router-dom';

import { PlusOutlined } from '@ant-design/icons';

import {
  Card,
  Table,
  Button,
  Tooltip,
  Alert,
  Modal,
  notification,
  Select,
  Row,
  Col,
} from 'antd';

import useSearchSeoContents from '~/hooks/content/useSearchSeoContents';
import useDeleteSeoContents from '~/hooks/content/useDeleteSeoContents';
import { getErrorMessage } from '~/util/Functions';
import { SEO_KIND_ALL, SEO_KIND_LIST } from '~/constants/Seo';
import { translateKind } from '~/util/Seo';
import usePaginationController from '~/hooks/utils/usePaginationController';
import { generateColumns } from './columns';

const { confirm } = Modal;
const { Option } = Select;

function SeoList() {
  const {
    current: pagination,
    onChange: onPaginationChange,
    reset: paginationReset,
    filters: paginationFilters,
  } = usePaginationController({
    initialPageSize: 10,
    initialPage: 1,
  });

  const {
    data: seoContents,
    loading: loadingSearch,
    error: errorSearch,
    totalCount,
  } = useSearchSeoContents({
    variables: {
      first: pagination?.pageSize,
      skip: pagination?.skip,
      kind: paginationFilters?.kind,
    },
  });

  const [deleteSeoContents, { loading: loadingDelete }] = useDeleteSeoContents({
    refetchQueries: ['useSearchSeoContents'],
    onCompleted: res => {
      const content = res?.seoSeoContentsDeleteSeoContent;

      notification.success({
        message: `SEO Excluído`,
        description: (
          <div>
            {content?.kind === 'category' ? 'Categoria' : 'Marca'}{' '}
            <b>
              {content?.contentName} (ID: {content?.contentId})
            </b>{' '}
            excluída com sucesso!
          </div>
        ),
        placement: 'topRight',
      });
    },
    onError: err => {
      notification.error({
        message: `Erro ao excluir SEO`,
        description: getErrorMessage(err),
        placement: 'topRight',
      });
    },
  });

  const handleDelete = id => {
    confirm({
      title: 'Você tem certeza que deseja excluir este item?',
      content: 'Ao excluir este item, ele será apagado permanentemente.',
      okText: 'Excluir',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        deleteSeoContents({ id });
      },
      onCancel() {
        // Cancel
      },
    });
  };

  const columns = generateColumns(handleDelete);

  const onShowSizeChange = (oldPage, newPageSize) => {
    onPaginationChange({
      pageSize: newPageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const onChangePage = (newPage, pageSize) => {
    onPaginationChange({
      page: newPage,
      pageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const handleChangeKindFilter = value => {
    paginationReset({
      filters: {
        kind: SEO_KIND_LIST.includes(value) ? value : undefined,
      },
    });
  };

  return (
    <Card
      data-testid="seoListItems"
      title="SEO"
      extra={
        <Row align="middle" gutter={[8, 0]}>
          <Col>Filtro por Tipo:</Col>
          <Col>
            <Select
              data-testid="selectKindFilter"
              onChange={handleChangeKindFilter}
              loading={loadingSearch}
              style={{ width: '150px' }}
              defaultValue={paginationFilters?.kind || SEO_KIND_ALL}
            >
              {[SEO_KIND_ALL, ...SEO_KIND_LIST].map(kindOption => (
                <Option key={kindOption} value={kindOption}>
                  {translateKind(kindOption)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Link to="/manage/content/seo/create">
              <Tooltip placement="bottom" title="Adicionar novo">
                <Button
                  data-testid="seoAddItemBtn"
                  type="primary"
                  icon={<PlusOutlined />}
                  size="default"
                  style={{ marginBottom: '0px' }}
                >
                  Adicionar
                </Button>
              </Tooltip>
            </Link>
          </Col>
        </Row>
      }
    >
      {errorSearch ? (
        <Alert message={getErrorMessage(errorSearch)} type="error" showIcon />
      ) : (
        <Table
          data-testid="seoItemList"
          rowKey={record => record.id}
          dataSource={seoContents}
          columns={columns}
          bordered
          loading={loadingSearch || loadingDelete}
          title={() => `Total encontrado - ${totalCount}`}
          scroll={{
            x: 'max-content',
          }}
          pagination={{
            current: pagination?.page,
            total: totalCount,
            showSizeChanger: true,
            onShowSizeChange,
            onChange: onChangePage,
            // Tamanho padrão 10, mostra outros tamanho dependendo do total de itens
            pageSize: pagination?.pageSize,
            pageSizeOptions: ['10'].concat(
              ['20', '30', '40', '50'].filter(size => {
                // Como a diferença de tamanho de página é de 10 em 10
                // É subtraido 10 do 'size', para que quando o 'totalCount'
                // seja 11 por exemplo, ele mostre a opção '20'
                return size - 10 <= totalCount;
              })
            ),
            position: 'both',
            locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
          }}
        />
      )}
    </Card>
  );
}

export default SeoList;
