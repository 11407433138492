import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SEO_CONTENTS_FRAGMENT } from './useSearchSeoContents';

export const MUTATION_DELETE_SEO_CONTENTS = gql`
  mutation useDeleteSeoContents($id: Int!) {
    seoSeoContentsDeleteSeoContent(input: { id: $id }) {
      ...SeoContentFragment
    }
  }
  ${SEO_CONTENTS_FRAGMENT}
`;

function useDeleteSeoContents(options = {}) {
  const [deleteSeo, { data, loading, error }] = useMutation(
    MUTATION_DELETE_SEO_CONTENTS,
    {
      ...options,
    }
  );

  const deleteSeoContent = useCallback(
    ({ id = undefined }) => {
      deleteSeo({
        variables: {
          id,
        },
      });
    },
    [deleteSeo]
  );

  return [
    deleteSeoContent,
    {
      loading,
      data: data?.seoSeoContentsDeleteSeoContent || null,
      error,
    },
  ];
}

export default useDeleteSeoContents;
