import { css } from 'styled-components';
import { darken } from 'polished';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, button } = THEME_BEHAVIOR;

const defColor = button.default.color ? button.default.color : theme.color;
const susColor = button.success.bgColor ? button.success.bgColor : theme.color;

export const AntButtons = css`
  body {
    .ant-btn {
      border-radius: ${theme.borderRadius};
      height: ${button.height};
      transition: all 0.3s;
      box-shadow: none;

      &:hover,
      &:focus {
        color: ${theme.color};
        background: #fff;
        border-color: ${theme.color};
      }

      &-link {
        color: ${theme.color};
      }

      &-icon-only {
        border-radius: ${theme.borderRadius};
        height: ${button.height};
        width: ${button.height};
        color: ${button.primary.bgColor};
        &:hover,
        &:focus,
        &:visited {
          background: transparent !important;
          border-color: transparent;
          color: ${button.primary.bgColor};
        }
      }

      &-default {
        background: ${button.default.bgColor} !important;
        color: ${defColor} !important;
        border-color: ${defColor} !important;
        &:hover {
          background: ${darken(0.02, button.default.bgColor)} !important;
        }
      }

      &-success {
        background: ${susColor} !important;
        color: ${button.success.color} !important;
        border-color: ${susColor} !important;
        &:hover {
          background: ${darken(0.1, susColor)} !important;
        }
        .anticon {
          svg {
            fill: ${button.success.color};
          }
        }
      }

      &-primary {
        background: ${button.primary.bgColor} !important;
        color: ${button.primary.color} !important;
        border-color: ${button.primary.color} !important;
        &:hover {
          background: ${darken(0.1, button.primary.bgColor)} !important;
        }
        .anticon {
          svg {
            fill: ${button.success.color};
          }
        }
      }

      &-danger {
        background: ${button.danger.bgColor} !important;
        color: ${button.danger.color} !important;
        border-color: ${button.danger.color} !important;
        &:hover {
          background: ${darken(0.1, button.danger.bgColor)} !important;
        }
        .anticon {
          svg {
            fill: ${button.success.color};
          }
        }
      }

      &-dangerous {
        &:not(.ant-btn-icon-only) {
          background: ${button.dangerous.bgColor} !important;
          color: ${button.dangerous.color} !important;
          border-color: ${button.dangerous.color} !important;
          &:hover {
            background: ${button.dangerous.color} !important;
            color: ${button.dangerous.bgColor} !important;
            border-color: ${button.dangerous.color} !important;
          }
        }
      }

      &[disabled] {
        &:not(.ant-btn-icon-only) {
          background: ${button.disabled.bgColor} !important;
          color: ${button.disabled.color} !important;
          border-color: ${button.disabled.bgColor} !important;
          opacity: 0.7;
          &:hover {
            background: ${darken(0.1, button.disabled.bgColor)} !important;
          }
          .anticon {
            svg {
              fill: ${button.disabled.color};
            }
          }
        }

        &.ant-btn-icon-only {
          border: 0px;
          color: ${button.disabled.color} !important;
          opacity: 0.5;
        }
      }
    }
  }
`;
