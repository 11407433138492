import React from 'react';
import { Tooltip, Tag } from 'antd';
import { EditOutlined, DeleteOutlined, ReadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import DragHandle from '~/components/DragSortTable/DragHandle';
import Button from '~/components/Button';
import SetIcon from '~/components/SetIcon';
import { IconColumn } from './styles';

export function generateColumns(handleDelete) {
  return [
    {
      title: '#',
      dataIndex: 'drag',
      key: 'drag',
      render: () => <DragHandle />,
    },
    {
      title: 'Ícone',
      dataIndex: 'icon',
      key: 'icon',
      width: 100,
      render: text => (
        <IconColumn>
          <SetIcon name={text} />
          <Tag>{text}</Tag>
        </IconColumn>
      ),
    },
    {
      title: 'Grupo',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Nº de perguntas',
      dataIndex: 'faqQuestions',
      key: 'faqQuestions',
      render: (text, record) => (
        <div style={{ minWidth: '120px' }}>{record.faqQuestions.length}</div>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
          <Tooltip placement="bottom" title="Editar">
            <Link to={`/manage/content/faq/category/edit/${record.id}`}>
              <Button
                data-testid="editBtn"
                type="link"
                icon={<EditOutlined />}
                style={{ margin: 0 }}
                size="small"
              />
            </Link>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={
              record.faqQuestions.length > 0
                ? 'Exclua as perguntas primeiro'
                : 'Excluir'
            }
          >
            <Button
              data-testid="deleteBtn"
              type="link"
              icon={<DeleteOutlined />}
              disabled={record.faqQuestions.length > 0}
              size="small"
              onClick={() => {
                handleDelete(record.id);
              }}
              style={{ margin: 0, color: 'red' }}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Pergunta">
            <Link
              to={{
                pathname: `/manage/content/faq/category/${record.id}/question`,
                state: { subjectName: text },
              }}
            >
              <Button
                data-testid="questionBtn"
                type="link"
                icon={<ReadOutlined />}
                style={{ marginBottom: 0 }}
                size="small"
              />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];
}
