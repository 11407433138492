import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col, Form, Typography, Divider, Alert } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import CKEditor, { ClassicEditor } from 'ckeditor4-react';
import Button from '~/components/Button';
import { notifyError } from '~/util/Functions';
import { CKEDITOR_CONFIG } from '~/constants/CKeditorConfig';
import { SEO_KIND_CATEGORY, SEO_KIND_LIST } from '~/constants/Seo';
import KindSelector from './KindSelector';
import ImagesForm from './ImagesForm';
import {
  MAIN_CONTENT_NAME,
  MAIN_TITLE_NAME,
  META_DESCRIPTION_NAME,
  META_KEYWORDS_NAME,
  META_TITLE_NAME,
  SECONDARY_TITLE_NAME,
  getFieldLabelByName,
} from './formLabels';

const { Title } = Typography;

const DefaultForm = ({ handleSubmit, initialData, isLoading }) => {
  const [form] = Form.useForm();
  const [hasNewUpload, setHasNewUpload] = useState(false);

  const onFinish = values => {
    handleSubmit(values);
    setHasNewUpload(false);
  };

  const onFinishFailed = ({ errorFields }) => {
    notifyError(
      'Erro ao criar SEO',
      <span>
        Verifique os dados preenchidos no formulário. Campos:{' '}
        <b>
          {errorFields
            ?.reduce((acc, currentError) => {
              acc.push(getFieldLabelByName(currentError?.name?.[0]));
              return acc;
            }, [])
            .join(', ')}
        </b>
      </span>
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="addSeoForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialData}
    >
      <KindSelector initialData={initialData} form={form} />

      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Informações Básicas
          </Title>
          <Divider style={{ marginTop: '5px' }} />
        </Col>
      </Row>

      <Form.Item
        label={getFieldLabelByName(MAIN_TITLE_NAME)}
        name={MAIN_TITLE_NAME}
        rules={[
          {
            required: true,
            message: 'Digite o título',
            whitespace: true,
          },
        ]}
      >
        <Input data-testid="addSeoMainTitle" />
      </Form.Item>

      <Form.Item
        label={getFieldLabelByName(SECONDARY_TITLE_NAME)}
        name={SECONDARY_TITLE_NAME}
        rules={[
          {
            message: 'Digite o texto alternativo',
          },
        ]}
      >
        <Input data-testid="addSeoSecondaryTitle" />
      </Form.Item>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            data-testid="seoTextEditor"
            label={getFieldLabelByName(MAIN_CONTENT_NAME)}
            name={MAIN_CONTENT_NAME}
            valuePropName="data"
            getValueFromEvent={event => event.editor.getData()}
          >
            <CKEditor editor={ClassicEditor} config={CKEDITOR_CONFIG} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Metatags
          </Title>
          <Divider style={{ marginTop: '5px' }} />
        </Col>
      </Row>

      <Form.Item
        label={getFieldLabelByName(META_TITLE_NAME)}
        name={META_TITLE_NAME}
        rules={[
          {
            message: 'Digite o título',
            whitespace: true,
          },
        ]}
      >
        <Input data-testid="addSeoSecondaryTitle" />
      </Form.Item>

      <Form.Item
        label={getFieldLabelByName(META_DESCRIPTION_NAME)}
        name={META_DESCRIPTION_NAME}
        rules={[
          {
            message: 'Digite a descrição',
            whitespace: true,
          },
        ]}
      >
        <Input data-testid="addSeoSecondaryTitle" />
      </Form.Item>

      <Form.Item
        label={`${getFieldLabelByName(
          META_KEYWORDS_NAME
        )} (separadas por vírgula)`}
        name={META_KEYWORDS_NAME}
        rules={[
          {
            message: 'Digite as palavras-chave',
            whitespace: true,
          },
        ]}
      >
        <Input data-testid="addSeoSecondaryTitle" />
      </Form.Item>

      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Imagens
          </Title>
          <Divider style={{ marginTop: '5px' }} />
        </Col>
      </Row>

      <ImagesForm
        form={form}
        logo={initialData?.logo}
        image={initialData?.image}
        uploadCallback={() => {
          setHasNewUpload(true);
        }}
      />

      {hasNewUpload && (
        <Row
          gutter={[0, 16]}
          type="flex"
          align="top"
          justify="center"
          style={{ marginBottom: '15px' }}
        >
          <Col span={24}>
            <Alert
              message="Uma nova imagem foi adicionada, clique em 'Salvar' no formulário para confirmar a atualização"
              description="Caso as alterações não reflitam na na loja, talvez seja necessário limpar o cache do navegador"
              type="info"
              showIcon
            />
          </Col>
        </Row>
      )}

      <Row type="flex" justify="end" align="middle">
        <Col>
          <Form.Item>
            <Button
              data-testid="addSeoSubmitBtn"
              type="success"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={isLoading}
            >
              Salvar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

DefaultForm.defaultProps = {
  initialData: {
    kind: SEO_KIND_CATEGORY,
  },
};

DefaultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    mainTitle: PropTypes.string,
    mainContent: PropTypes.string,
    secondaryTitle: PropTypes.string,
    image: PropTypes.string,
    logo: PropTypes.string,
    contentId: PropTypes.number,
    contentName: PropTypes.string,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    metaTitle: PropTypes.string,
    kind: PropTypes.oneOf(SEO_KIND_LIST),
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default DefaultForm;
