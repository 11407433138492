import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const keyFrame = percent => keyframes`
  0% {width: 0%;}
  100% {width: ${percent}%;}
`;

const Background = styled.div`
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
  margin: 0;
  padding: 0;
`;

const Label = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
`;

const Percent = styled.div`
  width: ${props => props.value}%;
  height: 5px;
  background-color: ${props => props.color};
  margin: 0;
  padding: 0;
  animation: ${props => keyFrame(props.value)} 2s;
`;

const PercentOff = styled.div`
  width: ${props => props.value}%;
  height: 5px;
  background-color: ${props => props.color};
  margin: 0;
  padding: 0;
`;

function LoadingBar({ percent, animation, color, label, showPercentLabel }) {
  return (
    <>
      {label && (
        <Label>
          {label} {showPercentLabel ? `(${percent}%)` : null}
        </Label>
      )}
      <Background>
        {animation ? (
          <Percent value={percent} color={color} />
        ) : (
          <PercentOff
            data-testid="loading-bar-percent-off"
            value={percent}
            color={color}
          />
        )}
      </Background>
    </>
  );
}

LoadingBar.propTypes = {
  percent: PropTypes.number,
  animation: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  showPercentLabel: PropTypes.bool,
};

LoadingBar.defaultProps = {
  percent: 98,
  animation: true,
  showPercentLabel: false,
  color: 'rgb(58, 203, 199)',
  label: undefined,
};

export default LoadingBar;
