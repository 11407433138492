import React from 'react';
import { Row, Col, Empty } from 'antd';

function Forbidden() {
  return (
    <div style={{ height: '100%' }} data-testid="notFound">
      <Row
        gutter={16}
        style={{ height: '100%' }}
        type="flex"
        justify="center"
        align="middle"
      >
        <Col>
          <Empty
            style={{ width: '200px' }}
            image={`${process.env.PUBLIC_URL}/images/icons/protection.svg`}
            description="Acesso Negado!"
          />
        </Col>
      </Row>
    </div>
  );
}

export default Forbidden;
