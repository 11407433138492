import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-top: 15px;
  margin-bottom: 14px;
`;

export const CardID = styled.div`
  text-align: center;
  font-size: 11px;
  color: #aaaaaa;
`;

export const CardDescription = styled.div`
  font-size: 11px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
