import React, { useEffect, useMemo } from 'react';
import { Card, Tooltip, Row, Col, Tabs, Alert, Space } from 'antd';
import {
  ArrowLeftOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import AddressForm from '~/pages/service/user/components/AddressForm';
import Button from '~/components/Button';
import LoadingBar from '~/components/LoadingBar';
import InfoCard from '~/components/InfoCard';
import BankForm from '~/pages/service/user/components/BankForm';

import { handleBlockUser } from '~/pages/service/user/actions';
import useUpdateStatusUser from '~/hooks/user/useUpdateStatusUser';
import { useListUserByDatabase } from '~/hooks/user/useListUserByDatabase';
import { getErrorMessage, notifyError, notifySuccess } from '~/util/Functions';
import useTabController from '~/hooks/utils/useTabController';

import {
  USER_KIND_PARTNER,
  USER_STATUS_ACTIVE,
  USER_TAB_DATA,
} from '~/constants/Users';
import BasicForm from './BasicForm';

function PartnerForm({ match }) {
  const userId = useMemo(() => {
    return Number(match?.params?.id) || null;
  }, [match]);

  const { currentTab, onChange: onChangeTab } = useTabController({
    tabKeys: [USER_TAB_DATA],
  });

  const [queryUserById, { users, loading, error }] = useListUserByDatabase();

  const [updateStatusUser, { loading: loadingBlock }] = useUpdateStatusUser({
    onCompleted: data => {
      notifySuccess(
        'Status do cliente atualizado',
        `Cliente ${
          data?.userUpdateStatusUser?.status === USER_STATUS_ACTIVE
            ? 'desbloqueado'
            : 'bloqueado'
        } com sucesso`
      );
    },
    onError: err => {
      notifyError('Erro ao atualizar status do cliente', err);
    },
  });

  useEffect(() => {
    if (userId) {
      queryUserById({
        kind: USER_KIND_PARTNER,
        first: 1,
        skip: 0,
        ids: [userId],
        address: true,
        bank: true,
        full: true,
      });
    }
  }, [userId]);

  const { user, renderBankForm } = useMemo(() => {
    return {
      user: users?.[0],
      renderBankForm: users?.[0]?.addresses?.[0]?.id,
    };
  }, [users]);

  const callBlock = () => {
    if (userId) {
      handleBlockUser({
        onOk: () => updateStatusUser({ userId }),
        currentStatus: user?.status,
        userKind: USER_KIND_PARTNER,
      });
    }
  };

  if (userId && loading) return <LoadingBar />;

  const tabItems = [
    {
      label: 'Dados',
      key: USER_TAB_DATA,
      children: (
        <>
          <Space direction="vertical" size={32} style={{ width: '100%' }}>
            {!!userId && (
              <InfoCard
                itemsList={[
                  {
                    id: 1,
                    title: 'Sacolas identificadas',
                    value: user?.identifiedBags,
                  },
                  {
                    id: 3,
                    title: 'Quantidade de peças',
                    value: user?.totalProducts,
                  },
                  {
                    id: 4,
                    title: 'Quantidade de vales-compra ',
                    value: user?.giftCardsTotal,
                  },
                  {
                    id: 5,
                    title: 'Valor em vales-compra ',
                    value: user?.giftCardsTotalValue,
                  },
                  {
                    id: 6,
                    title: 'Criado em',
                    value: user?.createdAt
                      ? moment(user.createdAt).format('DD/MM/YYYY')
                      : undefined,
                  },
                  {
                    id: 7,
                    title: 'Último login',
                    value: user?.lastLogin
                      ? moment(user.lastLogin).format('DD/MM/YYYY')
                      : undefined,
                  },
                ]}
              />
            )}
            <BasicForm
              initialData={user || {}}
              isEdit={!!userId}
              userKind={USER_KIND_PARTNER}
              userId={userId}
            />
          </Space>

          {!!userId && (
            <>
              <AddressForm
                initialData={user?.addresses?.[0] || undefined}
                isEdit={!!user?.addresses?.[0]?.id}
                userKind={USER_KIND_PARTNER}
                userId={userId}
              />
              {renderBankForm ? (
                <BankForm
                  initialData={user?.bankingAccount || undefined}
                  isEdit={!!user?.bankingAccount?.id}
                  userKind={USER_KIND_PARTNER}
                  userId={userId}
                />
              ) : (
                <Row
                  gutter={16}
                  type="flex"
                  align="top"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <Col span={24}>
                    <Alert
                      message="Preencha as informações de endereço para liberar o formulário de dados bancários"
                      banner
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <Card
      data-testid="partnerCardForm"
      title={
        <Row gutter={16} type="flex" align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Tooltip placement="bottom" title="Voltar para parceiros">
              <Link to="/manage/service/partner">
                <ArrowLeftOutlined data-testid="backToListBtn" />
              </Link>
            </Tooltip>
            <span style={{ marginLeft: '10px' }}>
              {userId ? 'EDITAR PARCEIRO' : 'ADICIONAR PARCEIRO'}
            </span>
          </Col>
          <Col md={12} xs={24}>
            <Row type="flex" justify="end" align="middle">
              {user ? (
                <Button
                  data-testid="blockUserBtn"
                  loading={loadingBlock}
                  type={
                    user.status === USER_STATUS_ACTIVE ? 'danger' : 'primary'
                  }
                  icon={
                    user.status === USER_STATUS_ACTIVE ? (
                      <LockOutlined />
                    ) : (
                      <UnlockOutlined />
                    )
                  }
                  onClick={callBlock}
                  style={{ margin: 0 }}
                >
                  {user.status === USER_STATUS_ACTIVE
                    ? 'Bloquear'
                    : 'Desbloquear'}
                </Button>
              ) : null}
            </Row>
          </Col>
        </Row>
      }
    >
      {error ? (
        <Alert message={getErrorMessage(error)} type="error" showIcon />
      ) : (
        <Tabs
          type="card"
          activeKey={currentTab}
          defaultActiveKey={USER_TAB_DATA}
          onChange={onChangeTab}
          data-testid="tabs"
          items={tabItems}
        />
      )}
    </Card>
  );
}

PartnerForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default PartnerForm;
