import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export function generateColumns() {
  const data = [
    {
      title: 'Produto',
      dataIndex: 'itemable',
      key: 'itemable',
      render: (_, { itemable, itemId }) => (
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://repassa.com.br/produtos/${itemable?.slug}`}
          >
            {itemable?.name}
          </a>
          <br />
          <small>
            <Text type="secondary"># {itemId}</Text> <br />
            <Text type="secondary">Preço: R$ {itemable?.price}</Text>
          </small>
        </>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, { id }) => <Text># {id}</Text>,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, { total }) => <Text>R$ {total}</Text>,
    },
    {
      title: 'Total de ajustes',
      dataIndex: 'adjustmentTotal',
      key: 'adjustmentTotal',
      render: (_, { adjustmentTotal }) => <Text>R$ {adjustmentTotal}</Text>,
    },
  ];

  return data;
}
