import gql from 'graphql-tag';

export const BRANDS_LIST = gql`
  query listBrands(
    $name: String
    $skip: Int
    $first: Int
    $orderBy: BrandOrder
  ) {
    productsProductSearchBrands(
      name: $name
      skip: $skip
      first: $first
      orderBy: $orderBy
    ) {
      nodes {
        active
        id
        name
        productCount
        seoDescription
        seoKeywords
        seoTitle
        slug
      }
      totalCount
    }
  }
`;

export const BRANDS_LIST_BY_IDS = gql`
  query listBrandsByIds($skip: Int!, $ids: [Int!]) {
    productsProductSearchBrands(skip: $skip, ids: $ids) {
      nodes {
        active
        id
        name
        productCount
        seoDescription
        seoKeywords
        seoTitle
        slug
      }
      totalCount
    }
  }
`;

export const BRAND_BY_ID = gql`
  query listBrands($id: Int) {
    productsProductSearchBrands(id: $id) {
      nodes {
        active
        id
        name
        productCount
        seoDescription
        seoKeywords
        seoTitle
        slug
      }
      totalCount
    }
  }
`;

export const BRAND_CREATE = gql`
  mutation createBrand($input: CreateBrandInput!) {
    brandsProductCreateBrand(input: $input) {
      id
      active
      name
      seoDescription
      seoKeywords
      seoTitle
    }
  }
`;

export const BRAND_UPDATE = gql`
  mutation updateBrand($input: UpdateBrandInput!) {
    brandsProductUpdateBrand(input: $input) {
      id
      active
      name
      seoDescription
      seoKeywords
      seoTitle
    }
  }
`;

export const BRAND_DELETE = gql`
  mutation deleteBrand($input: DeleteBrandInput!) {
    brandsProductDeleteBrand(input: $input) {
      id
      active
      name
      seoDescription
      seoKeywords
      seoTitle
    }
  }
`;
