import styled, { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, button } = THEME_BEHAVIOR;

const currentStatusModifiers = {
  canceled: () => css`
    color: ${button.danger.bgColor};
  `,
  started: () => css`
    color: ${theme.color};
  `,
  pending: () => css`
    color: #be9400;
  `,
};

export const CurrentStatus = styled.span`
  ${({ status }) => css`
    ${!!status && currentStatusModifiers[status]}
  `}
`;
