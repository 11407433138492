import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { AutoComplete, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from '../../../../../components/Button';
import { handleAddField } from '../actions';

import { OPTIONTYPES_PROPERTIES_LIST } from '../../../../../apollo/bag/product/product';

function CategorySearch({ optionTypes, properties, setCategory, form }) {
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState({});

  const [searchOptionTypes] = useLazyQuery(OPTIONTYPES_PROPERTIES_LIST, {
    context: {
      newBackend: true,
    },
    onCompleted: ({
      propertyProductSearchProperties: { nodes: dataProperties },
      optionTypesProductSearchOptionTypes: { nodes: dataOptionTypes },
    }) => {
      const items = [];

      dataOptionTypes.forEach(element => {
        items.push({
          ...element,
          key: element.id,
          text: element.name,
          value: element.name,
          type: 'optionType',
        });
      });

      dataProperties.forEach(element => {
        items.push({
          ...element,
          key: element.id,
          text: element.name,
          value: element.name,
          type: 'property',
        });
      });

      setOptions(items);
    },
    onError: () => {
      setOptions([]);
    },
  });

  const onSearch = text => {
    searchOptionTypes({
      variables: {
        name: text,
        first: 5,
      },
    });
  };

  const onSelect = id => {
    const selected = options.filter(item => item.key === id);

    if (selected.length > 0) {
      selected.forEach(item => {
        if (
          ![...optionTypes, ...properties].some(
            dataItem => dataItem.id === item.id
          )
        )
          setSelectedItem(item);
      });
    }
  };

  const callAddField = e => {
    handleAddField(e, {
      setSearchText,
      selectedItem,
      setCategory,
      optionTypes,
      properties,
      form,
    });
  };

  const handleSearchChange = event => {
    setSearchText(event);
  };

  return (
    <Row gutter={16} type="flex" align="top" justify="center">
      <Col xl={21} lg={19} md={19} xs={24}>
        <AutoComplete
          style={{ width: '100%', marginBottom: '10px' }}
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Busque uma categoria"
          data-testid="seach"
        />
      </Col>
      <Col xl={3} lg={5} md={5} xs={24}>
        <Button
          style={{ marginBottom: 0 }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={callAddField}
          data-testid="addOptionBtn"
        >
          Adicionar
        </Button>
      </Col>
    </Row>
  );
}

CategorySearch.propTypes = {
  optionTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCategory: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

export default CategorySearch;
