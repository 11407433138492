import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { USER_ADDRESS_KIND_SHIPPING } from '~/constants/Users';

export const CREATE_ADDRESS = gql`
  mutation useUserCreateAddress($input: CreateAddressInput!) {
    userCreateAddress(input: $input) {
      id
      address1
      address2
      city
      kind
      lat
      lng
      neighborhood
      number
      state {
        id
        name
        country
        code
        abbr
      }
      userId
      zipcode
    }
  }
`;

function useUserCreateAddress(options = {}) {
  const [createAddress, { loading, data, error }] = useMutation(
    CREATE_ADDRESS,
    {
      refetchQueries: ['useListUserByDatabase'],
      ...options,
    }
  );

  return [
    ({
      street,
      complement = undefined,
      zipcode,
      city,
      neighborhood,
      number,
      stateCode,
      userId,
      kind = USER_ADDRESS_KIND_SHIPPING,
    } = {}) => {
      const zipCodeOnlyNumbers = zipcode.replace(/[^\d]+/gi, '');

      createAddress({
        variables: {
          input: {
            address1: street,
            address2: complement,
            city,
            kind,
            neighborhood,
            number,
            stateCode,
            userId,
            zipcode: zipCodeOnlyNumbers,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUserCreateAddress;
