// Funções génericas para o formulário do Ant Design v4
import { isValidCPF, validateCNPJ } from '~/util/Functions';
import {
  passwordRegex,
  validationFormatMessages,
  passwordRegexSequence,
} from '~/constants/GeneralForms';

export const validatorCpf = (form, { successMessage, errorMessage }) => {
  return (_rule, value) => {
    if (!isValidCPF(value)) {
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.resolve(successMessage);
  };
};

export const validatorCnpj = (form, { successMessage, errorMessage }) => {
  return (_rule, value) => {
    if (!validateCNPJ(value)) {
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.resolve(successMessage);
  };
};

export const validatorCompareToFirst = (
  form,
  { field, successMessage, errorMessage }
) => {
  return (_rule, value) => {
    if (value && value !== form.getFieldValue(field)) {
      return Promise.reject(new Error(errorMessage));
    }
    return Promise.resolve(successMessage);
  };
};

export const formatDataToStructureTree = data => {
  const setDefaultTreeObject = category => ({
    id: category.id,
    title: category.name,
    key: category.id,
  });

  const sortedCategories = data.sort((prev, next) => prev.id - next.id);

  const hasNoValue = -1;

  const categories = sortedCategories
    .filter(category => !category?.parentId)
    .map(setDefaultTreeObject);

  sortedCategories.forEach(category => {
    if (!category?.parentId) return;

    const categoryIndex = categories.findIndex(
      item => item.id === category.parentId
    );

    if (categoryIndex !== hasNoValue) {
      const currentCategory = categories[categoryIndex];

      categories[categoryIndex] = {
        ...currentCategory,
        children: [
          ...(currentCategory.children || []),
          setDefaultTreeObject(category),
        ],
      };
    } else {
      const subParent = sortedCategories.find(
        item => item.id === category.parentId
      );
      const categoryParentIndex = categories.findIndex(
        item => item.id === subParent.parentId
      );
      const subParentIndex = categories[categoryParentIndex].children.findIndex(
        item => item.id === subParent.id
      );

      const currentCategory =
        categories[categoryParentIndex].children[subParentIndex];

      categories[categoryParentIndex].children[subParentIndex] = {
        ...currentCategory,
        children: [
          ...(currentCategory.children || []),
          setDefaultTreeObject(category),
        ],
      };
    }
  });

  return categories;
};

export const passwordValidator = (rule, value, form, confirmationFieldName) => {
  if (value.length === 0) {
    form.setFields([
      {
        name: confirmationFieldName,
        value: '',
        errors: ['Confirme sua senha.'],
      },
    ]);
  }

  if (value.length === 1) {
    form.setFields([
      { name: confirmationFieldName, errors: ['Confirme sua senha.'] },
    ]);
  }

  if (!passwordRegex.test(value)) {
    return Promise.reject(validationFormatMessages.passwordFormatIncorret);
  }
  if (passwordRegexSequence.test(value)) {
    return Promise.reject(validationFormatMessages.passwordSequence);
  }

  return Promise.resolve();
};

export const emailValidator = (rule, value, form, confirmationFieldName) => {
  if (!value) return Promise.reject(new Error('E-mail inválido'));
  if (value.length === 0) {
    form.setFields([
      {
        name: confirmationFieldName,
        value: '',
        errors: ['Confirme sua email.'],
      },
    ]);
  } else if (value.length === 1) {
    form.setFields([
      { name: confirmationFieldName, errors: ['Confirme sua email.'] },
    ]);
  }
  return Promise.resolve();
};
