import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Row, Col, Card, notification, Form } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Search from './Search';
import DragSortTable from '~/components/DragSortTable';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import DataChangeAlert from './DataChangeAlert';

import { handleDelete } from './actions';
import { generateColumns } from './columns';
import {
  MUTATION_UPSERT_FOOTER_BRANDS,
  QUERY_FOOTER_BRANDS_CONTENTS,
} from '~/apollo/content/footer';
import LoadingBar from '~/components/LoadingBar';
import Button from '~/components/Button';

function BrandCard() {
  const { tableData, setTableData } = useDragSortTableContext();
  const [dataChangedTimes, setDataChangedTimes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const deleteFooter = id => {
    setTableData(tableData.filter(item => item.id !== id));
  };

  const [upsertFooter] = useMutation(MUTATION_UPSERT_FOOTER_BRANDS, {
    context: {
      newBackend: true,
    },
    refetchQueries: [
      {
        query: QUERY_FOOTER_BRANDS_CONTENTS,
        context: {
          newBackend: true,
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: ({ contentDynamicContentsUpsertFooterContents: contents }) => {
      setTableData(contents);
      notification.success({
        message: `Seção de Marcas do Footer salva com sucesso`,
        placement: 'topRight',
      });
    },
    onError: err => {
      notification.error({
        message: `Erro ao adicionar a(s) marca(s)`,
        description: err.errors
          ? err.errors.map(e => e.message).join(';')
          : err.message,
        placement: 'topRight',
      });
    },
  });

  const submitBrandsFooter = () => {
    upsertFooter({
      variables: {
        input: {
          contentIds: tableData.map(item => item.dynamicContentable.id),
          kind: 'footer_brands',
        },
      },
    });
  };

  const [getBrands] = useLazyQuery(QUERY_FOOTER_BRANDS_CONTENTS, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({
      contentDynamicContentsSearchFooterContents: { nodes: dataBrands },
    }) => {
      setTableData(dataBrands);
      setLoading(false);
    },
    onError: errorSearch => {
      setError(errorSearch);
      setLoading(false);
    },
  });

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingBar />;
  if (error) return `Error! ${error.message}`;

  const columns = generateColumns(handleDelete, deleteFooter);

  return (
    <Card data-testid="footerBrandCard" title="Footer - Marcas">
      <Form
        onFinish={e => {
          submitBrandsFooter(e);
        }}
      >
        <Row gutter={16}>
          <Col xl={18} lg={16} md={16} sm={24} xs={24}>
            <Search />
          </Col>
          <Col xl={6} lg={8} md={8} sm={24} xs={24}>
            <Button
              data-testid="footerSubmitBrandFooterBtn"
              onClick={() => setDataChangedTimes(0)}
              type="success"
              block
              htmlType="submit"
              icon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </Col>
        </Row>

        <DataChangeAlert
          dataChangedTimes={dataChangedTimes}
          setDataChangedTimes={setDataChangedTimes}
        />

        <DragSortTable
          data-testid="footerBrandTable"
          data={tableData}
          columns={columns}
          rowKey={record => record.id}
          pagination={false}
          bordered
          style={{ marginTop: 10 }}
        />
      </Form>
    </Card>
  );
}

export default BrandCard;
