import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Select, Divider } from 'antd';

import Category from './Category';
import Filter from './Filter';
import Price from './Price';
import Time from './Time';
import TableCategory from './Category/TableCategory';
import TableFilter from './Filter/TableFilter';
import TablePrice from './Price/TablePrice';
import TableTime from './Time/TableTime';
import TableColor from './Color/TableColor';
import {
  TABLE_TYPE_CATEGORY,
  TABLE_TYPE_FILTER_OPTION_TYPES,
  TABLE_TYPE_FILTER_BRAND,
  TABLE_TYPE_FILTER_ONG_PERCENTAGE,
  TABLE_TYPE_FILTER_DISCOUNT,
  TABLE_TYPE_PRICE,
  TABLE_TYPE_TIME,
  TABLE_TYPE_COLOR,
  TABLE_TYPE_CONDITION,
} from '../RulesReducer/constants';

const { Option } = Select;

function RulesPromotion({
  form,
  promotion,
  tables,
  dispatch,
  addColor,
  setAddColor,
  isDisabled,
}) {
  const [selectedTypeRule, setSelectedTypeRule] = useState();

  const handleChangeRules = value => {
    setSelectedTypeRule(value);
    form.setFieldsValue({
      ruleType: value,
    });
  };

  const renderRules = () => {
    switch (selectedTypeRule) {
      case 'Category':
        return (
          <Category
            form={form}
            promotion={promotion}
            dispatch={dispatch}
            tables={tables}
            setAddColor={setAddColor}
            addColor={addColor}
            selectedTypeRule={selectedTypeRule}
          />
        );
      case 'Filter':
        return (
          <Filter
            form={form}
            promotion={promotion}
            dispatch={dispatch}
            tables={tables}
          />
        );
      case 'Price':
        return (
          <Price
            form={form}
            promotion={promotion}
            dispatch={dispatch}
            tables={tables}
          />
        );
      case 'Time':
        return <Time form={form} dispatch={dispatch} tables={tables} />;
      default:
        return <></>;
    }
  };

  const renderTables = () => {
    return tables.map(table => {
      if (table.type === TABLE_TYPE_CATEGORY) {
        return (
          <Col span={24} key={table.key}>
            <TableCategory table={table} />
          </Col>
        );
      }
      if (
        table.type === TABLE_TYPE_FILTER_OPTION_TYPES ||
        table.type === TABLE_TYPE_FILTER_BRAND ||
        table.type === TABLE_TYPE_FILTER_DISCOUNT ||
        table.type === TABLE_TYPE_FILTER_ONG_PERCENTAGE ||
        table.type === TABLE_TYPE_CONDITION
      ) {
        return (
          <Col span={24} key={table.key}>
            <TableFilter table={table} />
          </Col>
        );
      }
      if (table.type === TABLE_TYPE_PRICE) {
        return (
          <Col span={24} key={table.key}>
            <TablePrice table={table} />
          </Col>
        );
      }
      if (table.type === TABLE_TYPE_COLOR) {
        return (
          <Col span={24} key={table.key}>
            <TableColor table={table} />
          </Col>
        );
      }
      if (table.type === TABLE_TYPE_TIME) {
        return (
          <Col span={24} key={table.key}>
            <TableTime table={table} />
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Divider orientation="left">Regras</Divider>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Tipo de regra"
            name="ruleType"
            initialValue={promotion.ruleType}
          >
            <Select
              data-testid="ruleType"
              style={{ width: '100%' }}
              onChange={handleChangeRules}
              disabled={isDisabled}
            >
              <Option value="Category">Categoria</Option>
              <Option value="Filter">Filtros</Option>
              <Option value="Price">Preço</Option>
              <Option value="Time">Tempo de cadastro</Option>
            </Select>
          </Form.Item>
        </Col>
        {renderRules()}
        {renderTables()}
      </Row>
    </>
  );
}

RulesPromotion.propTypes = {
  form: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promotion: PropTypes.shape({
    ruleType: PropTypes.string,
    genre: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
        key: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            key: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  addColor: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    record: PropTypes.shape().isRequired,
    tableKey: PropTypes.string,
  }).isRequired,
  setAddColor: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default RulesPromotion;
