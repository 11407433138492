/* eslint-disable no-use-before-define */
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { CART_FRAGMENT } from './useFindCart';

function useRemoveProductFromCart(options = {}) {
  const [cartRemoveProduct, { loading, error }] = useMutation(
    REMOVE_PRODUCT_FROM_CART,
    {
      ...options,
    }
  );

  return [
    ({ cartToken, cartItemId }) => {
      const variables = {
        input: {
          cartToken,
          cartItemId,
        },
      };

      return cartRemoveProduct({ variables });
    },
    {
      loading,
      error,
    },
  ];
}

const REMOVE_PRODUCT_FROM_CART = gql`
  mutation useRemoveProductFromCart($input: RemoveProductInput!) {
    cartRemoveProduct(input: $input) {
      ...CartFragment
    }
  }
  ${CART_FRAGMENT}
`;

export default useRemoveProductFromCart;
