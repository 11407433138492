import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

export const spacing0 = {
  margin: 0,
};

export const greenStyle = {
  background: '#4caf50',
  borderColor: '#4caf50',
  color: 'black',
};

export const repassaStyle = {
  background: '#3acbc7',
  borderColor: '#3acbc7',
  color: 'black',
};

export function getStyle({ type, spacing = true, disabled = false }) {
  let inner_style = {};

  switch (type) {
    case 'repassa':
      inner_style = repassaStyle;
      break;
    case 'success':
      inner_style = greenStyle;
      break;
    default:
      break;
  }

  if (!spacing) {
    inner_style = { ...inner_style, ...spacing0 };
  }

  if (disabled) {
    inner_style = {
      ...inner_style,
      color: THEME_BEHAVIOR.button.disabled.bgColorSecondary,
      cursor: 'not-allowed',
    };
  }

  return inner_style;
}
