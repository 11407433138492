import styled from 'styled-components';

export const Container = styled.div`
  .ant-table-wrapper {
    .ant-table-tbody {
      > tr {
        > td {
          > .ant-table-wrapper {
            &:only-child {
              .ant-table {
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }

  tr.ant-table-expanded-row {
    td {
      > .ant-table-wrapper {
        margin: 0;
      }
    }
  }

  .ant-table-expanded-row {
    > .ant-table-cell {
      padding: 0px;
    }
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
      border: 0px;
    }
  }
`;

export const IconColumn = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  min-width: 120px;

  .ant-tag {
    margin-bottom: 0px;
    margin-left: 5px;
  }
`;
