export default {
  id: 'price',
  kind: 'price',
  presentation: 'Faixa de preços',
  optionValues: [
    {
      id: '0-25',
      key: '0-25',
      presentation: '0-25',
      gte: null,
      lte: 25,
      value: 'Até R$25,00',
    },
    {
      id: '26-49',
      key: '26-49',
      presentation: '26-49',
      gte: 26,
      lte: 49,
      value: 'De R$26,00 até R$49,00',
    },
    {
      id: '50-99',
      key: '50-99',
      presentation: '50-99',
      gte: 50,
      lte: 99,
      value: 'De R$50,00 até R$99,00',
    },
    {
      id: '100-199',
      key: '100-199',
      presentation: '100-199',
      gte: 100,
      lte: 199,
      value: 'De R$100,00 até 199,00',
    },
    {
      id: '200-0',
      key: '200-0',
      presentation: '200-0',
      gte: 200,
      lte: null,
      value: 'A partir de R$200,00',
    },
  ],
};
