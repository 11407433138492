import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Form, Button, Divider, Typography } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { triggerErrorNotification } from '~/util/Functions';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import ProductList from './ProductList';

function DefaultForm({ handleSubmit, initialData, isEdit }) {
  const { tableData, setTableData } = useDragSortTableContext();
  const [form] = Form.useForm();
  const { Title } = Typography;

  const onFinish = values => {
    if (isEdit) {
      return handleSubmit(values, tableData, setTableData);
    }

    const urlItems = values.urls
      .split(',')
      .map(item => item.trim())
      .filter(Boolean);

    const newValues = {
      ...values,
      urls: urlItems,
    };

    return handleSubmit(newValues, tableData, setTableData);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar bloco de produto',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="homeCreateProductBlockForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Detalhes
          </Title>
          <Divider style={{ marginTop: 5 }} />
        </Col>
        <Col span={24}>
          <Form.Item
            label="Título"
            name="title"
            initialValue={initialData.title}
            rules={[
              { required: true, message: 'Digite o título', whitespace: true },
            ]}
          >
            <Input data-testid="homeAddProductBlockTitle" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Subtítulo"
            name="subtitle"
            initialValue={initialData.subtitle}
            rules={[
              {
                message: 'O subtítulo não pode conter somente espaços',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="homeAddProductBlocksubtitle" />
          </Form.Item>
        </Col>
        {!isEdit && (
          <Col span={24} style={{ marginBottom: '24px' }}>
            <Form.Item
              label="Urls"
              name="urls"
              initialValue={initialData.urls.join(',')}
              rules={[
                { required: true, message: 'Digite o link', whitespace: true },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input data-testid="homeAddProductBlockUrl" />
            </Form.Item>
            <small>
              Caso queira adicionar mais de um produto, separe-os por vírgula.
            </small>
          </Col>
        )}
      </Row>

      {isEdit && (
        <Row gutter={16}>
          <Col span={24}>
            <ProductList form={form} block={initialData} isEdit={isEdit} />
          </Col>
        </Row>
      )}

      <Row gutter={16} justify="end">
        <Col>
          <Form.Item>
            <Button
              data-testid="defaultSubmitBtn"
              type="success"
              htmlType="submit"
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
              style={{ margin: 0, marginTop: 20 }}
            >
              {isEdit ? 'Salvar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

DefaultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    urls: PropTypes.array.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default DefaultForm;
