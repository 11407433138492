import React from 'react';
import { Empty } from 'antd';
import { EmptyContainer } from './styles';

function UnderConstruction() {
  return (
    <EmptyContainer data-testid="notFound">
      <Empty
        image={`${process.env.PUBLIC_URL}/images/loadings/underConstruction.gif`}
        description={
          <>
            Sistema de BackOffice
            <br />
            Repassa
          </>
        }
      />
    </EmptyContainer>
  );
}

export default UnderConstruction;
