import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const OPTION_TYPE_FRAGMENT = gql`
  fragment OptionTypeFragment on OptionType {
    id
    kind
    name
    position
    presentation
    required
    optionValues {
      id
      name
      optionTypeId
      presentation
      value
    }
  }
`;

export const PROPERTY_FRAGMENT = gql`
  fragment PropertyFragment on Property {
    id
    kind
    name
    position
    presentation
    required
  }
`;

export const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    active
    fixed
    id
    metaDescription
    metaKeywords
    metaTitle
    name
    parentId
    permalink
    productCount
    optionTypes {
      ...OptionTypeFragment
    }
    properties {
      ...PropertyFragment
    }
  }
  ${OPTION_TYPE_FRAGMENT}
  ${PROPERTY_FRAGMENT}
`;

export const CATEGORY_BASIC_FRAGMENT = gql`
  fragment CategoryBasicFragment on Category {
    id
    name
    permalink
    parentId
    active
    productCount
  }
`;

export const QUERY_SEARCH_CATEGORIES = gql`
  query useSearchCategories(
    $id: Int
    $ids: [Int!]
    $skip: Int
    $first: Int
    $name: String
    $permalink: String
    $parentId: Int
    $onlyActive: Boolean
    $orderBy: CategoryOrder
    $basic: Boolean = true
    $full: Boolean = true
  ) {
    categoriesProductSearchCategories(
      id: $id
      ids: $ids
      skip: $skip
      first: $first
      name: $name
      permalink: $permalink
      parentId: $parentId
      onlyActive: $onlyActive
      orderBy: $orderBy
    ) {
      nodes {
        ...CategoryBasicFragment @include(if: $basic)
        ...CategoryFragment @include(if: $full)
      }
      totalCount
    }
  }
  ${CATEGORY_FRAGMENT}
  ${CATEGORY_BASIC_FRAGMENT}
`;

function useSearchCategories(options = {}) {
  const { data, loading, error } = useQuery(QUERY_SEARCH_CATEGORIES, {
    variables: {
      first: DEFAULT_PAGE_SIZE,
      skip: 0,
    },
    ...options,
  });

  return {
    loading,
    data: data?.categoriesProductSearchCategories?.nodes || [],
    totalCount: data?.categoriesProductSearchCategories?.totalCount || 0,
    error,
  };
}

export default useSearchCategories;
