import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';
import {
  COUPON_FRAGMENT,
  COUPON_RULES_ACTIONS_FRAGMENT,
} from './useCreateCoupon';

export const QUERY_SEARCH_COUPONS = gql`
  query useSearchCoupon(
    $first: Int
    $skip: Int
    $couponFilter: String
    $category: String
    $kind: String
    $id: Int
    $rulesAndActions: Boolean = true
  ) {
    couponSearchCoupons(
      first: $first
      skip: $skip
      couponFilter: $couponFilter
      category: $category
      kind: $kind
      id: $id
    ) {
      nodes {
        ...CouponFragment
        ...CouponRulesActionsFragment @include(if: $rulesAndActions)
      }
      totalCount
    }
  }
  ${COUPON_FRAGMENT}
  ${COUPON_RULES_ACTIONS_FRAGMENT}
`;

function useSearchCoupon(options = {}) {
  const [search, { data, loading, error, refetch, called }] = useLazyQuery(
    QUERY_SEARCH_COUPONS,
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const searchCoupons = useCallback(
    ({
      first = DEFAULT_PAGE_SIZE,
      skip = 0,
      couponFilter = undefined,
      category = undefined,
      kind = undefined,
      id = undefined,
      rulesAndActions = false,
    }) => {
      search({
        variables: {
          first,
          skip,
          couponFilter,
          category,
          kind,
          id,
          rulesAndActions,
        },
      });
    },
    [search]
  );

  return [
    searchCoupons,
    {
      loading,
      data: data?.couponSearchCoupons?.nodes || [],
      totalCount: data?.couponSearchCoupons?.totalCount || 0,
      error,
      refetch,
      called,
    },
  ];
}

export default useSearchCoupon;
