export function handleSearch(searchText, isInactive, { setTable }) {
  setTable(table => {
    return {
      ...table,
      currentPage: 1,
      skip: 0,
      totalCount: 0,
      status: isInactive ? 'inactive' : 'active',
      searchText,
      loading: true,
    };
  });
}
