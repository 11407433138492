import { Modal } from 'antd';
import { USER_STATUS_INACTIVE } from '~/constants/Users';
import { userModalContent } from './utils';

const { confirm } = Modal;

export const handleBlockUser = ({
  onOk = () => {},
  onCancel = () => {},
  userKind,
  currentStatus,
}) => {
  const isBlocked = currentStatus === USER_STATUS_INACTIVE;
  const modalContent = userModalContent(isBlocked, userKind);

  confirm({
    title: modalContent.title,
    content: modalContent.content,
    okText: modalContent.okText,
    okType: modalContent.okType,
    cancelText: modalContent.cancelText,
    onOk,
    onCancel,
  });
};
