import styled from 'styled-components';

export const ProdCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    min-width: 50px;
    min-height: 50px;
    margin-right: 10px;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-size: cover;
  }
`;
