import React from 'react';
import { Empty, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { convertDate, getImageUrl } from '~/util/Functions';
import { getOrderItemStatus, getShipmentStatus } from '~/util/statusConverter';
import {
  ORDER_ITEM_STATUS_IN_RETURN,
  ORDER_ITEM_STATUS_SHIPPED,
} from '~/constants/Order';
import { getReturnReason } from '~/util/returnReasonsConverter';
import {
  SHIPMENT_DELIVERED,
  SHIPMENT_DELIVERY_NOT_MADE,
} from '~/constants/Shipment';
import Image from '~/components/Image';
import { USER_TAB_DATA } from '~/constants/Users';
import ApproveModal from './ApproveModal';
import InfoModal from './InfoModal';

export function generateColumns() {
  const data = [
    {
      title: 'Pedido',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Cliente',
      dataIndex: 'user',
      key: 'user',
      render: user => (
        <>
          {user?.fullname || '-'}
          {user?.id && (
            <Tooltip placement="bottom" title="Ir para cliente">
              <Link
                to={`/manage/service/client/edit/${user.id}?tab=${USER_TAB_DATA}`}
              >
                {' '}
                <ExportOutlined />
              </Link>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Email do cliente',
      dataIndex: 'user',
      key: 'user',
      render: user => user?.email || '-',
    },
    {
      title: 'Transportadora',
      dataIndex: 'shippingName',
      key: 'shippingName',
    },
    {
      title: 'Data do pedido',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => convertDate(text),
    },
    {
      title: 'Status de Envio',
      dataIndex: 'currentShipmentStatus',
      key: 'currentShipmentStatus',
      render: currentShipmentStatus => getShipmentStatus(currentShipmentStatus),
    },
  ];

  return data;
}

export function generateColumnsProducts(row, orders, setOrders) {
  const enableAproveButton = record => {
    return (
      (row.currentShipmentStatus === SHIPMENT_DELIVERED &&
        record.status === ORDER_ITEM_STATUS_IN_RETURN) ||
      (row.currentShipmentStatus === SHIPMENT_DELIVERY_NOT_MADE &&
        record.status === ORDER_ITEM_STATUS_SHIPPED)
    );
  };

  const data = [
    {
      title: 'ID',
      dataIndex: 'itemId',
      key: 'itemId',
      align: 'center',
      fixed: 'left',
      render: itemId => itemId || '-',
    },
    {
      title: 'Cód. Postagem',
      dataIndex: 'reverseCode',
      key: 'reverseCode',
      render: code => code || '-',
    },
    {
      title: 'Cód. Rastreio',
      dataIndex: 'trackingCode',
      key: 'trackingCode',
      render: code => code || '-',
    },
    {
      title: 'Foto',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: thumbnail => {
        if (thumbnail) {
          return (
            <Image
              style={{ width: 60, height: 'auto' }}
              src={getImageUrl(thumbnail)}
            />
          );
        }
        return (
          <Empty
            key={thumbnail}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 20 }}
            description={<small>sem foto</small>}
          />
        );
      },
    },
    {
      title: 'Nome do produto',
      dataIndex: 'itemName',
      key: 'itemName',
      render: (itemName, _, index) => {
        return (
          <InfoModal label={itemName || '-'} button="Ver e-mail do vendedor">
            <h2>Email do vendedor:</h2>
            <p>
              {row.orderItems[index] && row.orderItems[index].sellerEmail
                ? row.orderItems[index].sellerEmail
                : '-'}
            </p>
          </InfoModal>
        );
      },
    },
    {
      title: 'Motivo da devolução',
      dataIndex: 'orderItemReturn',
      key: 'reason',
      render: (itemName, _, index) => {
        return (
          <InfoModal
            label={getReturnReason(itemName?.reason)}
            button="Ver comentário"
          >
            <h2>Comentário:</h2>
            <p>
              {row.orderItems[index] &&
              row.orderItems[index].orderItemReturn?.comment
                ? row.orderItems[index].orderItemReturn?.comment
                : '-'}
            </p>
          </InfoModal>
        );
      },
    },
    {
      title: 'Sub-motivo da devolução',
      dataIndex: 'orderItemReturn',
      key: 'anotherReason',
      render: orderItemReturn =>
        getReturnReason(orderItemReturn?.anotherReason),
    },
    {
      title: 'Status do item',
      dataIndex: 'status',
      key: 'status',
      render: value => (value ? getOrderItemStatus(value) : '-'),
    },
    {
      title: 'Data da solicitação',
      dataIndex: 'orderItemReturn',
      key: 'requestedAt',
      render: orderItemReturn =>
        orderItemReturn?.requestedAt
          ? convertDate(orderItemReturn?.requestedAt)
          : '-',
    },
    {
      title: 'Data da aprovação',
      dataIndex: 'orderItemReturn',
      key: 'retunerdAt',
      render: orderItemReturn =>
        orderItemReturn?.retunerdAt
          ? convertDate(orderItemReturn?.retunerdAt)
          : '-',
    },
    {
      title: 'Ação',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: (record, _, index) => {
        return (
          enableAproveButton(record) && (
            <ApproveModal
              orderId={row.id}
              productId={row.orderItems[index]?.itemId}
              rowIndex={index}
              orders={orders}
              setOrders={setOrders}
            />
          )
        );
      },
    },
  ];

  return data;
}
