import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert, Space, Table } from 'antd';

import useBalanceExtract from '~/hooks/balance/useBalanceExtract';
import { createPaginationOptions, getErrorMessage } from '~/util/Functions';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';
import { generateColumns } from './columns';

function TableBalance({ userId }) {
  const columns = generateColumns();

  const [table, setTable] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    skip: 0,
  });

  const [
    balanceExtract,
    { data: extract, loading: loadingExtract, error: errorExtract, totalCount },
  ] = useBalanceExtract();

  const searchBalanceExtract = useCallback(balanceExtract, [
    table.pageSize,
    table.skip,
    userId,
  ]);

  useEffect(() => {
    searchBalanceExtract({
      first: table.pageSize,
      skip: table.skip,
      userId,
      full: true,
    });
  }, [searchBalanceExtract, table.pageSize, table.skip, userId]);

  function onChangePage(current, size) {
    const skip = (current - 1) * size;

    setTable({
      pageSize: size,
      currentPage: current,
      skip,
    });
  }

  function onShowSizeChange(_oldPage, newPageSize) {
    const newCurrentPage = Math.ceil(
      (table.currentPage * table.pageSize - table.pageSize + 1) / newPageSize
    );
    const skip = (newCurrentPage - 1) * newPageSize;

    setTable({
      pageSize: newPageSize,
      currentPage: newCurrentPage,
      skip,
    });
  }

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      {errorExtract && (
        <Alert message={getErrorMessage(errorExtract)} type="error" showIcon />
      )}
      <Table
        dataSource={extract}
        columns={columns}
        loading={loadingExtract}
        rowKey={record => record.id}
        title={() => `Total encontrado - ${totalCount}`}
        bordered
        scroll={{
          x: true,
        }}
        pagination={{
          current: table.currentPage,
          total: totalCount,
          showSizeChanger: true,
          onShowSizeChange,
          onChange: onChangePage,
          pageSize: table.pageSize,
          pageSizeOptions: createPaginationOptions(table.pageSize, totalCount),
          position: 'both',
          locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
        }}
      />
    </Space>
  );
}

TableBalance.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default TableBalance;
