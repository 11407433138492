import React from 'react';
import { Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';
import DragHandle from '~/components/DragSortTable/DragHandle';

export function generateColumns(
  subjectId,
  handleDelete,
  data,
  setData,
  deleteFaqCategoryQuestion
) {
  return [
    {
      title: '#',
      dataIndex: 'drag',
      key: 'drag',
      render: () => <DragHandle />,
    },
    {
      title: 'Pergunta',
      dataIndex: 'question',
      key: 'question',
      render: (text, record) => (
        <div style={{ minWidth: '120px' }}>{record.question}</div>
      ),
    },
    {
      title: 'FAQ Sacola do Bem',
      dataIndex: 'bagQuestion',
      key: 'bagQuestion',
      render: (text, record) => (
        <div style={{ minWidth: '160px' }}>
          {record.bagQuestion ? 'Sim' : 'Não'}
        </div>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: 90,
      align: 'center',
      fixed: 'right',
      render: (text, record) => (
        <div>
          <Tooltip placement="bottom" title="Editar">
            <Link
              to={`/manage/content/faq/category/${subjectId}/question/edit/${record.id}`}
            >
              <Button
                data-testid="editBtn"
                type="link"
                icon={<EditOutlined />}
                size="small"
                style={{ margin: 0 }}
              />
            </Link>
          </Tooltip>
          <Tooltip placement="bottom" title="Excluir">
            <Button
              data-testid="deleteBtn"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() =>
                handleDelete(
                  record.id,
                  data,
                  setData,
                  deleteFaqCategoryQuestion
                )
              }
              size="small"
              style={{ marginBottom: 0, color: 'red' }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
}
