import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, Col, Row, Tooltip } from 'antd';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';

function ExpandableRawOrder({ order }) {
  const [expand, setExpand] = useState(false);

  const handleExpand = () => setExpand(!expand);

  return (
    <Card
      style={{ marginTop: '10px' }}
      data-testid="rawOrder"
      title={
        <>
          <Row gutter={16} type="flex" align="middle" justify="space-between">
            <Col md={12} xs={24}>
              <span
                data-testid="orderExpandTitle"
                style={{ marginLeft: '10px' }}
              >
                Exibir dados da requisição (Backend)
              </span>
            </Col>
            <Col md={12} xs={24}>
              <Row type="flex" justify="end" align="middle">
                <Tooltip
                  placement="bottom"
                  title={
                    expand ? 'Recolher informações' : 'Expandir informações'
                  }
                >
                  <Button
                    type="ghost"
                    icon={
                      expand ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                    }
                    onClick={handleExpand}
                  />
                </Tooltip>
              </Row>
            </Col>
          </Row>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        {!order && (
          <Col span={24}>
            <Alert
              message="Não pedido para ser expandido"
              type="warning"
              showIcon
            />
          </Col>
        )}
        {expand && order && (
          <Col>
            <div>
              <pre>{JSON.stringify(order, null, 2)}</pre>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  );
}

ExpandableRawOrder.defaultProps = {
  order: null,
};

ExpandableRawOrder.propTypes = {
  order: PropTypes.shape(),
};

export default ExpandableRawOrder;
