import styled from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme } = THEME_BEHAVIOR;

export const Placeholder = styled.div`
  background-color: #ccc;
  opacity: 0.3;
  border-radius: ${theme.borderRadius};

  @keyframes flickerAnimation {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.2;
    }
  }

  animation: flickerAnimation 2s infinite;
`;
