import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { USER_ADDRESS_KIND_SHIPPING } from '~/constants/Users';

export const UPDATE_ADDRESS = gql`
  mutation useUserUpdateAddress($input: UpdateAddressInput!) {
    userUpdateAddress(input: $input) {
      id
      address1
      address2
      city
      kind
      lat
      lng
      neighborhood
      number
      state {
        id
        name
        country
        code
        abbr
      }
      userId
      zipcode
    }
  }
`;

function useUserUpdateAddress(options = {}) {
  const [updateAddress, { loading, data, error }] = useMutation(
    UPDATE_ADDRESS,
    {
      ...options,
    }
  );

  return [
    ({
      street,
      complement = undefined,
      zipcode,
      city,
      neighborhood,
      number,
      stateCode,
      addressId,
      kind = USER_ADDRESS_KIND_SHIPPING,
    } = {}) => {
      const zipCodeOnlyNumbers = zipcode.replace(/[^\d]+/gi, '');

      updateAddress({
        variables: {
          input: {
            address1: street,
            address2: complement,
            city,
            kind,
            neighborhood,
            number,
            stateCode,
            id: addressId,
            zipcode: zipCodeOnlyNumbers,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUserUpdateAddress;
