import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Col,
  Row,
  Form,
  Input,
  Alert,
  Table,
  Descriptions,
  notification,
} from 'antd';

import Button from '~/components/Button';
import useFindCart from '~/hooks/cart/useFindCart';
import useRemoveProductFromCart from '~/hooks/cart/useRemoveProductFromCart';
import { getErrorMessage, notifyError } from '~/util/Functions';

import { generateColumns } from './columns';

function CartInspector({ initialValues }) {
  const [form] = Form.useForm();
  const columns = generateColumns();

  const [findCart, { cart, error: errorFind, loading: loadingFind }] =
    useFindCart({
      fetchPolicy: 'network-only',
    });
  const [removeProduct, { loading: loadingRemove }] = useRemoveProductFromCart({
    onError: err => {
      notifyError('Erro ao remover item do carrinho', err);
    },
  });

  const onFinish = values => {
    findCart(values);
  };

  const clearCart = () => {
    if (cart?.cartItems?.length > 0) {
      const cartToken = form.getFieldValue('cartToken');

      if (cartToken && cart.status === 'open') {
        cart.cartItems.reduce(
          (p, cartItem) =>
            p.then(_ => removeProduct({ cartToken, cartItemId: cartItem.id })),
          Promise.resolve()
        );
      } else if (cart.status !== 'open') {
        notification.error({
          message: 'Erro ao limpar carrinho',
          duration: 10,
          description: 'Carrinho não possui status "aberto"',
          placement: 'topRight',
        });
      } else {
        form.validateFields();
      }
    }
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        initialValues={initialValues}
      >
        <Row type="flex" justify="center" align="bottom" gutter={8}>
          <Col span={20}>
            <Form.Item
              name="cartToken"
              label="Cart Token"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="repassa" htmlType="submit" block>
                Buscar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row type="flex" justify="start" align="middle" gutter={[16, 16]}>
        <Col span={4}>
          <Button
            disabled={!cart || cart?.cartItems?.length === 0}
            onClick={clearCart}
            type="repassa"
            block
          >
            Limpar carrinho
          </Button>
        </Col>
        {errorFind && (
          <Col span={24}>
            <Alert message={getErrorMessage(errorFind)} type="error" showIcon />
          </Col>
        )}
        {cart && (
          <Col span={24}>
            <Descriptions title="Carrinho" bordered>
              <Descriptions.Item label="Total de Ajustes">
                R$ {cart?.adjustmentTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Saldo Total">
                R$ {cart?.balanceTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Bonus Total">
                R$ {cart?.bonusTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Ajustes do carrinho">
                {cart?.cartAdjustments.length === 0
                  ? '-'
                  : cart?.cartAdjustments.map(adjust => (
                      <div key={adjust?.id}>
                        {adjust?.label} - R$ {adjust?.value}
                      </div>
                    ))}
              </Descriptions.Item>
              <Descriptions.Item label="Total">
                R$ {cart?.cartTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Cupom">
                {cart?.couponCode || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Expiração">
                {moment(cart?.expiresAt).format('DD/MM/YYYY HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="Parcelas">
                {cart?.installments}
              </Descriptions.Item>
              <Descriptions.Item label="Juros de parcelamento">
                R$ {cart?.interestRate}
              </Descriptions.Item>
              <Descriptions.Item label="Total dos itens">
                R$ {cart?.itemTotal}
              </Descriptions.Item>
              <Descriptions.Item label="ID do termo de pagamento">
                {cart?.paymentTermIds}
              </Descriptions.Item>
              <Descriptions.Item label="Tipo de pagamento">
                {cart?.paymentType}
              </Descriptions.Item>
              <Descriptions.Item label="ID do método de entrega">
                {cart?.shipmentMethodId}
              </Descriptions.Item>
              <Descriptions.Item label="Preço final de entrega">
                R$ {cart?.finalShipmentTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Preço de entrega">
                R$ {cart?.shipmentTotal}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {cart?.status}
              </Descriptions.Item>
              <Descriptions.Item label="Total do subsídio de frete">
                R$ {cart?.subsidyTotal}
              </Descriptions.Item>
              <Descriptions.Item label="ID do usuário">
                {cart?.userId}
              </Descriptions.Item>
              <Descriptions.Item label="Utiliza saldo?">
                {cart?.useBalance ? 'Sim' : 'Não'}
              </Descriptions.Item>
              <Descriptions.Item label="Utiliza saldo integralmente?">
                {cart?.paymentFullBalance ? 'Sim' : 'Não'}
              </Descriptions.Item>
              <Descriptions.Item label="Total de dinheiro economizado">
                R$ {cart?.totalMoneySaved}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
        <Col span={24}>
          <Table
            data-testid="table"
            dataSource={cart?.cartItems || []}
            columns={columns}
            loading={loadingFind || loadingRemove}
            rowKey={record => record.id}
            title={() => `Itens no carrinho`}
            bordered
            scroll={{
              x: true,
            }}
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
}

CartInspector.propTypes = {
  initialValues: PropTypes.shape({
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default CartInspector;
