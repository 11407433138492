import {
  isJsonEmpty,
  triggerErrorNotification,
  triggerSuccessNotification,
} from '~/util/Functions';

export const handleSubmitDefaultForm = (
  values,
  {
    isEdit,
    createCategory,
    updateCategory,
    category,
    setCategory,
    setRedirectToEdit,
  }
) => {
  if (!isEdit) {
    createCategory({
      variables: {
        input: {
          active: values.active,
          fixed: values.fixed,
          metaDescription: values.metaDescription,
          metaKeywords: values.metaKeywords,
          metaTitle: values.metaTitle,
          name: values.name,
          parentId: category.default.parentId,
          permalink: values.permalink,
          productCount: values.productCount,
          optionTypeIds: category.optionTypes.map(item => item.id),
          propertyIds: category.properties.map(item => item.id),
        },
      },
    })
      .then(successCreate => {
        const categoryData = successCreate.data.categoriesProductCreateCategory;

        setCategory({
          ...category,
          id: categoryData.id,
          default: {
            active: categoryData.active,
            fixed: categoryData.fixed,
            metaDescription: categoryData.metaDescription,
            metaKeywords: categoryData.metaKeywords,
            metaTitle: categoryData.metaTitle,
            name: categoryData.name,
            parentId: category.default.parentId,
            permalink: categoryData.permalink,
            productCount: categoryData.productCount,
          },
          optionTypes: categoryData.optionTypes,
          properties: categoryData.properties,
        });
        setRedirectToEdit(true);
        triggerSuccessNotification('Categoria criada', categoryData.message);
      })
      .catch(errorCreate => {
        triggerErrorNotification('Erro ao criar categoria', errorCreate);
      });
  } else {
    updateCategory({
      variables: {
        input: {
          id: category.id,
          active: values.active,
          fixed: values.fixed,
          metaDescription: values.metaDescription,
          metaKeywords: values.metaKeywords,
          metaTitle: values.metaTitle,
          name: values.name,
          parentId: values.parentId,
          productCount: values.productCount,
          optionTypeIds: category.optionTypes.map(item => item.id),
          propertyIds: category.properties.map(item => item.id),
        },
      },
    })
      .then(successUpdate => {
        const categoryData = successUpdate.data.categoriesProductUpdateCategory;

        setCategory({
          ...category,
          id: categoryData.id,
          default: {
            active: categoryData.active,
            fixed: categoryData.fixed,
            metaDescription: categoryData.metaDescription,
            metaKeywords: categoryData.metaKeywords,
            metaTitle: categoryData.metaTitle,
            name: categoryData.name,
            parentId: categoryData.parentId,
            productCount: categoryData.productCount,
          },
          optionTypes: categoryData.optionTypes,
          properties: categoryData.properties,
        });
        triggerSuccessNotification(
          'Categoria atualizada',
          'Categoria atualizada com sucesso'
        );
      })
      .catch(errorUpdate => {
        triggerErrorNotification('Erro ao atualizar categoria', errorUpdate);
      });
  }
};

export const loadCategories = ({ data, setCategory, setLoading }) => {
  if (data) {
    const {
      categoriesProductSearchCategories: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [category] = nodes;
      setCategory(prevState => {
        return {
          ...prevState,
          id: category.id,
          default: {
            active: category.active,
            fixed: category.fixed,
            metaDescription: category.metaDescription,
            metaKeywords: category.metaKeywords,
            metaTitle: category.metaTitle,
            name: category.name,
            parentId: category.parentId,
            permalink: category.permalink,
            productCount: category.productCount,
          },
          optionTypes: category.optionTypes
            ? category.optionTypes.map(item => {
                item.type = 'optionType';
                return item;
              })
            : [],
          properties: category.properties
            ? category.properties.map(item => {
                item.type = 'property';
                return item;
              })
            : [],
        };
      });
    }
    setLoading(false);
  }
};

function checkIfExists(arr, key) {
  return arr.find(element => element.key === key);
}

export function handleAddField(
  e,
  { setSearchText, selectedItem, setCategory, optionTypes, properties, form }
) {
  e.preventDefault();
  setSearchText('');

  if (!isJsonEmpty(selectedItem)) {
    if (selectedItem.type === 'optionType') {
      if (!checkIfExists(optionTypes, selectedItem.key)) {
        setCategory(prevState => {
          return {
            ...prevState,
            ...form.getFieldsValue(),
            optionTypes: [...prevState.optionTypes, selectedItem],
          };
        });
      }
    }
    if (selectedItem.type === 'property') {
      if (!checkIfExists(properties, selectedItem.key)) {
        setCategory(prevState => {
          return {
            ...prevState,
            ...form.getFieldsValue(),
            properties: [...prevState.properties, selectedItem],
          };
        });
      }
    }
  }
}

export function handleDelete(record, { optionTypes, properties, setCategory }) {
  if (record.type === 'optionType') {
    const newData = optionTypes.filter(obj => obj.id !== record.id);
    setCategory(prevState => ({ ...prevState, optionTypes: newData }));
  }
  if (record.type === 'property') {
    const newData = properties.filter(obj => obj.id !== record.id);
    setCategory(prevState => ({ ...prevState, properties: newData }));
  }
}
