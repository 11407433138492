function formatProductIds(productRule) {
  if (productRule) {
    const productIds = productRule.productIds.map(item => item.id);
    return {
      product: {
        ...productRule,
        productIds,
      },
    };
  }

  return null;
}

function formatUser(userRule) {
  if (userRule) {
    delete userRule.searchUsersRule;
    const userIds = userRule.userIds.map(item => item.id);
    return {
      user: {
        ...userRule,
        userIds,
      },
    };
  }

  return null;
}

function formatOrderTotal(orderTotalRule) {
  if (orderTotalRule) {
    return {
      orderTotal: {
        ...orderTotalRule,
        value: Number(orderTotalRule.value),
      },
    };
  }

  return null;
}

function formatIsFirstOrder(firstOrderRule) {
  if (firstOrderRule) {
    return {
      firstOrder: {
        ...firstOrderRule,
        isFirstOrder: firstOrderRule.isFirstOrder ? 'active' : 'inactive',
      },
    };
  }

  return null;
}

export const formattedRulesForm = rules => ({
  ...rules,
  ...formatProductIds(rules?.product),
  ...formatUser(rules?.user),
  ...formatOrderTotal(rules?.orderTotal),
  ...formatIsFirstOrder(rules?.firstOrder),
});
