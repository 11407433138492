import React from 'react';
import { notification } from 'antd';

export function handleSubmit(
  values,
  {
    isEdit,
    faqCategory,
    setFaqCategory,
    createFaqCategory,
    updateFaqCategory,
    setRedirectToEdit,
  }
) {
  if (!isEdit) {
    createFaqCategory({
      variables: {
        input: {
          subject: values.subject,
          weightPosition: 0,
          icon: values.icon,
        },
      },
    })
      .then(successUpt => {
        setFaqCategory(successUpt.data.faqCreateFaqSubject);
        notification.success({
          message: `Categoria criada`,
          description: (
            <div>
              A categoria <b>{successUpt.data.faqCreateFaqSubject.subject}</b>{' '}
              foi criada com sucesso!
            </div>
          ),
          placement: 'topRight',
        });
        setRedirectToEdit(true);
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao criar categoria`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  } else {
    updateFaqCategory({
      variables: {
        input: {
          id: faqCategory.id,
          subject: values.subject,
          weightPosition: faqCategory.weightPosition,
          icon: values.icon,
        },
      },
    })
      .then(successUpt => {
        setFaqCategory(successUpt.data.faqUpdateFaqSubject);
        notification.success({
          message: `Categoria atualizada`,
          description: <div>A categoria atualizada com sucesso!</div>,
          placement: 'topRight',
        });
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao atualizar categoria`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  }
}

export const loadFaqCayegory = ({ data, setFaqCategory, setLoading }) => {
  if (data) {
    const {
      faqSearchFaqSubjects: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [FaqSubject] = nodes;
      setFaqCategory(prevState => {
        return {
          ...prevState,
          id: FaqSubject.id,
          subject: FaqSubject.subject,
          weightPosition: FaqSubject.weightPosition,
          icon: FaqSubject.icon,
        };
      });
    }
    setLoading(false);
  }
};
