import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Tooltip, Row, Col } from 'antd';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { RulesReducer, initialState } from './RulesReducer';

import {
  PROMOTION_CREATE,
  PROMOTION_UPDATE,
  PROMOTION_SEARCH_BY_ID,
} from '~/apollo/marketing/promotion';
import { SEARCH_CATEGORIES_BY_ID } from '~/apollo/bag/category';
import { BRANDS_LIST_BY_IDS } from '~/apollo/bag/brand';
import { notifyError, getErrorMessage } from '~/util/Functions';
import history from '~/services/history';
import DefaultForm from './DefaultForm';

import {
  handleSubmitForm,
  loadPromotion,
  loadCategories,
  loadBrands,
} from './actions';
import LoadingBar from '~/components/LoadingBar';

function PromotionForm({ match }) {
  const [tables, dispatch] = useReducer(RulesReducer, initialState);

  // Controla modal cores
  const [addColor, setAddColor] = useState({
    visible: false,
    record: {},
    tableKey: undefined,
  });

  const [isEdit, setIsEdit] = useState(match.url.includes('edit'));
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState({
    id: match.params.id || null,
    name: '',
    category: 'macro',
    descriptionSeller: '',
    sellerDescription: '',
    discount: 0,
    startedAt: null,
    rules: [],
    expiredAt: null,
    sellerNotifiedAt: null,
    ruleType: '',
    genre: '',
    categories: [],
    categoriesTable: [],
    property: '',
    filters: [],
    gte: 0,
    lte: 0,
    timer: '',
    banner: null,
    categoryColors: {},
    categoryRuleIds: {},
  });

  const [searchPromotionById, { data }] = useLazyQuery(PROMOTION_SEARCH_BY_ID, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    variables: {
      id: Number(match.params.id),
    },
    onError: errorSearch => {
      notifyError('Erro ao buscar promoção', errorSearch);
      setError(errorSearch);
      setLoading(false);
    },
  });

  const [searchCategoriesById, { data: categories }] = useLazyQuery(
    SEARCH_CATEGORIES_BY_ID,
    {
      context: {
        newBackend: true,
      },
      fetchPolicy: 'network-only',
      onError: errorSearch => {
        notifyError('Erro ao buscar categorias da regra', errorSearch);
        setError(errorSearch);
        setLoading(false);
      },
    }
  );

  const [searchBrandsById, { data: brands }] = useLazyQuery(
    BRANDS_LIST_BY_IDS,
    {
      context: {
        newBackend: true,
      },
      fetchPolicy: 'network-only',
      onError: errorSearch => {
        notifyError('Erro ao buscar marcas da regra', errorSearch);
        // setError(errorSearch);
        setLoading(false);
      },
    }
  );

  const [createPromotion] = useMutation(PROMOTION_CREATE, {
    context: {
      newBackend: true,
    },
  });

  const [updatePromotion] = useMutation(PROMOTION_UPDATE, {
    context: {
      newBackend: true,
    },
  });

  useEffect(() => {
    if (isEdit) {
      searchPromotionById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    loadPromotion({
      data,
      setPromotion,
      setLoading,
      setError,
      searchCategoriesById,
      searchBrandsById,
      tables,
      dispatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    loadCategories({ tables, categories, dispatch, setAddColor, promotion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    loadBrands({ tables, brands, dispatch, promotion });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  useEffect(() => {
    if (redirectToEdit) {
      setIsEdit(true);
      setRedirectToEdit(false);
    }
  }, [redirectToEdit]);

  if (isEdit && loading) return <LoadingBar />;
  if (isEdit && error) return `Error! ${getErrorMessage(error)}`;

  if (redirectToEdit) {
    return <Redirect to={`/manage/marketing/promotion/edit/${promotion.id}`} />;
  }

  return (
    <Card
      data-testid="promotionForm"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para promoção">
            <ArrowLeftOutlined
              onClick={history.goBack}
              style={{ marginRight: 10 }}
            />
          </Tooltip>
          {isEdit ? 'Editar Promoção' : 'Nova promoção'}
        </>
      }
    >
      <Row gutter={16} type="flex" justify="center" align="top">
        <Col>
          <DefaultForm
            handleSubmitForm={values => {
              handleSubmitForm(values, {
                isEdit,
                setRedirectToEdit,
                createPromotion,
                updatePromotion,
                promotion,
                setPromotion,
                tables,
                dispatch,
              });
            }}
            promotion={promotion}
            tables={tables}
            dispatch={dispatch}
            isEdit={isEdit}
            addColor={addColor}
            setAddColor={setAddColor}
          />
        </Col>
      </Row>
    </Card>
  );
}

PromotionForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default PromotionForm;
