import React from 'react';
import { Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';
import { convertDate } from '~/util/Functions';
import { deleteModal } from './actions';

const categoryOptions = {
  promotion: 'Promoção',
  attendance: 'Atendimento',
};

export function generateColumns({ deleteCoupon }) {
  return [
    {
      title: 'Tipo',
      detaIndex: 'kind',
      key: 'kind',
      render: ({ kind }) =>
        ({
          bag_order: 'Sacola',
          cart: 'Pedido',
          both: 'Ambos',
        }[kind]),
    },
    {
      title: 'Área',
      dataIndex: 'category',
      key: 'area',
      render: text => categoryOptions[text],
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Cupom',
      dataIndex: 'code',
      key: 'code',
    },

    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: '300px',
      render: text => (text?.length ? text : 'Sem descrição'),
    },
    // {
    //   title: 'Quantidade de uso',
    //   dataIndex: 'usageQuantity',
    //   key: 'usageQuatity',
    // },
    {
      title: 'Limite de Uso',
      dataIndex: 'usageLimit',
      key: 'usageLimit',
      width: 150,
      render: text => text || 'Sem limite de uso',
    },
    {
      title: 'Validade',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      width: 120,
      render: text =>
        !text || text === '' ? 'Sem expiração' : convertDate(text),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 90,
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Editar">
            <Link
              to={{
                pathname: `/manage/marketing/coupons/edit/${record.id}`,
                state: { coupon: record },
              }}
            >
              <Button
                data-testid="couponEdit"
                type="link"
                icon={<EditOutlined />}
                size="small"
                style={{ margin: 0 }}
              />
            </Link>
          </Tooltip>
          <Tooltip placement="bottom" title="Excluir">
            <Button
              data-testid="couponDelete"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() =>
                deleteModal({
                  deleteCoupon,
                  couponId: record.id,
                  couponName: record.name,
                })
              }
              size="small"
              style={{ margin: 0, color: 'red' }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
}
