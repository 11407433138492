import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Row, Col, Typography } from 'antd';

const MyRow = ({ children }) => (
  <Row
    gutter={32}
    type="flex"
    justify="center"
    align="middle"
    style={{ marginRight: '5px', marginTop: '10px' }}
  >
    {children}
  </Row>
);

MyRow.propTypes = {
  children: PropTypes.node.isRequired,
};

function BagFeedback({ icon, title, description, bottom }) {
  const { Title, Text } = Typography;

  return (
    <>
      <MyRow>
        <Col>
          <span style={{ fontSize: '50px' }}>{icon}</span>
        </Col>
      </MyRow>
      <MyRow>
        <Col>
          <Title level={3}>{title}</Title>
        </Col>
      </MyRow>
      <MyRow>
        <Col>
          <Text>{description}</Text>
        </Col>
      </MyRow>
      <MyRow>{bottom}</MyRow>
    </>
  );
}

BagFeedback.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.node,
  bottom: PropTypes.node,
};

BagFeedback.defaultProps = {
  icon: <CheckCircleOutlined />,
  description: '',
  bottom: <></>,
};

export default BagFeedback;
