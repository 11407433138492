import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

function TablePrice({ table }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <Table
        data-testid="promotionColor"
        dataSource={table.data}
        columns={table.columns}
        rowKey={record => record.key}
        pagination={false}
        bordered
        scroll={{
          x: true,
        }}
      />
    </div>
  );
}

TablePrice.propTypes = {
  table: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default TablePrice;
