import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const UPDATE_CUSTOMER = gql`
  mutation useUpdateCustomer($input: UpdateCustomerInput!) {
    userUpdateCustomer(input: $input) {
      id
      firstName
      lastName
      mobilePhone
      document
      email
      gender
      status
      avatar
      banner
      # sellingCount
      # soldCount
      # ordersCount
      # purchasedCount
      # createdAt
    }
  }
`;

function useUpdateCustomer(options = {}) {
  const [updateCustomer, { loading, data, error }] = useMutation(
    UPDATE_CUSTOMER,
    {
      ...options,
    }
  );

  return [
    ({
      userId = undefined,
      document = undefined,
      firstName = undefined,
      lastName = undefined,
      ddd = undefined,
      mobilePhone = undefined,
      gender = undefined,
      password = undefined,
      passwordConfirmation = undefined,
      avatar = undefined,
      banner = undefined,
      slug = undefined,
    } = {}) => {
      updateCustomer({
        variables: {
          input: {
            userId,
            document: document ? document.replace(/[^\d]+/gi, '') : undefined,
            firstName,
            lastName,
            mobilePhone:
              ddd && mobilePhone
                ? `${ddd}${mobilePhone.replace(/[^\d]+/gi, '')}`
                : '',
            gender,
            password,
            passwordConfirmation,
            avatar,
            banner,
            slug,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUpdateCustomer;
