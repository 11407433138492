export default {
  id: 'discount',
  kind: 'discount',
  presentation: 'Faixa de descontos',
  optionValues: [
    {
      dataTestId: '50-59',
      id: '50-59',
      key: '50-59',
      presentation: '50-59',
      gte: 50,
      lte: 59,
      value: 'de 50% até 59%',
    },
    {
      dataTestId: '60-69',
      id: '60-69',
      key: '60-69',
      presentation: '60-69',
      gte: 60,
      lte: 69,
      value: 'de 60% até 69%',
    },
    {
      dataTestId: '70-79',
      id: '70-79',
      key: '70-79',
      presentation: '70-79',
      gte: 70,
      lte: 79,
      value: 'de 70% até 79%',
    },
    {
      dataTestId: '80-89',
      id: '80-89',
      key: '80-89',
      presentation: '80-89',
      gte: 80,
      lte: 89,
      value: 'de 80% até 89%',
    },
    {
      dataTestId: '90-',
      id: '90-',
      key: '90-',
      presentation: '90-',
      gte: 90,
      lte: null,
      value: 'mais de 90%',
    },
  ],
};
