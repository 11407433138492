import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Col,
  InputNumber,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';

import useSearchProducts from '~/hooks/products/useSearchProducts';
import { createPaginationOptions, getErrorMessage } from '~/util/Functions';
import Button from '~/components/Button';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';
import {
  PRODUCT_STATUS,
  STATE_IN_PAYOUT,
  STATE_IN_RETURN,
  STATE_IN_TRANSIT,
} from '~/constants/Product';
import { generateColumns } from './columns';
import ExpandableProduct from './ExpandableProduct';

const { Text } = Typography;
const inputNumberStyle = { width: '100%' };

function TableProducts({ userId }) {
  const columns = generateColumns();
  const [status, setStatus] = useState(undefined);
  const [searchById, setSearchById] = useState(undefined);

  const [table, setTable] = useState({
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    skip: 0,
  });

  const [searchProducts, { data, loading, error, totalCount }] =
    useSearchProducts();

  const search = useCallback(searchProducts, [
    table.pageSize,
    table.skip,
    userId,
  ]);

  const onSearchProductById = value => {
    setSearchById(value || undefined);
  };

  const handleSearchProductId = () => {
    search({ id: searchById, userId });
  };

  useEffect(() => {
    search({
      first: table.pageSize,
      skip: table.skip,
      userId,
      status,
    });
  }, [search, table.pageSize, table.skip, userId, status]);

  function onChangePage(current, size) {
    const skip = (current - 1) * size;

    setTable({
      pageSize: size,
      currentPage: current,
      skip,
    });
  }

  function onShowSizeChange(_oldPage, newPageSize) {
    const newCurrentPage = Math.ceil(
      (table.currentPage * table.pageSize - table.pageSize + 1) / newPageSize
    );
    const skip = (newCurrentPage - 1) * newPageSize;

    setTable({
      pageSize: newPageSize,
      currentPage: newCurrentPage,
      skip,
    });
  }

  function handleFilterProductByStatus(value) {
    setStatus(value);
  }

  return (
    <>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Row gutter={[16, 8]}>
          <Col lg={18} md={18} xs={24} sm={24}>
            <Row>
              <Text>Busque por ID</Text>
            </Row>
            <Row gutter={[10, 20]} type="flex" align="middle">
              <Col xs={16} sm={20} span={20}>
                <InputNumber
                  data-testid="inputFilter"
                  placeholder="Insira o ID..."
                  min={0}
                  style={inputNumberStyle}
                  value={searchById}
                  onChange={e => onSearchProductById(e)}
                />
              </Col>
              <Col xs={8} sm={4} span={4}>
                <Button
                  data-testid="buttonForm"
                  type="success"
                  block
                  onClick={handleSearchProductId}
                >
                  FILTRAR
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={6} md={6} xs={24} sm={24}>
            <label htmlFor="statusFilter">
              Filtrar por Status
              <Select
                showSearch
                allowClear
                name="statusFilter"
                style={{ width: '100%' }}
                placeholder="Selecione um status"
                optionFilterProp="children"
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
                onChange={value => {
                  handleFilterProductByStatus(value);
                }}
              >
                {PRODUCT_STATUS.map(({ key, label }) => (
                  <Select.Option key={key} value={key}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </label>
          </Col>
        </Row>

        {error && (
          <Alert message={getErrorMessage(error)} type="error" showIcon />
        )}
        <Table
          data-testid="table"
          dataSource={data}
          columns={columns}
          loading={loading}
          rowKey={record => record.id}
          title={() => `Total encontrado - ${totalCount}`}
          bordered
          scroll={{
            x: true,
          }}
          expandable={{
            expandedRowRender: record => <ExpandableProduct product={record} />,
            rowExpandable: record =>
              [STATE_IN_PAYOUT, STATE_IN_RETURN, STATE_IN_TRANSIT].includes(
                record.currentState
              ),
          }}
          pagination={{
            current: table.currentPage,
            total: totalCount,
            showSizeChanger: true,
            hideOnSinglePage: true,
            onShowSizeChange,
            onChange: onChangePage,
            pageSize: table.pageSize,
            pageSizeOptions: createPaginationOptions(
              table.pageSize,
              totalCount
            ),
            position: 'both',
            locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
          }}
        />
      </Space>
    </>
  );
}
TableProducts.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default TableProducts;
