import React from 'react';
import { Badge, Typography } from 'antd';

const { Text } = Typography;

const {
  SEO_KIND_BRAND,
  SEO_KIND_BRAND_TRANSLATED,
  SEO_KIND_CATEGORY,
  SEO_KIND_CATEGORY_TRANSLATED,
  SEO_KIND_ALL,
  SEO_KIND_ALL_TRANSLATED,
} = require('~/constants/Seo');

export const translateKind = kind => {
  switch (kind) {
    case SEO_KIND_BRAND:
      return SEO_KIND_BRAND_TRANSLATED;
    case SEO_KIND_CATEGORY:
      return SEO_KIND_CATEGORY_TRANSLATED;
    case SEO_KIND_ALL:
      return SEO_KIND_ALL_TRANSLATED;
    default:
      return kind;
  }
};

const renderCategoryNode = category => {
  return (
    <Text>
      {category.permalink}{' '}
      <Badge
        status={category.active ? 'success' : 'error'}
        title={category.active ? 'Ativa' : 'Inativa'}
      />{' '}
      <small>
        <Text type="secondary">({category.active ? 'Ativa' : 'Inativa'})</Text>
      </small>
      <small>
        {' '}
        <Text type="secondary">
          (<b>Produtos:</b> {category.productCount})
        </Text>
      </small>
    </Text>
  );
};

// Essa função está no 'util/Seo.js', e não em um Util de categoria
// pois o retorno dela é formatado no modelo que a página de
// criação/edição de SEO precisa
export const groupCategoriesByParentId = categories => {
  return categories
    .sort((a, b) => a.parentId - b.parentId)
    .reduce((acc, category) => {
      if (!category.parentId) {
        acc.push({
          title: renderCategoryNode(category),
          value: category.id,
          key: category.id,
          children: [],
        });
      }

      const parentCategoryIndex = acc.findIndex(
        c => c.key === category.parentId
      );
      if (parentCategoryIndex !== -1) {
        acc[parentCategoryIndex].children.push({
          title: renderCategoryNode(category),
          value: category.id,
          key: category.id,
          children: [],
        });
      }

      acc.map(a => {
        const subCategoryIndex = a.children.findIndex(
          c => c.key === category.parentId
        );

        if (subCategoryIndex !== -1) {
          a.children[subCategoryIndex].children.push({
            title: renderCategoryNode(category),
            value: category.id,
            key: category.id,
            children: [],
          });
        }

        return a;
      });

      return acc;
    }, []);
};
