import React from 'react';
import { Modal, notification } from 'antd';

const { confirm } = Modal;

export function updateTable(refetch, setSubject, setData) {
  refetch()
    .then(
      ({
        data: {
          faqSearchFaqQuestions: { nodes: faqQuestions },
        },
      }) => {
        let faqSubject = null;

        faqQuestions.forEach(element => {
          element.key = element.id;
          faqSubject = element.faqSubject;
        });
        faqQuestions.sort((a, b) =>
          a.weightPosition > b.weightPosition ? 1 : -1
        );

        if (faqSubject) setSubject(faqSubject);
        setData(faqQuestions);

        notification.success({
          message: `Tabela atualizada`,
          description: <div>Tabela atualizada com sucesso</div>,
          placement: 'topRight',
        });
      }
    )
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao atualizar tabela`,
        description: errorUpt.errors
          ? errorUpt.errors.map(e => e.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
}

export function handleSavePosition(data, updateFaqCategoryQuestions) {
  const promises = [];

  data.forEach((item, index) => {
    promises.push(
      updateFaqCategoryQuestions({
        variables: {
          input: {
            id: item.id,
            question: item.question,
            weightPosition: index + 1,
            answer: item.answer || '',
            bagQuestion: item.bagQuestion,
            anchor: item.anchor || '',
          },
        },
      })
    );
  });

  Promise.all(promises)
    .then(() => {
      notification.success({
        message: `Posições das perguntas`,
        description: (
          <div>
            As posições das perguntas da categoria foram salvas com sucesso!
          </div>
        ),
        placement: 'topRight',
      });
    })
    .catch(err => {
      notification.error({
        message: `Erro ao salvar posições das perguntas`,
        description: err.errors
          ? err.errors.map(e => e.message).join(';')
          : err.message,
        placement: 'topRight',
      });
    });
}

export function deleteQuestion(
  questionId,
  data,
  setData,
  deleteFaqCategoryQuestion
) {
  deleteFaqCategoryQuestion({
    variables: {
      input: {
        id: questionId,
      },
    },
  })
    .then(() => {
      const newData = data.filter(obj => {
        if (obj.id !== questionId) return true;
        return false;
      });
      setData(newData);

      notification.success({
        message: `Pergunta deletada`,
        description: <div>A pergunta foi deletada com sucesso!</div>,
        placement: 'topRight',
      });
    })
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao deletar pergunta`,
        description: errorUpt.errors
          ? errorUpt.errors.map(e => e.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
}

export function handleDelete(id, data, setData, deleteFaqCategoryQuestion) {
  confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content: 'Ao excluir este item, ele será apagado permanentemente.',
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteQuestion(id, data, setData, deleteFaqCategoryQuestion);
    },
    onCancel() {
      // Cancel
    },
  });
}
