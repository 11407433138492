export const FIT_TO_SMALL = 'too_small';
export const FIT_TOO_BIG = 'too_big';
export const FIT_TOO_TIGHT = 'too_tight';
export const FIT_TOO_LOOSE = 'too_loose';

export const FIT = 'fit';
export const COLOR_PRINT = 'color_print';
export const UNCOMFORTABLE = 'uncomfortable';
export const IMAGE_WEIRD = 'image_weird';

export const BAD_SMELL_DIRTY = 'bad_smell_dirty';
export const MOLDY = 'moldy';
export const WORN_OUT = 'worn_out';
export const FADED = 'faded';

export const SIZE = 'size';
export const DESCRIPTION = 'description';
export const COLOR = 'color';
export const MODEL = 'Model';

export const UNSTITCHED = 'unstitched';
export const TORN_HOLED = 'torn_holed';
export const PEELED_OFF = 'peeled_off';
export const BROKEN_DETACHED = 'broken_detached';

export const DIDNT_FIT = 'didnt_fit';
export const DISLIKE = 'dislike';
export const QUALITY = 'quality';
export const UNDESCRIBED = 'undescribed';
export const DEFECT = 'defect';
export const RECEIVED_ANOTHER = 'received_another';
export const REGRET = 'regret';

export const TRANSLATED_FIT_TO_SMALL = 'Ficou pequena';
export const TRANSLATED_FIT_TOO_BIG = 'Ficou grande';
export const TRANSLATED_FIT_TOO_TIGHT = 'Ficou justa';
export const TRANSLATED_FIT_TOO_LOOSE = 'Ficou larga';

export const TRANSLATED_FIT = 'Modelagem';
export const TRANSLATED_COLOR_PRINT = 'Cor / estampa';
export const TRANSLATED_UNCOMFORTABLE = 'Desconfortável';
export const TRANSLATED_IMAGE_WEIRD = 'Diferente da foto';

export const TRANSLATED_BAD_SMELL_DIRTY = 'Mal cheiro / suja';
export const TRANSLATED_MOLDY = 'Mofada';
export const TRANSLATED_WORN_OUT = 'Esgarçada';
export const TRANSLATED_FADED = 'Desbotada';

export const TRANSLATED_SIZE = 'Tamanho';
export const TRANSLATED_DESCRIPTION = 'Descrição';
export const TRANSLATED_COLOR = 'Cor';
export const TRANSLATED_MODEL = 'Modelo';

export const TRANSLATED_UNSTITCHED = 'Descosturada';
export const TRANSLATED_TORN_HOLED = 'Rasgada / Furada';
export const TRANSLATED_PEELED_OFF = 'Descascada';
export const TRANSLATED_BROKEN_DETACHED = 'Quebrada / descolada';

export const TRANSLATED_DIDNT_FIT = 'Não Serviu';
export const TRANSLATED_DISLIKE = 'Não gostei';
export const TRANSLATED_QUALITY = 'Qualidade da peça';
export const TRANSLATED_UNDESCRIBED = 'Peça divergente do anunciado';
export const TRANSLATED_DEFECT = 'Defeito';
export const TRANSLATED_RECEIVED_ANOTHER = 'Recebi a peça de outra pessoa';
export const TRANSLATED_REGRET = 'Arrependimento';
