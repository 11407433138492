import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Divider, Alert } from 'antd';
import PropTypes from 'prop-types';

import Upload from '~/components/Upload/Image';

function ImagesForm({ avatar, banner, form }) {
  const [onBannerHover, setOnBannerHover] = useState(false);
  const [onAvatarHover, setOnAvatarHover] = useState(false);
  const [newImage, setNewImage] = useState(false);
  const [hasAvatarError, setAvatarError] = useState(false);
  const [hasBannerError, setBannerError] = useState(false);

  useEffect(() => {
    setNewImage(false);
  }, [avatar, banner]);

  const handleChangeAvatar = uplodedImage => {
    form.setFieldsValue({ avatar: uplodedImage });
    setNewImage(true);
  };

  const handleChangeBanner = uplodedImage => {
    form.setFieldsValue({ banner: uplodedImage });
    setNewImage(true);
  };

  const getStyle = hover => ({
    cursor: 'pointer',
    borderColor: hover ? '#1890ff' : '#d9d9d9',
    borderRadius: '2px',
    borderStyle: 'dashed',
    borderWidth: '1px',
    borderImageOutset: '0',
    borderImageRepeat: 'stretch',
    borderImageSlice: '100%',
    borderImageSource: 'none',
    borderImageWidth: '1',
  });

  return (
    <div style={{ marginBottom: '30px' }}>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={6} lg={24} md={24} xs={24}>
          <Form.Item
            label="Avatar"
            name="avatar"
            data-testid="avatarInput"
            rules={[
              {
                message: 'Inserir uma imagem',
              },
            ]}
          >
            <div
              style={banner && !hasAvatarError ? getStyle(onAvatarHover) : {}}
              onMouseEnter={() => setOnAvatarHover(true)}
              onMouseLeave={() => setOnAvatarHover(false)}
            >
              <Upload
                data-testid="avatarUploadInput"
                accept="image/*"
                onChange={handleChangeAvatar}
                thumb={avatar}
                listType={avatar ? 'picture' : 'picture-card'}
                onError={() => setAvatarError(true)}
              />
            </div>
          </Form.Item>
        </Col>
        <Col xl={18} lg={24} md={24} xs={24}>
          <Form.Item
            label="Banner"
            name="banner"
            data-testid="bannerInput"
            rules={[
              {
                message: 'Inserir uma imagem',
              },
            ]}
          >
            <div
              style={banner && !hasBannerError ? getStyle(onBannerHover) : {}}
              onMouseEnter={() => setOnBannerHover(true)}
              onMouseLeave={() => setOnBannerHover(false)}
            >
              <Upload
                data-testid="bannerUploadInput"
                accept="image/*"
                onChange={handleChangeBanner}
                thumb={banner}
                listType={banner ? 'picture' : 'picture-card'}
                onError={() => setBannerError(true)}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>
      {newImage && (
        <Row gutter={16} type="flex" align="top" justify="center">
          <Col span={24}>
            <Alert
              message="Uma nova imagem foi adicionada, clique em 'Salvar' no formulário 'Informações Básicas' para confirmar a atualização"
              description="Caso as alterações não reflitam na vitrine, talvez seja necessário reindexar o usuário"
              type="info"
              showIcon
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

ImagesForm.defaultProps = {
  avatar: null,
  banner: null,
};

ImagesForm.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
  }).isRequired,
  avatar: PropTypes.string,
  banner: PropTypes.string,
};

export default ImagesForm;
