import React from 'react';
import { notification, Modal } from 'antd';

const { confirm } = Modal;

export function updateTable(refetch, setTableData) {
  refetch()
    .then(
      ({
        data: {
          faqSearchFaqSubjects: { nodes: faqCategories },
        },
      }) => {
        faqCategories.forEach(element => {
          element.key = element.id;
        });
        faqCategories.sort((a, b) =>
          a.weightPosition > b.weightPosition ? 1 : -1
        );
        setTableData(faqCategories);

        notification.success({
          message: `Tabela atualizada`,
          description: <div>Tabela atualizada com sucesso</div>,
          placement: 'topRight',
        });
      }
    )
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao atualizar tabela`,
        description: errorUpt.errors
          ? errorUpt.errors.map(e => e.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
}

export function handleSavePosition({ tableData, updateFaqCategory }) {
  const promises = [];

  tableData.forEach((item, index) => {
    promises.push(
      updateFaqCategory({
        variables: {
          input: {
            id: item.id,
            subject: item.subject,
            weightPosition: index + 1,
            icon: item.icon,
          },
        },
      })
    );
  });

  Promise.all(promises)
    .then(() => {
      notification.success({
        message: `Posições das categorias`,
        description: (
          <div>As posições das categorias foram salvas com sucesso!</div>
        ),
        placement: 'topRight',
      });
    })
    .catch(err => {
      notification.error({
        message: `Erro ao salvar posições das categorias`,
        description: err.errors
          ? err.errors.map(e => e.message).join(';')
          : err.message,
        placement: 'topRight',
      });
    });
}

export function deleteCategory(
  categoryId,
  { deleteFaqCategory, tableData, setTableData }
) {
  deleteFaqCategory({
    variables: {
      input: {
        id: categoryId,
      },
    },
  })
    .then(successUpt => {
      const newData = tableData.filter(obj => {
        if (obj.id !== categoryId) return true;
        return false;
      });
      setTableData(newData);

      notification.success({
        message: `Categoria deletada`,
        description: (
          <div>
            A categoria <b>{successUpt.data.faqDeleteFaqSubject.subject}</b> foi
            deletada com sucesso!
          </div>
        ),
        placement: 'topRight',
      });
    })
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao deletar categoria`,
        description: errorUpt.errors
          ? errorUpt.errors.map(e => e.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
}

export function handleDelete(id, hooks) {
  confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content: 'Ao excluir este item, ele será apagado permanentemente.',
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteCategory(id, hooks);
    },
    onCancel() {
      // Cancel
    },
  });
}
