import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { ApolloProvider } from '@apollo/react-hooks';
import { store } from './appRedux/store';
import history from './services/history';
import { authProvider } from './services/msalAuthProvider';
import { apolloClient } from './services/apolloClient';
import 'antd/dist/antd.less';

import App from './containers/App';
import SignIn from './containers/SignIn';

const NextApp = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ApolloProvider client={apolloClient}>
          {process.env.REACT_APP_BYPASS_LOGIN === 'true' ? (
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          ) : (
            <AzureAD provider={authProvider} reduxStore={store}>
              {({ authenticationState }) => {
                switch (authenticationState) {
                  case AuthenticationState.Unauthenticated:
                    return <SignIn authProvider={authProvider} />;
                  default:
                    return (
                      <Switch>
                        <Route path="/" component={App} />
                      </Switch>
                    );
                }
              }}
            </AzureAD>
          )}
        </ApolloProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default NextApp;
