const formatOrderAdjustAction = orderAdjust => ({
  ...orderAdjust,
  value: parseFloat(orderAdjust?.value),
});

export function formatActionsForm(actions) {
  if (!actions?.orderAdjust && actions?.freeShipping) {
    return {
      freeShipping: actions?.freeShipping,
    };
  }

  if (actions?.orderAdjust && !actions?.freeShipping) {
    return {
      orderAdjust: formatOrderAdjustAction(actions.orderAdjust),
    };
  }

  if (actions?.orderAdjust && actions?.freeShipping) {
    return {
      orderAdjust: formatOrderAdjustAction(actions?.orderAdjust),
      freeShipping: actions?.freeShipping,
    };
  }

  return null;
}
