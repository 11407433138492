import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Select } from 'antd';
import Button from '~/components/Button';

import { addTimeRule } from '~/pages/marketing/promotion/PromotionForm/actions';

const { Option } = Select;

function Time({ form, dispatch, tables }) {
  const [options, setOptions] = useState([]);
  const [config] = useState({
    minMonth: 1,
    maxMonth: 12,
    jumpMonth: 1,
    tableKey: `promotionTime`,
  });

  // Generate time options based on config hook
  const generateOptions = () => {
    const arr = [];

    for (let i = config.minMonth; i <= config.maxMonth; i += config.jumpMonth) {
      arr.push({
        value: i,
        text: `${i} ${i === 1 ? 'mês de cadastro' : 'meses de cadastro'}`,
        ruleId: null,
      });
    }

    arr.push({
      value: '12+',
      text: `Mais de 12 meses de cadastro`,
      ruleId: null,
    });

    // Save generated options for on 'handleAddTable' function
    setOptions(arr);
  };

  useEffect(() => {
    generateOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.maxMonth, config.minMonth, config.jumpMonth]);

  const handleAddTable = data => {
    // Reset select
    form.setFieldsValue({
      time: -1,
    });

    // Get selected option
    const selected = options.filter(item => item.value === data);

    if (selected.length > 0) {
      addTimeRule({
        dispatch,
        tables,
        newRows: [
          {
            key: `time-${data}`,
            time: selected[0],
            quantity: 0,
          },
        ],
        tableKey: config.tableKey,
      });
    }
  };

  // Get selected time and check if is valid
  const handleAdd = () => {
    const time = form.getFieldValue('time');
    if (time >= config.minMonth || time === '12+') {
      handleAddTable(time);
    }
  };

  // Render times options
  const renderOptions = () => {
    return options.map(item => (
      <Option key={item.value} value={item.value}>
        {item.text}
      </Option>
    ));
  };

  return (
    <>
      <Col span={24}>
        <Form.Item name="time" initialValue={-1} label="Tempo" key="time">
          <Select style={{ width: '100%' }}>
            <Option key={-1} value={-1}>
              Selecione o tempo de cadastro
            </Option>
            {renderOptions()}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Button
            data-testid="addTimeRuleBtn"
            type="primary"
            onClick={handleAdd}
          >
            Adicionar
          </Button>
        </Form.Item>
      </Col>
    </>
  );
}

Time.propTypes = {
  form: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default Time;
