import styled from 'styled-components';

export const CardState = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: rgb(58, 203, 199);
  color: #fff;
  padding: 3px;
  font-size: 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  margin-top: 15px;
`;

export const CardName = styled.div`
  text-align: center;
  font-size: 11px;
`;

export const CardID = styled.div`
  text-align: center;
  font-size: 11px;
  color: #aaaaaa;
`;

export const CardPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
`;

export const CardPriceDescription = styled.div`
  font-size: 11px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardPriceText = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

export const CardSalePriceValue = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const CardPriceValue = styled(CardSalePriceValue)`
  ${props =>
    props.saleDiscount && `color: #aaaaaa; text-decoration: line-through;`}
`;

export const CardReverseCode = styled.span`
  text-align: center;
  font-size: 11px;
  color: #888;
`;

export const CardButtons = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: ${props => (props.disabled ? 'none' : 'not-allowed')};
  opacity: ${props => (props.disabled ? 0.4 : '')};
`;

export const CardButtonDetails = styled.small`
  text-decoration: underline;
`;

export const CardDescription = styled.div`
  color: #999;
  font-size: 12px;
`;
