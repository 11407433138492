import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, Table } from 'antd';
import useSearchDispatchHistoric from '~/hooks/orders/useSearchBagDispatchHistoric';
import { historicColumns } from './columns';

function DispatchHistoric({ bagOrderId, handleClose }) {
  const [searchDispatchHistoric, { historic, loading }] =
    useSearchDispatchHistoric();

  useEffect(() => {
    searchDispatchHistoric({ bagOrderId });
  }, [bagOrderId]);

  return (
    <Modal
      centered
      open={!!bagOrderId}
      onCancel={handleClose}
      title="Histórico de Status de Expedição"
      footer={false}
      width={900}
    >
      <Table
        data-testid="table"
        dataSource={historic.reverse()}
        columns={historicColumns}
        loading={loading}
        rowKey={record => record.id}
        bordered
        scroll={{
          x: true,
        }}
        pagination={false}
      />
    </Modal>
  );
}

DispatchHistoric.propTypes = {
  bagOrderId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DispatchHistoric;
