export const SHIPMENT_DISPATCH_AWAITING = 'awaiting';
export const SHIPMENT_DISPATCH_PROCESSING = 'processing';
export const SHIPMENT_DISPATCH_PROCESSED = 'processed';
export const SHIPMENT_DISPATCH_CANCELED = 'canceled';

export const SHIPMENT_DISPATCH_STATUS = [
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSING,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_CANCELED,
];
