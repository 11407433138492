import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, DatePicker, InputNumber, Select } from 'antd';
import moment from 'moment';
import { Option } from 'antd/lib/mentions';
import { ORDER_STATUS } from '~/constants/Order';
import Button from '~/components/Button';
import { SHIPMENT_STATUS } from '~/constants/Shipment';

function Filter({ onFilterChange }) {
  const [filters, setFilters] = useState(null);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const clearFilters = () => {
    onFilterChange(null);
    setFilters({});
    setDateRange({
      start: null,
      end: null,
    });
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xl={4} lg={12} xs={24}>
          <InputNumber
            style={{ width: '100%' }}
            data-testid="inputFilterId"
            placeholder="ID do pedido"
            value={filters?.id}
            controls={false}
            maxLength={12}
            min={0}
            onChange={number =>
              setFilters({
                ...filters,
                id: number || undefined,
              })
            }
          />
        </Col>
        <Col xl={4} lg={12} xs={24}>
          <InputNumber
            style={{ width: '100%' }}
            data-testid="inputFilterProductId"
            placeholder="ID do produto"
            value={filters?.productId}
            controls={false}
            maxLength={12}
            min={0}
            onChange={number =>
              setFilters({
                ...filters,
                productId: number || undefined,
              })
            }
          />
        </Col>

        <Col xl={4} lg={12} xs={24}>
          <Select
            data-testid=""
            style={{ width: '100%' }}
            placeholder="Status do produto"
            value={filters?.orderItemStatus}
            onChange={value => {
              setFilters({
                ...filters,
                orderItemStatus: value === '' ? undefined : value,
              });
            }}
          >
            <Option value="" />
            {ORDER_STATUS.map(status => (
              <Option key={status.key} value={status.key}>
                {status.label}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xl={4} lg={12} xs={24}>
          <Select
            data-testid=""
            style={{ width: '100%' }}
            placeholder="Status de envio"
            value={filters?.currentShipmentStatus}
            onChange={value => {
              setFilters({
                ...filters,
                currentShipmentStatus: value === '' ? undefined : value,
              });
            }}
          >
            <Option value="" />
            {SHIPMENT_STATUS.map(status => (
              <Option key={status.key} value={status.key}>
                {status.label}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xl={8} lg={24} xs={24} data-testid="rangePicker">
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            allowEmpty={[false, false]}
            allowClear={false}
            value={[dateRange.start, dateRange.end]}
            ranges={{
              Dia: [moment(), moment()],
              Semana: [moment().day(0), moment().day(6)],
              Mês: [moment().startOf('month'), moment().endOf('month')],
            }}
            onChange={(date, _) => {
              setDateRange({
                start: date[0],
                end: date[1],
              });
              const startDateRage = `${moment(date[0])
                .startOf('day')
                .format('YYYY-MM-DD[T]HH:mm:ssZ')}`;

              const endDateRage = `${moment(date[1])
                .endOf('day')
                .format('YYYY-MM-DD[T]HH:mm:ssZ')}`;

              return setFilters({
                ...filters,
                requestDate: `${startDateRage},${endDateRage}`,
              });
            }}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xl={6} lg={12} xs={24}>
          <Input
            data-testid="inputFilterCustomerName"
            placeholder="Nome do cliente"
            value={filters?.customerName}
            onChange={event =>
              setFilters({
                ...filters,
                customerName: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }
          />
        </Col>
        <Col xl={5} lg={12} xs={24}>
          <Input
            data-testid="inputFilterCustomerEmail"
            placeholder="Email do cliente"
            value={filters?.customerEmail}
            onChange={event =>
              setFilters({
                ...filters,
                customerEmail: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }
          />
        </Col>
        <Col xl={5} lg={12} xs={24}>
          <Input
            data-testid="inputFilterSellerEmail"
            placeholder="Email do vendedor"
            value={filters?.sellerEmail}
            onChange={event =>
              setFilters({
                ...filters,
                sellerEmail: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }
          />
        </Col>
        <Col xl={4} lg={12} xs={24}>
          <Input
            data-testid="inputFilterReverseCode"
            placeholder="Código postagem"
            value={filters?.reverseCode}
            onChange={event =>
              setFilters({
                ...filters,
                reverseCode: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }
          />
        </Col>
        <Col xl={4} lg={12} xs={24}>
          <Input
            data-testid="inputFilterTrackingCode"
            placeholder="Código rastreio"
            value={filters?.trackingCode}
            onChange={event =>
              setFilters({
                ...filters,
                trackingCode: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col xl={5} lg={8} xs={24}>
          <Button
            data-testid="buttonSubmit"
            type="success"
            spacing={false}
            style={{ width: '100%' }}
            onClick={() => onFilterChange(filters)}
            disabled={
              !(
                filters &&
                Object.values(filters).some(filter => filter !== undefined)
              )
            }
          >
            BUSCAR
          </Button>
        </Col>
        <Col xl={6} lg={8} xs={24}>
          <Button
            data-testid="buttonClear"
            type="default"
            spacing={false}
            style={{ width: '100%' }}
            onClick={clearFilters}
          >
            LIMPAR BUSCA
          </Button>
        </Col>
      </Row>
    </>
  );
}

Filter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
};

export default Filter;
