export default {
  id: 'condition',
  kind: 'condition',
  presentation: 'Estado de uso',
  optionValues: [
    {
      dataTestId: 'newWithTag',
      id: 'new_with_tag',
      key: 'new_with_tag',
      presentation: 'new_with_tag',
      value: 'Nova com etiqueta',
    },
    {
      dataTestId: 'kindlyUsed',
      id: 'kindly_used',
      key: 'kindly_used',
      presentation: 'kindly_used',
      value: 'Gentilmente usada',
    },
  ],
};
