import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const CUSTOMER_SIGNUP = gql`
  mutation useCustomerSignUp($input: SignUpCustomerInput!) {
    userCustomerSignUp(input: $input) {
      user {
        id
        firstName
        lastName
        email
        status
        createdAt
        ... on UserCustomer {
          gender
          # TODO - bugfix: falta o backend corrigir o retorno das queries abaixo
          # sellingCount
          # ordersCount
          # purchasedCount
        }
      }
    }
  }
`;

function useCustomerSignUp(options = {}) {
  const [customerSignUp, { loading, data, error }] = useMutation(
    CUSTOMER_SIGNUP,
    {
      ...options,
    }
  );

  return [
    ({
      email,
      firstName,
      lastName,
      gender = undefined,
      password,
      passwordConfirmation,
      acceptTerms = true,
    } = {}) => {
      customerSignUp({
        variables: {
          input: {
            email,
            firstName,
            lastName,
            password,
            passwordConfirmation,
            gender,
            acceptTerms,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useCustomerSignUp;
