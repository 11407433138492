import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const QUERY_BALANCE_EXTRACT = gql`
  query useBalanceExtract(
    $userId: Int!
    $skip: Int
    $initialDate: String
    $finalDate: String
    $first: Int
  ) {
    balanceAdminShowAccountStatement(
      userId: $userId
      skip: $skip
      initialDate: $initialDate
      finalDate: $finalDate
      first: $first
    ) {
      nodes {
        code
        createdAt
        description
        id
        kind
        title
        updatedAt
        value
        items
      }
      totalCount
    }
  }
`;

function useBalanceExtract(options = {}) {
  const [searchBalanceExtract, { error, data, loading, refetch }] =
    useLazyQuery(QUERY_BALANCE_EXTRACT, {
      fetchPolicy: 'network-only',
      ...options,
    });

  return [
    ({ userId, skip = 0, first = DEFAULT_PAGE_SIZE }) => {
      searchBalanceExtract({
        variables: {
          userId,
          skip,
          first,
        },
      });
    },
    {
      data: data?.balanceAdminShowAccountStatement?.nodes || [],
      totalCount: data?.balanceAdminShowAccountStatement?.totalCount || 0,
      loading,
      error,
      refetch,
    },
  ];
}

export default useBalanceExtract;
