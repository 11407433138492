import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { triggerErrorNotification } from '~/util/Functions';

export const FRAGMENT_BASIC_USER = gql`
  fragment BasicUserFragment on UserInterface {
    id
    firstName
    lastName
    email
    kind
  }
`;

export const FRAGMENT_ADDRESS_USER = gql`
  fragment AddressUserFragment on UserInterface {
    id
    addresses {
      address1
      address2
      city
      createdAt
      id
      kind
      lat
      lng
      neighborhood
      number
      state {
        abbr
        code
        country
        id
        name
      }
      updatedAt
      userId
      zipcode
    }
  }
`;

export const FRAGMENT_BANK_USER = gql`
  fragment BankUserFragment on UserInterface {
    id
    bankingAccount {
      account
      accountType
      agency
      code
      id
      name
      userId
    }
  }
`;

export const FRAGMENT_FULL_USER = gql`
  fragment FullUserFragment on UserInterface {
    # TODO: O parametro avatar esta comentado porque atualmente esta quebrando no backend
    avatar
    banner
    # ordersCount
    # profilableId
    # profilableType
    # dateLastLogin
    # followersCount
    # profileImage
    # soldCount
    createdAt
    currentLastBalance
    document
    documentType
    id
    lastProductIds
    likedProductIds
    mobilePhone
    slug
    status
    updatedAt
    ... on UserCustomer {
      createdAt
      miniBio
      ordersCount
      sellingCount
      purchasedCount
      soldCount
      gender
    }
    ... on UserOng {
      amountTransferred
      responsible
      sellingCount
      soldCount
      miniBio
      website
    }
    ... on UserPartner {
      responsible
      # bagsGeneratedCount
      # bagsIdentifiedCount
      # vouchersGeneratedCount
    }
  }
`;

export const SEARCH_USERS_BY_DATABASE = gql`
  query useListUserByDatabase(
    $full: Boolean = false
    $address: Boolean = false
    $bank: Boolean = false
    $first: Int!
    $email: String
    $name: String
    $skip: Int!
    $kind: Kind
    $ids: [Int!]
  ) {
    usersUserDatabaseSearchUsers(
      first: $first
      email: $email
      name: $name
      skip: $skip
      kind: $kind
      ids: $ids
    ) {
      nodes {
        ...BasicUserFragment
        ...AddressUserFragment @include(if: $address)
        ...BankUserFragment @include(if: $bank)
        ...FullUserFragment @include(if: $full)
      }
    }
  }
  ${FRAGMENT_BASIC_USER}
  ${FRAGMENT_ADDRESS_USER}
  ${FRAGMENT_BANK_USER}
  ${FRAGMENT_FULL_USER}
`;

export function useListUserByDatabase(options = {}) {
  const [queryUsers, { data, loading, error }] = useLazyQuery(
    SEARCH_USERS_BY_DATABASE,
    {
      fetchPolicy: 'network-only',
      onError: err => {
        triggerErrorNotification('Erro ao buscar usuário(s)', err);
      },
      ...options,
    }
  );

  return [
    ({
      kind = undefined,
      ids = undefined,
      first = 10,
      email = '',
      name = undefined,
      skip = 0,
      full = false,
      address = false,
      bank = false,
    } = {}) => {
      queryUsers({
        variables: {
          kind,
          ids,
          first,
          email,
          name,
          skip,
          full,
          address,
          bank,
        },
      });
    },
    {
      users: data?.usersUserDatabaseSearchUsers?.nodes || [],
      loading,
      error,
    },
  ];
}
