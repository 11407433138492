export const DEV_MODE = process.env.NODE_ENV === 'development';
export const TAB_SIZE = 992;

export const DEFAULT_PAGE_SIZE = 40;

export const regexIsDateConverted =
  /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/-]\d{4}$/;
export const regexIsDateWithTimezone =
  /([+-](?:2[0-3]|[01][0-9])(:)?[0-5][0-9])$/;

export const DATE_FORMAT_SIMPLE = 'YYYY-MM-DD';
