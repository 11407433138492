import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { generateColumns } from './columns';

function TableCustomFields({
  optionTypes,
  properties,
  setCategory,
  table,
  setTable,
}) {
  const [data, setData] = useState([...optionTypes, ...properties]);
  const columns = generateColumns({
    setTable,
    optionTypes,
    properties,
    setCategory,
  });

  useEffect(() => {
    setData([...optionTypes, ...properties]);
  }, [optionTypes, properties]);

  return (
    <Table
      data-testid="table"
      dataSource={data}
      columns={columns}
      loading={table.loading}
      rowKey={record => record.id}
      title={() => (
        <div style={{ textAlign: 'center' }}>
          Total encontrado - {data.length}
        </div>
      )}
      bordered
      scroll={{
        x: 'max-content',
      }}
      pagination={false}
    />
  );
}

TableCustomFields.propTypes = {
  optionTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCategory: PropTypes.func.isRequired,
  table: PropTypes.shape({
    totalCount: PropTypes.number,
    loading: PropTypes.bool,
  }).isRequired,
  setTable: PropTypes.func.isRequired,
};

export default TableCustomFields;
