import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const DragSortTableContext = createContext();

const DragSortTableProvider = ({ children }) => {
  const [tableData, setTableData] = useState([]);

  return (
    <DragSortTableContext.Provider value={{ tableData, setTableData }}>
      {children}
    </DragSortTableContext.Provider>
  );
};

const useDragSortTableContext = () => {
  const context = useContext(DragSortTableContext);
  if (!context) {
    throw new Error(
      'useDragSortTableContext must be used within a DragSortTableProvider'
    );
  }
  return context;
};

DragSortTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useDragSortTableContext, DragSortTableProvider };
