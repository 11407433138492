export const USER_TAB_DATA = 'data';
export const USER_TAB_ORDER = 'order';
export const USER_TAB_BAG = 'bag';
export const USER_TAB_PRODUCTS = 'products';
export const USER_TAB_BALANCE = 'balance';

export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';

export const USER_GENDER_FEMALE = 'female';
export const USER_GENDER_MALE = 'male';
export const USER_GENDER_PREFER_NOT_TO_DISCLOSE = 'prefer_not_to_disclose';
export const USER_GENDER_UNCERTAIN = 'uncertain';

export const USER_KIND_CUSTOMER = 'customer';
export const USER_KIND_ONG = 'ong';
export const USER_KIND_PARTNER = 'partner';

export const USER_ADDRESS_KIND_SHIPPING = 'shipping';
export const USER_ADDRESS_KIND_BILLING = 'billing';

export const USER_BANK_ACCOUNT_TYPE_CURRENT = 'current';
export const USER_BANK_ACCOUNT_TYPE_SAVINGS = 'savings';

export const PARTICLES_OF_NAME = ['dos', 'da', 'das', 'de', 'do'];

export const userKindLabels = {
  [USER_KIND_CUSTOMER]: 'Cliente',
  [USER_KIND_ONG]: 'Ong',
  [USER_KIND_PARTNER]: 'Parceiro',
};

export const userBankAccountTypeLabels = {
  [USER_BANK_ACCOUNT_TYPE_CURRENT]: 'Conta Corrente',
  [USER_BANK_ACCOUNT_TYPE_SAVINGS]: 'Conta Poupança',
};

export const userGenderLabels = {
  [USER_GENDER_FEMALE]: 'Feminino',
  [USER_GENDER_MALE]: 'Masculino',
  [USER_GENDER_PREFER_NOT_TO_DISCLOSE]: 'Prefiro não declarar',
  [USER_GENDER_UNCERTAIN]: 'Incerto',
};
