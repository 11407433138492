import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  AutoComplete,
  Row,
  Badge,
  Col,
  Typography,
  Input,
  Tag,
  Form,
} from 'antd';

import { BRANDS_LIST } from '~/apollo/bag/brand';
import useDebounce from '~/util/useDebounce';
import { TABLE_TYPE_FILTER_BRAND } from '../../RulesReducer/constants';

function BrandSearch({
  selectedBrands,
  setSelectedBrands,
  handleAddFilter,
  tables,
  form,
}) {
  const { Text } = Typography;
  const [options, setOptions] = useState([]);
  const [rawOptions, setRawOptions] = useState([]);
  const debounce = useDebounce();

  const [searchBrands, { loading }] = useLazyQuery(BRANDS_LIST, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ productsProductSearchBrands: { nodes: brands } }) => {
      const newOptions = brands.map(item => {
        return {
          value: item.id,
          label: (
            <Text>
              {item.name}{' '}
              <Badge
                status={item.active ? 'success' : 'error'}
                title={item.active ? 'Ativa' : 'Inativa'}
              />{' '}
              <small>
                <Text type="secondary">
                  ({item.active ? 'Ativa' : 'Inativa'})
                </Text>
              </small>{' '}
              <small>
                <Text type="secondary">
                  (<b>Slug:</b> {item.slug}){' '}
                </Text>
                <Text type="secondary">
                  (<b>Produtos:</b> {item.productCount})
                </Text>
              </small>
            </Text>
          ),
        };
      });

      setOptions(newOptions);
      setRawOptions(brands);
    },
    onError: () => {
      setOptions([]);
      setRawOptions([]);
    },
  });

  const onSearch = searchText => {
    if (!searchText) return;

    debounce(() => {
      searchBrands({
        variables: {
          name: searchText,
          first: 20,
          skip: 0,
          orderBy: 'NAME_ASC',
        },
      });
    }, 500);
  };

  const onSelect = id => {
    const isBrandSelected = selectedBrands.find(brand => brand.id === id);

    if (!isBrandSelected) {
      const selected = rawOptions.find(item => item.id === id);

      setSelectedBrands(prevState => [
        {
          ...selected,
          key: selected.id,
          ruleId: null,
        },
        ...prevState,
      ]);
    }

    form.setFieldsValue({ searchBrands: '' });
  };

  const removeSelectedBrand = id => {
    const selected = selectedBrands.find(item => item.id === id);
    if (selected) {
      const filteredBrands = selectedBrands.filter(item => item.id !== id);
      setSelectedBrands(filteredBrands);

      const brandExists = tables.find(
        table => table.type === TABLE_TYPE_FILTER_BRAND
      );
      if (brandExists) {
        const filteredBrandsOnTable = brandExists.data.filter(
          brand => brand.id !== id
        );
        handleAddFilter('brand', filteredBrandsOnTable);
      }
    }
  };

  return (
    <Row gutter={16}>
      <Col span={24}>
        {selectedBrands.map(brand => (
          <Tag
            key={brand.id}
            closable
            onClose={() => removeSelectedBrand(brand.id)}
            style={{ marginBottom: '10px' }}
            data-testid={brand.name}
          >
            {brand.name}
          </Tag>
        ))}
        <Form.Item name="searchBrands">
          <AutoComplete
            style={{ width: '100%', marginBottom: '10px' }}
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            placeholder="Busque uma marca"
            data-testid="searchBrands"
          >
            <Input.Search style={{ marginBottom: 0 }} loading={loading} />
          </AutoComplete>
        </Form.Item>
      </Col>
    </Row>
  );
}

BrandSearch.propTypes = {
  setSelectedBrands: PropTypes.func.isRequired,
  selectedBrands: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      ruleId: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleAddFilter: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      columns: PropTypes.arrayOf(Object).isRequired,
      data: PropTypes.arrayOf(Object).isRequired,
      key: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
};

export default BrandSearch;
