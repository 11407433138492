import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SendFilesConciliation from '~/pages/financial/conciliationNFSe/sendFiles';
import StatusConciliation from '~/pages/financial/conciliationNFSe/status';
import { oneOfAzureGroups } from '~/util/Auth';
import NotFound from '~/containers/NotFound';
import GroupRoute from '../../../components/GroupRoute';
import {
  TECNOLOGIA,
  CADASTRO,
  ATENDIMENTO,
  LOGISTICA,
} from '../../../constants/UserGroups';

function Financial({ match, groups }) {
  return (
    <Switch>
      {/* Conciliação de NFSe - Enviar arquivos para conciliação */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        path={`${match.url}/conciliation-nfse/send-files`}
        component={SendFilesConciliation}
        exact
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        path={`${match.url}/conciliation-nfse/status`}
        component={StatusConciliation}
        exact
      />
      <Route component={NotFound} />
    </Switch>
  );
}

Financial.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Financial.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Financial);
