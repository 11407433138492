import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';
import { AntFormFields } from './components/formFields';
import { AntCard } from './components/card';
import { AntPagination } from './components/pagination';
import { AntTable } from './components/table';
import { AntToastNotification } from './components/notification';
import { AntModal } from './components/modal';
import { AntAlertMessageBox } from './components/alert';
import { AntButtons } from './components/buttons';

const { Footer, Content } = Layout;

export const AntDesignStyleExtension = createGlobalStyle`
  ${AntFormFields}
  ${AntCard}
  ${AntPagination}
  ${AntTable}
  ${AntToastNotification}
  ${AntModal}
  ${AntAlertMessageBox}
  ${AntButtons}
`;

export const StyledLayout = styled(Layout)`
  height: 100vh;
`;

export const StyledContent = styled(Content)`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const StyledMain = styled.main`
  box-sizing: border-box;
  padding: 32px 32px 0;
  flex: 1;
`;

export const StyledFooter = styled(Footer)`
  border-top: solid 1px #e8e8e8;
  padding: 14px 32px;
  margin-top: 32px;
  color: #545454;
  font-size: 14px;
  background: #fffffe;
`;
