import React from 'react';
import BlockList from './list';
import { DragSortTableProvider } from '~/providers/DragSortTable';

const BlockListCard = () => {
  return (
    <DragSortTableProvider>
      <BlockList />
    </DragSortTableProvider>
  );
};

export default BlockListCard;
