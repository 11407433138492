import { useMutation } from '@apollo/react-hooks';
import { notification } from 'antd';
import { BLOCK_ATTRIBUTE_DELETE } from '~/apollo/marketing/home';

function useDeleteBlockAttribute(options = {}) {
  const [deleteAttribute, { loading, data, error }] = useMutation(
    BLOCK_ATTRIBUTE_DELETE,
    {
      onError: err => {
        notification.error({
          message: `Erro ao excluir atributo`,
          description: err.errors
            ? err.errors.map(obj => obj.message).join(';')
            : err.message,
          placement: 'topRight',
        });
      },
      onCompleted() {
        notification.success({
          message: `Atributo excluído com sucesso.`,
          placement: 'topRight',
        });
      },
      ...options,
    }
  );

  return [
    ({ id = undefined } = {}) => {
      deleteAttribute({
        variables: {
          input: {
            id,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useDeleteBlockAttribute;
