import styled from 'styled-components';

import { Card as CardAntd, Slider as SliderAntd } from 'antd';

export const Card = styled(CardAntd).attrs({
  size: 'small',
})`
  box-shadow: ${props =>
    props?.$error ? '0px 0px 4px 0px rgba(255, 0, 0, 1)' : ''};

  .ant-card-head {
    background-color: #ccf2f1;
    border: 0;
  }
  .ant-card-body {
    background-color: #ebfafa;
    border: 0;
    margin: 10x 10x 0px 10px;
  }
  .ant-card-actions {
    background-color: #dcf6f5;
    border: 0;
  }
  .ant-card-head-title,
  .ant-card-extra {
    margin: 0px 10px;
    padding: 0px 0px;
  }
  .ant-card-extra > button {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
  }
  .anticon-delete {
    color: #3acbc7;
  }
`;

export const Slider = styled(SliderAntd)`
  .ant-slider-mark-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12.5px;
    width: 60px;
    word-wrap: break-word;
  }
  .ant-slider-rail,
  .ant-slider-track {
    background-color: ${props =>
      props?.disabled ? '#d9d9d9 !important' : 'none'};
  }

  @media screen and (max-width: 1200px) {
    .ant-slider-handle {
      width: 22px;
      height: 22px;
      bottom: -5px;
    }
  }
`;
