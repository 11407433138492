export const ATENDIMENTO = 'ad-atendimento';
export const CADASTRO = 'ad-cadastro';
export const LOGISTICA = 'ad-logistica';
export const MARKETING = 'ad-marketing';
export const TECNOLOGIA = 'ad-tecnologia';

export const USER_GROUPS = [
  ATENDIMENTO,
  CADASTRO,
  LOGISTICA,
  MARKETING,
  TECNOLOGIA,
];
