import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip, Typography } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

export function generateColumns(deleteSeoContents) {
  return [
    {
      title: 'Título',
      dataIndex: 'mainTitle',
      key: 'title',
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'type',
      render: (text, record) =>
        record.kind === 'category' ? 'Categoria' : 'Marca',
    },
    {
      title: 'Tipo Selecionado',
      dataIndex: 'contentName',
      key: 'typeItem',
      render: (text, record) => (
        <>
          <Text>{record.contentName}</Text>{' '}
          <Text code>ID: {record.contentId}</Text>
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="Editar SEO">
            <Link to={`/manage/content/seo/edit/${record.id}`}>
              <Button
                type="link"
                icon={<EditOutlined />}
                data-testid="seoEditBtn"
                size="small"
                style={{ marginBottom: 0 }}
              />
            </Link>
          </Tooltip>
          <Tooltip placement="bottom" title="Deletar SEO">
            <Button
              type="link"
              icon={<DeleteOutlined />}
              data-testid="seoDeleteBtn"
              onClick={() => deleteSeoContents(record.id)}
              size="small"
              style={{ marginBottom: 0, color: 'red' }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
}
