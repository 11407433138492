import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Card } from 'antd';
import { QUERY_RETURN } from '~/apollo/logistics/return';

import Filter from './Filter';
import TableReturn from './TableReturn';

function Return() {
  const [orders, setOrders] = useState([]);
  const [table, setTable] = useState({
    loading: false,
  });

  function updateHooks(searchOrders) {
    setOrders(searchOrders);
    setTable({ loading: false });
  }

  const [listOrdersToReturn] = useLazyQuery(QUERY_RETURN, {
    context: { newBackend: true },
    fetchPolicy: 'network-only',
    onCompleted: ({ ordersOrderListOrders: { nodes: searchOrders } }) => {
      updateHooks(searchOrders);
    },
    onError: () => updateHooks([], 0),
  });

  const handleFiltering = filters => {
    if (
      filters &&
      Object.values(filters).some(filter => filter !== undefined)
    ) {
      setTable({ loading: true });
      listOrdersToReturn({
        variables: filters,
      });
    } else {
      if (filters === null) setOrders([]);
      setTable({ loading: false });
    }
  };

  return (
    <Card data-testid="returnForm" title="APROVAÇÃO DE DEVOLUÇÃO">
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter onFilterChange={handleFiltering} />
      </Card.Grid>
      <Card.Grid style={{ width: '100%' }}>
        <TableReturn
          table={table}
          setOrders={setOrders}
          setTable={setTable}
          orders={orders}
        />
      </Card.Grid>
    </Card>
  );
}

export default Return;
