import React, { useEffect, useState } from 'react';
import { Form, Typography, Row, Col, Select, Tag, Input } from 'antd';

import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { notifyError } from '~/util/Functions';
import { createObj } from '~/util/Auxiliary';
import useSearchProducts from '~/hooks/products/useSearchProducts';
import { Card } from '../styles';

const arrayField = ['rules', 'product'];
const { Search } = Input;
const { Text } = Typography;
const { Option } = Select;

function Products({ form, deleteHandler, initialRules, error: ruleError }) {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [matchPolicy, setMatchPolicy] = useState('_all');

  const setFormFieldsValue = (customFieldName, value) => {
    form.setFieldsValue(createObj(customFieldName, value, arrayField));
  };

  const [searchProducts, { loading }] = useSearchProducts({
    onCompleted: ({ productsProductDatabaseSearchProducts: { nodes } }) => {
      if (nodes.length === 0) {
        setError('ID(s) não encontrado(s)');
      } else {
        const newTags = [...tags, ...nodes];
        setError(false);
        setTags(newTags);
        setFormFieldsValue('productIds', newTags);
      }
    },
    onError: err => {
      notifyError('Erro ao buscar produtos', err);
    },
  });

  useEffect(() => {
    if (initialRules && initialRules?.productIds?.length) {
      searchProducts({
        first: 10,
        skip: 0,
        basic: false,
        onlyName: true,
        ids: initialRules.productIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchProducts = value => {
    if (!value) return;

    const ids = value
      .split(',')
      .map(item => Number(item))
      .filter(item => !!item && !tags.find(tag => tag.id === item));

    if (ids?.length) {
      form.resetFields([[...arrayField, 'productIds']]);
      searchProducts({
        first: 10,
        skip: 0,
        basic: false,
        onlyName: true,
        ids,
      });
    } else {
      setError(
        'ID(s) inválidos ou duplicados. Por favor verifique os dados preenchidos!'
      );
    }
  };

  const onSelectPolicy = value => {
    setMatchPolicy(value);
  };

  const removeTag = id => {
    const newSelectedItens = tags.filter(item => item.id !== id);
    setFormFieldsValue('productIds', newSelectedItens);
    setTags(newSelectedItens);
  };

  return (
    <Card
      $error={ruleError}
      data-testid="productsRule"
      size="small"
      title="Quais produtos são válidos?"
      extra={
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
        />
      }
    >
      <Row gutter={[16, 16]} type="flex" align="top" justify="start">
        <Col span={tags?.length ? 24 : 0}>
          {tags?.map(item => (
            <Tag
              key={item.id}
              closable
              onClose={() => removeTag(item.id)}
              style={{ marginBottom: '4px', whiteSpace: 'normal' }}
            >
              <span style={{ color: 'rgba(0, 0, 0, 0.85' }}>{item.name}</span>
            </Tag>
          ))}
        </Col>
      </Row>
      <Form.Item
        name={[...arrayField, 'productIds']}
        validateStatus={error ? 'error' : undefined}
        rules={[
          () => ({
            validator() {
              if (tags.length) {
                setError(false);
                return Promise.resolve();
              }
              return Promise.reject(new Error('Adicione ao menos um produto'));
            },
          }),
        ]}
      >
        <Row gutter={[16, 16]} type="flex" align="top" justify="start">
          <Col span={24}>
            <span style={{ fontSize: '12px' }}>
              Inserir os IDs dos produtos, separados por vírgula.
            </span>
            <Search
              placeholder="Selecione os produtos"
              onSearch={onSearchProducts}
              style={{ width: '100%' }}
              className="search-products"
              loading={loading}
              allowClear
            />
            {error && <span style={{ color: '#ff4d4f' }}>{error}</span>}
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ marginBottom: 0 }}>
        <Row gutter={16} type="flex" align="middle" justify="start">
          <Col>
            <Text>O pedido deve conter</Text>
          </Col>
          <Col>
            <Form.Item
              noStyle
              name={[...arrayField, 'matchPolicyTypeProduct']}
              initialValue={initialRules?.matchPolicyTypeProduct || '_all'}
              rules={[{ required: true, message: 'Selecione 1 opção' }]}
            >
              <Select
                style={{ width: '150px' }}
                data-testid="productsRuleOptionSelect"
                onSelect={onSelectPolicy}
                loading={loading}
              >
                <Option value="_all">Todos</Option>
                <Option value="_any">Pelo menos um</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Text>
              {matchPolicy === '_all' ? 'estes produtos' : 'destes produtos'}
            </Text>
          </Col>
        </Row>
      </Form.Item>
    </Card>
  );
}

Products.defaultProps = {
  error: false,
  initialRules: {},
};

Products.propTypes = {
  form: PropTypes.shape().isRequired,
  deleteHandler: PropTypes.func.isRequired,
  error: PropTypes.bool,
  initialRules: PropTypes.instanceOf(Object),
};

export default Products;
