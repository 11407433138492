import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import styled from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme } = THEME_BEHAVIOR;

const CustomButton = styled.button`
  color: ${theme.color};
  background: none;
  text-align: left;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
`;

const InfoModal = ({ label, button, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <span>{label} </span>
      <CustomButton onClick={() => setOpen(true)}>{button}</CustomButton>
      <Modal
        destroyOnClose
        open={open}
        closable
        footer={false}
        onCancel={() => setOpen(false)}
      >
        {children}
      </Modal>
    </div>
  );
};

InfoModal.propTypes = {
  label: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfoModal;
