const Auxiliary = props => props.children;

export const createObj = (customFieldName, value, fieldName) => {
  const obj = {};
  let objAux = obj;
  fieldName.forEach(item => {
    objAux[item] = {};
    objAux = objAux[item];
  });

  objAux[customFieldName] = value;

  return obj;
};

export default Auxiliary;
