import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Image as AntdImage } from 'antd';
import { getImageUrl } from '~/util/Functions';

const fallback = `${process.env.PUBLIC_URL}/images/fallbackImage.png`;

const Image = ({ src, alt, ...restOfProps }) => {
  return (
    <CustomImg
      data-testid="img"
      src={src || fallback}
      preview={{
        src: getImageUrl(src, '1024x0') || getImageUrl(fallback, '1024x0'),
      }}
      alt={alt}
      fallback={fallback}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
    />
  );
};

export const CustomImg = styled(AntdImage)`
  @media (max-width: 400px) {
    width: ${props => (props.full ? `${props.full}%` : '')};
  }
`;

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  alt: 'Imagem',
  src: fallback,
};

export default React.memo(Image);
