import React from 'react';
import PropType from 'prop-types';
import { Card, Row, Col, Typography } from 'antd';
import { WindowsOutlined } from '@ant-design/icons';
import Image from '~/components/Image';
import Button from '../../components/Button';

function LoginCard({ login, error }) {
  const { Title, Text } = Typography;

  return (
    <>
      <Row
        justify="space-around"
        align="middle"
        style={{
          height: '100vh',
          background:
            'radial-gradient(circle, rgba(58,203,199,0.1407913507199755) 0%, rgba(58,203,199,1) 100%)',
        }}
      >
        <Card style={{ width: 400 }}>
          <Row gutter={16} type="flex" justify="center" align="middle">
            <Col span={16}>
              <Image
                alt="logo"
                src={require('../../assets/images/logo-repassa.svg')}
                style={{ maxWidth: '100%' }}
              />
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Title
                type="secondary"
                level={3}
                style={{ fontWeight: 'lighter' }}
              >
                backoffice
              </Title>
            </Col>
            <Col span={24}>
              <Row
                style={{ marginTop: '25px' }}
                gutter={16}
                type="flex"
                justify="center"
                align="middle"
              >
                <Col>
                  <Button
                    data-testid="azureLoginBtn"
                    icon={<WindowsOutlined />}
                    size="large"
                    type="repassa"
                    onClick={login}
                  >
                    Login com Azure
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              {error && (
                <Text type="danger" data-testid="signInError">
                  Ocorreu um erro durante a autenticação, por favor, tente
                  novamente!
                </Text>
              )}
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
}

LoginCard.propTypes = {
  login: PropType.func.isRequired,
  error: PropType.shape(),
};

LoginCard.defaultProps = {
  error: null,
};

export default LoginCard;
