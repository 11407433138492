const { USER_GROUPS } = require('~/constants/UserGroups');

export const oneOfAzureGroups = (componentProps, propName) => {
  const azureGroups = componentProps[propName];

  if (!Array.isArray(azureGroups))
    return new Error(`${propName} must be an array.`);

  if (azureGroups.length < 1)
    return new Error(`${propName} must have at least one element.`);

  const hasAtLeastOne = azureGroups.reduce((acc, item) => {
    if (USER_GROUPS.includes(item)) acc = true;
    return acc;
  }, false);

  if (!hasAtLeastOne)
    return new Error(`${propName} must have at least one of ${USER_GROUPS}.`);

  return null;
};
