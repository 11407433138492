import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, card } = THEME_BEHAVIOR;

export const AntCard = css`
  body {
    .ant-card {
      border-radius: ${theme.borderRadius};
    }
    .ant-card-head-title {
      font-size: ${card.title.fontSize};
      font-weight: ${card.title.fontWeight};
      color: ${card.title.color};
      .anticon {
        svg {
          width: ${card.icon.size};
          height: ${card.icon.size};
          fill: ${card.icon.color};
        }
      }
    }
  }
`;
