import React from 'react';
import PropTypes from 'prop-types';
import QuestionList from './list';
import { DragSortTableProvider } from '~/providers/DragSortTable';

const QuestionCard = ({ match, location }) => {
  return (
    <DragSortTableProvider>
      <QuestionList match={match} location={location} />
    </DragSortTableProvider>
  );
};

QuestionCard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,

  location: PropTypes.shape({
    state: PropTypes.shape({
      subjectName: PropTypes.shape().isRequired,
      subject: PropTypes.shape({
        subject: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default QuestionCard;
