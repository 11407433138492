import { notification } from 'antd';

export const triggerErrorNotification = (title, err) => {
  notification.error({
    message: title,
    duration: 10,
    description: err.errors
      ? err.errors.map(objError => objError.message).join(';')
      : err.message,
    placement: 'topRight',
  });
};

export const triggerSuccessNotification = (title, text) => {
  notification.success({
    message: title,
    duration: 10,
    description: text,
    placement: 'topRight',
  });
};

export const handleSubmitDefaultForm = (
  values,
  { isEdit, createBrand, updateBrand, brand, setBrand, setRedirectToEdit }
) => {
  if (!isEdit) {
    createBrand({
      variables: {
        input: {
          name: values.name,
          seoTitle: values.seoTitle,
          seoDescription: values.seoDescription,
          seoKeywords: values.seoKeywords,
        },
      },
    })
      .then(successCreate => {
        const brandData = successCreate.data.brandsProductCreateBrand;

        setBrand({
          ...brand,
          id: brandData.id,
          default: {
            name: brandData.name,
            seoTitle: brandData.seoTitle,
            seoDescription: brandData.seoDescription,
            seoKeywords: brandData.seoKeywords,
          },
        });
        setRedirectToEdit(true);
        triggerSuccessNotification('Marca criada', brandData.message);
      })
      .catch(errorCreate => {
        triggerErrorNotification('Erro ao criar marca', errorCreate);
      });
  } else {
    updateBrand({
      variables: {
        input: {
          id: brand.id,
          name: values.name,
          seoTitle: values.seoTitle,
          seoDescription: values.seoDescription,
          seoKeywords: values.seoKeywords,
        },
      },
    })
      .then(successUpdate => {
        const brandData = successUpdate.data.brandsProductUpdateBrand;

        setBrand({
          ...brand,
          id: brandData.id,
          default: {
            name: brandData.name,
            seoTitle: brandData.seoTitle,
            seoDescription: brandData.seoDescription,
            seoKeywords: brandData.seoKeywords,
          },
        });
        triggerSuccessNotification(
          'Marca atualizada',
          'Marca atualizada com sucesso'
        );
      })
      .catch(errorUpdate => {
        triggerErrorNotification('Erro ao atualizar marca', errorUpdate);
      });
  }
};

export const loadBrands = ({ data, setBrand, setLoading }) => {
  if (data) {
    const {
      productsProductSearchBrands: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [brand] = nodes;
      setBrand(prevState => {
        return {
          ...prevState,
          id: brand.id,
          default: {
            name: brand.name,
            seoTitle: brand.seoTitle,
            seoDescription: brand.seoDescription,
            seoKeywords: brand.seoKeywords,
          },
        };
      });
    }
    setLoading(false);
  }
};
