import React from 'react';
import CategoryList from './list';
import { DragSortTableProvider } from '~/providers/DragSortTable';

const CategoryCard = () => {
  return (
    <DragSortTableProvider>
      <CategoryList />
    </DragSortTableProvider>
  );
};

export default CategoryCard;
