import {
  BACKEND_LOGIN_SUCCESS,
  BACKEND_LOGIN_ERROR,
} from '../../constants/ActionTypes';

export const backendLogin = (obj, type) => dispatch => {
  if (type === 'success') {
    dispatch({
      type: BACKEND_LOGIN_SUCCESS,
      payload: obj,
    });
  } else {
    dispatch({
      type: BACKEND_LOGIN_ERROR,
      payload: obj,
    });
  }
};
