import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Tooltip /* , Modal, Select, Timeline, Row, Col */ } from 'antd';
import {
  LinkOutlined,
  QuestionCircleOutlined,
  ExportOutlined,
  // FileExcelOutlined,
  // ImportOutlined,
} from '@ant-design/icons';

import Image from '~/components/Image';
import {
  getImageUrl,
  toCurrencyFormat,
  formatPascalCase,
  normalizeAssetsUri,
} from '~/util/Functions';
import {
  IMAGE_POSITION_PRINCIPAL,
  PARTICLES_OF_PRODUCT_NAME,
} from '~/constants/Product';
import { getOrderItemStatus } from '~/util/statusConverter';
import { ORDER_ORIGIN_ORDER_DETAILS } from '~/constants/Order';
import {
  CardState,
  CardTextContainer,
  CardName,
  CardPrice,
  CardPriceDescription,
  CardPriceValue,
  CardID,
  CardReverseCode,
  CardPriceText,
  // CardButtons,
  // CardButtonDetails,
  // CardDescription,
} from './styles';

// const { confirm } = Modal;
// const { Option } = Select;

function CardItem({
  images,
  name,
  slug,
  itemValue,
  adjustmentTotal,
  itemTotal,
  status,
  itemId,
  reverseCode,
  devolutionItem,
}) {
  const [itemStatus /* , setItemStatus */] = useState(status);
  // const [visible, setVisible] = useState(false);
  // const [open, setOpen] = useState(false);

  const principalImage =
    images?.find(item => item?.position === IMAGE_POSITION_PRINCIPAL)
      ?.imageUrl || images?.[0]?.imageUrl;

  // const handleGenerate = () => {
  //   confirm({
  //     title: 'Deseja gerar um código de logística reversa?',
  //     content: '',
  //     onOk() {
  //       setItemStatus('finished');
  //     },
  //     onCancel() {},
  //   });
  // };

  const renderStateTitle = () => {
    return <CardState>{getOrderItemStatus(itemStatus)}</CardState>;
  };

  // const gridStyle = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'space-between',
  //   width: '100%',
  //   height: '46px',
  //   padding: '10px',
  //   textAlign: 'center',
  //   textDecoration: 'underline',
  //   fontSize: 14,
  // };

  // TODO: não iremos entregar a feature de devolução e logística reversa ainda, dependência do backend

  // const renderStateButtons = () => {
  //   if (
  //     (new Date(orderDate) > new Date() && itemStatus === 'ag_payment') ||
  //     (new Date(orderDate) > new Date() && itemStatus === 'ag_completion')
  //   ) {
  //     return [
  //       <Card.Grid style={gridStyle} key="glr" onClick={handleGenerate}>
  //         <CardButtons>
  //           <ImportOutlined key="import" />
  //           <small>Gerar LR</small>
  //         </CardButtons>
  //       </Card.Grid>,
  //     ];
  //   }
  //   return [
  //     <Card.Grid style={gridStyle} key="glr">
  //       <CardButtons disabled>
  //         <ImportOutlined key="import" />
  //         <small>Gerar LR</small>
  //       </CardButtons>
  //     </Card.Grid>,
  //   ];
  // };

  const productNameFormatted = formatPascalCase(
    name,
    PARTICLES_OF_PRODUCT_NAME
  );

  return (
    <Card
      hoverable
      size="small"
      bodyStyle={{
        margin: 0,
        cursor: 'default',
      }}
      cover={
        <Image
          alt="Sem dados"
          style={{ objectFit: 'cover', height: '200px' }}
          src={getImageUrl(principalImage)}
        />
      }
      // actions={[
      //   <CardButtonDetails onClick={() => setVisible(true)} aria-hidden="true">
      //     Detalhes da devolução
      //   </CardButtonDetails>,
      // ]}
    >
      {renderStateTitle()}
      <CardTextContainer>
        <CardName>{productNameFormatted || 'Produto Indisponível'}</CardName>
        <CardID>
          ID: {itemId}
          {slug && (
            <Tooltip
              placement="bottom"
              title="Ir para página de detalhe de produto"
            >
              <Link
                target="_blank"
                to={{
                  pathname: `${normalizeAssetsUri(
                    process.env.REACT_APP_STOREFRONT_URL
                  )}produtos/${slug}`,
                }}
              >
                {' '}
                <ExportOutlined />
              </Link>
            </Tooltip>
          )}
        </CardID>
        <CardPrice>
          <CardPriceDescription>Valor do produto</CardPriceDescription>
          <CardPriceValue>{toCurrencyFormat(itemValue)}</CardPriceValue>
          <CardPriceDescription>
            <CardPriceText>Desconto</CardPriceText>
            <Tooltip
              placement="bottom"
              title="Na página do cliente este valor não é exibido quando está zerado"
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </CardPriceDescription>
          <CardPriceValue>{toCurrencyFormat(adjustmentTotal)}</CardPriceValue>
          <CardPriceDescription>Valor pago</CardPriceDescription>
          <CardPriceValue>{toCurrencyFormat(itemTotal)}</CardPriceValue>
        </CardPrice>
        {reverseCode && (
          <CardReverseCode>
            COD. LOGISTICA. REVERSA: <br /> {reverseCode}
          </CardReverseCode>
        )}

        {devolutionItem?.id ? (
          <>
            <CardPriceDescription>Pedido de devolução</CardPriceDescription>
            <Tooltip
              placement="bottom"
              title="Abrir página do pedido de devolução em uma nova aba."
            >
              <Link
                to={`/manage/orders/products/edit/${devolutionItem.id}?from=${ORDER_ORIGIN_ORDER_DETAILS}`}
                target="_blank"
              >
                <LinkOutlined /> {devolutionItem.id}
              </Link>
            </Tooltip>
          </>
        ) : null}
      </CardTextContainer>

      {/* {renderStateButtons()} */}

      {/* <Card.Grid style={gridStyle}>
        <CardButtons>
          <FileExcelOutlined />
          <small>Cancelar</small>
        </CardButtons>
      </Card.Grid>
      <Modal
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <Row
          gutter={16}
          type="flex"
          justify="center"
          align="top"
          style={{ marginBottom: 50 }}
        >
          <Col span={2}>
            <QuestionCircleOutlined
              style={{ fontSize: '30px', color: 'rgb(250, 173, 20)' }}
            />
          </Col>
          <Col span={22}>
            <h3>Tem certeza que deseja cancelar?</h3>
            <CardDescription>
              Após cancelado, o valor será extornado para o cliente e sairá do
              pedido.
            </CardDescription>
          </Col>
        </Row>
        <Row gutter={16} type="flex" justify="center" align="middle">
          <Col span={24}>
            <Select style={{ width: '100%' }} defaultValue="">
              <Option value="">Motivo do cancelamento?</Option>
            </Select>
          </Col>
        </Row>
      </Modal> */}
      {/* <Modal
        title={`Detalhes da devolução - ${returnCode}`}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
      >
      </Modal> */}
    </Card>
  );
}

CardItem.defaultProps = {
  reverseCode: null,
  adjustmentTotal: 0,
  slug: null,
  images: [],
  name: null,
  devolutionItem: null,
  itemTotal: 0,
};

CardItem.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      imageUrl: PropTypes.string,
      position: PropTypes.oneOf(['detail', 'principal', 'back', 'label']),
    })
  ),
  name: PropTypes.string,
  slug: PropTypes.string,
  itemValue: PropTypes.number.isRequired,
  adjustmentTotal: PropTypes.number,
  itemTotal: PropTypes.number,
  status: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  reverseCode: PropTypes.string,
  devolutionItem: PropTypes.shape({
    id: PropTypes.number,
  }),
};

export default CardItem;
