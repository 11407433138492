import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

import { OPTION_TYPES_PROMOTION } from '~/apollo/bag/optionTypes';

/*
 * setter = Função de 'set' do hook (onde vai guardar os dados)
 * onError = Função personalizada para tratar o erro
 */
function useFilter({ _getter, setter, onError }) {
  const [optionTypesPromotion, { data }] = useLazyQuery(
    OPTION_TYPES_PROMOTION,
    {
      context: {
        newBackend: true,
      },
      onError,
    }
  );

  const callQuery = variables => {
    optionTypesPromotion({
      variables,
    });
  };

  useEffect(() => {
    if (data) {
      const {
        optionTypesProductSearchOptionTypes: { nodes },
      } = data;

      nodes.forEach(element => {
        element.optionValues = element.optionValues.map(option => {
          return {
            ...option,
            key: option.presentation,
          };
        });
        element.kind = 'optionType';
      });

      setter(nodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return [callQuery, { data }];
}

useFilter.propTypes = {
  getter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setter: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default useFilter;
