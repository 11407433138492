import React from 'react';
import { notification } from 'antd';

function getByKind(data, kind) {
  const objs = [];
  data.forEach(item => {
    if (item.kind === kind) objs.push(item);
  });
  return objs;
}

export function handleSubmitHeader(arr, kind, values, updateContentHeader) {
  const currentValue = getByKind(arr, kind);

  if (currentValue.length > 0) {
    updateContentHeader({
      variables: {
        input: {
          kind,
          id: currentValue[0].id,
          title: values[kind],
        },
      },
    })
      .then(() => {
        notification.success({
          message: `Header atualizado`,
          description: <div>Header atualizado com sucesso</div>,
          placement: 'topRight',
        });
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao atualizar Header`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  }
}

export function handleSubmitStickyBar(item, values, updateContentHeader) {
  updateContentHeader({
    variables: {
      input: {
        kind: item.kind,
        id: item.id,
        title: values.text,
        url: values.url,
      },
    },
  })
    .then(() => {
      notification.success({
        message: `Sticky Bar atualizado`,
        description: <div>Sticky Bar atualizado com sucesso</div>,
        placement: 'topRight',
      });
    })
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao atualizar Sticky Bar`,
        description: errorUpt.errors
          ? errorUpt.errors.map(obj => obj.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
}
