import {
  AAD_LOGIN_SUCCESS,
  AAD_LOGOUT_SUCCESS,
  AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS,
  AAD_ACQUIRED_ACCESS_TOKEN_ERROR,
  BACKEND_LOGIN_SUCCESS,
  BACKEND_LOGOUT_SUCCESS,
  AAD_AUTHENTICATED_STATE_CHANGED,
  BACKEND_LOGIN_ERROR,
  AAD_ACQUIRED_ID_TOKEN_SUCCESS,
  AAD_GET_ACCESS_TOKEN,
} from '../../constants/ActionTypes';
import { TECNOLOGIA } from '../../constants/UserGroups';
import { authProvider } from '../../services/msalAuthProvider';
import { clearCookies } from '../../util/Functions';

const initialSettings = {
  loggedIn: process.env.REACT_APP_BYPASS_LOGIN === 'true',
  aadResponse:
    process.env.REACT_APP_BYPASS_LOGIN === 'true'
      ? {
          account: {
            name: 'Bypassed Login',
            groups: [TECNOLOGIA],
          },
        }
      : null,
  azure: null,
  account:
    process.env.REACT_APP_BYPASS_LOGIN === 'true'
      ? {
          name: 'Bypassed Login',
          groups: [TECNOLOGIA],
        }
      : null,
  errors: {},
};

const auth = (state = initialSettings, action) => {
  switch (action.type) {
    case AAD_GET_ACCESS_TOKEN:
      authProvider.getAccessToken();
      return {
        ...state,
        state: null,
      };
    case AAD_LOGIN_SUCCESS:
      authProvider.getAccessToken();
      return {
        ...state,
        aadResponse: action.payload,
        account: {
          azureId: action.payload.account.accountIdentifier,
          name: action.payload.account.name,
          email: action.payload.account.userName,
        },
      };
    case AAD_ACQUIRED_ID_TOKEN_SUCCESS:
      return state;
    case AAD_AUTHENTICATED_STATE_CHANGED:
      return state;
    case AAD_LOGOUT_SUCCESS:
      clearCookies();
      return {
        ...state,
        azure: null,
        loggedIn: false,
        aadResponse: null,
        account: null,
      };
    case AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        azure: action.payload,
      };
    case AAD_ACQUIRED_ACCESS_TOKEN_ERROR:
      clearCookies();
      return {
        ...state,
        azure: null,
        loggedIn: false,
        aadResponse: null,
        account: null,
        errors: {
          ...state.errors,
          AAD_ACQUIRED_ACCESS_TOKEN_ERROR: action.payload,
        },
      };
    case BACKEND_LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        account: {
          ...state.account,
          ...action.payload.data.userAuthAzureLogin,
        },
      };
    case BACKEND_LOGIN_ERROR:
      clearCookies();
      return {
        ...state,
        azure: null,
        loggedIn: false,
        aadResponse: null,
        account: null,
        errors: {
          ...state.errors,
          BACKEND_LOGIN_ERROR: action.payload,
        },
      };
    case BACKEND_LOGOUT_SUCCESS:
      clearCookies();
      return {
        ...state,
        azure: null,
        loggedIn: false,
        aadResponse: null,
        account: null,
      };
    default:
      return state;
  }
};

export default auth;
