import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme } = THEME_BEHAVIOR;

export const AntTable = css`
  body {
    .ant-table {
      &-title {
        border-top-left-radius: ${theme.borderRadius};
        border-top-right-radius: ${theme.borderRadius};
      }
      &-container {
        border-top-left-radius: ${theme.borderRadius};
        border-top-right-radius: ${theme.borderRadius};

        & table > thead > tr:first-child th:first-child {
          border-top-left-radius: ${theme.borderRadius};
        }

        & table > thead > tr:last-child th:last-child {
          border-top-right-radius: ${theme.borderRadius};
        }
      }
    }
  }
`;
