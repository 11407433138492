import React, { useEffect } from 'react';
import { Form, Input, Row, Col, Typography, Divider, Select } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import { notifyError, notifySuccess } from '~/util/Functions';
import { banks } from '~/constants/GeneralForms';
import {
  USER_BANK_ACCOUNT_TYPE_CURRENT,
  USER_BANK_ACCOUNT_TYPE_SAVINGS,
  USER_KIND_CUSTOMER,
  USER_KIND_ONG,
  USER_KIND_PARTNER,
  userBankAccountTypeLabels,
  userKindLabels,
} from '~/constants/Users';
import useUserSetBankingAccount from '~/hooks/user/useUserSetBankingAccount';

function BankForm({ initialData, userId, userKind, isEdit }) {
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const { Option } = Select;

  const userLabel = userKindLabels[userKind];
  const notificationText = isEdit ? 'atualizado' : 'registrado';

  const [setBankingAccount, { loading }] = useUserSetBankingAccount({
    onCompleted: () => {
      notifySuccess(
        `Banco ${notificationText}`,
        `Banco de ${userLabel} ${notificationText} com sucesso!`
      );
    },
    onError: err => {
      notifyError(`Erro ao ${notificationText} banco de ${userLabel}`, err);
    },
  });

  const onFinish = values => {
    setBankingAccount({
      account: values.account,
      accountType: values.accountType,
      agency: values.agency,
      code: values.code,
      name: values.name,
      userId,
      id: initialData?.id,
    });
  };

  const onFinishFailed = () => {
    notifyError('Erro ao criar banco');
  };

  useEffect(() => form.resetFields(), [initialData, form]);

  return (
    <Form
      form={form}
      name="bank"
      data-testid="bankClientForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        name: initialData.name,
        code: `${initialData.code}`,
        agency: initialData.agency,
        account: initialData.account,
        accountType: initialData.accountType,
      }}
    >
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="center"
        style={{ marginBottom: '20px' }}
      >
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Informações Bancárias
          </Title>
          <Divider />
          <Text>
            Atenção: o CPF/CNPJ deve ser o mesmo do titular da conta bancária
            cadastrada. Se for divergente, não será possível transferir valores
            para a conta cadastrada.
          </Text>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: 'Preencha um nome descritivo para conta',
              },
            ]}
          >
            <Input data-testid="name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="Banco"
            name="code"
            rules={[
              {
                required: true,
                message: 'Selecione 1 opção',
              },
            ]}
          >
            <Select data-testid="code">
              {banks.map(item => (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="Agência"
            name="agency"
            rules={[
              {
                required: true,
                message: 'Digite sua agência',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="agency" />
          </Form.Item>
        </Col>
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="Conta"
            name="account"
            rules={[
              {
                required: true,
                message: 'Digite sua conta',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="account" />
          </Form.Item>
        </Col>
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="Tipo da conta"
            name="accountType"
            rules={[
              {
                required: true,
                message: 'Selecione 1 opção',
              },
            ]}
          >
            <Select data-testid="accountType">
              <Option value={USER_BANK_ACCOUNT_TYPE_CURRENT}>
                {userBankAccountTypeLabels[USER_BANK_ACCOUNT_TYPE_CURRENT]}
              </Option>
              <Option value={USER_BANK_ACCOUNT_TYPE_SAVINGS}>
                {userBankAccountTypeLabels[USER_BANK_ACCOUNT_TYPE_SAVINGS]}
              </Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="end"
        style={{ marginTop: '10px' }}
      >
        <Col>
          <Button
            data-testid="bankSubmitBtn"
            type="success"
            htmlType="submit"
            icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            block
            style={{ margin: 0 }}
            loading={loading}
          >
            {isEdit ? 'Salvar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

BankForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userKind: PropTypes.oneOf([
    USER_KIND_CUSTOMER,
    USER_KIND_ONG,
    USER_KIND_PARTNER,
  ]).isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    agency: PropTypes.string,
    account: PropTypes.string,
    accountType: PropTypes.oneOf([
      USER_BANK_ACCOUNT_TYPE_CURRENT,
      USER_BANK_ACCOUNT_TYPE_SAVINGS,
    ]),
    active: PropTypes.bool,
    kind: PropTypes.string,
    name: PropTypes.string,
  }),
};

BankForm.defaultProps = {
  initialData: {
    id: undefined,
    code: '341',
    agency: '',
    account: '',
    accountType: USER_BANK_ACCOUNT_TYPE_CURRENT,
    active: true,
    kind: '',
    name: '',
  },
};

export default BankForm;
