import React from 'react';
import { Form, Input, Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import { CustomButtonStickyBar, CustomCard } from './styles';

function DefaultStickyBar(props) {
  const { initialValue, handleSubmit, placeholder } = props;

  const [form] = Form.useForm();

  const onFinish = values => {
    handleSubmit(values);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar conteudo de Sticky Bar',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="stickyBarForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <CustomCard hoverable>
        <Row gutter={16} type="flex" align="middle" justify="center">
          <Col xl={24} lg={24} md={24} xs={24}>
            <Form.Item
              label="Texto"
              name="text"
              initialValue={initialValue.text}
              rules={[
                {
                  required: true,
                  max: 255,
                  message: `Texto é obrigatório e precisa ter 255 ou menos caracteres`,
                  whitespace: true,
                },
              ]}
            >
              <Input data-testid="stickyBarText" placeholder={placeholder} />
            </Form.Item>
          </Col>
          <Col xl={18} lg={18} md={18} xs={24}>
            <Form.Item
              label="URL"
              name="url"
              initialValue={initialValue.url}
              rules={[
                {
                  max: 255,
                  message: `URL precisa ter 255 ou menos caracteres`,
                  whitespace: true,
                },
              ]}
            >
              <Input data-testid="stickyBarUrl" placeholder={placeholder} />
            </Form.Item>
          </Col>
          <Col xl={6} lg={6} md={6} xs={24}>
            <CustomButtonStickyBar>
              <Button
                data-testid="stickyBarButton"
                type="success"
                block
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Salvar
              </Button>
            </CustomButtonStickyBar>
          </Col>
        </Row>
      </CustomCard>
    </Form>
  );
}

DefaultStickyBar.propTypes = {
  initialValue: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  handleSubmit: props => {
    const { handleSubmit } = props;
    if (handleSubmit === undefined || typeof handleSubmit !== 'function') {
      return new Error('Please provide a handleSubmit function!');
    }
    return null;
  },
};

DefaultStickyBar.defaultProps = {
  handleSubmit: undefined,
  placeholder: '',
  initialValue: {
    text: '',
    url: '',
  },
};

export default DefaultStickyBar;
