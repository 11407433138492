import styled, { createGlobalStyle } from 'styled-components';
import { DragOutlined } from '@ant-design/icons';

export const DragSortTableGlobalStyles = createGlobalStyle`
  .row-dragging {
    display: flex;
    box-sizing: border-box;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f0f0;
    background-color: #fafafa;
    opacity: 0.9;
  }
`;

export const DragHandleIcon = styled(DragOutlined)`
  cursor: move;
  font-size: 25px;
  transition: all 0.3s;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;
