import gql from 'graphql-tag';

// New Backend
export const QUERY_FAQ_CATEGORIES = gql`
  query listFaqSubjects($first: Int) {
    faqSearchFaqSubjects(first: $first) {
      nodes {
        id
        icon
        faqQuestions {
          id
          anchor
          answer
          bagQuestion
          question
          weightPosition
        }
        slug
        subject
        weightPosition
      }
      totalCount
    }
  }
`;

// New Backend
export const QUERY_FAQ_QUESTIONS_CATEGORIES = gql`
  query searchFaqQuestions($faqSubjectId: Int) {
    faqSearchFaqQuestions(faqSubjectId: $faqSubjectId) {
      nodes {
        anchor
        answer
        bagQuestion
        faqSubject {
          subject
          id
        }
        id
        question
        weightPosition
      }
      totalCount
    }
  }
`;

// New Backend
export const QUERY_FAQ_CATEGORY_BY_ID = gql`
  query searchFaqSubjectsById($id: Int) {
    faqSearchFaqSubjects(id: $id) {
      nodes {
        id
        icon
        faqQuestions {
          id
          anchor
          answer
          bagQuestion
          question
          weightPosition
        }
        slug
        subject
        weightPosition
      }
    }
  }
`;

// New Backend
export const QUERY_FAQ_QUESTIONS_BY_ID = gql`
  query searchFaqQuestionsById($id: Int) {
    faqSearchFaqQuestions(id: $id) {
      nodes {
        anchor
        answer
        bagQuestion
        faqSubject {
          subject
          id
        }
        id
        question
        weightPosition
      }
    }
  }
`;

// New Backend
export const MUTATION_DELETE_FAQ_CATEGORY = gql`
  mutation deleteFaqSubject($input: DeleteFaqSubjectInput!) {
    faqDeleteFaqSubject(input: $input) {
      id
      faqQuestions {
        anchor
        answer
        bagQuestion
        id
        question
        weightPosition
      }
      icon
      slug
      subject
      weightPosition
    }
  }
`;

// New Backend
export const MUTATION_DELETE_FAQ_CATEGORY_QUESTION = gql`
  mutation deleteFaqQuestion($input: DeleteFaqQuestionInput!) {
    faqDeleteFaqQuestion(input: $input) {
      anchor
      answer
      bagQuestion
      faqSubject {
        id
        subject
      }
      id
      question
      weightPosition
    }
  }
`;

// New Backend
export const MUTATION_CREATE_FAQ_CATEGORY = gql`
  mutation createFaqSubject($input: CreateFaqSubjectInput!) {
    faqCreateFaqSubject(input: $input) {
      id
      icon
      slug
      subject
      weightPosition
      faqQuestions {
        anchor
        answer
        bagQuestion
        id
        question
        weightPosition
      }
    }
  }
`;

// New Backend
export const MUTATION_CREATE_FAQ_CATEGORY_QUESTION = gql`
  mutation createFaqQuestion($input: CreateFaqQuestionInput!) {
    faqCreateFaqQuestion(input: $input) {
      anchor
      answer
      bagQuestion
      faqSubject {
        id
        subject
      }
      id
      question
      weightPosition
    }
  }
`;

// New Backend
export const MUTATION_UPDATE_FAQ_CATEGORY = gql`
  mutation updateFaqSubject($input: UpdateFaqSubjectInput!) {
    faqUpdateFaqSubject(input: $input) {
      id
      icon
      slug
      subject
      weightPosition
      faqQuestions {
        anchor
        answer
        bagQuestion
        id
        question
        weightPosition
      }
    }
  }
`;

// New Backend
export const MUTATION_UPDATE_FAQ_CATEGORY_QUESTION = gql`
  mutation updateFaqQuestion($input: UpdateFaqQuestionInput!) {
    faqUpdateFaqQuestion(input: $input) {
      id
      weightPosition
      question
      answer
      anchor
      bagQuestion
    }
  }
`;
