import React from 'react';
import PropTypes from 'prop-types';
import { Input, Checkbox, Form, Row, Col } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import CKEditor, { ClassicEditor } from 'ckeditor4-react';
import { CKEDITOR_CONFIG } from '~/constants/CKeditorConfig';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';

function DefaultForm({ handleSubmit, initialData, isEdit }) {
  const [form] = Form.useForm();

  const onFinish = values => {
    handleSubmit(values);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar categoria',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="defaultCategoryForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        question: initialData.question,
        bagQuestion: initialData.bagQuestion,
        anchor: initialData.anchor,
        answer: initialData.answer,
      }}
    >
      <Form.Item
        label="Pergunta"
        name="question"
        rules={[
          {
            required: true,
            message: 'Preencha qual é a pergunta!',
            whitespace: true,
          },
        ]}
      >
        <Input data-testid="question" />
      </Form.Item>

      <Form.Item
        label="Âncora"
        name="anchor"
        rules={[
          {
            required: true,
            message:
              'Preencha o qual a âncora para a pergunta (apenas letras, underline e traço)',
            whitespace: true,
            pattern: new RegExp(/^[a-zA-Z_-]+$/),
          },
        ]}
      >
        <Input data-testid="anchor" />
      </Form.Item>

      <Form.Item
        label="Resposta"
        name="answer"
        valuePropName="data"
        getValueFromEvent={event => event.editor.getData()}
      >
        <CKEditor editor={ClassicEditor} config={CKEDITOR_CONFIG} />
      </Form.Item>

      <Form.Item name="bagQuestion" valuePropName="checked">
        <Checkbox data-testid="bagQuestion">
          Incluir no FAQ da Sacola do Bem
        </Checkbox>
      </Form.Item>

      <Row justify="end">
        <Col>
          <Button
            data-testid="defaultSubmitBtn"
            type="success"
            htmlType="submit"
            icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            style={{ margin: 0 }}
          >
            {isEdit ? 'Salvar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

DefaultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    question: PropTypes.string,
    bagQuestion: PropTypes.bool,
    anchor: PropTypes.string,
    answer: PropTypes.string,
  }),
  isEdit: PropTypes.bool.isRequired,
};

DefaultForm.defaultProps = {
  initialData: {
    question: '',
    bagQuestion: false,
    anchor: '',
    answer: '',
  },
};

export default DefaultForm;
