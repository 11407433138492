import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Switch, Typography } from 'antd';
import Button from '~/components/Button';

import { handleSearch } from './actions';

const { Text } = Typography;

function Filter({ setTable }) {
  const [search, setSearch] = useState('');
  const [isInactive, setIsInactive] = useState(false);

  return (
    <>
      <Row gutter={[10, 20]} type="flex" align="middle">
        <Col xl={20} lg={24} md={24} xs={24}>
          <Input
            data-testid="inputFilter"
            placeholder="Busque por e-mail ou CPF"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Switch
            data-testid="switchFilter"
            checked={isInactive}
            onClick={() => setIsInactive(!isInactive)}
          />
          <Text style={{ marginLeft: 5 }}>
            {isInactive ? 'Inativo' : 'Ativo'}
          </Text>
        </Col>
        <Col span={24}>
          <Button
            data-testid="buttonForm"
            type="success"
            full="100"
            onClick={() => handleSearch(search, isInactive, { setTable })}
          >
            FILTRAR
          </Button>
        </Col>
      </Row>
    </>
  );
}

Filter.propTypes = {
  setTable: PropTypes.func.isRequired,
};

export default Filter;
