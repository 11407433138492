import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { notifyError } from '~/util/Functions';
import {
  COUPON_OPERATOR_AND,
  COUPON_CATEGORY_PROMOTION,
  COUPON_KIND_BOTH,
} from '~/constants/Coupon';
import {
  COUPON_FRAGMENT,
  COUPON_RULES_ACTIONS_FRAGMENT,
} from './useCreateCoupon';

export const UPDATE_COUPON = gql`
  mutation useUpdateCoupon(
    $couponId: Int!
    $actions: CouponActionKindInput!
    $andOrOperator: CouponOptionVerifyRule
    $category: CouponCategory!
    $code: String!
    $description: String
    $expiresAt: ISO8601Date
    $kind: CouponKind!
    $name: String!
    $rules: CouponRuleKindInput!
    $startsAt: ISO8601Date!
    $usageLimit: Int
    $usageLimitCpf: Int
  ) {
    couponUpdateCoupon(
      input: {
        couponId: $couponId
        actions: $actions
        andOrOperator: $andOrOperator
        category: $category
        code: $code
        description: $description
        expiresAt: $expiresAt
        kind: $kind
        name: $name
        rules: $rules
        startsAt: $startsAt
        usageLimit: $usageLimit
        usageLimitCpf: $usageLimitCpf
      }
    ) {
      ...CouponFragment
      ...CouponRulesActionsFragment
    }
  }
  ${COUPON_FRAGMENT}
  ${COUPON_RULES_ACTIONS_FRAGMENT}
`;

function useUpdateCoupon(options = {}) {
  const [updateCoupon, { error, data, loading }] = useMutation(UPDATE_COUPON, {
    onError: errUpdate => {
      notifyError('Erro ao atualizar cupom', errUpdate);
    },
    ...options,
  });

  return [
    ({
      andOrOperator = COUPON_OPERATOR_AND,
      category = COUPON_CATEGORY_PROMOTION,
      kind = COUPON_KIND_BOTH,
      code = '',
      description = undefined,
      startsAt = undefined,
      expiresAt = undefined,
      name = '',
      usageLimit = undefined,
      usageLimitCpf = undefined,
      rules = {},
      actions = {},
      couponId = undefined,
    }) => {
      updateCoupon({
        variables: {
          couponId,
          andOrOperator,
          category,
          kind,
          code,
          description,
          startsAt,
          expiresAt,
          name,
          usageLimit,
          usageLimitCpf,
          rules,
          actions,
        },
      });
    },
    {
      data: data?.couponUpdateCoupon,
      loading,
      error,
    },
  ];
}

export default useUpdateCoupon;
