import React from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import App from '~/routes/index';
import { footerText } from '~/util/config';

import { ToggleMenuProvider } from '~/providers/ToggleMenu';

import MainMenu from '~/containers/MainMenu';
import TopBar from '~/containers/TopBar';

import {
  StyledLayout,
  StyledContent,
  StyledMain,
  StyledFooter,
  AntDesignStyleExtension,
} from './styles';

function MainApp({ match }) {
  return (
    <Layout>
      <ToggleMenuProvider>
        <MainMenu />
        <StyledLayout>
          <TopBar />
          <StyledContent>
            <StyledMain>
              <App match={match} />
            </StyledMain>
            <StyledFooter>{footerText}</StyledFooter>
          </StyledContent>
        </StyledLayout>
      </ToggleMenuProvider>
      <AntDesignStyleExtension />
    </Layout>
  );
}

MainApp.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainApp;
