export const SEO_KIND_BRAND = 'brand';
export const SEO_KIND_CATEGORY = 'category';

export const SEO_KIND_BRAND_TRANSLATED = 'Marca';
export const SEO_KIND_CATEGORY_TRANSLATED = 'Categoria';

export const SEO_KIND_ALL = 'all';
export const SEO_KIND_ALL_TRANSLATED = 'Todas';

export const SEO_KIND_LIST = [SEO_KIND_BRAND, SEO_KIND_CATEGORY];
