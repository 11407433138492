import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input } from 'antd';
import Button from '../../../../components/Button';

import { handleSearch } from './actions';

function Filter({ setTable }) {
  const [search, setSearch] = useState('');
  return (
    <>
      <Row gutter={[10, 20]} type="flex" align="middle">
        <Col span={24}>
          <Input
            data-testid="inputFilter"
            placeholder="Busque pelo nome da categoria, dentro da categoria atual"
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Button
            data-testid="buttonForm"
            type="success"
            full="100"
            onClick={() => handleSearch(search, { setTable })}
          >
            FILTRAR
          </Button>
        </Col>
      </Row>
    </>
  );
}

Filter.propTypes = {
  setTable: PropTypes.func.isRequired,
};

export default Filter;
