import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Row, Typography } from 'antd';
import {
  notifySuccess,
  notifyError,
  closeAllNotifications,
} from '~/util/Functions';

import useApproveOrderItemReturn from '~/hooks/orders/useConfirmOrdersItemReturn';
import { ORDER_SHIPMENT_SUB_TYPE_DROPOFF } from '~/constants/Order';

const { Text } = Typography;

const ModalForm = ({
  open,
  orderId,
  productId,
  rowIndex,
  onClose,
  orders,
  setOrders,
  setLoading,
}) => {
  const [currentOrder, setCurrentOrder] = useState(null);
  const [form] = Form.useForm();

  const [approveReturn] = useApproveOrderItemReturn({
    onCompleted: ({ confirmOrdersItemReturn }) => {
      setOrders(prevOrders => {
        return prevOrders.map(order => {
          if (order.id === orderId) {
            return {
              ...order,
              orderItems: order.orderItems.map((item, index) => {
                if (index === rowIndex) {
                  item.status = confirmOrdersItemReturn?.status;
                  if (confirmOrdersItemReturn?.orderItemReturn?.retunerdAt) {
                    item.orderItemReturn = {
                      ...item?.orderItemReturn,
                      retunerdAt:
                        confirmOrdersItemReturn?.orderItemReturn?.retunerdAt,
                    };
                  }
                }
                return item;
              }),
            };
          }
          return order;
        });
      });
      notifySuccess('Sucesso!', 'Devolução recebida.');
      setLoading(false);
    },
    onError: approveError => {
      notifyError('Um erro ocorreu', approveError);
      setLoading(false);
    },
  });

  const handleApprove = reverseCode => {
    setLoading(true);
    approveReturn({
      productId,
      orderId,
      reverseCode,
    });
  };

  const onFinish = value => {
    closeAllNotifications();
    handleApprove(value.reverseCode);
    onClose();
  };

  const onFinishFailed = ({ errorFields }) => {
    closeAllNotifications();
    errorFields.forEach(errorField => {
      notifyError('Um erro ocorreu', errorField.errors);
    });
  };

  const handleSubmitForm = () => {
    form.submit();
  };

  useEffect(() => {
    setCurrentOrder(orders.find(order => order.id === orderId));
  }, [orders]);

  return (
    <Modal
      open={open}
      title=""
      okText="Sim"
      cancelText="Não"
      onCancel={onClose}
      onOk={() => handleSubmitForm()}
    >
      <Form
        form={form}
        name="modalForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {currentOrder?.currentShipmentOrderSubType ===
        ORDER_SHIPMENT_SUB_TYPE_DROPOFF ? (
          <Row style={{ marginTop: '15px', marginBottom: '10px' }}>
            <Form.Item
              name="reverseCode"
              data-testid="reverseCodeModalFormInput"
              label="Código de postagem/Coleta"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: 'Por favor, digite o código.',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Row>
        ) : null}
        <Row>
          <Text strong>Aprovar devolução e efetuar estorno ao comprador?</Text>
        </Row>
      </Form>
    </Modal>
  );
};

const ApproveModal = ({ orderId, productId, rowIndex, orders, setOrders }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div data-testid="confirmationModalContainer">
      <Button
        data-testid="approvedBtn"
        type="success"
        loading={loading}
        onClick={() => setOpen(true)}
      >
        Aprovar
      </Button>
      <ModalForm
        open={open}
        orderId={orderId}
        productId={productId}
        rowIndex={rowIndex}
        setLoading={setLoading}
        onClose={() => setOpen(false)}
        orders={orders}
        setOrders={setOrders}
      />
    </div>
  );
};

ModalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  orderId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrders: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

ApproveModal.propTypes = {
  orderId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  setOrders: PropTypes.func.isRequired,
};

export default ApproveModal;
