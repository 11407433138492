import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import createRootReducer from '../reducers/index';
import history from '../../services/history';
import { DEV_MODE } from '../../constants/Global';

const middlewares = [routerMiddleware(history), thunk];
const initialState = {};

const dev = () => {
  if (DEV_MODE) {
    return (
      (window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()) ||
      compose
    );
  }
  return compose;
};

const store = createStore(
  createRootReducer(history),
  initialState,
  compose(applyMiddleware(...middlewares), dev())
);

export { store };
