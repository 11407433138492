import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const QUERY_SEARCH_SHIPPING_COMPANIES = gql`
  query useShippingCompanies($id: Int, $skip: Int, $first: Int) {
    shipmentSearchShippingCompanies(id: $id, skip: $skip, first: $first) {
      nodes {
        id
        name
      }
      totalCount
    }
  }
`;

function useShippingCompanies(options = {}) {
  const { data, loading, error } = useQuery(QUERY_SEARCH_SHIPPING_COMPANIES, {
    variables: {
      first: 10,
      skip: 0,
      id: undefined,
    },
    ...options,
  });

  return {
    loading,
    data: data?.shipmentSearchShippingCompanies?.nodes || [],
    totalCount: data?.shipmentSearchShippingCompanies?.totalCount || 0,
    error,
  };
}

export default useShippingCompanies;
