/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import { DragSortTableGlobalStyles } from './styles';
import { onSortEnd as onSortEndUtils } from './utils';

const DragSortTable = ({
  data,
  columns,
  rowKey,
  pagination,
  ...restOfProps
}) => {
  const [sortedData, setSortedData] = useState({ dataSource: data });
  const { setTableData } = useDragSortTableContext();

  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => <tbody {...props} />);

  useEffect(() => {
    setSortedData({ dataSource: data });
  }, [data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onSortEndUtils({
      oldIndex,
      newIndex,
      setTableData,
      setSortedData,
      sortedData,
    });
  };

  const DraggableContainer = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const { dataSource } = sortedData;
    const index = dataSource.findIndex(x => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const { dataSource } = sortedData;

  return (
    <>
      <Table
        data-testid="dragndrop-table"
        dataSource={dataSource}
        columns={columns}
        rowKey={rowKey}
        pagination={pagination}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        {...restOfProps}
      />
      <DragSortTableGlobalStyles />
    </>
  );
};

DragSortTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowKey: PropTypes.func.isRequired,
  pagination: PropTypes.bool.isRequired,
};

export default DragSortTable;
