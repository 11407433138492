import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  UserOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';

import { Modal, Dropdown, Menu } from 'antd';
import { authProvider } from '~/services/msalAuthProvider';

import Image from '~/components/Image';
import {
  LogoContainer,
  UserProfileCard,
  UserProfileLegacy,
  LogoLink,
  LogoutButton,
} from './styles';

const { Button } = Dropdown;
const { Item } = Menu;

const MenuHeader = ({ username, _profilePic }) => {
  const logo = require('~/assets/images/logo-repassa.svg');
  const legacyMode = false;

  const ModalConfirm = () => {
    Modal.confirm({
      title: 'Logout',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja realmente sair?',
      okText: 'confirmar',
      cancelText: 'cancelar',
      onOk: authProvider.logout,
    });
  };

  const menu = (
    <Menu>
      <Item key="1">
        <LogoutButton className="legacy" onClick={ModalConfirm}>
          <LogoutOutlined /> Sair
        </LogoutButton>
      </Item>
    </Menu>
  );

  return (
    <>
      <LogoContainer>
        <LogoLink to="/">
          <Image alt="Logo" src={logo} preview={false} />
        </LogoLink>
      </LogoContainer>

      <UserProfileCard data-legacy={legacyMode}>
        <div>
          <UserOutlined />
          {username}
        </div>
        <LogoutButton onClick={ModalConfirm}>
          <LogoutOutlined /> Sair
        </LogoutButton>
      </UserProfileCard>

      <UserProfileLegacy data-legacy={legacyMode}>
        <div className="profileIcon">
          <UserOutlined />
        </div>
        <div className="profileName">{username}</div>
        <Button
          className="profileButton"
          overlay={menu}
          icon={<DownOutlined />}
        />
      </UserProfileLegacy>
    </>
  );
};

MenuHeader.defaultProps = {
  _profilePic: null,
  username: '',
};

MenuHeader.propTypes = {
  username: PropTypes.string,
  _profilePic: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    username: state.auth.loggedIn ? state.auth.account.name : '',
    profilePic: state.auth.loggedIn ? state.auth.account.profilePic : null,
  };
};

export default connect(mapStateToProps)(MenuHeader);
