import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme } = THEME_BEHAVIOR;

export const AntToastNotification = css`
  body {
    .ant-notification {
      .ant-notification-notice {
        border-radius: ${theme.borderRadius};
      }
    }
  }
`;
