import { notification, Modal } from 'antd';
import arrayMove from 'array-move';
import history from '../../../../services/history';

const { confirm } = Modal;

export const statusSuccess = (name = '') => {
  notification.success({
    message: `${name} salvo com sucesso.`,
    placement: 'topRight',
  });
};

export const statusError = err => {
  notification.error({
    message: `Erro ao processar esta alteração`,
    description: err.errors
      ? err.errors.map(e => e.message).join(';')
      : err.message,
    placement: 'topRight',
  });
};

export const modalSaved = (kind, goBack = false) => {
  if (goBack) {
    history.goBack();
  }

  notification.success({
    message: `${kind} salvo com sucesso`,
    placement: 'topRight',
  });
};

export const onCreateBlock = (
  values,
  { block, homeBlocksCreateBlock, setLoading }
) => {
  setLoading(true);
  homeBlocksCreateBlock({
    variables: {
      input: {
        kind: block.kind,
        published: false,
        title: values.title,
        weightPosition: block.weightPosition,
        startDate: values.startDate,
        endDate: values.endDate,
      },
    },
  })
    .then(res => {
      const newBlock = res.data.homeBlocksCreateBlock;
      statusSuccess('Bloco');
      setLoading(false);
      history.replace(
        `/manage/marketing/home/${newBlock.kind}/edit/${newBlock.id}`,
        {
          block: newBlock,
        }
      );
    })
    .catch(err => {
      statusError(err);
      setLoading(false);
    });
};

const updateWeightPosition = (tableData, navigationUpdateBlockBanner) => {
  tableData.forEach((item, index) => {
    navigationUpdateBlockBanner({
      variables: {
        input: {
          id: item.id,
          weightPosition: index,
        },
      },
    }).catch(errorUpt => {
      notification.error({
        message: `Erro ao atualizar a ordem dos banners`,
        description: errorUpt.errors
          ? errorUpt.errors.map(obj => obj.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
    return false;
  });
};

const updateAttributeWeightPosition = (attributeData, updateAttribute) => {
  attributeData.forEach((item, index) => {
    updateAttribute({
      id: item.id,
      weightPosition: index,
    });
    return false;
  });
};

export const onUpdateBlock = (
  tableData,
  attributeData,
  {
    setLoading,
    navigationUpdateBlock,
    navigationUpdateBlockBanner,
    updateAttribute,
    block,
  }
) => {
  setLoading(true);
  navigationUpdateBlock({
    variables: {
      input: {
        id: block.id,
        title: block.title,
        startDate: block.startDate,
        endDate: block.endDate,
        kind: block.kind,
      },
    },
  })
    .then(() => modalSaved('Bloco', false))
    .catch(err => statusError(err));

  if (tableData.length > 0) {
    updateWeightPosition(tableData, navigationUpdateBlockBanner);
  }

  if (attributeData.length > 0) {
    updateAttributeWeightPosition(attributeData, updateAttribute);
  }
  setLoading(false);
};

export const onSubmitBanner = (
  values,
  { setLoadForm, block, navigationCreateBlockBanner }
) => {
  const newBlockBanner = {
    blockId: block.id,
    banner: values.banner,
    url: values.url,
    desktopSize: values.desktopSize || 12,
    desktopHide: values.desktopHide,
    mobileSize: values.mobileSize || 12,
    mobileHide: values.mobileHide,
    weightPosition: block.blockBanners.length,
    alt: values.alt,
    buttonText: values.buttonText,
    buttonBackgroundColor: values.buttonBackgroundColor,
    buttonTextColor: values.buttonTextColor,
    navigationBackgroundColor: values.navigationBackgroundColor,
  };

  navigationCreateBlockBanner({
    variables: {
      input: newBlockBanner,
    },
  })
    .then(() => {
      statusSuccess(block.kind === 'banner' ? 'Banner' : 'Carrossel');
      setLoadForm(false);
    })
    .catch(err => {
      statusError(err);
      setLoadForm(false);
    });
};

export const disableSubmit = (form, title) => {
  const fieldEmpty = form.getFieldValue('title');
  const titleEmpty = Boolean(!title.length);
  const statusField = Boolean(!fieldEmpty);
  const result = statusField && titleEmpty;
  return result;
};

export const changeTitle = (e, setBlock) => {
  const newTitle = e.target.value;
  setBlock(prev => {
    return {
      ...prev,
      title: newTitle,
    };
  });
};

export const onSubmitBlock = (
  values,
  form,
  { setLoading, createNewBlockBanner }
) => {
  setLoading(true);
  createNewBlockBanner(values);
  form.resetFields();
  setLoading(false);
};

export const onChangePublished = (
  id,
  type,
  { block, setBlock, navigationUpdateBlockBanner }
) => {
  const newData = { ...block };
  let blockChange;
  newData.blockBanners.forEach(obj => {
    if (obj.id === id) {
      obj[type] = !obj[type];
      blockChange = obj;
    }
  });

  navigationUpdateBlockBanner({
    variables: {
      input: {
        id: blockChange.id,
        desktopHide: blockChange.desktopHide,
        mobileHide: blockChange.mobileHide,
        weightPosition: blockChange.weightPosition,
      },
    },
  })
    .then(() => {
      setBlock(newData);
      statusSuccess();
    })
    .catch(err => statusError(err));
};

export const onDelete = (
  id,
  { navigationDeleteBlockBanner, block, setBlock }
) => {
  navigationDeleteBlockBanner({
    variables: {
      input: { id },
    },
  })
    .then(() => {
      statusSuccess();
      const newData = { ...block };
      newData.blockBanners = block.blockBanners.filter(obj => obj.id !== id);
      setBlock(newData);
    })
    .catch(err => {
      statusError(err);
    });
};

export const checkDelete = (
  id,
  { navigationDeleteBlockBanner, block, setBlock }
) => {
  confirm({
    title: 'Excluir este banner?',
    content:
      'Confirme que deseja excluir o banner para deleta-lo permanentemente.',
    okText: 'excluir',
    okType: 'danger',
    cancelText: 'cancelar',
    onOk() {
      onDelete(id, { navigationDeleteBlockBanner, block, setBlock });
    },
    onCancel() {},
  });
};

export const checkDeleteAttr = (id, { deleteAttribute }) => {
  confirm({
    title: 'Excluir este atributo?',
    content:
      'Confirme que deseja excluir o atributo para deleta-lo permanentemente.',
    okText: 'excluir',
    okType: 'danger',
    cancelText: 'cancelar',
    onOk() {
      deleteAttribute({ id });
    },
    onCancel() {},
  });
};

export const onSortEnd = ({ oldIndex, newIndex }, { block, setBlock }) => {
  const blockBanners = arrayMove(block.blockBanners, oldIndex, newIndex);
  blockBanners.forEach((item, i) => {
    item.weightPosition = i;
  });
  setBlock(oldState => ({
    ...oldState,
    blockBanners,
  }));
};

export const onUpdateBlockBanner = ({
  navigationUpdateBlockBanner,
  id,
  values,
  setLoadForm,
}) => {
  navigationUpdateBlockBanner({
    variables: {
      input: {
        id,
        banner: values?.banner,
        alt: values?.alt,
        desktopHide: values?.desktopHide,
        desktopSize: values?.desktopSize,
        mobileHide: values?.mobileHide,
        mobileSize: values?.mobileSize,
        url: values?.url,
        buttonText: values.buttonText,
        buttonBackgroundColor: values.buttonBackgroundColor,
        buttonTextColor: values.buttonTextColor,
        navigationBackgroundColor: values.navigationBackgroundColor,
      },
    },
  })
    .then(() => {
      setLoadForm(false);
      statusSuccess();
    })
    .catch(err => statusError(err));
};
