import React, { useMemo } from 'react';
import { Form, Input, Row, Col, Typography, Divider } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '~/components/Button';
import DDDSelector from '~/pages/service/user/components/DDDSelector';

import { getDDDandNumber, notifyError, notifySuccess } from '~/util/Functions';
import {
  validatorCompareToFirst,
  validatorCnpj,
  passwordValidator,
  emailValidator,
} from '~/util/Forms';

import { passwordRegex, phoneRegex, cnpjRegex } from '~/constants/GeneralForms';
import {
  USER_KIND_CUSTOMER,
  USER_KIND_ONG,
  USER_KIND_PARTNER,
  USER_TAB_DATA,
  userKindLabels,
} from '~/constants/Users';

import useCreatePartner from '~/hooks/user/useCreatePartner';
import useUpdatePartner from '~/hooks/user/useUpdatePartner';

function BasicForm({ initialData, isEdit, userId, userKind }) {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const history = useHistory();

  const userLabel = userKindLabels[userKind];

  const mobilePhone = useMemo(() => {
    return getDDDandNumber(initialData?.mobilePhone);
  }, [initialData.mobilePhone]);

  const [createPartner, { loading: loadingCreate }] = useCreatePartner({
    onCompleted: data => {
      notifySuccess(
        `${userLabel} registrado`,
        `${userLabel} registrado com sucesso!`
      );
      const id = data?.userCustomerSignUp?.user?.id;
      history.replace(
        `/manage/service/partner/edit/${id}?tab=${USER_TAB_DATA}`
      );
    },
    onError: err => {
      notifyError(`Erro ao registrar ${userLabel}`, err);
    },
  });

  const [updatePatner, { loading: loadingUpdate }] = useUpdatePartner({
    onCompleted: () => {
      notifySuccess(
        `${userLabel} atualizado`,
        `${userLabel} atualizado com sucesso`
      );
    },
    onError: err => {
      notifyError(`Erro ao atualizar ${userLabel}`, err);
    },
  });

  const onFinish = values => {
    if (!isEdit) {
      createPartner({
        document: values.document,
        email: values.email,
        firstName: values.firstName,
        ddd: values.ddd,
        mobilePhone: values.mobilePhone,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        responsible: values.responsible,
      });
    } else {
      updatePatner({
        partnerId: userId,
        document: values.document,
        firstName: values.firstName,
        ddd: values.ddd,
        mobilePhone: values.mobilePhone,
        responsible: values.responsible,
      });
    }
  };

  const onFinishFailed = () => {
    notifyError(`Erro ao criar ${userLabel}`);
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="basicPartnerForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        firstName: initialData.firstName,
        responsible: initialData.responsible,
        document: initialData.document,
        mobilePhone: mobilePhone.phone,
        ddd: mobilePhone.ddd,
        email: initialData.email,
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        slug: initialData.slug,
      }}
    >
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Informações Básicas
          </Title>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="Marca"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Digite a marca. ',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="firstName" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="Responsável"
            name="responsible"
            rules={[
              {
                message: 'Digite o responsável. ',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="responsible" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="CNPJ"
            name="document"
            rules={[
              {
                required: true,
                message: 'Digite o CNPJ. ',
                whitespace: true,
                pattern: cnpjRegex,
              },
              {
                validator: validatorCnpj(form, {
                  errorMessage: 'Informe um CNPJ válido',
                }),
              },
            ]}
          >
            <Input data-testid="document" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="DDD + Celular"
            name="mobilePhone"
            tooltip="Quando o número estiver vazio o DDD será desconsiderado"
            rules={[
              {
                message: 'Digite um número de celular válido. ',
                whitespace: true,
                pattern: phoneRegex,
              },
            ]}
          >
            <Input addonBefore={<DDDSelector />} data-testid="mobilePhone" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col
          xl={isEdit ? 24 : 12}
          lg={isEdit ? 24 : 12}
          md={isEdit ? 24 : 12}
          xs={24}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            hasFeedback
            rules={[
              {
                type: 'email',
                required: !isEdit,
                message: 'Digite um e-mail válido. ',
                whitespace: true,
              },
              {
                validator: (rule, value) =>
                  emailValidator(rule, value, form, 'emailConfirmation'),
              },
            ]}
          >
            <Input data-testid="email" disabled={isEdit} />
          </Form.Item>
        </Col>
        {!isEdit && (
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              label="Confirmação de E-Mail"
              name="emailConfirmation"
              hasFeedback
              rules={[
                {
                  type: 'email',
                  required: !isEdit,
                  message: 'Confirme com um e-mail válido. ',
                  whitespace: true,
                },
                {
                  validator: validatorCompareToFirst(form, {
                    field: 'email',
                    errorMessage: 'Os e-mails são diferentes',
                  }),
                },
              ]}
            >
              <Input data-testid="emailConfirmation" disabled={isEdit} />
            </Form.Item>
          </Col>
        )}
      </Row>
      {!isEdit && (
        <Row gutter={16} type="flex" align="top" justify="center">
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              label="Senha"
              name="password"
              hasFeedback
              rules={[
                {
                  required: !isEdit,
                  validator: (rule, value) =>
                    passwordValidator(
                      rule,
                      value,
                      form,
                      'passwordConfirmation'
                    ),
                },
              ]}
            >
              <Input.Password data-testid="password" />
            </Form.Item>
          </Col>
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.password !== currentValues.password
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Confirmação senha"
                  name="passwordConfirmation"
                  hasFeedback
                  rules={[
                    {
                      required: !isEdit || getFieldValue('password').length > 0,
                      message: 'Confirme sua senha. ',
                      pattern: passwordRegex,
                    },
                    {
                      validator: validatorCompareToFirst(form, {
                        field: 'password',
                        errorMessage: 'Senha e confirmação não são iguais.',
                      }),
                    },
                  ]}
                >
                  <Input.Password
                    data-testid="passwordConfirmation"
                    disabled={getFieldValue('password').length === 0}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {isEdit && (
        <Row gutter={16} type="flex" align="top" justify="start">
          <Col xl={24} lg={24} md={24} xs={24}>
            <Form.Item
              label="Username para URL do perfil"
              name="slug"
              rules={[
                {
                  message: 'Digite o slug',
                  whitespace: true,
                },
              ]}
            >
              <Input data-testid="slug" disabled />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="end"
        style={{ marginTop: '10px' }}
      >
        <Col>
          <Button
            data-testid="basicSubmitBtn"
            type="success"
            htmlType="submit"
            icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            block
            loading={loadingCreate || loadingUpdate}
          >
            {isEdit ? 'Salvar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

BasicForm.defaultProps = {
  userId: null,
  initialData: {
    firstName: '',
    lastName: '',
    document: '',
    mobilePhone: '',
    email: '',
    responsible: '',
    slug: '',
  },
};

BasicForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  userKind: PropTypes.oneOf([
    USER_KIND_CUSTOMER,
    USER_KIND_ONG,
    USER_KIND_PARTNER,
  ]).isRequired,
  initialData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    document: PropTypes.string,
    mobilePhone: PropTypes.string,
    email: PropTypes.string,
    responsible: PropTypes.string,
    slug: PropTypes.string,
  }),
};

export default BasicForm;
