import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Typography, Divider, Input, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import useCreateBlockAttribute from '~/hooks/marketing/attributes/useCreateAttributes';

function FormAttribute({
  block,
  updateAttribute,
  blockAttributeItem,
  setBlockAttributeItem,
}) {
  const [createAttribute] = useCreateBlockAttribute({
    refetchQueries: ['homeSearchBlockByID'],
  });

  const [loadForm, setLoadForm] = useState(false);
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      url: blockAttributeItem.url,
      text: blockAttributeItem.text,
    });
    setIsDisable(
      Object.keys(blockAttributeItem).length
        ? false
        : block?.blockAttributesBar?.length >= 4
    );
  }, [block?.blockAttributesBar, blockAttributeItem, form]);

  const renderTitleForm = (
    <>
      <Row gutter={16} type="flex" justify="space-between" align="middle">
        <Col>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Barra de Atributos
          </Title>
        </Col>
      </Row>
      <Divider />
    </>
  );

  const onFinish = values => {
    if (isDisable) {
      notification.info({
        message: 'Maximo de 4 atributos.',
        duration: 5,
        placement: 'topRight',
      });
      return;
    }
    setLoadForm(true);

    if (Object.keys(blockAttributeItem).length) {
      updateAttribute({
        blockId: block.id,
        id: blockAttributeItem.id,
        ...values,
      });
      setBlockAttributeItem({});
    } else {
      createAttribute({
        blockId: block.id,
        weightPosition:
          block?.blockAttributesBar?.at(-1)?.weightPosition + 1 || 0,
        ...values,
      });
    }
    setLoadForm(false);
    form.resetFields();
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao adicionar atributo',
      values,
      errorFields,
      outOfDate
    );
  };

  const buttonTextDisplayed = () => {
    if (loadForm) {
      return 'Aguarde...';
    }
    if (Object.keys(blockAttributeItem).length) {
      return 'Salvar';
    }
    return 'Adicionar';
  };

  return (
    <>
      {renderTitleForm}
      <Form
        form={form}
        layout="vertical"
        name="createBannerBlockForm"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
      >
        <Row gutter={16} type="flex" justify="center" align="top">
          <Col span={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Texto"
                  name="text"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Campo obrigatório.',
                      type: 'string',
                    },
                  ]}
                >
                  <Input data-testid="homeAddAttributeBlockText" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="URL"
                  name="url"
                  rules={[
                    {
                      required: false,
                      whitespace: true,
                      message: 'Campo obrigatório. Deve ser uma URL válida.',
                      type: 'url',
                    },
                  ]}
                >
                  <Input data-testid="homeAddAttributeBlockUrl" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" justify="end" align="middle">
              <Col>
                <Button
                  type="success"
                  htmlType="submit"
                  icon={<PlusOutlined />}
                  spacing
                  data-testid="homeAddAttributeItemBtn"
                  style={{ margin: 0 }}
                  loading={loadForm}
                >
                  {buttonTextDisplayed()}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

FormAttribute.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number,
    blockAttributesBar: PropTypes.any,
  }).isRequired,
  updateAttribute: PropTypes.func.isRequired,
  blockAttributeItem: PropTypes.object.isRequired,
  setBlockAttributeItem: PropTypes.func.isRequired,
};

export default FormAttribute;
