import moment from 'moment';
import { notifyError, notifySuccess } from '~/util/Functions';
import {
  TABLE_TYPE_CATEGORY,
  TABLE_TYPE_FILTER_OPTION_TYPES,
  TABLE_TYPE_FILTER_BRAND,
  TABLE_TYPE_FILTER_DISCOUNT,
  TABLE_TYPE_FILTER_ONG_PERCENTAGE,
  TABLE_TYPE_PRICE,
  TABLE_TYPE_TIME,
  TABLE_TYPE_COLOR,
  ACTION_ADD_TABLE,
  ACTION_CLEAR,
  ACTION_ADD_MULTROW,
  TABLE_TYPE_CONDITION,
} from '~/pages/marketing/promotion/PromotionForm/RulesReducer/constants';

import { initialTableState } from './RulesReducer/actions';

export const loadCategories = ({
  tables,
  dispatch,
  categories,
  setAddColor,
  promotion,
}) => {
  if (categories) {
    const {
      categoriesProductSearchCategories: { nodes, totalCount },
    } = categories;

    if (nodes && totalCount > 0) {
      // Parse nodes para o formato correto
      const parsedNodes = nodes.map(item => {
        const permalink = item.permalink.split('/');
        return {
          ...item,
          id: item.id,
          title: item.name,
          value: item.id,
          key: item.id,
          parentSlug: permalink.length > 0 ? permalink[0] : null,
          color: promotion.categoryColors[item.id] || [],
          ruleId: promotion.categoryRuleIds[item.id] || [],
          quantity: item.productCount,
          isLeaf: !!item.parentId,
        };
      });

      // Agrupa filhos com seus respectivos pais e salva seus IDs
      // para remove do array principal
      const itensToRemove = [];
      parsedNodes.forEach(item => {
        const childrens = parsedNodes.filter(children => {
          if (children.parentId === item.id) {
            itensToRemove.push(children.id);
          }
          return children.parentId === item.id;
        });
        if (childrens.length > 0) item.children = childrens;
        return item;
      });

      // Remove categorias/nodes que foram adicionadas como filhos
      // de outras categorias, do array principal
      const newNodes = parsedNodes.filter(
        item => !itensToRemove.includes(item.id)
      );

      // Agrupa por parentId para gerar as tabelas por genero
      const auxTables = {};
      newNodes.forEach(item => {
        if (!auxTables[item.parentSlug]) {
          auxTables[item.parentSlug] = [];
        }
        auxTables[item.parentSlug].push(item);
      });

      // Carregar tabelas de categoria usando dispatch
      Object.keys(auxTables).forEach(key => {
        const exists = tables.filter(table => table.key === key);

        // Previni duplicação de tabelas quando salva a edição de promoção
        if (exists.length === 0) {
          dispatch({
            type: ACTION_ADD_TABLE,
            payload: initialTableState({
              key,
              type: TABLE_TYPE_CATEGORY,
              title: key,
              dispatch,
              setAddColor,
              data: auxTables[key],
            }),
          });
        }
      });
    }
  }
};

export const addTimeRule = ({ dispatch, tables, newRows, tableKey }) => {
  if (newRows) {
    // Check if table already exists
    const exists =
      tables.filter(table => {
        return table.key === tableKey;
      }).length > 0;

    if (exists) {
      // Add into existing table
      dispatch({
        type: ACTION_ADD_MULTROW,
        payload: {
          tableKey,
          newRows,
        },
      });
    } else {
      // Create a new table
      dispatch({
        type: ACTION_ADD_TABLE,
        payload: initialTableState({
          key: tableKey,
          type: TABLE_TYPE_TIME,
          dispatch,
          data: newRows,
        }),
      });
    }
  }
};

export const addFilterRule = (
  kind,
  { tables, dispatch, newRows, tableKey, title, setSelectedBrands, form }
) => {
  const exists =
    tables.filter(table => {
      return table.key === tableKey;
    }).length > 0;

  if (exists) {
    dispatch({
      type: ACTION_ADD_MULTROW,
      payload: {
        tableKey,
        newRows,
      },
    });
  } else {
    switch (kind) {
      case 'condition':
        dispatch({
          type: ACTION_ADD_TABLE,
          payload: initialTableState({
            key: tableKey,
            type: TABLE_TYPE_CONDITION,
            dispatch,
            data: newRows,
            title,
          }),
        });
        break;
      case 'brand':
        dispatch({
          type: ACTION_ADD_TABLE,
          payload: initialTableState({
            key: tableKey,
            type: TABLE_TYPE_FILTER_BRAND,
            dispatch,
            data: newRows,
            title,
            setSelectedBrands,
          }),
        });
        break;
      case 'discount':
        dispatch({
          type: ACTION_ADD_TABLE,
          payload: initialTableState({
            key: tableKey,
            type: TABLE_TYPE_FILTER_DISCOUNT,
            dispatch,
            data: newRows,
            title,
            form,
          }),
        });
        break;
      case 'ongPercentage':
        dispatch({
          type: ACTION_ADD_TABLE,
          payload: initialTableState({
            key: tableKey,
            type: TABLE_TYPE_FILTER_ONG_PERCENTAGE,
            dispatch,
            data: newRows,
            title,
          }),
        });
        break;
      case 'optionType':
        dispatch({
          type: ACTION_ADD_TABLE,
          payload: initialTableState({
            key: tableKey,
            type: TABLE_TYPE_FILTER_OPTION_TYPES,
            dispatch,
            data: newRows,
            title,
          }),
        });
        break;
      default:
        break;
    }
  }
};

export const loadBrands = ({ tables, dispatch, brands, promotion }) => {
  if (brands) {
    const {
      productsProductSearchBrands: { nodes, totalCount },
    } = brands;

    if (nodes && totalCount > 0) {
      const newRows = nodes.map(brand => {
        return {
          ...brand,
          key: brand.id,
          ruleId: promotion.brandRuleIds[brand.id],
        };
      });

      addFilterRule('brand', {
        tables,
        dispatch,
        newRows,
        tableKey: 'Marca',
        title: 'Marca',
      });
    }
  }
};

export const addPriceRule = ({
  tables,
  dispatch,
  newRows,
  tableKey,
  title,
}) => {
  const exists =
    tables.filter(table => {
      return table.key === tableKey;
    }).length > 0;

  if (exists) {
    dispatch({
      type: ACTION_ADD_MULTROW,
      payload: {
        tableKey,
        newRows,
      },
    });
  } else {
    dispatch({
      type: ACTION_ADD_TABLE,
      payload: initialTableState({
        key: tableKey,
        type: TABLE_TYPE_PRICE,
        dispatch,
        data: newRows,
        title,
      }),
    });
  }
};

export const addConditionRule = ({
  tables,
  dispatch,
  newRows,
  tableKey,
  title,
}) => {
  const exists =
    tables.filter(table => {
      return table.key === tableKey;
    }).length > 0;

  if (exists) {
    dispatch({
      type: ACTION_ADD_MULTROW,
      payload: {
        tableKey,
        newRows,
      },
    });
  } else {
    dispatch({
      type: ACTION_ADD_TABLE,
      payload: initialTableState({
        key: tableKey,
        type: TABLE_TYPE_CONDITION,
        dispatch,
        data: newRows,
        title,
      }),
    });
  }
};

export const addColorRule = ({
  tables,
  dispatch,
  newRows,
  tableKey,
  title,
}) => {
  const exists =
    tables.filter(table => {
      return table.key === tableKey;
    }).length > 0;

  if (exists) {
    dispatch({
      type: ACTION_ADD_MULTROW,
      payload: {
        tableKey,
        newRows,
      },
    });
  } else {
    dispatch({
      type: ACTION_ADD_TABLE,
      payload: initialTableState({
        key: tableKey,
        type: TABLE_TYPE_COLOR,
        dispatch,
        data: newRows,
        title,
      }),
    });
  }
};

export const parseRules = promotion => {
  const categoryIds = [];
  const categoryColors = {};
  const categoryRuleIds = {};
  const priceRule = [];
  const timeRuleRows = [];
  const optionTypeRules = {};
  const ongPercentageRules = [];
  const discountRules = [];
  const brandIds = [];
  const brandRuleIds = {};
  const colors = [];
  const conditionRules = [];

  promotion.rules.forEach(ruleItem => {
    switch (ruleItem.kind) {
      case 'condition':
        conditionRules.push([ruleItem.rule.conditionValue]);
        break;
      case 'brand':
        ruleItem.rule.id.forEach(brandId => {
          brandIds.push(brandId);
          brandRuleIds[brandId] = ruleItem.id;
        });
        break;
      case 'color':
        ruleItem.rule.colorNames.forEach(color => {
          colors.push({
            key: `color-${color}`,
            color,
          });
        });
        break;
      case 'category':
        ruleItem.rule.items.forEach(category => {
          categoryIds.push(category.id);
          categoryColors[category.id] = category.colors;
          categoryRuleIds[category.id] = ruleItem.id;
        });
        break;
      case 'property_filter':
        if (!optionTypeRules[ruleItem.rule.name]) {
          optionTypeRules[ruleItem.rule.name] = {
            title: ruleItem.rule.name,
            tableKey: ruleItem.rule.name,
            rows: [],
          };
        }

        optionTypeRules[ruleItem.rule.name].rows.push({
          id: ruleItem.rule.value,
          key: ruleItem.rule.value,
          name: ruleItem.rule.value,
          presentation: ruleItem.rule.value,
          ruleId: ruleItem.id,
        });
        break;
      case 'discount':
        // eslint-disable-next-line no-case-declarations
        const { discountGte, discountLte } = ruleItem.rule;

        discountRules.push({
          key: `${discountGte}-${discountLte}`,
          id: `${discountGte}-${discountLte}`,
          name: `de ${discountGte}% até ${discountLte}%`,
          presentation: `${discountGte}-${discountLte}`,
          lte: discountLte,
          gte: discountGte,
          ruleId: ruleItem.id,
        });
        break;
      case 'ong_percentage':
        ongPercentageRules.push({
          key: ruleItem.rule.ongPercentageValue,
          id: ruleItem.rule.ongPercentageValue,
          name: `doação de ${ruleItem.rule.ongPercentageValue}%`,
          presentation: ruleItem.rule.ongPercentageValue,
          ruleId: ruleItem.id,
        });
        break;
      case 'price':
        priceRule.push({
          key: `price-${Number(ruleItem.rule.priceGte).toFixed(2)}-${Number(
            ruleItem.rule.priceLte
          ).toFixed(2)}`,
          price: {
            gte: Number(ruleItem.rule.priceGte).toFixed(2),
            lte: Number(ruleItem.rule.priceLte).toFixed(2),
          },
          quantity: 0,
        });
        break;
      case 'registration_time':
        timeRuleRows.push({
          key: `time-${ruleItem.rule.registrationTimeValue}`,
          time: {
            value: ruleItem.rule.registrationTimeValue,
            text: `${
              ruleItem.rule.registrationTimeValue === '12+'
                ? 'Mais de 12'
                : ruleItem.rule.registrationTimeValue
            } ${
              ruleItem.rule.registrationTimeValue === '1'
                ? 'mês de cadastro'
                : 'meses de cadastro'
            }`,
            ruleId: ruleItem.id,
          },
          quantity: 0,
        });
        break;
      default:
        break;
    }
  });

  return {
    optionTypeRules,
    discountRules,
    ongPercentageRules,
    priceRule,
    timeRuleRows,
    categoryColors,
    categoryRuleIds,
    categoryIds,
    brandRuleIds,
    brandIds,
    colors,
    conditionRules,
  };
};

export const loadPromotion = ({
  data,
  setPromotion,
  setLoading,
  setError,
  searchCategoriesById,
  searchBrandsById,
  tables,
  dispatch,
}) => {
  if (data) {
    const {
      salesSaleSearch: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [promotion] = nodes;

      const {
        priceRule,
        timeRuleRows,
        categoryColors,
        categoryRuleIds,
        categoryIds,
        optionTypeRules,
        discountRules,
        ongPercentageRules,
        brandIds,
        brandRuleIds,
        colors,
        conditionRules,
      } = parseRules(promotion);
      addFilterRule('discount', {
        dispatch,
        tables,
        newRows: discountRules,
        tableKey: 'DESCONTOS',
        title: 'DESCONTOS',
      });
      addFilterRule('ongPercentage', {
        dispatch,
        tables,
        newRows: ongPercentageRules,
        tableKey: 'REPASSE SOLIDÁRIO',
        title: 'REPASSE SOLIDÁRIO',
      });

      addFilterRule('condition', {
        dispatch,
        tables,
        newRows: conditionRules,
        tableKey: 'condition',
        title: 'Estado de uso',
      });

      Object.keys(optionTypeRules).forEach(key => {
        addFilterRule('optionType', {
          dispatch,
          tables,
          newRows: optionTypeRules[key].rows,
          tableKey: key,
          title: optionTypeRules[key].title,
        });
      });

      addColorRule({
        dispatch,
        tables,
        newRows: colors,
        tableKey: 'promotionColor',
        title: 'Cores',
      });

      addPriceRule({
        dispatch,
        tables,
        newRows: priceRule,
        tableKey: 'promotionPrice',
        title: 'Preço',
      });

      addTimeRule({
        dispatch,
        tables,
        newRows: timeRuleRows,
        tableKey: 'promotionTime',
      });

      setPromotion(prevState => {
        return {
          ...prevState,
          ...promotion,
          category: promotion.kind,
          startedAt: moment(promotion.startedAt),
          expiredAt: moment(promotion.expiredAt),
          sellerNotifiedAt: moment(promotion.sellerNotifiedAt),
          categoryColors,
          categoryRuleIds,
          brandRuleIds,
          colors,
        };
      });

      searchBrandsById({
        variables: {
          skip: 0,
          ids: brandIds,
        },
      });

      if (categoryIds[0]?.length) {
        searchCategoriesById({
          variables: {
            skip: 0,
            onlyActive: true,
            orderBy: 'NAME_ASC',
            ids: categoryIds[0],
          },
        });
      }
    } else {
      setError({
        message: 'Promoção não encontrada',
      });
    }
    setLoading(false);
  }
};

export const generateCategoryRule = item => {
  const rules = [];

  item.data.forEach(itemData => {
    if (itemData.children) {
      itemData.children.forEach(children => {
        let counter = 0;
        itemData.color.forEach(parentColor => {
          if (children.color.indexOf(parentColor) !== -1) {
            counter += 1;
          }
        });

        if (counter !== itemData.color.length) {
          // Quer dizer que o array de color do pai é diferente do filho
          rules.push({
            id: children.id,
            colors: children.color,
            ruleId: children.ruleId,
          });
        }
      });
    }
    rules.push({
      id: itemData.id,
      colors: itemData.color,
      ruleId: itemData.ruleId,
    });
  });

  return rules;
};

export const generateDiscountRule = item => {
  const rules = [];

  const fillDiscountRules = (discountLte, discountGte) => {
    const rule = {
      kind: 'discount',
      rule: {
        discount: {
          discountLte,
          discountGte,
        },
      },
      _destroy: false,
    };

    rules.push(rule);
  };

  item.data.forEach(itemData => {
    fillDiscountRules(itemData.lte, itemData.gte);
  });

  return rules;
};

export const generateBrandRule = item => {
  const rules = [];

  const rule = {
    kind: 'brand',
    rule: {
      brand: {
        id: item.data.map(itemData => itemData.id),
      },
    },
    _destroy: false,
  };
  rules.push(rule);

  return rules;
};

export const generateFilterOngPercentageRule = (item, promotion) => {
  const rules = [];
  const oldRulesIds = [];

  // Separa IDs das regras vindas da requisição de carregamento da página
  promotion.rules.forEach(rule => {
    if (rule.kind === 'ong_percentage') {
      oldRulesIds.push(rule.id);
    }
  });

  item.data.forEach(itemData => {
    const rule = {
      kind: 'ong_percentage',
      rule: {
        ongPercentage: {
          ongPercentageValue: itemData.id,
        },
      },
      _destroy: false,
    };

    rules.push(rule);
  });

  // Cria objeto de exclusão de regras que vão ser deletadas
  oldRulesIds.forEach(id => {
    rules.push({
      id,
      kind: 'ong_percentage',
      rule: JSON.stringify({ value: 1 }),
      _destroy: true,
    });
  });

  return rules;
};

export const generateFilterOptionTypeRule = (item, promotion) => {
  const rules = [];
  const oldRulesIds = [];

  // Separa IDs das regras vindas da requisição de carregamento da página
  promotion.rules.forEach(rule => {
    if (rule.kind === 'property_filter') {
      oldRulesIds.push(rule.id);
    }
  });

  item.data.forEach(itemData => {
    const rule = {
      kind: 'property_filter',
      rule: JSON.stringify({
        name: item.key,
        value: itemData.presentation,
      }),
      _destroy: false,
    };

    rules.push(rule);
  });

  // Cria objeto de exclusão de regras que vão ser deletadas
  oldRulesIds.forEach(id => {
    rules.push({
      id,
      kind: 'property_filter',
      rule: JSON.stringify({ name: 'A', value: 'B' }),
      _destroy: true,
    });
  });

  return rules;
};

export const generatePriceRule = (item, promotion) => {
  const rules = [];
  const oldRulesIds = [];

  // Separa IDs das regras vindas da requisição de carregamento da página
  promotion.rules.forEach(rule => {
    if (rule.kind === 'price') {
      oldRulesIds.push(rule.id);
    }
  });

  item.data.forEach(itemData => {
    const rule = {
      kind: 'price',
      rule: {
        price: {
          priceLte: Number(itemData.price.lte),
          priceGte: Number(itemData.price.gte),
        },
      },
      _destroy: false,
    };

    rules.push(rule);
  });

  // Cria objeto de exclusão de regras que vão ser deletadas
  oldRulesIds.forEach(id => {
    rules.push({
      id,
      kind: 'price',
      rule: JSON.stringify({ lte: 1, gte: 2 }),
      _destroy: true,
    });
  });

  return rules;
};

export const generateConditionRules = item => {
  const rules = [];

  const rule = {
    kind: 'condition',
    rule: {
      condition: {
        conditionValue: item.data[0],
      },
    },
    _destroy: false,
  };
  rules.push(rule);

  return rules;
};

export const generateColorRule = item => {
  const rules = [];

  const rule = {
    kind: 'color',
    rule: {
      color: {
        colorNames: item.data.map(itemData => itemData.color),
      },
    },
    _destroy: false,
  };
  rules.push(rule);

  return rules;
};

export const generateTimeRule = (item, promotion) => {
  const rules = [];
  let oldRules = [];

  // Separa IDs das regras vindas da requisição de carregamento da página
  promotion.rules.forEach(rule => {
    if (rule.kind === 'registration_time') {
      oldRules.push(rule);
    }
  });

  item.data.forEach(itemData => {
    // Remove IDs de regras que ainda existem (não foram deletadas)
    oldRules = oldRules.filter(rule => rule.id !== itemData.time.ruleId);

    // Cria objeto de criação/edição de regras existentes
    const rule = {
      kind: 'registration_time',
      rule: {
        registrationTime: {
          registrationTimeValue: `${itemData.time.value}`,
        },
      },
      _destroy: false,
    };

    if (itemData.time.ruleId) {
      rule.id = itemData.time.ruleId;
    }
    rules.push(rule);
  });

  // Cria objeto de exclusão de regras que vão ser deletadas
  oldRules.forEach(rule => {
    rules.push({
      id: rule.id,
      rule: {
        registrationTime: {
          registrationTimeValue: rule.registrationTimeValue,
        },
      },
      kind: 'registration_time',
      _destroy: true,
    });
  });

  return rules;
};

export const generateRulesAttributes = (tables, promotion) => {
  const rules = [];

  const ruleGroup = {};

  tables.forEach(item => {
    switch (item.type) {
      case TABLE_TYPE_CATEGORY:
        generateCategoryRule(item).forEach(rule => {
          if (!ruleGroup[rule.ruleId]) {
            ruleGroup[rule.ruleId] = [];
          }

          ruleGroup[rule.ruleId].push({
            id: rule.id,
            colors: rule.colors,
          });
        });

        Object.keys(ruleGroup).forEach(key => {
          const newRule = {
            kind: 'category',
            rule: {
              category: {
                items: ruleGroup[key],
              },
            },
          };

          if (key && key !== 'undefined') {
            newRule.id = Number(key);
          }

          rules.push(newRule);
        });
        break;
      case TABLE_TYPE_FILTER_BRAND:
        generateBrandRule(item).forEach(filter => {
          rules.push(filter);
        });
        break;
      case TABLE_TYPE_COLOR:
        generateColorRule(item).forEach(filter => {
          rules.push(filter);
        });
        break;
      case TABLE_TYPE_FILTER_DISCOUNT:
        generateDiscountRule(item, promotion).forEach(filter => {
          rules.push(filter);
        });
        break;
      case TABLE_TYPE_FILTER_ONG_PERCENTAGE:
        generateFilterOngPercentageRule(item, promotion).forEach(filter => {
          rules.push(filter);
        });
        break;
      case TABLE_TYPE_FILTER_OPTION_TYPES:
        generateFilterOptionTypeRule(item, promotion).forEach(filter => {
          rules.push(filter);
        });
        break;
      case TABLE_TYPE_PRICE:
        generatePriceRule(item, promotion).forEach(price => {
          rules.push(price);
        });
        break;
      case TABLE_TYPE_CONDITION:
        generateConditionRules(item).forEach(condition => {
          rules.push(condition);
        });
        break;
      case TABLE_TYPE_TIME:
        generateTimeRule(item, promotion).forEach(timeRule => {
          rules.push(timeRule);
        });
        break;
      default:
        break;
    }
  });

  return rules;
};

export const handleSubmitForm = (
  values,
  {
    isEdit,
    setRedirectToEdit,
    createPromotion,
    updatePromotion,
    promotion,
    setPromotion,
    tables,
    dispatch,
  }
) => {
  const rulesAttributes = generateRulesAttributes(tables, promotion);

  dispatch({
    type: ACTION_CLEAR,
  });
  if (!isEdit) {
    createPromotion({
      variables: {
        input: {
          banner: values.banner,
          buyerDescription: values.buyerDescription,
          discount: values.discount,
          expiredAt: values.expiredAt.format(),
          name: values.name,
          rulesAttributes,
          sellerDescription: values.sellerDescription,
          sellerNotifiedAt: values.sellerNotifiedAt.format(),
          startedAt: values.startedAt.format(),
          kind: values.category,
          // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
          // visible: Boolean(values.visible),
        },
      },
    })
      .then(data => {
        const {
          data: { salesSaleCreate: createData },
        } = data;

        setPromotion(prevState => {
          return {
            ...prevState,
            id: createData.id,
            slug: createData.slug,
          };
        });

        notifySuccess(
          'Promoção criada com sucesso',
          `Promoção (${createData.slug}) criada com sucesso.`
        );
        setRedirectToEdit(true);
      })
      .catch(err => {
        notifyError('Erro ao criar promoção', err);
      });
  } else {
    updatePromotion({
      variables: {
        input: {
          id: promotion.id,
          banner: values.banner,
          buyerDescription: values.buyerDescription,
          discount: values.discount,
          expiredAt: values.expiredAt ? values.expiredAt.format() : undefined,
          name: values.name,
          rulesAttributes,
          sellerDescription: values.sellerDescription,
          sellerNotifiedAt: values.sellerNotifiedAt
            ? values.sellerNotifiedAt.format()
            : undefined,
          startedAt: values.startedAt ? values.startedAt.format() : undefined,
          kind: values.category,
          // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
          // visible: Boolean(values.visible),
        },
      },
    })
      .then(data => {
        const {
          data: { salesSaleUpdate: updateData },
        } = data;

        notifySuccess(
          'Promoção atualizada com sucesso',
          `Promoção (${updateData.slug}) atualizada com sucesso.`
        );
      })
      .catch(err => {
        notifyError('Erro ao atualizar promoção', err);
      });
  }
};
