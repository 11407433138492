import React from 'react';
import { Card, Row, Col } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';

import {
  UPDATE_CONTENT_HEADER,
  QUERY_CONTENT_HEADER_ALL,
} from '~/apollo/content/header';

import DefaultHeader from './DefaultHeader';
import DefaultStickyBar from './DefaultStickyBar';

import { handleSubmitHeader, handleSubmitStickyBar } from './actions';
import LoadingBar from '~/components/LoadingBar';

function getByKind(data, kind) {
  const objs = [];
  data.forEach(item => {
    if (item.kind === kind) objs.push(item);
  });
  return objs;
}

function Header() {
  const { loading, error, data } = useQuery(QUERY_CONTENT_HEADER_ALL, {
    context: {
      newBackend: true,
    },
  });
  const [updateContentHeader] = useMutation(UPDATE_CONTENT_HEADER, {
    context: {
      newBackend: true,
    },
  });

  if (loading) return <LoadingBar />;
  if (error) return `Error! ${error.message}`;

  const contentData = data.contentDynamicContentsSearchHeaderContents.nodes;
  const topLinks = getByKind(contentData, 'top_link');

  return (
    <>
      <Card data-testid="headerCard" title="Header Padrão">
        <Row gutter={16}>
          <Col span={24}>
            <DefaultHeader
              fieldName="Titulo do síte"
              fieldId="site_title"
              initialValue={getByKind(contentData, 'site_title')[0]}
              handleSubmit={values => {
                handleSubmitHeader(
                  contentData,
                  'site_title',
                  values,
                  updateContentHeader
                );
              }}
            />
          </Col>
          <Col span={24}>
            <DefaultHeader
              fieldName="Descrição do síte"
              fieldId="site_description"
              initialValue={getByKind(contentData, 'site_description')[0]}
              handleSubmit={values => {
                handleSubmitHeader(
                  contentData,
                  'site_description',
                  values,
                  updateContentHeader
                );
              }}
            />
          </Col>
          <Col span={24}>
            <DefaultHeader
              fieldName="Palavras-chave do site (separados por vírgula)"
              fieldId="site_keywords"
              initialValue={getByKind(contentData, 'site_keywords')[0]}
              handleSubmit={values => {
                handleSubmitHeader(
                  contentData,
                  'site_keywords',
                  values,
                  updateContentHeader
                );
              }}
            />
          </Col>
        </Row>
      </Card>
      <Card
        data-testid="stickyConfig"
        title="Sticky Bar"
        style={{ marginTop: 20 }}
      >
        {topLinks.length > 0 ? (
          topLinks.map(item => (
            <Row gutter={16} key={item.id}>
              <Col span={24}>
                <DefaultStickyBar
                  initialValue={{
                    text: item.title,
                    url: item.url,
                  }}
                  handleSubmit={values => {
                    handleSubmitStickyBar(item, values, updateContentHeader);
                  }}
                />
              </Col>
            </Row>
          ))
        ) : (
          <div>Não há items!</div>
        )}
      </Card>
    </>
  );
}

export default Header;
