import React from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from './styles';

const LoadingPLaceholder = props => {
  const { style } = props;

  return <Placeholder data-testid="loading-placeholder" style={style} />;
};

LoadingPLaceholder.propTypes = {
  style: PropTypes.shape().isRequired,
};

export default LoadingPLaceholder;
