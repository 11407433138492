import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Tooltip, Card, Button, Alert } from 'antd';
import { Link } from 'react-router-dom';

import LoadingBar from '~/components/LoadingBar';
import FeedbackMessage from '~/components/FeedbackMessage';
import history from '~/services/history';
import useSearchSeoContents from '~/hooks/content/useSearchSeoContents';
import { getErrorMessage, notifyError, notifySuccess } from '~/util/Functions';
import useCreateSeoContent from '~/hooks/content/useCreateSeoContent';
import { SEO_KIND_BRAND, SEO_KIND_CATEGORY } from '~/constants/Seo';
import { translateKind } from '~/util/Seo';
import useUpdateSeoContent from '~/hooks/content/useUpdateSeoContent';
import DefaultForm from './DefaultForm';

function SeoForm({ match }) {
  const seoContentId = useMemo(() => {
    return Number(match.params.id) || null;
  }, [match]);

  // Para valida se existe um parametro de ID, mesmo que seja 'string'
  // Pois o 'seoContentId' retorna 'null' se ele não for um number
  // E como a requisição faz um 'skip' quando o 'seoContentId' não existe
  // E necessário esse parametro a mais para validar quando o usuário insere
  // um ID que não é número e mostrar a mensagem de 'Não encontrado'
  const hasParamId = useMemo(() => {
    return Boolean(match.params.id);
  }, [match]);

  const {
    data,
    loading: loadingSeoContent,
    error,
  } = useSearchSeoContents({
    skip: !seoContentId,
    variables: {
      first: 1,
      skip: 0,
      id: seoContentId,
    },
  });

  const [createSeoContent, { loading: loadingCreate }] = useCreateSeoContent({
    onCompleted: res => {
      const id = res?.seoSeoContentsCreateSeoContent?.id;
      const kind = res?.seoSeoContentsCreateSeoContent?.kind;
      const contentName = res?.seoSeoContentsCreateSeoContent?.contentName;

      notifySuccess(
        'Sucesso ao criar SEO',
        <span>
          Sucesso ao criar SEO para {translateKind(kind)} <b>{contentName}</b>
        </span>
      );

      history.replace(`/manage/content/seo/edit/${id}`);
    },
    onError: err => {
      notifyError('Erro ao criar SEO', err);
    },
  });

  const [updateSeoContent, { loading: loadingUpdate }] = useUpdateSeoContent({
    onCompleted: res => {
      const id = res?.seoSeoContentsUpdateSeoContent?.id;
      const kind = res?.seoSeoContentsUpdateSeoContent?.kind;
      const contentName = res?.seoSeoContentsUpdateSeoContent?.contentName;

      notifySuccess(
        'Sucesso ao atualizar SEO',
        <span>
          Sucesso ao atualizar SEO {translateKind(kind)} <b>{contentName}</b>
        </span>
      );

      history.replace(`/manage/content/seo/edit/${id}`);
    },
    onError: err => {
      notifyError('Erro ao atualizar SEO', err);
    },
  });

  const getContentId = (kind, values) => {
    if (kind === SEO_KIND_CATEGORY) return values?.categoryId;
    if (kind === SEO_KIND_BRAND) return values?.brandId;
    return undefined;
  };

  const callSubmit = values => {
    // Poderia passar um spreading de 'values' aqui, mas fiz assim pra legibilidade do código
    // ficar melhor, quem bate o olho já vai saber o que está sendo enviado e não precisa verificar
    // o <Form/>
    if (!seoContentId) {
      createSeoContent({
        contentId: getContentId(values?.kind, values),
        kind: values?.kind,
        image: values?.newImage,
        logo: values?.newLogo,
        mainTitle: values?.mainTitle,
        secondaryTitle: values?.secondaryTitle,
        mainContent: values?.mainContent,
        metaTitle: values?.metaTitle,
        metaDescription: values?.metaDescription,
        metaKeywords: values?.metaKeywords,
      });
    } else {
      updateSeoContent({
        id: seoContentId,
        contentId: getContentId(values?.kind, values),
        kind: values?.kind,
        image: values?.newImage,
        logo: values?.newLogo,
        mainTitle: values?.mainTitle,
        secondaryTitle: values?.secondaryTitle,
        mainContent: values?.mainContent,
        metaTitle: values?.metaTitle,
        metaDescription: values?.metaDescription,
        metaKeywords: values?.metaKeywords,
      });
    }
  };

  if (seoContentId && loadingSeoContent) return <LoadingBar />;

  return (
    <Card
      data-testid="seoAddItem"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para lista de itens SEO">
            <Link to="/manage/content/seo">
              <ArrowLeftOutlined data-testid="backToListBtn" />
            </Link>
          </Tooltip>
          <span style={{ marginLeft: '10px' }}>
            SEO - {seoContentId ? 'Editar SEO' : 'Adicionar SEO'}
          </span>
        </>
      }
    >
      {error && (
        <Alert message={getErrorMessage(error)} type="error" showIcon />
      )}
      {(seoContentId || hasParamId) && !data?.[0] ? (
        <FeedbackMessage
          icon={<CloseCircleOutlined />}
          title="Conteúdo SEO não encontrado"
          description={
            <center>
              <small>(ID: {seoContentId || match.params.id})</small>
              <p>Conteúdo SEO especificado não foi encontrado</p>
            </center>
          }
          bottom={
            <Button
              type="default"
              htmlType="submit"
              data-testid="seoReturnToListBtn"
              onClick={() => {
                history.push('/manage/content/seo');
              }}
            >
              Ir para listagem
            </Button>
          }
        />
      ) : (
        <DefaultForm
          handleSubmit={callSubmit}
          initialData={data[0]}
          isLoading={loadingCreate || loadingUpdate}
        />
      )}
    </Card>
  );
}

SeoForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape(),
  }).isRequired,
};

export default SeoForm;
