import { useRef } from 'react';

function useDebounce() {
  const debounceRef = useRef(null);

  const debouncedFn = (fn, delay) => {
    window.clearTimeout(debounceRef.current);
    debounceRef.current = window.setTimeout(() => {
      fn();
    }, delay);
  };

  return debouncedFn;
}

export default useDebounce;
