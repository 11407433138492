import {
  BAG_ORDER_STATUS_CANCELED,
  BAG_ORDER_STATUS_COMPLETED,
  BAG_ORDER_STATUS_PENDING,
} from '~/constants/BagOrder';
import { BALANCE_LABELS, BALANCE_TYPE_CREDIT } from '~/constants/Balance';
import {
  ORDER_ITEM_STATUS_APPROVED,
  ORDER_ITEM_STATUS_CANCELED,
  ORDER_ITEM_STATUS_COMPLETED,
  ORDER_ITEM_STATUS_IN_RETURN,
  ORDER_ITEM_STATUS_PENDING,
  ORDER_ITEM_STATUS_PENDING_SHIPMENT,
  ORDER_ITEM_STATUS_RECEIVED,
  ORDER_ITEM_STATUS_RETURNED,
  ORDER_ITEM_STATUS_SHIPPED,
  STATUS_CANCELED,
  STATUS_COMPLETED,
  STATUS_PENDING as ORDER_STATUS_PENDING,
  ORDER_KIND_ORIGINAL,
  TRANSLATED_ORDER_KIND_ORIGINAL,
  TRANSLATED_ORDER_KIND_DEVOLUTION,
  ORDER_KIND_DEVOLUTION,
  ORDER_SHIPMENT_SUB_TYPE_PICKUP,
  TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_PICKUP,
  ORDER_SHIPMENT_SUB_TYPE_DROPOFF,
  TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_DROPOFF,
} from '~/constants/Order';
import {
  PAYMENT_TYPE_BALANCE,
  PAYMENT_TYPE_BANKSLIP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_INVOICE,
  PAYMENT_TYPE_PIX,
  STATUS_AWAITING_PAYMENT,
  STATUS_CANCELED as PAYMENT_STATUS_CANCELED,
  STATUS_CREATED,
  STATUS_EXPIRED,
  STATUS_IN_ANALYSIS,
  STATUS_PAID,
  STATUS_PAYMENT_PENDING,
  STATUS_PENDING as PAYMENT_STATUS_PENDING,
  STATUS_REFUNDED,
  STATUS_REVERSAL,
  STATUS_REVERSED,
  STATUS_WAITING,
  PAYMENT_TYPE_FREE,
} from '~/constants/Payment';

import {
  STATE_ACTIVE,
  STATE_CANCELED,
  STATE_CART,
  STATE_IN_ORDER,
  STATE_IN_PAYMENT,
  STATE_IN_PAYOUT,
  STATE_IN_RETURN,
  STATE_IN_TRANSIT,
  STATE_NEW,
  STATE_PENDING,
  STATE_RETURNED,

  // translations
  TRANSLATED_STATE_ACTIVE,
  TRANSLATED_STATE_CART,
  TRANSLATED_STATE_IN_ORDER,
  TRANSLATED_STATE_IN_PAYMENT,
  TRANSLATED_STATE_IN_PAYOUT,
  TRANSLATED_STATE_IN_RETURN,
  TRANSLATED_STATE_IN_TRANSIT,
  TRANSLATED_STATE_NEW,
  TRANSLATED_STATE_PENDING,
  TRANSLATED_STATE_RETURNED,
  TRANSLATED_STATE_CANCELED,
  STATE_DONATED,
  TRANSLATED_STATE_DONATED,
  STATE_SHORTAGE,
  TRANSLATED_STATE_SHORTAGE,
} from '~/constants/Product';

import {
  SHIPMENT_COMPLETED,
  SHIPMENT_DELIVERED,
  SHIPMENT_DELIVERY_NOT_MADE,
  SHIPMENT_ON_ROUTE,
  SHIPMENT_SEND,
  SHIPMENT_WAITING_SENT,
  SHIPMENT_DONE,
  SHIPMENT_PENDING,
  SHIPMENT_PROCESSED,
  SHIPMENT_RECEIVED,
} from '~/constants/Shipment';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_CANCELED,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
} from '~/constants/ShipmentDispatch';

export const getProductStatus = (status, isAnticipated) => {
  if (!status) return 'Sem status';
  if (isAnticipated) return 'Produto antecipado';

  switch (status) {
    case STATE_ACTIVE:
      return TRANSLATED_STATE_ACTIVE;
    case STATE_CART:
      return TRANSLATED_STATE_CART;
    case STATE_IN_ORDER:
      return TRANSLATED_STATE_IN_ORDER;
    case STATE_IN_PAYMENT:
      return TRANSLATED_STATE_IN_PAYMENT;
    case STATE_IN_PAYOUT:
      return TRANSLATED_STATE_IN_PAYOUT;
    case STATE_IN_RETURN:
      return TRANSLATED_STATE_IN_RETURN;
    case STATE_IN_TRANSIT:
      return TRANSLATED_STATE_IN_TRANSIT;
    case STATE_NEW:
      return TRANSLATED_STATE_NEW;
    case STATE_PENDING:
      return TRANSLATED_STATE_PENDING;
    case STATE_RETURNED:
      return TRANSLATED_STATE_RETURNED;
    case STATE_CANCELED:
      return TRANSLATED_STATE_CANCELED;
    case STATE_DONATED:
      return TRANSLATED_STATE_DONATED;
    case STATE_SHORTAGE:
      return TRANSLATED_STATE_SHORTAGE;
    default:
      return status;
  }
};

export const getOrderItemStatus = status => {
  if (!status) return 'Sem status';

  switch (status) {
    case ORDER_ITEM_STATUS_APPROVED:
      return 'Aprovado';
    case ORDER_ITEM_STATUS_CANCELED:
      return 'Cancelado';
    case ORDER_ITEM_STATUS_COMPLETED:
      return 'Finalizado';
    case ORDER_ITEM_STATUS_IN_RETURN:
      return 'Em devolução';
    case ORDER_ITEM_STATUS_PENDING:
      return 'Pendente';
    case ORDER_ITEM_STATUS_PENDING_SHIPMENT:
      return 'Envio pendente';
    case ORDER_ITEM_STATUS_RECEIVED:
      return 'Recebido';
    case ORDER_ITEM_STATUS_RETURNED:
      return 'Devolvido';
    case ORDER_ITEM_STATUS_SHIPPED:
      return 'Enviado';
    default:
      return status;
  }
};

export const getBagOrderStatus = status => {
  if (!status) return 'Sem status';

  switch (status) {
    case BAG_ORDER_STATUS_PENDING:
      return 'Aguardando';
    case BAG_ORDER_STATUS_CANCELED:
      return 'Cancelado';
    case BAG_ORDER_STATUS_COMPLETED:
      return 'Finalizado';
    default:
      return status;
  }
};

export const getShipmentStatus = (status, kind = ORDER_KIND_ORIGINAL) => {
  if (!status) return 'Sem status';
  switch (status) {
    case SHIPMENT_PENDING:
    case SHIPMENT_WAITING_SENT:
      return 'Aguardando envio';
    case SHIPMENT_SEND:
      return 'Enviado';
    case SHIPMENT_PROCESSED:
      return 'Envio processado';
    case SHIPMENT_ON_ROUTE:
      return 'Em rota de entrega';
    case SHIPMENT_RECEIVED:
      return 'Recebido';
    case SHIPMENT_DELIVERED:
      return 'Entregue';
    case SHIPMENT_DONE:
      return 'Envio feito';
    case SHIPMENT_COMPLETED:
      return 'Finalizado';
    case SHIPMENT_DELIVERY_NOT_MADE:
      return kind === ORDER_KIND_ORIGINAL
        ? 'Entrega não realizada'
        : 'Falha no envio';
    default:
      return status;
  }
};

export const getOrderStatus = status => {
  if (!status) return 'Sem status';

  switch (status) {
    case ORDER_STATUS_PENDING:
      return 'Aguardando';
    case STATUS_CANCELED:
      return 'Cancelado';
    case STATUS_COMPLETED:
      return 'Finalizado';
    default:
      return status;
  }
};

export const isRepurchaseOrder = value => {
  if (value === undefined || value === null) return 'Não consta';
  return value ? 'Sim' : 'Não';
};

export const getPaymentStatus = status => {
  if (!status) return 'Sem status';
  switch (status) {
    case STATUS_CREATED:
      return 'Criado';
    case STATUS_PAID:
      return 'Pago';
    case STATUS_AWAITING_PAYMENT:
    case STATUS_WAITING:
    case PAYMENT_STATUS_PENDING:
    case STATUS_PAYMENT_PENDING:
      return 'Aguardando pagamento';
    case STATUS_REVERSAL:
      return 'Estorno';
    case STATUS_REFUNDED:
      return 'Estornado';
    case STATUS_REVERSED:
      return 'Revertido';
    case STATUS_EXPIRED:
      return 'Expirado';
    case STATUS_IN_ANALYSIS:
      return 'Em Análise';
    case PAYMENT_STATUS_CANCELED:
      return 'Cancelado';
    default:
      return status;
  }
};

export const getShipmentDispatchStatus = status => {
  if (!status) return 'Sem status';

  switch (status) {
    case SHIPMENT_DISPATCH_AWAITING:
      return 'Aguardando separação';
    case SHIPMENT_DISPATCH_PROCESSING:
      return 'Em separação';
    case SHIPMENT_DISPATCH_PROCESSED:
      return 'Separado';
    case SHIPMENT_DISPATCH_CANCELED:
      return 'Cancelado';
    default:
      return status;
  }
};

export const getPaymentType = status => {
  switch (status) {
    case PAYMENT_TYPE_CREDIT_CARD:
      return 'Cartão de crédito';
    case PAYMENT_TYPE_INVOICE:
      return 'Boleto bancário';
    case PAYMENT_TYPE_BANKSLIP:
      return 'Boleto bancário';
    case PAYMENT_TYPE_BALANCE:
      return 'Saldo Repassa';
    case PAYMENT_TYPE_PIX:
      return 'Pix';
    case PAYMENT_TYPE_FREE:
      return 'Grátis';
    default:
      return status;
  }
};

export const balanceLabelConverter = (key, type = BALANCE_TYPE_CREDIT) => {
  const label = BALANCE_LABELS[type][key];
  if (label) return label;
  return key;
};

export const getProductOrderKind = kind => {
  switch (kind) {
    case ORDER_KIND_ORIGINAL:
      return TRANSLATED_ORDER_KIND_ORIGINAL;
    case ORDER_KIND_DEVOLUTION:
      return TRANSLATED_ORDER_KIND_DEVOLUTION;
    default:
      return TRANSLATED_ORDER_KIND_ORIGINAL;
  }
};

export const getShipmentOrderSubType = type => {
  if (!type) return 'Não consta';

  switch (type) {
    case ORDER_SHIPMENT_SUB_TYPE_DROPOFF:
      return TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_DROPOFF;
    case ORDER_SHIPMENT_SUB_TYPE_PICKUP:
      return TRANSLATED_ORDER_SHIPMENT_SUB_TYPE_PICKUP;
    default:
      return type;
  }
};
