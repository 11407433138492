import React from 'react';
import { Tooltip } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { normalizeAssetsUri } from '~/util/Functions';
import Button from '../../../../components/Button';
import { handleDelete } from './actions';

export function generateColumns(hooks) {
  return [
    {
      key: 'name',
      title: 'Nome',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      width: 180,
      sorter: (a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        return 0;
      },
      render: (text, { id, permalink }) => (
        <Tooltip
          placement="bottom"
          title={`Ver sub categorias de /${permalink}`}
        >
          <Link to={`/manage/content/category/${id}`}>{text}</Link>
        </Tooltip>
      ),
    },
    {
      key: 'permalink',
      title: 'Link da Categoria (permalink)',
      dataIndex: 'permalink',
      render: (text, record) => (
        <div style={{ minWidth: '200px' }}>
          {text}{' '}
          <Tooltip placement="bottom" title="Abrir na loja">
            <a
              rel="noopener noreferrer"
              href={`${normalizeAssetsUri(
                process.env.REACT_APP_STOREFRONT_URL
              )}${record.permalink}`}
              target="_blank"
            >
              <ExportOutlined />
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      key: 'productCount',
      title: 'Quantidade de produtos',
      dataIndex: 'productCount',
      width: 200,
      align: 'center',
      render: (text, record) => {
        return <div style={{ minWidth: '180px' }}>{record.productCount}</div>;
      },
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'x',
      width: 80,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div style={{ minWidth: '80px' }}>
            <Tooltip placement="bottom" title="Editar">
              <Link to={`/manage/content/category/edit/${record.id}`}>
                <Button
                  data-testid="editBtn"
                  type="link"
                  icon={<EditOutlined />}
                  style={{ margin: 0 }}
                />
              </Link>
            </Tooltip>
            <Tooltip placement="bottom" title="Excluir">
              <Button
                data-testid="deleteBtn"
                type="link"
                onClick={() => handleDelete(record.id, hooks)}
                style={{ margin: 0, padding: 0, color: 'red' }}
                icon={<DeleteOutlined />}
                disabled={!!record.isFixed}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
}
