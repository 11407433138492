import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Alert, Row, Col, Drawer, Typography, Carousel } from 'antd';

import { getErrorMessage, getImageUrl } from '~/util/Functions';
import { BLOCKS_LIST } from '~/apollo/marketing/home';

import Image from '~/components/Image';
import LoadingBar from '~/components/LoadingBar';

const { Title, Text } = Typography;

function BlockProductHeader({ title, subtitle }) {
  return (
    <>
      <Col span={24}>
        <Title level={3} style={{ textAlign: 'center', fontWeight: 'normal' }}>
          {title}
        </Title>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Text>{subtitle}</Text>
      </Col>
    </>
  );
}

BlockProductHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

function BlockProducts({ products }) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        overflowX: 'scroll',
      }}
    >
      {products
        .filter(product => !product.desktopHide)
        .map(product => (
          <div
            key={product.id}
            style={{
              minWidth: '200px',
              padding: '5px',
            }}
            className="testeProductPreview"
          >
            <div
              style={{
                margin: '5px',
                width: '100%',
              }}
            >
              {product.product.images.length > 0 ? (
                <Image
                  src={getImageUrl(product.product.images[0].imageUrl)}
                  width="100%"
                  height="200px"
                  style={{ objectFit: 'contain' }}
                  alt={product.product.name}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    lineHeight:
                      product.product.images.length > 0 ? null : '100px',
                    whiteSpace: 'nowrap',
                    background: '#CCC',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Produto
                </div>
              )}
            </div>
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                textTransform: 'uppercase',
              }}
            >
              <Text>
                {product.product.brand ? product.product.brand.name : null}
              </Text>
            </div>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Text>{product.product.name}</Text>
            </div>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <Text>R$ {product.product.price}</Text>
            </div>
          </div>
        ))}
    </div>
  );
}

BlockProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function BlockBanner({ banners }) {
  return (
    <>
      {banners.map(banner => {
        const imageDimensions = banner.desktopSize * (1920 / 12);

        return banner.desktopHide ? null : (
          <Col key={banner.id} span={banner.desktopSize * 2}>
            <div
              style={{
                margin: '5px',
                width: '100%',
              }}
            >
              {banner.banner ? (
                <Image
                  src={getImageUrl(banner.banner, `${imageDimensions}x0`)}
                  width="100%"
                  style={{ objectFit: 'contain' }}
                  alt={banner.alt}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    lineHeight: banner.banner ? null : '100px',
                    whiteSpace: 'nowrap',
                    background: '#CCC',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Banner
                </div>
              )}
            </div>
          </Col>
        );
      })}
    </>
  );
}

BlockBanner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function BlockCarousel({ banners }) {
  return (
    <Col span={24}>
      <Carousel autoplay>
        {banners.map(banner => {
          return banner.desktopHide ? null : (
            <div>
              {banner.banner ? (
                <Image
                  src={getImageUrl(banner.banner, `1024x0`)}
                  width="100%"
                  style={{ objectFit: 'contain' }}
                  alt={banner.alt}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                    lineHeight: banner.banner ? null : '100px',
                    whiteSpace: 'nowrap',
                    background: '#CCC',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Banner
                </div>
              )}
            </div>
          );
        })}
      </Carousel>
    </Col>
  );
}

BlockCarousel.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function Preview({ open, setOpen }) {
  const { error, data, loading } = useQuery(BLOCKS_LIST, {
    variables: { published: true, basic: false },
  });

  const renderContent = () => {
    if (error) {
      return <Alert message={getErrorMessage(error)} type="error" showIcon />;
    }
    if (loading) return <LoadingBar />;

    return (
      <Row type="flex" justify="center" align="middle">
        <Col>
          {data?.homeBlocksSearchBlocks?.nodes?.map?.(block => (
            <Row
              key={block.id}
              gutter={4}
              type="flex"
              justify="center"
              align="top"
              style={{
                marginBottom: '30px',
                maxWidth: '960px',
              }}
            >
              {block.kind === 'banner_carousel' && (
                <BlockCarousel banners={block.blockBanners} />
              )}
              {block.kind === 'banner' && (
                <BlockBanner banners={block.blockBanners} />
              )}
              {block.kind === 'product' && (
                <>
                  <BlockProductHeader
                    title={block.title}
                    subtitle={block.subtitle}
                  />
                  <BlockProducts products={block.blockProducts} />
                </>
              )}
            </Row>
          ))}
        </Col>
      </Row>
    );
  };

  return (
    <Drawer
      title="Preview da Home"
      open={open}
      closable
      onClose={() => setOpen(false)}
      width="100VW"
    >
      {renderContent()}
    </Drawer>
  );
}

Preview.defaultProps = {
  open: false,
  setOpen: () => {},
};

Preview.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default Preview;
