import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { convertDate, toCurrencyFormat } from '~/util/Functions';
import { getRelativeItemTypeFromKind } from '~/util/Balance';

import {
  ITEM_TYPE_BAG,
  ITEM_TYPE_BAG_ORDER,
  ITEM_TYPE_BONUS,
  ITEM_TYPE_DONATION,
  ITEM_TYPE_ORDER,
  ITEM_TYPE_ORDER_ITEM,
  ITEM_TYPE_ORDER_PAYOUT,
  ITEM_TYPE_PAYOUT_DONATION,
  ITEM_TYPE_VOUCHER,
} from '~/constants/Balance';

const { Text } = Typography;

const getUserRoute = (kind, slug) => {
  switch (kind) {
    case 'partner':
      return `https://repassa.com.br/parceiros-do-bem/${slug}`;
    case 'customer':
    case 'ong':
    default:
      // TODO: Parece que ONG também usa o link /vitrines
      return `https://repassa.com.br/vitrines/${slug}`;
  }
};

const renderItems = (id, kind, items) => {
  const itemType = getRelativeItemTypeFromKind(kind);
  switch (itemType) {
    case ITEM_TYPE_VOUCHER:
    case ITEM_TYPE_BONUS:
    case ITEM_TYPE_DONATION:
    case ITEM_TYPE_PAYOUT_DONATION:
      return items.map((item, index) => (
        <span key={`${id}-${item.id}`}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            to={{ pathname: getUserRoute(item.kind, item.slug) }}
          >
            ID: #{item.id} - {item.name}
          </a>
          {index === items.length - 1 ? '' : ';'}
        </span>
      ));
    case ITEM_TYPE_BAG_ORDER:
    case ITEM_TYPE_BAG:
      return items.map((item, index) => (
        <span key={`${id}-${item.id}`}>
          Código: <Text type="secondary">{item.barcode}</Text>{' '}
          <small>
            <Text type="secondary">(ID: {item.id})</Text>
          </small>
          {index === items.length - 1 ? '' : <br />}
        </span>
      ));
    case ITEM_TYPE_ORDER:
    case ITEM_TYPE_ORDER_PAYOUT:
    case ITEM_TYPE_ORDER_ITEM:
      return items.map((item, index) => (
        <span key={`${id}-${item.id}`}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={{ pathname: `https://repassa.com.br/produtos/${item.slug}` }}
          >
            ID: #{item.id} - {item.name}
          </Link>
          {index === items.length - 1 ? '' : <br />}
        </span>
      ));
    default:
      return null;
  }
};

export function generateColumns() {
  const data = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, { id }) => `#${id}`,
    },
    {
      title: 'Tipo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (_, { id, description, kind, items }) => (
        <Text>
          {description}
          <br />
          {renderItems(id, kind, items)}
        </Text>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (_, { value }) => (
        <Text type={value >= 0 ? 'success' : 'danger'}>
          {toCurrencyFormat(value)}
        </Text>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt, updatedAt }) => (
        <>
          {convertDate(createdAt)}
          <br />
          <small>
            <Text type="secondary">
              (Última atualização: {convertDate(updatedAt)})
            </Text>
          </small>
        </>
      ),
    },
  ];

  return data;
}
