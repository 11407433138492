import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { Typography, Row, Col, Divider, Table } from 'antd';

import useDeleteBlockAttribute from '~/hooks/marketing/attributes/useDeleteAttributes';
import { generateAttrColumns } from './columns';
import { checkDeleteAttr } from './actions';

function ListAttributes({
  block,
  setBlock,
  attributeData,
  setAttributeData,
  updateBlockAttribute,
}) {
  const { Title } = Typography;

  const [deleteAttribute] = useDeleteBlockAttribute({
    refetchQueries: ['homeSearchBlockByID'],
  });

  const setOrderedTableData = currentData => {
    let newData = [];

    if (currentData?.length > 0) {
      const attrData = [];
      newData = [...currentData];

      const orderedNewData = newData.sort((a, b) =>
        a.weightPosition > b.weightPosition ? 1 : -1
      );

      orderedNewData.forEach((data, index) => {
        attrData.push({
          ...data,
          weightPosition: index,
        });
      });

      setAttributeData(attrData);
    }
  };

  const handleChangeSort = (type, position) => {
    if (type === 'up' && position === attributeData[0].weightPosition) return;
    if (type === 'down' && position === attributeData.at(-1).weightPosition)
      return;

    const newPosition = type === 'up' ? position - 1 : position + 1;
    const arr = attributeData.map(item => {
      if (
        item.weightPosition === position ||
        item.weightPosition === newPosition
      ) {
        return {
          ...item,
          weightPosition:
            item.weightPosition === position ? newPosition : position,
        };
      }
      return item;
    });

    setOrderedTableData(arr);
  };

  useEffect(() => {
    setOrderedTableData(block.blockAttributesBar);
    // eslint-disable-next-line
  }, [block.blockAttributesBar]);

  const columns = generateAttrColumns(
    block,
    setBlock,
    handleChangeSort,
    updateBlockAttribute,
    checkDeleteAttr,
    deleteAttribute
  );

  const renderForm = () => {
    return (
      <>
        <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
          Listagem de atributos
        </Title>
        <Divider />
        <Row gutter={16} style={{ marginBottom: 50 }}>
          <Col span={24}>
            <Table
              title={() =>
                attributeData?.length
                  ? `Total encontrado - ${attributeData.length}`
                  : null
              }
              dataSource={attributeData}
              columns={columns}
              rowKey={record => record.id}
              bordered
              scroll={{ x: 'max-content' }}
              expandable={false}
              pagination={false}
            />
          </Col>
        </Row>
      </>
    );
  };

  return renderForm();
}

ListAttributes.propTypes = {
  block: PropType.shape().isRequired,
  setBlock: PropType.func.isRequired,
};

export default ListAttributes;
