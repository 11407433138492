import React from 'react';

import {
  SettingOutlined,
  ContainerOutlined,
  CopyrightOutlined,
  ShoppingOutlined,
  RollbackOutlined,
  SmileOutlined,
  HeartOutlined,
  LikeOutlined,
  FileOutlined,
  TagOutlined,
  HomeOutlined,
  GiftOutlined,
  LayoutOutlined,
  QuestionOutlined,
  MonitorOutlined,
  CloudUploadOutlined,
  FileSyncOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import {
  TECNOLOGIA,
  ATENDIMENTO,
  CADASTRO,
  LOGISTICA,
  MARKETING,
} from '../../constants/UserGroups';

export const menuData = [
  {
    key: 'orders',
    menuGroup: [
      {
        key: 'manage/orders',
        title: 'Pedidos',
        menuItem: [
          {
            key: 'manage/orders/product/return',
            title: 'Aprovação de Devolução',
            icon: <RollbackOutlined />,
            to: '/manage/orders/product/return',
            groups: [TECNOLOGIA, ATENDIMENTO, LOGISTICA],
          },
          {
            key: 'manage/orders/products',
            title: 'Pedidos de Produto',
            icon: <FileOutlined />,
            to: '/manage/orders/products',
            groups: [TECNOLOGIA, ATENDIMENTO, LOGISTICA],
          },
          {
            key: 'manage/orders/bag',
            title: 'Pedidos de Sacola do Bem',
            icon: <ShoppingOutlined />,
            to: '/manage/orders/bag',
            groups: [TECNOLOGIA, ATENDIMENTO, LOGISTICA],
          },
        ],
      },
    ],
    subMenu: [],
  },
  {
    key: 'service',
    menuGroup: [
      {
        key: 'manage/service',
        title: 'Atendimento',
        menuItem: [
          {
            key: 'manage/service/client',
            title: 'Clientes',
            icon: <SmileOutlined />,
            to: '/manage/service/client',
            groups: [TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA],
          },
          {
            key: 'manage/service/ong',
            title: 'ONGs',
            icon: <HeartOutlined />,
            to: '/manage/service/ong',
            groups: [TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA],
          },
          {
            key: 'manage/service/partner',
            title: 'Parceiros',
            icon: <LikeOutlined />,
            to: '/manage/service/partner',
            groups: [TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA],
          },
        ],
      },
    ],
    subMenu: [],
  },
  {
    key: 'marketing',
    menuGroup: [
      {
        key: 'manage/marketing',
        title: 'Marketing',
        menuItem: [
          {
            key: 'manage/marketing/coupons',
            title: 'Cupons',
            icon: <TagOutlined />,
            to: '/manage/marketing/coupons',
            groups: [TECNOLOGIA, MARKETING],
          },
          {
            key: 'manage/marketing/home',
            title: 'Painel da Home',
            icon: <HomeOutlined />,
            to: '/manage/marketing/home',
            groups: [TECNOLOGIA, MARKETING],
          },
          {
            key: 'manage/marketing/promotion',
            title: 'Promoções',
            icon: <GiftOutlined />,
            to: '/manage/marketing/promotion',
            groups: [TECNOLOGIA, MARKETING],
          },
        ],
      },
    ],
    subMenu: [],
  },
  {
    key: 'financial',
    menuGroup: [
      {
        key: 'manage/financial',
        title: 'Financeiro',
        menuItem: [],
      },
    ],
    subMenu: [
      {
        key: 'financialConciliationNFSe',
        title: 'Conciliação de NFS-e',
        icon: <FileSyncOutlined />,
        menuItem: [
          {
            key: 'manage/financial/conciliation-nfse/send-files',
            title: 'Enviar Arquivos',
            icon: <CloudUploadOutlined />,
            to: '/manage/financial/conciliation-nfse/send-files',
            groups: [TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA],
          },
          {
            key: 'manage/financial/conciliation-nfse/status',
            title: 'Status da Conciliação',
            icon: <CheckCircleOutlined />,
            to: '/manage/financial/conciliation-nfse/status',
            groups: [TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA],
          },
        ],
      },
    ],
  },
  {
    key: 'content',
    menuGroup: [
      {
        key: 'manage/content',
        title: 'Conteúdo Dinâmico',
        menuItem: [
          {
            key: 'manage/content/header',
            title: 'Header',
            icon: <LayoutOutlined />,
            to: '/manage/content/header',
            groups: [TECNOLOGIA, ATENDIMENTO, MARKETING],
          },
          {
            key: 'manage/content/faq',
            title: 'FAQ',
            icon: <QuestionOutlined />,
            to: '/manage/content/faq',
            groups: [TECNOLOGIA, ATENDIMENTO, MARKETING],
          },
          {
            key: 'manage/content/seo',
            title: 'SEO',
            icon: <MonitorOutlined />,
            to: '/manage/content/seo',
            groups: [TECNOLOGIA, ATENDIMENTO, MARKETING],
          },
          {
            key: 'manage/content/footer',
            title: 'Footer',
            icon: <LayoutOutlined />,
            to: '/manage/content/footer',
            groups: [TECNOLOGIA, ATENDIMENTO, MARKETING],
          },
        ],
      },
    ],
    subMenu: [
      {
        key: 'bagSettings',
        title: 'Configuração',
        icon: <SettingOutlined />,
        menuItem: [
          {
            key: 'manage/content/category',
            title: 'Categoria',
            icon: <ContainerOutlined />,
            to: '/manage/content/category',
            groups: [TECNOLOGIA, CADASTRO],
          },
          {
            key: 'manage/content/brand',
            title: 'Marca',
            icon: <CopyrightOutlined />,
            to: '/manage/content/brand',
            groups: [TECNOLOGIA, CADASTRO],
          },
        ],
      },
    ],
  },
];
