import { LOCATION_CHANGE } from 'connected-react-router';
import {
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
} from '../../constants/ActionTypes';

const initialSettings = {
  navCollapsed: true,
  pathname: '',
  width: window.innerWidth,
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.navCollapsed,
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    default:
      return state;
  }
};

export default settings;
