export const ICON_SIZE = [
  {
    key: 1,
    size: 8,
  },
  {
    key: 2,
    size: 12,
  },
  {
    key: 3,
    size: 16,
  },
  {
    key: 4,
    size: 24,
  },
  {
    key: 5,
    size: 36,
  },
  {
    key: 6,
    size: 48,
  },
  {
    key: 7,
    size: 64,
  },
  {
    key: 8,
    size: 98,
  },
  {
    key: 9,
    size: 132,
  },
];
