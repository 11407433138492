import React from 'react';
import { Form, Select } from 'antd';

import { DDDs } from '~/constants/GeneralForms';

const { Option } = Select;

export default function () {
  return (
    <Form.Item name="ddd" noStyle>
      <Select style={{ width: 70 }}>
        {DDDs.map(ddd => {
          return (
            <Option key={ddd} value={ddd}>
              {ddd}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}
