import { useMutation } from '@apollo/react-hooks';
import { notifyError } from '~/util/Functions';
import { MUTATION_APPROVE_RETURN } from '~/apollo/logistics/return.js';

function useApproveOrderItemReturn(options = {}) {
  const [approveReturn, { error, data, loading }] = useMutation(
    MUTATION_APPROVE_RETURN,
    {
      onError: errApprove => {
        notifyError('Um erro ocorreu', errApprove);
      },
      ...options,
    }
  );

  return [
    ({
      productId = undefined,
      orderId = undefined,
      reverseCode = undefined,
    }) => {
      approveReturn({
        variables: {
          productId,
          orderId,
          reverseCode,
        },
      });
    },
    {
      data: data?.confirmOrdersItemReturn,
      loading,
      error,
    },
  ];
}

export default useApproveOrderItemReturn;
