import { userKindLabels } from '~/constants/Users';

export const userModalContent = (isBlocked, userKind) => {
  const willBlockUser = isBlocked ? 'desbloquear' : 'bloquear';
  const userBlockedSuccessfully = isBlocked ? 'desbloqueado' : 'bloqueado';
  const kindLabel = userKindLabels[userKind];

  return {
    title: `Você tem certeza que deseja ${willBlockUser} este ${kindLabel}?`,
    content: `Ao ${willBlockUser} este ${kindLabel}, ele ${
      isBlocked ? 'ganhará' : 'perderá'
    } acesso à plataforma.`,
    okText: willBlockUser,
    okType: isBlocked ? 'primary' : 'danger',
    cancelText: 'cancelar',
    userBlockedMessage: `${kindLabel} ${userBlockedSuccessfully}`,
    userBlockedDescription: `${kindLabel} ${userBlockedSuccessfully} com sucesso!`,
  };
};
