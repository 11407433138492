import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';
import { Card, Tooltip } from 'antd';
import { CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import history from '~/services/history';
import LoadingBar from '~/components/LoadingBar';
import FeedbackMessage from '~/components/FeedbackMessage';
import Button from '~/components/Button';
import { DragSortTableProvider } from '~/providers/DragSortTable';

import {
  BLOCK_CREATE,
  BLOCK_UPDATE,
  BLOCKS_LIST,
  BLOCK_ID_SEARCH,
  BLOCK_PRODUCT_UPDATE,
} from '~/apollo/marketing/home';
import DefaultForm from './DefaultForm';
import { handleSubmit, loadBlockProduct } from './actions';

function BlockProduct({ match }) {
  const [isEdit, setIsEdit] = useState(match.url.includes('edit'));
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(null);
  const [blockProduct, setBlockProduct] = useState({
    title: '',
    subtitle: '',
    urls: [],
  });

  const [queryBlockProductById, { data }] = useLazyQuery(BLOCK_ID_SEARCH, {
    context: {
      newBackend: true,
    },
    variables: {
      id: Number(match.params.id),
    },
    fetchPolicy: 'network-only',
    onCompleted: result => {
      const {
        homeBlocksSearchBlocks: { nodes: BlockProductNodes },
      } = result;

      if (BlockProductNodes.length > 0) {
        setBlockProduct(BlockProductNodes[0]);
      } else {
        setNotFound(true);
      }
    },
    onError: errorSearch => {
      setError(errorSearch);
      setLoading(false);
    },
  });

  const [createBlockProduct] = useMutation(BLOCK_CREATE, {
    context: {
      newBackend: true,
    },
    update(cache, { data: { homeBlocksCreateBlock: addBlock } }) {
      try {
        const { homeBlocksSearchBlocks } = cache.readQuery({
          query: BLOCKS_LIST,
        });

        homeBlocksSearchBlocks.nodes = homeBlocksSearchBlocks.nodes.concat([
          addBlock,
        ]);

        cache.writeQuery({
          query: BLOCKS_LIST,
          data: {
            homeBlocksSearchBlocks,
          },
        });
      } catch {
        // Cache doesn't exists, do nothing
      }
    },
  });

  const [updateBlockProduct] = useMutation(BLOCK_UPDATE, {
    context: {
      newBackend: true,
    },
  });

  const [updateProductFromBlock] = useMutation(BLOCK_PRODUCT_UPDATE, {
    context: {
      newBackend: true,
    },
  });

  const callSubmit = (values, tableData, setTableData) => {
    handleSubmit(values, tableData, setTableData, {
      isEdit,
      blockProduct,
      setBlockProduct,
      createBlockProduct,
      updateBlockProduct,
      updateProductFromBlock,
      setRedirectToEdit,
    });
  };

  useEffect(() => {
    if (isEdit) {
      queryBlockProductById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    loadBlockProduct({ data, setBlockProduct, setLoading });
  }, [data]);

  useEffect(() => {
    if (redirectToEdit) {
      setIsEdit(true);
      setRedirectToEdit(false);
    }
  }, [redirectToEdit, setIsEdit]);

  if (isEdit && loading) return <LoadingBar />;
  if (isEdit && error) return `Error! ${error.message}`;

  if (redirectToEdit) {
    return (
      <Redirect to={`/manage/marketing/home/product/edit/${blockProduct.id}`} />
    );
  }

  if (notFound)
    return (
      <Card>
        <FeedbackMessage
          icon={<CloseCircleOutlined />}
          title="Bloco de produto não encontrada"
          description={
            <center>
              <small>(ID: {match.params.id})</small>
              <p>Bloco de produto especificado não foi encontrado</p>
            </center>
          }
          bottom={
            <Button
              type="default"
              htmlType="submit"
              data-testid="blockProductReturnBtn"
              onClick={() => {
                history.push('/manage/marketing/home');
              }}
            >
              Ir para listagem
            </Button>
          }
        />
      </Card>
    );

  return (
    <Card
      data-testid="blockProductCardForm"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para listagem">
            <ArrowLeftOutlined
              onClick={history.goBack}
              data-testid="homeBackToBlocksBtn"
            />
          </Tooltip>
          <span style={{ marginLeft: '10px' }}>HOME - Bloco de produto</span>
        </>
      }
    >
      <DragSortTableProvider>
        <DefaultForm
          handleSubmit={callSubmit}
          initialData={blockProduct}
          isEdit={isEdit}
        />
      </DragSortTableProvider>
    </Card>
  );
}

BlockProduct.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
      kind: PropTypes.string,
      published: PropTypes.bool,
      subtitle: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      weightPosition: PropTypes.number,
    }).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default BlockProduct;
