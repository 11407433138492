import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Typography, Tooltip } from 'antd';
import {
  DeleteOutlined,
  FileExcelOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Button from '~/components/Button';
import Image from '~/components/Image';
import UppyUpload from './uppy';

const { Text } = Typography;

const UploadImg = ({
  thumb,
  id,
  alt,
  text,
  onChange,
  listType,
  onError,
  onRemove,
}) => {
  const [image, upload, error, { clearErrors }] = UppyUpload(id);
  const [thumbnail, setThumbnail] = useState(thumb);
  const [loading, setLoading] = useState(false);
  const [loadThumbError, setLoadThumbError] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(text);

  useEffect(() => {
    if (image) {
      onChange(image);
    }
  }, [image]);

  useEffect(() => {
    setThumbnail(thumb);
    setLoadThumbError(false);
  }, [thumb]);

  const onLoadImageError = () => {
    setLoadThumbError(true);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function validations(file) {
    const isJpgOrPng =
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif';

    if (!isJpgOrPng)
      message.error('You can only upload JPG/JPEG/PNG/GIF file!');

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) message.error('Image must smaller than 2MB!');

    return [isJpgOrPng, isLt2M];
  }

  const beforeUpload = file => {
    try {
      clearErrors();
      setLoadThumbError(false);
      const [isJpgOrPng, isLt2M] = validations(file);
      upload(file);

      getBase64(file, img => {
        setThumbnail(img);
        setLoading(false);
      });

      return isJpgOrPng && isLt2M;
    } catch (err) {
      return false;
    }
  };

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }
  };

  const customRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const icon = useMemo(() => {
    if (!(error.length > 0 || loadThumbError) && loading) {
      setFeedbackMessage('Carregando');
      return <LoadingOutlined />;
    }

    if (error.length > 0) {
      setFeedbackMessage('Erro de Upload');
      return <FileExcelOutlined />;
    }

    if (loadThumbError) {
      setFeedbackMessage('Erro de carregamento');
      return <FileExcelOutlined />;
    }

    setFeedbackMessage(text);
    return <PlusOutlined />;
  }, [loadThumbError, error, loading, text]);

  const hasError = useMemo(
    () => loadThumbError || error.length > 0,
    [loadThumbError, error]
  );

  useEffect(() => {
    if (hasError) onError();
  }, [hasError]);

  const onRemoveFile = () => {
    setThumbnail(null);
    setLoadThumbError(false);
    clearErrors();
    onRemove();
  };

  return (
    <div>
      <Upload
        name={id}
        listType={!hasError && thumbnail ? listType : 'picture-card'}
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={customRequest}
        data-testid="uploadComponent"
      >
        {thumbnail && !loading && !hasError ? (
          <Image
            src={thumbnail}
            alt={alt}
            style={{ width: '100%' }}
            data-testid="thumbnailUploaded"
            onError={onLoadImageError}
            preview={false}
          />
        ) : (
          <div data-testid="uploadImageBtn">
            <Text type={hasError ? 'danger' : ''}>{icon}</Text>
            <div className="ant-upload-text">
              <Text type={hasError > 0 ? 'danger' : ''}>{feedbackMessage}</Text>
            </div>
          </div>
        )}
      </Upload>
      {onRemove && thumbnail && (
        <Tooltip placement="bottom" title="Limpar imagem">
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={onRemoveFile}
            size="small"
            style={{
              position: 'absolute',
              left: 0,
              margin: '5px',
              border: '0px',
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};

UploadImg.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  thumb: PropTypes.string,
  listType: PropTypes.oneOf(['picture', 'picture-card', 'text']),
  onError: PropTypes.func,
  onRemove: PropTypes.func,
};

UploadImg.defaultProps = {
  alt: 'Image',
  id: 'uppyUpload',
  text: 'Upload',
  listType: 'picture-card',
  onChange: () => {},
  thumb: null,
  onError: () => {},
  onRemove: null,
};

export default UploadImg;
