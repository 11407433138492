export default {
  id: 'ongPercentage',
  kind: 'ongPercentage',
  presentation: 'Repasse Solidário',
  optionValues: [
    {
      id: 25,
      key: 25,
      presentation: 25,
      value: 'doação de 25%',
      dataTestId: '25',
    },
    {
      id: 50,
      key: 50,
      presentation: 50,
      value: 'doação de 50%',
      dataTestId: '50',
    },
    {
      id: 75,
      key: 75,
      presentation: 75,
      value: 'doação de 75%',
      dataTestId: '75',
    },
    {
      id: 100,
      key: 100,
      presentation: 100,
      value: 'doação de 100%',
      dataTestId: '100',
    },
  ],
};
