export default {
  preto: {
    label: 'Preto',
    hex: '#000',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#000', url: null },
      claro: { label: 'Claro', hex: '#696969', url: null },
    },
  },
  branco: {
    label: 'Branco',
    hex: '#ffffff',
    url: null,
    tones: {
      mesclado: { label: 'Mesclado', hex: '', url: 'm-branco.jpg' },
    },
  },
  cinza: {
    label: 'Cinza',
    hex: '#9a999e',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#808080', url: null },
      claro: { label: 'Claro', hex: '#DCDCDC', url: null },
      medio: { label: 'Médio', hex: '#C0C0C0', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-cinza.jpg' },
    },
  },
  amarelo: {
    label: 'Amarelo',
    hex: '#feee00',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#CBCC14', url: null },
      claro: { label: 'Claro', hex: '#FAF7CC', url: null },
      medio: { label: 'Médio', hex: '#F5F499', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-amarelo.jpg' },
      amarronzado: { label: 'Amarronzado', hex: '#B8860B', url: null },
      esverdeado: { label: 'Esverdeado', hex: '#CCCC00', url: null },
      mostarda: { label: 'Mostarda', hex: '#DAA520', url: null },
      ocre: { label: 'Ocre', hex: '#CC7722', url: null },
    },
  },
  azul: {
    label: 'Azul',
    hex: '#77bae8',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#00008B', url: null },
      claro: { label: 'Claro', hex: '#87CEFA', url: null },
      medio: { label: 'Médio', hex: '#00BFFF', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-azul.jpg' },
      marinho: { label: 'Marinho', hex: '#000059', url: null },
      turquesa: { label: 'Turquesa', hex: '#40E0D0', url: null },
      klein: { label: 'Klein', hex: '#002FA7', url: null },
      bic: { label: 'Bic', hex: '#194E92', url: null },
      indigo: { label: 'Indigo', hex: '#09409B', url: null },
      denim: { label: 'Denim', hex: '#6D9EAD', url: null },
    },
  },
  laranja: {
    label: 'Laranja',
    hex: '#ffa200',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#FF9900', url: null },
      claro: { label: 'Claro', hex: '#FFCC66', url: null },
      medio: { label: 'Médio', hex: '#FF9933', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-laranja.jpg' },
      terracota: { label: 'Terracota', hex: '#D74426', url: null },
    },
  },
  marrom: {
    label: 'Marrom',
    hex: '#8c7f71',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#783C00', url: null },
      claro: { label: 'Claro', hex: '#FFCC66', url: null },
      medio: { label: 'Médio', hex: '#BC5E00', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-marrom.jpg' },
      caramelo: { label: 'Caramelo', hex: '#8B5742', url: null },
    },
  },
  roxo: {
    label: 'Roxo',
    hex: '#58167d',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#993399', url: null },
      claro: { label: 'Claro', hex: '#8A2BE2', url: null },
      medio: { label: 'Médio', hex: '#9370DB', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-roxo.jpg' },
      purpura: { label: 'Púrpura', hex: '#800080', url: null },
    },
  },
  rosa: {
    label: 'Rosa',
    hex: '#dca3c6',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#FFC0CB', url: null },
      claro: { label: 'Claro', hex: '#FFEAEC', url: null },
      medio: { label: 'Médio', hex: '#DB7093', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-rosa.jpg' },
      rose: { label: 'Rosê', hex: '#B76E79', url: null },
      fuchsia: { label: 'Fúschia', hex: '#FF00FF', url: null },
      goiaba: { label: 'Goiaba', hex: '#CD5C5C', url: null },
      magenta: { label: 'Magenta', hex: '#FF00FF', url: null },
      pessego: { label: 'Pêssego', hex: '#F29186', url: null },
      quartz: { label: 'Quartz', hex: '#AC7175', url: null },
      choque: { label: 'Choque', hex: '#F744D0', url: null },
    },
  },
  verde: {
    label: 'Verde',
    hex: '#a3bd31',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#006400', url: null },
      claro: { label: 'Claro', hex: '#90EE90', url: null },
      medio: { label: 'Médio', hex: '#008000', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-verde.jpg' },
      musgo: { label: 'Musgo', hex: '#556B2F', url: null },
      militar: { label: 'Militar', hex: '#6B8E23', url: null },
      esmeralda: { label: 'Esmeralda', hex: '#00A86B', url: null },
      oliva: { label: 'Oliva', hex: '#90EE90', url: null },
    },
  },
  vermelho: {
    label: 'Vermelho',
    hex: '#bf0411',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#CC0000', url: null },
      claro: { label: 'Claro', hex: '#FF4040', url: null },
      medio: { label: 'Médio', hex: '#FF0000', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-vermelho.jpg' },
      vinho: { label: 'Vinho', hex: '#820000', url: null },
      burgundy: { label: 'Burgundy', hex: '#810016', url: null },
      cereja: { label: 'Cereja', hex: '#DE3163', url: null },
      bordo: { label: 'Bordô', hex: '#800000', url: null },
    },
  },
  nude: {
    label: 'Nude',
    hex: '#E4D9B9',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#D9C8AA', url: null },
      claro: { label: 'Claro', hex: '#F7F3ED', url: null },
      medio: { label: 'Médio', hex: '#FAF0E6', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-nude.jpg' },
    },
  },
  offWhite: {
    label: 'Off-White',
    hex: null,
    url: 'off-white.jpeg',
    tones: {
      escuro: { label: 'Escuro', hex: '#F5F5F5', url: null },
      claro: { label: 'Claro', hex: '#FFFFFF', url: null },
      medio: { label: 'Médio', hex: '#F5F4EF', url: null },
    },
  },
  prata: {
    label: 'Prata',
    hex: '#C1C2C4',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#C3C3C3', url: null },
      claro: { label: 'Claro', hex: '#F4F4F4', url: null },
      medio: { label: 'Médio', hex: '#C0C0C0', url: null },
    },
  },
  bege: {
    label: 'Bege',
    hex: '#E0D8CD',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#FFEFD5', url: null },
      claro: { label: 'Claro', hex: '#FAEBD7', url: null },
      medio: { label: 'Médio', hex: '#F5F5DC', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-bege.jpg' },
      caqui: { label: 'Caqui', hex: '#F0E68C', url: null },
      creme: { label: 'Creme', hex: '#FAEBD7', url: null },
    },
  },
  dourado: {
    label: 'Dourado',
    hex: '#B5973C',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#B8860B', url: null },
      claro: { label: 'Claro', hex: '#E6BC53', url: null },
      medio: { label: 'Médio', hex: '#E6BC53', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-dourado.jpg' },
      ouroVelho: { label: 'Ouro Velho', hex: '#625300', url: null },
      bronze: { label: 'Bronze', hex: '#CD7F32', url: null },
      cobre: { label: 'Cobre', hex: '#B87333', url: null },
    },
  },
  coral: {
    label: 'Coral',
    hex: '#DF4C3B',
    url: null,
    tones: {
      escuro: { label: 'Escuro', hex: '#FF4D0D', url: null },
      claro: { label: 'Claro', hex: '#FFBCA4', url: null },
      medio: { label: 'Médio', hex: '#FF7F50', url: null },
      mesclado: { label: 'Mesclado', hex: null, url: 'm-coral.jpg' },
      salmao: { label: 'Salmão', hex: '#FA7F72', url: null },
    },
  },
  furtaCor: {
    label: 'Furta-Cor',
    hex: null,
    url: 'furta-cor.jpeg',
    tones: {},
  },
  colorido: {
    label: 'Colorido',
    hex: null,
    url: 'colorido.png',
    tones: {},
  },
};
