import React from 'react';
import { Modal, notification } from 'antd';
import { ProdCard } from './styles';

export const addProductToBlock = async (
  blockId,
  createBlockProduct,
  tableData,
  setOrderedTableData,
  products
) => {
  const starterPosition = tableData.length;
  const newProdBlock = [...tableData];

  const promises = products.map(async (item, index) =>
    createBlockProduct({
      variables: {
        input: {
          blockId,
          productId: item.id,
          weightPosition: starterPosition + index,
        },
      },
    })
      .then(res => {
        newProdBlock.push(res.data.homeBlockProductsCreateBlockProduct);
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao adicionar produto ao bloco`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      })
  );

  await Promise.all(promises)
    .then(() => {
      const prodSize = products.length;
      const notificationMessage =
        prodSize > 1
          ? `${prodSize} produtos foram adicionados com sucesso!`
          : `Produto adicionado com sucesso!`;

      notification.success({
        message: `Tabela de produtos atualizada`,
        description: <div>{notificationMessage}</div>,
        placement: 'topRight',
      });
      setOrderedTableData(newProdBlock);
    })
    .catch(errorUpt => {
      notification.error({
        message: `Erro ao adicionar produto ao bloco`,
        description: errorUpt.errors
          ? errorUpt.errors.map(obj => obj.message).join(';')
          : errorUpt.message,
        placement: 'topRight',
      });
    });
};

function deleteItem(id, deleteBlockProduct, tableData, setOrderedTableData) {
  deleteBlockProduct({
    variables: {
      input: { id },
    },
  })
    .then(() => {
      const newData = tableData.filter(obj => {
        if (obj.id !== id) {
          return true;
        }
        notification.success({
          message: `Produto excluído`,
          description: <div>Produto excluído com sucesso!</div>,
          placement: 'topRight',
        });
        return false;
      });

      setOrderedTableData(newData);
    })
    .catch(errorDelete => {
      notification.error({
        message: `Erro ao excluir o produto`,
        description: errorDelete.errors
          ? errorDelete.errors.map(obj => obj.message).join(';')
          : errorDelete.message,
        placement: 'topRight',
      });
    });
}

export function handleDelete(
  id,
  deleteSeoContent,
  tableData,
  setOrderedTableData
) {
  const { confirm } = Modal;
  const prodBlock = tableData.find(prod => prod.id === id);
  const { name } = prodBlock.product;
  const { imageUrl } = prodBlock.product.images[0];

  confirm({
    title: 'Você tem certeza que deseja remover este item?',
    content: (
      <ProdCard>
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
        <span>{name}</span>
      </ProdCard>
    ),
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteItem(id, deleteSeoContent, tableData, setOrderedTableData);
    },
    onCancel() {
      // Cancel
    },
  });
}
