import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Typography,
  Row,
  Col,
  Divider,
  DatePicker,
  notification,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import { disableSubmit, changeTitle, onSubmitBlock } from './actions';

const { RangePicker } = DatePicker;

function FormBlock(props) {
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const { block, isEdit, createNewBlockBanner, setBlock } = props;
  const [form] = Form.useForm();
  const [dates, setDates] = useState({
    startDate: block?.startDate,
    endDate: block?.endDate,
  });
  const titleError =
    form.isFieldTouched('title') && form.getFieldError('title');

  const renderTitleForm = (
    <>
      <Row gutter={16} type="flex" justify="space-between" align="middle">
        <Col>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Informações
          </Title>
        </Col>
      </Row>
      <Divider />
    </>
  );

  const dateFormatValue = 'YYYY/MM/DD';

  const renderSubmitDates = (
    <Form.Item
      type="flex"
      initialValue={block.title}
      rules={[
        {
          required: true,
          message: 'Campo obrigatório. Selecione uma data',
        },
      ]}
      style={{ marginBottom: '24px' }}
    >
      {isEdit ? (
        <RangePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          ranges={{
            Dia: [moment(), moment()],
            Semana: [moment().day(0), moment().day(6)],
            Mês: [moment().startOf('month'), moment().endOf('month')],
          }}
          onCalendarChange={value => {
            const formsDate = {
              startDate: value[0]?.format(),
              endDate: value[1]?.format(),
            };
            setDates({
              startDate: formsDate.startDate,
              endDate: formsDate.endDate,
            });
            setBlock(prevState => ({
              ...prevState,
              startDate: formsDate.startDate,
              endDate: formsDate.endDate,
            }));
          }}
          defaultValue={[
            moment(block.startDate, dateFormatValue),
            moment(block.endDate, dateFormatValue),
          ]}
        />
      ) : (
        <RangePicker
          style={{ width: '100%' }}
          format="DD/MM/YYYY"
          ranges={{
            Dia: [moment(), moment()],
            Semana: [moment().day(0), moment().day(6)],
            Mês: [moment().startOf('month'), moment().endOf('month')],
          }}
          onCalendarChange={value => {
            const formsDate = {
              startDate: value[0]?.format(),
              endDate: value[1]?.format(),
            };
            setDates(formsDate);
          }}
        />
      )}
    </Form.Item>
  );

  const renderSubmitButton = (
    <Row gutter={16} type="flex" align="top" justify="left">
      <Col span={6}>
        <Button
          style={{ marginTop: '0px' }}
          type="primary"
          htmlType="submit"
          icon={<PlusOutlined />}
          data-testid="homeCreateBlockItemBtn"
          disabled={disableSubmit(form, block.title)}
          loading={loading}
        >
          {loading ? 'Criando...' : 'Criar Bloco'}
        </Button>
      </Col>
    </Row>
  );

  const onFinish = values => {
    const newValue = {
      ...values,
      startDate: dates.startDate,
      endDate: dates.endDate,
    };

    if (!dates?.startDate || !dates.endDate) {
      notification.error({
        message: `Erro na criação do bloco`,
        description:
          "Os campos 'Data inicial' e 'Data final' devem ser preenchidos",
        placement: 'topRight',
      });
      return;
    }

    onSubmitBlock(newValue, form, { setLoading, createNewBlockBanner });
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar bloco de banner',
      values,
      errorFields,
      outOfDate
    );
  };

  const renderForm = (
    <Form
      form={form}
      name="form-block-banner"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ marginBottom: '40px' }}
    >
      <Row gutter={16}>
        <Col span={24}>{renderTitleForm}</Col>
        <Col span={24}>
          <Form.Item
            validateStatus={titleError ? 'error' : ''}
            help={titleError || ''}
            label="Título"
            name="title"
            initialValue={block.title}
            rules={[
              {
                required: true,
                message: 'Campo obrigatório. Digite o título do bloco',
              },
            ]}
            style={{ marginBottom: '24px' }}
          >
            <Input
              data-testid="homeAddBannerBlockTitle"
              onChange={e => {
                changeTitle(e, setBlock);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="left">
        <Col span={8} xs={22} lg={10}>
          {renderSubmitDates}
        </Col>
        <Col span={6}>{isEdit ? false : renderSubmitButton}</Col>
      </Row>
    </Form>
  );

  return renderForm;
}

FormBlock.propTypes = {
  title: PropTypes.string.isRequired,
  createNewBlockBanner: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setBlock: PropTypes.func.isRequired,
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    weightPosition: PropTypes.number.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default FormBlock;
