// BagOrder Types
export const BAG_ORDER_TYPE_PURCHASE = 'purchase';
export const BAG_ORDER_TYPE_REPROVED = 'reproved';
export const BAG_ORDER_TYPE_RETURN = 'return';

export const BAG_ORDER_TYPES = [
  BAG_ORDER_TYPE_PURCHASE,
  BAG_ORDER_TYPE_REPROVED,
  BAG_ORDER_TYPE_RETURN,
];

// BagOrdert status
export const BAG_ORDER_STATUS_PENDING = 'pending';
export const BAG_ORDER_STATUS_CANCELED = 'canceled';
export const BAG_ORDER_STATUS_COMPLETED = 'completed';
export const BAG_ORDER_STATUSES = [
  BAG_ORDER_STATUS_CANCELED,
  BAG_ORDER_STATUS_COMPLETED,
  BAG_ORDER_STATUS_PENDING,
];

//   Payment Status
export const BAG_ORDER_PAYMENT_TYPE_CREATED = 'created';
export const BAG_ORDER_PAYMENT_TYPE_WAITING = 'waiting';
export const BAG_ORDER_PAYMENT_TYPE_IN_ANALYSIS = 'in_analysis';
export const BAG_ORDER_PAYMENT_TYPE_CANCELED = 'canceled';
export const BAG_ORDER_PAYMENT_TYPE_REFUNDED = 'refunded';
export const BAG_ORDER_PAYMENT_TYPE_REVERSED = 'reversed';
export const BAG_ORDER_PAYMENT_TYPE_PAID = 'paid';
export const BAG_ORDER_PAYMENT_TYPE_EXPIRED = 'expired';

export const BAG_ORDER_PAYMENT_TYPES = [
  BAG_ORDER_PAYMENT_TYPE_CREATED,
  BAG_ORDER_PAYMENT_TYPE_WAITING,
  BAG_ORDER_PAYMENT_TYPE_IN_ANALYSIS,
  BAG_ORDER_PAYMENT_TYPE_CANCELED,
  BAG_ORDER_PAYMENT_TYPE_REFUNDED,
  BAG_ORDER_PAYMENT_TYPE_REVERSED,
  BAG_ORDER_PAYMENT_TYPE_PAID,
  BAG_ORDER_PAYMENT_TYPE_EXPIRED,
];

export const SELLER_CENTER_BAG_TRIAGEM_APROVADA = 'FULLY_APPROVED';
export const SELLER_CENTER_BAG_TRIAGEM_REPROVADA = 'FULLY_DISAPPROVED';
export const SELLER_CENTER_BAG_TRIAGEM_PARCIALMENTE_APROVADA =
  'PARTIALLY_APPROVED';

// Indicador de origem da rota da listagem de pedidos da Sacola do Bem
export const ORDER_ORIGIN_BAG_ORDER_LISTING = 'bag_order_listing';
export const ORDER_ORIGIN_CLIENT_BAG_LISTING = 'client_bag_listing';
