import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { generateColumns } from './columns';

function TableBrand({
  brand,
  deleteBrand,
  newBrandId,
  setNewBrandId,
  table,
  setTable,
}) {
  const columns = generateColumns({
    brand,
    newBrandId,
    setNewBrandId,
    setTable,
    deleteBrand,
  });

  function onChangePage(current, size) {
    const skip = (current - 1) * size;

    setTable({
      ...table,
      pageSize: size,
      currentPage: current,
      loading: true,
      skip,
    });
  }

  function onShowSizeChange(oldPage, newPageSize) {
    const newCurrentPage = Math.ceil(
      (table.currentPage * table.pageSize - table.pageSize + 1) / newPageSize
    );
    const skip = (newCurrentPage - 1) * newPageSize;

    setTable({
      ...table,
      pageSize: newPageSize,
      currentPage: newCurrentPage,
      loading: true,
      skip,
    });
  }
  return (
    <Table
      data-testid="table"
      dataSource={brand}
      columns={columns}
      loading={table.loading}
      rowKey={record => record.id}
      title={() => `Total encontrado - ${table.totalCount}`}
      bordered
      scroll={{
        x: 'max-content',
      }}
      pagination={{
        current: table.currentPage,
        total: table.totalCount,
        showSizeChanger: true,
        onShowSizeChange,
        onChange: onChangePage,
        // Tamanho padrão 10, mostra outros tamanho dependendo do total de itens
        pageSizeOptions: ['10'].concat(
          ['20', '30', '40', '50'].filter(size => {
            return size <= table.totalCount;
          })
        ),
        position: 'both',
        locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
      }}
    />
  );
}

TableBrand.propTypes = {
  brand: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteBrand: PropTypes.func.isRequired,
  newBrandId: PropTypes.string.isRequired,
  setNewBrandId: PropTypes.func.isRequired,
  table: PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    skip: PropTypes.number,
    totalCount: PropTypes.number,
    searchText: PropTypes.string,
    searchDate: PropTypes.string,
    searchStatus: PropTypes.string,
    loading: PropTypes.bool,
    refetch: PropTypes.bool,
  }).isRequired,
  setTable: PropTypes.func.isRequired,
};

export default TableBrand;
