import React, { useEffect } from 'react';
import { Alert, Card, Table } from 'antd';
import moment from 'moment';

import { emailRegex } from '~/constants/GeneralForms';
import useSearchOrder from '~/hooks/orders/useSearchOrder';
import {
  ORDER_DIRECTION_DESC,
  ORDER_LIST_REPURCHASE,
  ORDER_LIST_WITHOUT_REPURCHASE,
} from '~/constants/Order';
import { getErrorMessage } from '~/util/Functions';
import usePaginationController from '~/hooks/utils/usePaginationController';
import { DATE_FORMAT_SIMPLE } from '~/constants/Global';
import { generateColumns } from './columns';
import Filter from './Filter';

function OrderList() {
  const {
    current: pagination,
    onChange: onPaginationChange,
    reset: paginationReset,
    filters: paginationFilters,
  } = usePaginationController({
    initialPageSize: 10,
    initialPage: 1,
  });

  const [searchOrder, { data, loading, totalCount, error }] = useSearchOrder();
  const columns = generateColumns();

  const getRepurchaseFilterValue = value => {
    switch (value) {
      case ORDER_LIST_REPURCHASE:
        return true;
      case ORDER_LIST_WITHOUT_REPURCHASE:
        return false;
      default:
        return undefined;
    }
  };

  const fetchOrders = newVariables => {
    const startDate = newVariables?.filters?.startDate;
    const endDate = newVariables?.filters?.endDate;
    const searchText = newVariables?.filters?.searchText;
    const kind = newVariables?.filters?.kind;
    const status = newVariables?.filters?.status;
    const currentPaymentStatus = newVariables?.filters?.currentPaymentStatus;
    const currentShipmentDispatchStatus =
      newVariables?.filters?.currentShipmentDispatchStatus;
    const currentShipmentStatus = newVariables?.filters?.currentShipmentStatus;
    const repurchase = getRepurchaseFilterValue(
      newVariables?.filters?.repurchase
    );

    let orderId;
    let userEmail;

    if (searchText) {
      if (searchText.match(emailRegex)) {
        userEmail = searchText;
      } else {
        orderId = searchText;
      }
    }

    searchOrder({
      endDate: endDate || undefined,
      initDate: startDate || undefined,
      id: Number(orderId) || undefined,
      orderDirection: ORDER_DIRECTION_DESC,
      skip: pagination.skip,
      size: pagination.pageSize,
      userEmail,
      kind,
      status,
      currentPaymentStatus,
      currentShipmentDispatchStatus,
      currentShipmentStatus,
      repurchase,
    });
  };

  useEffect(() => {
    const newFilters = {
      filters: {
        startDate: paginationFilters?.startDate,
        endDate: paginationFilters?.endDate,
        searchText: paginationFilters?.searchText,
        kind: paginationFilters?.kind,
        status: paginationFilters?.status,
        currentShipmentStatus: paginationFilters?.currentShipmentStatus,
        currentPaymentStatus: paginationFilters?.currentPaymentStatus,
        currentShipmentDispatchStatus:
          paginationFilters?.currentShipmentDispatchStatus,
        repurchase: paginationFilters?.repurchase,
      },
    };

    fetchOrders({ ...newFilters });
  }, [pagination?.skip, pagination?.pageSize]);

  const onChangePage = (newPage, pageSize) => {
    onPaginationChange({
      page: newPage,
      pageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const onShowSizeChange = (oldPage, newPageSize) => {
    onPaginationChange({
      pageSize: newPageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const handleResetFilters = () => {
    const newFilters = {
      filters: {
        startDate: undefined,
        endDate: undefined,
        searchText: undefined,
        status: undefined,
        currentPaymentStatus: undefined,
        currentShipmentStatus: undefined,
        kind: undefined,
        currentShipmentDispatchStatus: undefined,
        repurchase: undefined,
      },
    };

    // Atualizo os filtros na URL com o 'paginationReset'. Não atualizo a paginação aqui
    // pois a função 'paginationReset' já faz isso internamente.
    paginationReset(newFilters);
    // Como a chamada da requisição acontece quase instantaneamente, é necessário
    // ele não consegue ler a URL já atualizada, então se faz necessário enviar as
    // variaveis atualizadas via argumento da função.
    fetchOrders({ page: 1, pageSize: 10, ...newFilters });
  };

  const handleSubmit = () => {
    const startDate = paginationFilters?.startDate
      ? moment(paginationFilters?.startDate).format(DATE_FORMAT_SIMPLE)
      : undefined;
    const endDate = paginationFilters?.endDate
      ? moment(paginationFilters?.endDate).format(DATE_FORMAT_SIMPLE)
      : undefined;

    const newFilters = {
      filters: {
        ...paginationFilters,
        startDate,
        endDate,
      },
    };

    // Atualizo os filtros na URL com o 'paginationReset'. Não atualizo a paginação aqui
    // pois a função 'paginationReset' já faz isso internamente.
    paginationReset(newFilters);
    // Como a chamada da requisição acontece quase instantaneamente, é necessário
    // ele não consegue ler a URL já atualizada, então se faz necessário enviar as
    // variaveis atualizadas via argumento da função.
    fetchOrders({ page: 1, pageSize: 10, ...newFilters });
  };

  return (
    <Card data-testid="orderCard" title="PEDIDOS DE PRODUTO">
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter
          onChange={onPaginationChange}
          onSubmit={handleSubmit}
          onReset={handleResetFilters}
          currentFilters={paginationFilters || undefined}
        />
      </Card.Grid>

      <Card.Grid style={{ width: '100%' }}>
        {error ? (
          <Alert message={getErrorMessage(error)} type="error" showIcon />
        ) : (
          <Table
            data-testid="table"
            dataSource={data}
            columns={columns}
            loading={loading}
            rowKey={record => record?.id}
            title={() => `Total encontrado - ${totalCount}`}
            bordered
            scroll={{
              x: 'max-content',
            }}
            pagination={{
              current: pagination?.page,
              total: totalCount,
              showSizeChanger: true,
              onShowSizeChange,
              onChange: onChangePage,
              // Tamanho padrão 10, mostra outros tamanho dependendo do total de itens
              pageSizeOptions: ['10'].concat(
                ['20', '30', '40', '50'].filter(size => {
                  return size <= totalCount;
                })
              ),
              position: 'both',
              locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
            }}
          />
        )}
      </Card.Grid>
    </Card>
  );
}

export default OrderList;
