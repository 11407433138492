import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'antd';
import ColorsModal from '../Category/ColorsModal';
import Button from '~/components/Button';

import { addColorRule } from '../../actions';

function Color({ dispatch, tables }) {
  const [colors, setColors] = useState([]);

  const handleAddTable = () => {
    addColorRule({
      tables,
      dispatch,
      newRows: colors.map(colorName => {
        return {
          key: `color-${colorName}`,
          color: colorName,
        };
      }),
      tableKey: `promotionColor`,
      title: 'Preço',
    });
    setColors([]);
  };

  return (
    <>
      <Col span={24}>
        <Form.Item>
          <ColorsModal newColors={colors} setNewColors={setColors} />
        </Form.Item>
      </Col>
      <Form.Item>
        <Button
          data-testid="addPriceRuleBtn"
          type="primary"
          onClick={handleAddTable}
        >
          Adicionar
        </Button>
      </Form.Item>
    </>
  );
}

Color.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Color;
