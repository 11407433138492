import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PlusOutlined, SyncOutlined, DragOutlined } from '@ant-design/icons';
import { Card, Tooltip, Table } from 'antd';

import Button from '~/components/Button';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import DragSortTable from '~/components/DragSortTable';

import { updateTable, handleSavePosition, handleDelete } from './actions';
import { generateColumns } from './columns';

import { Container } from './styles';

import {
  QUERY_FAQ_CATEGORIES,
  MUTATION_DELETE_FAQ_CATEGORY,
  MUTATION_UPDATE_FAQ_CATEGORY,
} from '../../../../apollo/content/faq';
import LoadingBar from '../../../../components/LoadingBar';

const CategoryList = () => {
  const [totalCount, setTotalCount] = useState(0);
  const { tableData, setTableData } = useDragSortTableContext();

  const { loading, error, refetch } = useQuery(QUERY_FAQ_CATEGORIES, {
    context: {
      newBackend: true,
    },
    onCompleted: ({
      faqSearchFaqSubjects: { nodes: faqCategories, totalCount: total },
    }) => {
      faqCategories.sort((a, b) =>
        a.weightPosition > b.weightPosition ? 1 : -1
      );
      setTableData(faqCategories);
      setTotalCount(total);
    },
  });

  const [deleteFaqCategory] = useMutation(MUTATION_DELETE_FAQ_CATEGORY, {
    context: {
      newBackend: true,
    },
    update(cache, { data: { faqDeleteFaqSubject: removeSubject } }) {
      try {
        const { faqSearchFaqSubjects } = cache.readQuery({
          query: QUERY_FAQ_CATEGORIES,
        });

        faqSearchFaqSubjects.nodes = faqSearchFaqSubjects.nodes.filter(obj => {
          return obj.id !== removeSubject.id;
        });

        cache.writeQuery({
          query: QUERY_FAQ_CATEGORIES,
          data: {
            faqSearchFaqSubjects,
          },
        });
      } catch (err) {
        // Cache doesn't exists, do nothing
      }
    },
  });
  const [updateFaqCategory] = useMutation(MUTATION_UPDATE_FAQ_CATEGORY, {
    context: {
      newBackend: true,
    },
  });

  if (loading) return <LoadingBar />;
  if (error) return `Error! ${error.message}`;

  const columns = generateColumns(id => {
    handleDelete(id, { deleteFaqCategory, tableData, setTableData });
  });

  const questionColumns = [
    {
      title: 'Pergunta',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'FAQ Sacola do Bem',
      dataIndex: 'bagQuestion',
      key: 'bagQuestion',
      render: (text, record) => (
        <div style={{ minWidth: '160px' }}>
          {record.bagQuestion ? 'Sim' : 'Não'}
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Card
        data-testid="faqCategoryCard"
        title="FAQ - Categorias"
        extra={
          <>
            <Tooltip placement="bottom" title="Atualizar tabela">
              <Button
                data-testid="updateBtn"
                type="link"
                icon={<SyncOutlined />}
                size="default"
                onClick={() => {
                  updateTable(refetch, setTableData);
                }}
                style={{
                  margin: 0,
                }}
              />
            </Tooltip>
            <Link to="/manage/content/faq/category/create">
              <Tooltip placement="bottom" title="Adicionar nova categoria">
                <Button
                  data-testid="addBtn"
                  type="primary"
                  icon={<PlusOutlined />}
                  size="default"
                  style={{
                    margin: 0,
                    marginLeft: '5px',
                  }}
                >
                  Adicionar
                </Button>
              </Tooltip>
            </Link>
            <Tooltip placement="bottom" title="Salvar ordem das categorias">
              <Button
                data-testid="saveBtn"
                icon={<DragOutlined />}
                size="default"
                type="success"
                onClick={() => {
                  handleSavePosition({ tableData, updateFaqCategory });
                }}
                style={{
                  margin: 0,
                  marginLeft: '5px',
                }}
              >
                Salvar
              </Button>
            </Tooltip>
          </>
        }
      >
        <DragSortTable
          data-testid="table"
          data={tableData}
          columns={columns}
          rowKey={record => record.id}
          bordered
          title={() => `Total encontrado - ${totalCount}`}
          scroll={{
            x: 'max-content',
          }}
          pagination={false}
          expandable={{
            expandedRowRender: record => (
              <Table
                rowKey={rcd => rcd.id}
                dataSource={record.faqQuestions}
                columns={questionColumns}
                pagination={false}
                bordered={false}
              />
            ),
            rowExpandable: record =>
              record.faqQuestions.length !== 0 ? 'no-expand' : null,
          }}
        />
      </Card>
    </Container>
  );
};

export default CategoryList;
