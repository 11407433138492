import React from 'react';
import {
  DeleteOutlined,
  SkinOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Avatar, Tooltip, Button } from 'antd';

import styled from 'styled-components';
import DragHandle from '~/components/DragSortTable/DragHandle';
import { getImageUrl } from '~/util/Functions';
import Image from '~/components/Image';

const avatarImg = text =>
  text !== '' ? (
    <Image shape="square" size="large" src={getImageUrl(text)} />
  ) : (
    <Avatar shape="square" size="large" icon={<SkinOutlined />} />
  );

const visibilityToggle = (
  item,
  {
    onChangePublished,
    checkDelete,
    navigationDeleteBlockBanner,
    navigationUpdateBlockBanner,
    block,
    setBlock,
    updateBlockBanner,
  }
) => (
  <>
    <Tooltip
      placement="bottom"
      title={item.desktopHide ? 'Mostrar Desktop' : 'Esconder Desktop'}
    >
      <Button
        data-testid="homeToggleBlockDesktop"
        type="link"
        icon={item.desktopHide ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={() =>
          onChangePublished(item.id, 'desktopHide', {
            block,
            setBlock,
            navigationUpdateBlockBanner,
          })
        }
        size="small"
        style={{ margin: 0 }}
      >
        Desktop
      </Button>
    </Tooltip>
    <Tooltip
      placement="bottom"
      title={item.mobileHide ? 'Mostrar Mobile' : 'Esconder Mobile'}
    >
      <Button
        data-testid="homeToggleBlockMobile"
        type="link"
        icon={item.mobileHide ? <EyeInvisibleOutlined /> : <EyeOutlined />}
        onClick={() =>
          onChangePublished(item.id, 'mobileHide', {
            block,
            setBlock,
            navigationUpdateBlockBanner,
          })
        }
        size="small"
        style={{ margin: 0 }}
      >
        Mobile
      </Button>
    </Tooltip>
    <Tooltip placement="bottom" title="Editar">
      <Button
        role="button"
        type="link"
        icon={<EditOutlined />}
        onClick={() => updateBlockBanner(item.id)}
        size="small"
        style={{ marginBottom: 0, color: '#0095ff' }}
      />
    </Tooltip>

    <Tooltip placement="bottom" title="Excluir">
      <Button
        data-testid="homeRemoveProductItemBtn"
        type="link"
        icon={<DeleteOutlined />}
        onClick={() =>
          checkDelete(item.id, {
            navigationDeleteBlockBanner,
            block,
            setBlock,
          })
        }
        size="small"
        style={{ marginBottom: 0, color: 'red' }}
      />
    </Tooltip>
  </>
);

export const generateColumns = ({
  block,
  setBlock,
  navigationDeleteBlockBanner,
  navigationUpdateBlockBanner,
  onChangePublished,
  checkDelete,
  updateBlockBanner,
}) => {
  return [
    {
      title: '#',
      dataIndex: 'drag',
      key: 'drag',
      width: 50,
      fixed: 'left',
      render: () => (block.blockBanners.length ? <DragHandle /> : false),
    },
    {
      title: 'Banner',
      dataIndex: 'banner',
      key: 'banner',
      width: 100,
      render: text => avatarImg(text),
    },
    {
      title: 'Alt',
      dataIndex: 'alt',
      key: 'alt',
      width: 300,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      width: 350,
    },
    {
      title: 'Tamanho Desktop',
      dataIndex: 'desktopSize',
      key: 'desktopSize',
      width: 100,
    },
    {
      title: 'Tamanho Mobile',
      dataIndex: 'mobileSize',
      key: 'mobileSize',
      width: 100,
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (text, item) =>
        visibilityToggle(item, {
          onChangePublished,
          checkDelete,
          navigationDeleteBlockBanner,
          navigationUpdateBlockBanner,
          block,
          setBlock,
          updateBlockBanner,
        }),
    },
  ];
};

const HandleButton = styled.button`
  background: none;
  margin: 0;
  padding: 0;
  color: #bfbfbf;
  border: none;
  margin: 3px 0;
  display: flex;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #9b9b9b;
  }
`;

const customOrderHandle = (weightPosition, handleChangeSort) => (
  <>
    <HandleButton
      type="button"
      onClick={() => handleChangeSort('up', weightPosition)}
    >
      <CaretUpOutlined />
    </HandleButton>
    <HandleButton
      type="button"
      onClick={() => handleChangeSort('down', weightPosition)}
    >
      <CaretDownOutlined />
    </HandleButton>
  </>
);

export const generateAttrColumns = (
  block,
  setBlock,
  handleChangeSort,
  updateBlockAttribute,
  checkDeleteAttr,
  deleteAttribute
) => {
  return [
    {
      title: '#',
      dataIndex: 'weightPosition',
      key: 'weightPosition',
      width: 50,
      fixed: 'left',
      render: weightPosition =>
        block.blockAttributesBar.length
          ? customOrderHandle(weightPosition, handleChangeSort)
          : false,
    },
    {
      title: 'Texto',
      dataIndex: 'text',
      key: 'text',
      width: 400,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      width: 400,
      render: url => url || '-',
    },
    {
      title: 'Ações',
      key: 'action',
      dataIndex: 'id',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: id => {
        return (
          <>
            <Tooltip placement="bottom" title="Editar">
              <Button
                type="link"
                icon={<EditOutlined />}
                size="small"
                onClick={() => updateBlockAttribute(id)}
                style={{ margin: 0 }}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Excluir">
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => checkDeleteAttr(id, { deleteAttribute })}
                size="small"
                style={{ margin: 0, color: 'red' }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
};

// export const generateAttrColumns = () => {
//   return [
//     {
//       title: 'Texto',
//       dataIndex: 'text',
//       key: 'text',
//       width: 400,
//     },
//   ];
// };
