import gql from 'graphql-tag';

export const AZURE_LOGIN = gql`
  mutation login($input: AzureLoginInput!) {
    userAuthAzureLogin(input: $input) {
      id
      groups
      token
      profilePic
    }
  }
`;
