import React from 'react';
import { Tooltip, Modal, Tag /* DatePicker, Radio, Row, Col */ } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Button from '~/components/Button';
import DragHandle from '~/components/DragSortTable/DragHandle';

import { homeBlocksKind } from '~/util/HomeBlocks';
import { handleDelete, handlePublished } from './actions';

const { confirm } = Modal;

const modalDelete = ({
  block,
  blocksList,
  navigationDeleteBlock,
  setTableData,
}) => {
  confirm({
    title: 'Você tem certeza que deseja excluir este bloco?',
    content: 'Ao excluir este bloco, ele será apagado permanentemente.',
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      handleDelete({
        block,
        setTableData,
        blocksList,
        navigationDeleteBlock,
      });
    },
    onCancel() {},
  });
};

// TODO: DEFINIDO MAIS NAO UTILIZADO, VERIFICAR
// const modalSchedule = () => {
//   confirm({
//     title: `Agendar publicação/despublicação do bloco`,
//     content: (
//       <Row gutter={[16, 16]} type="flex" align="middle" justify="start">
//         <Col span={24}>
//           <DatePicker showTime placeholder="Selecione a data e hora" />
//         </Col>
//         <Col span={24}>
//           <Radio.Group>
//             <Radio value={1}>Publicar</Radio>
//             <Radio value={2}>Despublicar</Radio>
//           </Radio.Group>
//         </Col>
//       </Row>
//     ),
//     okText: 'Agendar',
//     okType: 'primary',
//     cancelText: 'Cancelar',
//     onOk() {},
//     onCancel() {},
//   });
// };

const modalpublished = (
  block,
  { blocksList, updateBlockList, setTableData }
) => {
  const action = block.published ? 'Desativar' : 'Ativar';

  confirm({
    title: `Você tem certeza que deseja ${action} a publicação deste Bloco?`,
    okText: action,
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      handlePublished(block.id, { blocksList, updateBlockList, setTableData });
    },
    onCancel() {},
  });
};

export const generateColumns = ({
  tableData,
  updateBlockList,
  setTableData,
  navigationDeleteBlock,
}) => {
  return [
    {
      title: 'Ordem',
      dataIndex: 'drag',
      key: 'drag',
      fixed: 'left',
      render: () => <DragHandle />,
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tipo',
      dataIndex: 'kind',
      key: 'kind',
      render: block => homeBlocksKind(block),
    },
    {
      title: 'Visibilidade',
      dataIndex: 'published',
      key: 'published',
      render: published => (
        <Tag style={{ margin: 0 }} color={published ? 'green' : 'red'}>
          {published ? 'Publicado' : 'Despublicado'}
        </Tag>
      ),
    },
    {
      title: 'Data de publicação',
      dataIndex: 'startDate',
      key: 'startDate',
      render: startDate => moment(startDate).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixex: 'right',
      render: block => {
        return (
          <>
            <Tooltip placement="bottom" title="Editar">
              <Link
                to={`/manage/marketing/home/${block.kind}/edit/${block.id}`}
              >
                <Button
                  data-testid="homeEditBlock"
                  type="link"
                  icon={<EditOutlined />}
                  size="small"
                  style={{ margin: 0 }}
                />
              </Link>
            </Tooltip>
            <Tooltip placement="bottom" title="Excluir">
              <Button
                data-testid="homehandleDelete"
                type="link"
                icon={<DeleteOutlined />}
                onClick={() =>
                  modalDelete({
                    block,
                    setTableData,
                    blocksList: tableData,
                    navigationDeleteBlock,
                  })
                }
                size="small"
                style={{ margin: 0, color: 'red' }}
              />
            </Tooltip>
            <Tooltip
              placement="bottom"
              title={block.published ? 'Despublicar' : 'Publicar'}
            >
              <Button
                data-testid="homeToggleBlock"
                type="link"
                icon={
                  block.published ? <EyeInvisibleOutlined /> : <EyeOutlined />
                }
                onClick={() =>
                  modalpublished(block, {
                    setTableData,
                    blocksList: tableData,
                    updateBlockList,
                  })
                }
                size="small"
                style={{ margin: 0 }}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
};
