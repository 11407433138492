import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const FRAGMENT_BAG_PARTNER = gql`
  fragment BagPartnerFragment on PartnerBag {
    id
    code
    updatedAt
    createdAt
    discardedAt
    chargeDiscount
    partner {
      email
      kind
      slug
      status
      firstName
      id
      lastName
      # responsible
    }
  }
`;

export const FRAGMENT_BAG_ORDER = gql`
  fragment BagOrderFragment on BagOrder {
    id
    invoiceUrl
    kind
    createdAt
    updatedAt
    barcode
    bankSlipCode
    bankSlipExpireAt
    paymentStatus
    shipmentStatus
    devolution
    # paymentMethodName
    ongId
    ongPercentage
    total
    paymentType
    status: currentStatus
  }
`;

export const FRAGMENT_BASIC_BAG = gql`
  fragment BasicBagFragment on Bag {
    allowDonation
    bagDeliveryOption
    barcode
    createdAt
    id
    ongPercentage
    postalOrder
    postalOrderExpiration
    qtyApprovedItems
    qtyReprovedItems
    reproveReasons
    reprovedStatus
    requested
    shipmentReceivedAt
    status
    updatedAt
    userId
    userOngId
    warehouse
  }
`;

export const FRAGMENT_FULL_BAG = gql`
  fragment FullBagFragment on Bag {
    allowAnticipation
    anticipated
    partnerBag {
      ...BagPartnerFragment
    }
    lastBagOrder {
      ...BagOrderFragment
    }
    bagOrders {
      ...BagOrderFragment
    }
  }
  ${FRAGMENT_BAG_PARTNER}
  ${FRAGMENT_BAG_ORDER}
`;

export const QUERY_SEARCH_BAGS = gql`
  query useSearchBags(
    $userId: Int!
    $skip: Int
    $first: Int
    $full: Boolean = false
  ) {
    bagsBagAdminListBags(userId: $userId, skip: $skip, first: $first) {
      totalCount
      nodes {
        ...BasicBagFragment
        ...FullBagFragment @include(if: $full)
      }
    }
  }
  ${FRAGMENT_BASIC_BAG}
  ${FRAGMENT_FULL_BAG}
`;

function useSearchBags(options = {}) {
  const [searchBags, { error, data, loading, refetch }] = useLazyQuery(
    QUERY_SEARCH_BAGS,
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  return [
    ({ userId, skip = 0, first = DEFAULT_PAGE_SIZE, full = false }) => {
      searchBags({
        variables: {
          userId,
          skip,
          first,
          full,
        },
      });
    },
    {
      data: data?.bagsBagAdminListBags?.nodes || [],
      totalCount: data?.bagsBagAdminListBags?.totalCount || 0,
      loading,
      error,
      refetch,
    },
  ];
}

export default useSearchBags;
