import gql from 'graphql-tag';

export const OPTION_TYPES_LIST = gql`
  query listOptionTypes($first: Int!, $skip: Int!, $name: String) {
    optionTypesProductSearchOptionTypes(
      first: $first
      skip: $skip
      name: $name
    ) {
      nodes {
        id
        kind
        name
        position
        presentation
        required
      }
      totalCount
    }
  }
`;
export const OPTION_TYPES_PROMOTION = gql`
  query listOptionTypes($orderBy: OptionTypeOrder) {
    optionTypesProductSearchOptionTypes(orderBy: $orderBy) {
      nodes {
        id
        kind
        name
        position
        presentation
        required
        optionValues {
          id
          name
          presentation
          value
        }
      }
    }
  }
`;

export const OPTION_TYPES_BY_ID = gql`
  query optionTypesById($id: Int) {
    optionTypesProductSearchOptionTypes(id: $id) {
      nodes {
        id
        kind
        name
        position
        presentation
        required
        optionValues {
          id
          name
          presentation
          value
        }
      }
      totalCount
    }
  }
`;

export const OPTION_TYPES_DELETE = gql`
  mutation optionTypesDelete($input: DeleteOptionInput!) {
    optionTypesProductDeleteOptionType(input: $input) {
      id
      kind
      name
      position
      presentation
      required
    }
  }
`;

export const OPTION_TYPES_CREATE = gql`
  mutation optionTypesCreate($input: CreateOptionInput!) {
    optionTypesProductCreateOptionType(input: $input) {
      id
      kind
      name
      position
      presentation
      required
    }
  }
`;

export const OPTION_TYPES_UPDATE = gql`
  mutation optionTypesUpdate($input: UpdateOptionInput!) {
    optionTypesProductUpdateOptionType(input: $input) {
      id
      kind
      name
      position
      presentation
      required
    }
  }
`;

export const OPTION_VALUES_LIST = gql`
  query optionValueList($optionTypeId: Int) {
    optionValuesProductSearchOptionValues(optionTypeId: $optionTypeId) {
      nodes {
        id
        name
        presentation
        optionTypeId
        value
      }
      totalCount
    }
  }
`;

export const OPTION_VALUE_CREATE = gql`
  mutation optionValueCreate($input: CreateOptionValueInput!) {
    optionValuesProductCreateOptionValue(input: $input) {
      id
      name
      optionTypeId
      presentation
      value
    }
  }
`;

export const OPTION_VALUE_DELETE = gql`
  mutation optionValueDelete($input: DeleteOptionValueInput!) {
    optionValuesProductDeleteOptionValue(input: $input) {
      id
      optionTypeId
      presentation
      name
    }
  }
`;
