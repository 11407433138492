import React from 'react';
import { Avatar, Empty, Typography } from 'antd';
import {
  IMAGE_POSITION_PRINCIPAL,
  PARTICLES_OF_PRODUCT_NAME,
} from '~/constants/Product';
import {
  convertDate,
  getImageUrl,
  toCurrencyFormat,
  formatPascalCase,
} from '~/util/Functions';
import { getProductStatus } from '~/util/statusConverter';

const { Text } = Typography;

export function generateColumns() {
  const data = [
    {
      title: 'Imagem',
      dataIndex: 'images',
      key: 'images',
      render: (_, { images }) => {
        if (images.length > 0) {
          let image = images.find(
            item => item.position === IMAGE_POSITION_PRINCIPAL
          );
          image = image || images[0];

          return (
            <Avatar
              shape="square"
              size={64}
              src={getImageUrl(image.imageUrl, '70x')}
            />
          );
        }

        return (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            imageStyle={{ height: 20 }}
            description={<small>sem foto</small>}
          />
        );
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_, { slug, id, name }) => (
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://repassa.com.br/produtos/${slug}`}
          >
            {formatPascalCase(name, PARTICLES_OF_PRODUCT_NAME)}
          </a>{' '}
          <small>
            <Text type="secondary">(ID: {id})</Text>
          </small>
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'currentState',
      key: 'currentState',
      render: (_, { stateLogs, anticipated }) => {
        const status = stateLogs[stateLogs.length - 1];

        if (status) {
          return `${getProductStatus(status.state, anticipated)} (${convertDate(
            status.date
          )})`;
        }

        return 'Sem status';
      },
    },
    {
      title: 'Promoção ativa',
      dataIndex: 'currentSale',
      key: 'currentSale',
      render: (
        _,
        { currentSale, sellerSale, saleDiscount, sellerSaleDiscount }
      ) => {
        if (sellerSale) {
          return `${sellerSale.name}\n(Desconto: ${toCurrencyFormat(
            sellerSaleDiscount
          )})`;
        }
        if (currentSale) {
          return `${currentSale.name}\n(Desconto: ${toCurrencyFormat(
            saleDiscount
          )})`;
        }
        return 'Sem promoção';
      },
    },
    {
      title: 'Preço inicial',
      dataIndex: 'originalPrice',
      key: 'originalPrice',
      render: (_, { originalPrice, userPrice }) =>
        userPrice === 0
          ? toCurrencyFormat(originalPrice)
          : toCurrencyFormat(userPrice),
    },
    {
      title: 'Subsídio de frete',
      dataIndex: 'subsidy',
      key: 'subsidy',
      render: (_, { subsidy }) => toCurrencyFormat(subsidy),
    },
    {
      title: 'Preço de venda',
      dataIndex: 'sellerPrice',
      key: 'sellerPrice',
      render: (_, { sellerPrice, price }) => {
        return toCurrencyFormat(price || sellerPrice);
      },
    },
  ];

  return data;
}
