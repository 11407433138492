import gql from 'graphql-tag';

/*
type CreateInput {
  banner: String
  buyerDescription: String
  discount: Int!
  expiredAt: ISO8601Date!
  name: String!
  rulesAttributes: [SaleRuleAttributes!]!
  sellerDescription: String
  sellerNotifiedAt: ISO8601Date!
  startedAt: ISO8601Date!
}

type SaleRuleAttributes {
  _destroy: Boolean
  id:Int
  kind: SaleRuleKind!
  rule: JSON!
}

enum SaleRuleKind {
  brand
  category
  discount
  ong_percentage
  price
  property_filter
  registration_time
}

enum SaleStatus {
  canceled
  expired
  pending
  started
}

*/
export const PROMOTION_CREATE = gql`
  mutation createPromotion($input: CreateInput!) {
    salesSaleCreate(input: $input) {
      bannerUrl
      buyerDescription
      currentStatus
      # TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
      # visible
      discount
      expiredAt
      id
      name
      rules {
        id
        kind
        rule {
          ... on SaleRuleRegistrationTime {
            registrationTimeValue
          }
          ... on SaleRulePropertyFilter {
            propertyValue
            propertyName
          }
          ... on SaleRulePrice {
            priceLte
            priceGte
          }
          ... on SaleRuleOngPercentage {
            ongPercentageValue
          }
          ... on SaleRuleDiscount {
            discountLte
            discountGte
          }
          ... on SaleRuleCondition {
            conditionValue
          }
          ... on SaleRuleColor {
            colorNames
          }
          ... on SaleRuleBrand {
            id
          }
          ... on SaleRuleCategories {
            items {
              colors
              id
            }
          }
        }
        saleId
      }
      sellerDescription
      sellerNotifiedAt
      slug
      startedAt
      kind
    }
  }
`;

export const PROMOTION_UPDATE = gql`
  mutation updatePromotion($input: UpdateInput!) {
    salesSaleUpdate(input: $input) {
      bannerUrl
      buyerDescription
      currentStatus
      discount
      expiredAt
      id
      # TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
      # visible
      name
      rules {
        id
        kind
        rule {
          ... on SaleRuleRegistrationTime {
            registrationTimeValue
          }
          ... on SaleRulePropertyFilter {
            propertyValue
            propertyName
          }
          ... on SaleRulePrice {
            priceLte
            priceGte
          }
          ... on SaleRuleOngPercentage {
            ongPercentageValue
          }
          ... on SaleRuleDiscount {
            discountLte
            discountGte
          }
          ... on SaleRuleCondition {
            conditionValue
          }
          ... on SaleRuleColor {
            colorNames
          }
          ... on SaleRuleBrand {
            id
          }
          ... on SaleRuleCategories {
            items {
              colors
              id
            }
          }
        }
        saleId
      }
      sellerDescription
      sellerNotifiedAt
      slug
      startedAt
      kind
    }
  }
`;

export const PROMOTION_SEARCH_BY_ID = gql`
  query searchPromotionById($id: Int) {
    salesSaleSearch(id: $id, first: 1) {
      nodes {
        bannerUrl
        buyerDescription
        currentStatus
        discount
        expiredAt
        id
        # TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
        # visible
        name
        rules {
          id
          kind
          rule {
            ... on SaleRuleRegistrationTime {
              registrationTimeValue
            }
            ... on SaleRulePropertyFilter {
              propertyValue
              propertyName
            }
            ... on SaleRulePrice {
              priceLte
              priceGte
            }
            ... on SaleRuleOngPercentage {
              ongPercentageValue
            }
            ... on SaleRuleDiscount {
              discountLte
              discountGte
            }
            ... on SaleRuleCondition {
              conditionValue
            }
            ... on SaleRuleColor {
              colorNames
            }
            ... on SaleRuleBrand {
              id
            }
            ... on SaleRuleCategories {
              items {
                colors
                id
              }
            }
          }
          saleId
        }
        sellerDescription
        sellerNotifiedAt
        slug
        startedAt
        kind
      }
      totalCount
    }
  }
`;

export const PROMOTION_LIST = gql`
  query listPromotions($first: Int!, $skip: Int!) {
    salesSaleSearch(first: $first, skip: $skip) {
      nodes {
        bannerUrl
        buyerDescription
        currentStatus
        discount
        expiredAt
        id
        # TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
        # visible
        name
        rules {
          id
          kind
          saleId
        }
        sellerDescription
        sellerNotifiedAt
        slug
        startedAt
        kind
      }
      totalCount
    }
  }
`;

export const PROMOTION_CANCEL = gql`
  mutation cancelPromotion($input: CancelSaleInput!) {
    salesSaleCancel(input: $input) {
      bannerUrl
      buyerDescription
      currentStatus
      discount
      expiredAt
      id
      name
      rules {
        id
        kind
        rule {
          ... on SaleRuleRegistrationTime {
            registrationTimeValue
          }
          ... on SaleRulePropertyFilter {
            propertyValue
            propertyName
          }
          ... on SaleRulePrice {
            priceLte
            priceGte
          }
          ... on SaleRuleOngPercentage {
            ongPercentageValue
          }
          ... on SaleRuleDiscount {
            discountLte
            discountGte
          }
          ... on SaleRuleCondition {
            conditionValue
          }
          ... on SaleRuleColor {
            colorNames
          }
          ... on SaleRuleBrand {
            id
          }
          ... on SaleRuleCategories {
            items {
              colors
              id
            }
          }
        }
        saleId
      }
      sellerDescription
      sellerNotifiedAt
      slug
      startedAt
      kind
    }
  }
`;

export const PROMOTION_DELETE = gql`
  mutation DeletePromotion($input: DeleteInput!) {
    salesSaleDelete(input: $input) {
      bannerUrl
      buyerDescription
      currentStatus
      discount
      expiredAt
      id
      name
      rules {
        id
        kind
        rule {
          ... on SaleRuleRegistrationTime {
            registrationTimeValue
          }
          ... on SaleRulePropertyFilter {
            propertyValue
            propertyName
          }
          ... on SaleRulePrice {
            priceLte
            priceGte
          }
          ... on SaleRuleOngPercentage {
            ongPercentageValue
          }
          ... on SaleRuleDiscount {
            discountLte
            discountGte
          }
          ... on SaleRuleCondition {
            conditionValue
          }
          ... on SaleRuleColor {
            colorNames
          }
          ... on SaleRuleBrand {
            id
          }
          ... on SaleRuleCategories {
            items {
              colors
              id
            }
          }
        }
        saleId
      }
      sellerDescription
      sellerNotifiedAt
      slug
      startedAt
      kind
    }
  }
`;

export const PROMOTION_CATEGORIES = gql`
  query listPromotionCategories($orderBy: CategoryOrder!, $parentId: Int) {
    categoriesProductSearchCategories(orderBy: $orderBy, parentId: $parentId) {
      nodes {
        active
        fixed
        id
        name
        parentId
        permalink
        productCount
      }
    }
  }
`;
