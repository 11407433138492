export const KIND_ADVANCE_PAYMENT = 'advance_payment';
export const KIND_BAG_ORDER_BALANCE_BAG_BUY = 'bag_order_balance_bag_buy';
export const KIND_BAG_ORDER_BALANCE_BAG_REFUND = 'bag_order_balance_bag_refund';
export const KIND_BAG_ORDER_PARTIAL_BAG_PAYMENT =
  'bag_order_partial_bag_payment';
export const KIND_BAG_ORDER_REFUND_PARTIAL_BAG_PAYMENT =
  'bag_order_refund_partial_bag_payment';
export const KIND_BAG_ORDER_REFUND_REVERSE_BAG_TAX =
  'bag_order_refund_reverse_bag_tax';
export const KIND_BONUS_SHARE_GOOD = 'bonus_share_good';
export const KIND_BONUS_TRANSFER = 'bonus_transfer';
export const KIND_DONATION = 'donation';
export const KIND_ORDER_BALANCE_BUY = 'order_balance_buy';
export const KIND_ORDER_BALANCE_REFUND = 'order_balance_refund';
export const KIND_ORDER_PARTIAL_PAYMENT = 'order_partial_payment';
export const KIND_ORDER_REFUND_PARTIAL_PAYMENT = 'order_refund_partial_payment';
export const KIND_ORDER_REFUND_REVERSE_TAX = 'order_refund_reverse_tax';
export const KIND_ORDER_PAYOUT = 'order_payout';
export const KIND_PAYOUT_DONATION = 'payout_donation';
export const KIND_VOUCHER = 'voucher';
export const KIND_WITHDRAW = 'withdraw';
export const KIND_WITHDRAW_REFUND = 'withdraw_refund';
export const KIND_ORDER_ITEM_REFUND_PAYMENT = 'order_item_refund_payment';
export const KIND_REVERSE_IMPROPER_CREDIT = 'reverse_improper_credit';
export const KIND_BALANCE_RELEASE_DUE_ACCOUNT_REACTIOVATION =
  'balance_release_due_account_reactivation';
export const KIND_BALANCE_BLOCK_DUE_TO_ACCOUNT_INACTIVITY =
  'balance_block_due_to_account_inactivity';
export const KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT =
  'debit_overpaid_reimbursement_adjustment';
export const KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT_BY_ITEM =
  'debit_overpaid_reimbursement_adjustment_by_item';
export const KIND_PAYOUT_FIXED_SALES_FEE = 'payout_fixed_sales_fee';

export const ITEM_TYPE_BAG = 'bag';
export const ITEM_TYPE_BAG_ORDER = 'bag_order';
export const ITEM_TYPE_BONUS = 'bonus';
export const ITEM_TYPE_DONATION = 'donation';
export const ITEM_TYPE_ORDER_PAYOUT = 'order_payout';
export const ITEM_TYPE_PAYOUT_DONATION = 'payout_donation';
export const ITEM_TYPE_VOUCHER = 'voucher';
export const ITEM_TYPE_ORDER = 'order';
export const ITEM_TYPE_ORDER_ITEM = 'order_item';
export const ITEM_TYPE_USER = 'user';

export const getKindRelativeItemType = kind => {
  switch (kind) {
    case KIND_ADVANCE_PAYMENT:
      return ITEM_TYPE_BAG;
    case KIND_BAG_ORDER_BALANCE_BAG_BUY:
    case KIND_BAG_ORDER_BALANCE_BAG_REFUND:
    case KIND_BAG_ORDER_PARTIAL_BAG_PAYMENT:
    case KIND_BAG_ORDER_REFUND_PARTIAL_BAG_PAYMENT:
    case KIND_BAG_ORDER_REFUND_REVERSE_BAG_TAX:
      return ITEM_TYPE_BAG_ORDER;
    case KIND_BONUS_SHARE_GOOD:
      return ITEM_TYPE_BONUS;
    case KIND_BONUS_TRANSFER:
    case KIND_WITHDRAW:
    case KIND_WITHDRAW_REFUND:
      return ITEM_TYPE_USER;
    case KIND_DONATION:
      return ITEM_TYPE_DONATION;
    case KIND_ORDER_BALANCE_BUY:
    case KIND_ORDER_BALANCE_REFUND:
    case KIND_ORDER_PARTIAL_PAYMENT:
    case KIND_ORDER_REFUND_PARTIAL_PAYMENT:
    case KIND_ORDER_REFUND_REVERSE_TAX:
      return ITEM_TYPE_ORDER;
    case KIND_ORDER_PAYOUT:
    case KIND_PAYOUT_FIXED_SALES_FEE:
    case KIND_REVERSE_IMPROPER_CREDIT:
      return ITEM_TYPE_ORDER_PAYOUT;
    case KIND_PAYOUT_DONATION:
      return ITEM_TYPE_PAYOUT_DONATION;
    case KIND_VOUCHER:
      return ITEM_TYPE_VOUCHER;
    case KIND_ORDER_ITEM_REFUND_PAYMENT:
      return ITEM_TYPE_ORDER_ITEM;
    default:
      return kind;
  }
};

export const BALANCE_TYPE_CREDIT = 'credit';
export const BALANCE_TYPE_DEBIT = 'debit';

export const BALANCE_LABELS = {
  [BALANCE_TYPE_CREDIT]: {
    [KIND_ADVANCE_PAYMENT]: 'Antecipação de Pagamento',
    [KIND_BAG_ORDER_BALANCE_BAG_REFUND]: 'Estorno de pedido da Sacola do Bem',
    [KIND_BAG_ORDER_REFUND_PARTIAL_BAG_PAYMENT]:
      'Estorno Pagamento Parcial de Sacola do Bem',
    [KIND_BAG_ORDER_REFUND_REVERSE_BAG_TAX]:
      'Estorno de Frete de Sacola do Bem',
    [KIND_ORDER_BALANCE_REFUND]: 'Estorno de pedido',
    [KIND_ORDER_ITEM_REFUND_PAYMENT]: 'Estorno de um item do pedido',
    [KIND_ORDER_REFUND_PARTIAL_PAYMENT]: 'Estorno Pagamento Parcial',
    [KIND_ORDER_REFUND_REVERSE_TAX]: 'Estorno de Frete',
    [KIND_ORDER_PAYOUT]: 'Venda de Produto',
    [KIND_PAYOUT_DONATION]: 'Doação de Venda', // (quem receberá será uma Ong)
    [KIND_BONUS_SHARE_GOOD]: 'Bônus da ação de Espalhe o Bem',
    [KIND_BONUS_TRANSFER]: 'Bônus de restituição',
    [KIND_DONATION]: 'Doação Direta', // (quem receberá será uma Ong)
    [KIND_VOUCHER]: 'Vale Compras', // (quem receberá será um Parceiro)
    [KIND_WITHDRAW_REFUND]: 'Reembolso de Saque',
    [KIND_BALANCE_RELEASE_DUE_ACCOUNT_REACTIOVATION]: 'Liberação de saldo',
  },
  [BALANCE_TYPE_DEBIT]: {
    [KIND_BAG_ORDER_BALANCE_BAG_BUY]:
      'Compra de Sacola do Bem com saldo Repassa',
    [KIND_BAG_ORDER_PARTIAL_BAG_PAYMENT]: 'Pagamento Parcial de Sacola do Bem',
    [KIND_ORDER_BALANCE_BUY]: 'Compra com saldo Repassa',
    [KIND_ORDER_PARTIAL_PAYMENT]: 'Pagamento Parcial',
    [KIND_PAYOUT_DONATION]: 'Doação de Venda',
    [KIND_DONATION]: 'Doação',
    [KIND_VOUCHER]: 'Vale Compras',
    [KIND_WITHDRAW]: 'Saque',
    [KIND_REVERSE_IMPROPER_CREDIT]: 'Estorno de Crédito Indevido',
    [KIND_BALANCE_BLOCK_DUE_TO_ACCOUNT_INACTIVITY]: 'Bloqueio de saldo',
    [KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT]: 'Ajuste de reembolso',
    [KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT_BY_ITEM]:
      'Ajuste de reembolso do produto',
    [KIND_PAYOUT_FIXED_SALES_FEE]: 'Tarifa de venda',
  },
};
