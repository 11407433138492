import React, { useMemo, useState } from 'react';
import { Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import Upload from '~/components/Upload/Image';
import { getFieldLabelByName, IMAGE_NAME, LOGO_NAME } from './formLabels';

const getStyle = hover => ({
  cursor: 'pointer',
  borderColor: hover ? '#1890ff' : '#d9d9d9',
  borderRadius: '2px',
  borderStyle: 'dashed',
  borderWidth: '1px',
  borderImageOutset: '0',
  borderImageRepeat: 'stretch',
  borderImageSlice: '100%',
  borderImageSource: 'none',
  borderImageWidth: '1',
  width: 'fit-content',
});

function ImagesForm({ logo, image, form, uploadCallback }) {
  const [onImageHover, setOnImageHover] = useState(false);
  const [onLogorHover, setOnLogoHover] = useState(false);

  const [logoRemoved, setLogoRemoved] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);

  const [logoError, setLogoError] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleChangeLogo = uplodedLogo => {
    setLogoError(false);
    setLogoRemoved(false);
    form.setFieldsValue({ newLogo: uplodedLogo });
    uploadCallback();
  };

  const handleChangeImage = uplodedImage => {
    setImageError(false);
    setImageRemoved(false);
    form.setFieldsValue({ newImage: uplodedImage });
    uploadCallback();
  };

  const onImageRemove = () => {
    setImageRemoved(true);
    setImageError(false);
    form.setFieldsValue({ newImage: null });
    uploadCallback();
  };

  const onLogoRemove = () => {
    setLogoRemoved(true);
    setLogoError(false);
    form.setFieldsValue({ newLogo: null });
    uploadCallback();
  };

  const onLogoError = () => {
    setLogoError(true);
  };

  const onImageError = () => {
    setImageError(true);
  };

  const hasLogo = useMemo(() => logo && !logoRemoved, [logo, logoRemoved]);
  const hasImage = useMemo(() => image && !imageRemoved, [image, imageRemoved]);

  return (
    <Row gutter={16} type="flex" align="top" justify="center">
      <Col xl={6} lg={24} md={24} xs={24}>
        <Form.Item
          label={getFieldLabelByName(LOGO_NAME)}
          name={LOGO_NAME}
          data-testid="logoInput"
          rules={[
            {
              message: 'Inserir uma imagem',
            },
          ]}
        >
          <div
            style={hasLogo && !logoError ? getStyle(onLogorHover) : {}}
            onMouseEnter={() => setOnLogoHover(true)}
            onMouseLeave={() => setOnLogoHover(false)}
          >
            <Upload
              data-testid="logoUploadInput"
              accept="image/*"
              onChange={handleChangeLogo}
              thumb={hasLogo ? logo : null}
              listType={hasLogo ? 'picture' : 'picture-card'}
              onRemove={onLogoRemove}
              onError={onLogoError}
            />
          </div>
        </Form.Item>
      </Col>
      <Col xl={18} lg={24} md={24} xs={24}>
        <Form.Item
          label={getFieldLabelByName(IMAGE_NAME)}
          name={IMAGE_NAME}
          data-testid="imageInput"
          rules={[
            {
              message: 'Inserir uma imagem',
            },
          ]}
        >
          <div
            style={hasImage && !imageError ? getStyle(onImageHover) : {}}
            onMouseEnter={() => setOnImageHover(true)}
            onMouseLeave={() => setOnImageHover(false)}
          >
            <Upload
              data-testid="imageUploadInput"
              accept="image/*"
              onChange={handleChangeImage}
              thumb={hasImage ? image : null}
              listType={hasImage ? 'picture' : 'picture-card'}
              onRemove={onImageRemove}
              onError={onImageError}
            />
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
}

ImagesForm.defaultProps = {
  logo: null,
  image: null,
  uploadCallback: () => {},
};

ImagesForm.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
  }).isRequired,
  logo: PropTypes.string,
  image: PropTypes.string,
  uploadCallback: PropTypes.func,
};

export default ImagesForm;
