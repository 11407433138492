import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, form } = THEME_BEHAVIOR;

const switchColor = form.formSwitch.color ? form.formSwitch.color : theme.color;

export const AntFormFields = css`
  // TEXT IMPUT
  // --------------------------------------------------------------- //
  body {
    .ant-input,
    .ant-input-number {
      border-radius: ${theme.borderRadius};
      height: ${form.field.height};
      line-height: ${form.field.height};

      &-group {
        &-addon {
          border-top-left-radius: ${theme.borderRadius};
          border-bottom-left-radius: ${theme.borderRadius};
        }
      }

      &-focused,
      &:focus,
      &:hover {
        border-color: ${theme.color};
        outline: ${form.field.outline};
        box-shadow: ${form.field.boxShadow};
      }
    }
  }

  // TEXT IMPUT GROUP ADD-ON
  // --------------------------------------------------------------- //
  #root {
    .ant-input {
      &-group-addon {
        box-sizing: border-box;
        height: ${form.field.height};
        > div {
          > div {
            margin: 0;
            padding: 0;
            > div {
            }
          }
        }
      }
    }
  }

  // PASSWORD IMPUT
  // --------------------------------------------------------------- //
  body {
    .ant-input-affix-wrapper {
      border-radius: ${theme.borderRadius};
      height: ${form.field.height};
      line-height: ${form.field.height};
      .ant-input {
        height: auto;
      }
    }
  }

  // SELECT
  // --------------------------------------------------------------- //
  body {
    .ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: ${theme.borderRadius};
        height: ${form.field.height};
        line-height: ${form.field.height};
        .ant-select-selection-search-input,
        .ant-select-selection-placeholder {
          height: ${form.field.height};
          line-height: ${form.field.height};
        }
        .ant-select-selection-item {
          border-radius: ${theme.borderRadius};
          line-height: ${form.field.height};
        }
      }
    }

    #root {
      .ant-select {
        &-focused,
        &:hover {
          .ant-select-selector {
            border-color: ${theme.color};
            outline: ${form.field.outline};
            box-shadow: ${form.field.boxShadow};
          }
        }
        &-multiple {
          height: auto;
          min-height: ${form.field.height};
          .ant-select-selector {
            height: auto;
            min-height: ${form.field.height};
          }
          .ant-select-selection {
            &-overflow {
              height: auto;
              min-height: 30px;
            }
            &-overflow-item {
              height: auto;
              &-suffix {
                height: 20px;
              }
            }
            &-item {
              border-radius: ${theme.borderRadiusLow};
              line-height: inherit;
              display: flex;
              align-items: center;
            }
            &-search {
              height: 20px;
              line-height: 20px;
              &-input {
                height: 20px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }

  // DATE PICKER
  // --------------------------------------------------------------- //
  body {
    .ant-picker {
      border-radius: ${theme.borderRadius};
      height: ${form.field.height};
    }
    .ant-picker-panel-container {
      border-radius: ${theme.borderRadius};
    }
  }

  // SWITCH
  // --------------------------------------------------------------- //
  body {
    .ant-switch {
      &-checked {
        background-color: ${switchColor};
      }
    }
  }

  // CUSTOM FIELD REQUIRED
  // --------------------------------------------------------------- //
  body {
    .ant-form-item-custom-required {
      &:before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
  }
`;
