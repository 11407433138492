import React, { useState } from 'react';
import { Tabs, Card } from 'antd';

import { store } from '~/appRedux/store';

import GraphQLRequester from '~/pages/tech/GraphQL/Requester';
import CartInspector from '~/pages/tech/GraphQL/CartInspector';
import useTabController from '~/hooks/utils/useTabController';

const TAB_KEYS = ['graphql-requester', 'cart-inspector'];

function GraphQLOperator() {
  const { currentTab, onChange: onChangeTab } = useTabController({
    tabKeys: TAB_KEYS,
  });

  const getToken = () => {
    const state = store.getState();
    return state.auth.loggedIn ? state.auth.account.token : null;
  };

  const [initialValues] = useState({
    url:
      process.env.REACT_APP_BACKEND_URI || 'https://api.v2.hmg.repassa.com.br',
    token: getToken(),
  });

  return (
    <Card>
      <Tabs
        type="card"
        activeKey={currentTab}
        onChange={onChangeTab}
        items={[
          {
            label: 'GraphQL Requester',
            key: 'graphql-requester',
            children: <GraphQLRequester initialValues={initialValues} />,
          },
          {
            label: 'Cart Inspector',
            key: 'cart-inspector',
            children: <CartInspector initialValues={initialValues} />,
          },
        ]}
      />
    </Card>
  );
}

export default GraphQLOperator;
