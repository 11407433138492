export const STATUS_CREATED = 'created';
export const STATUS_PAID = 'paid';
export const STATUS_AWAITING_PAYMENT = 'awaiting_payment';
export const STATUS_WAITING = 'waiting';
export const STATUS_PENDING = 'pending';
export const STATUS_PAYMENT_PENDING = 'payment_pending';
export const STATUS_PAYMENT_CANCELED = 'payment_canceled';
export const STATUS_REVERSAL = 'reversal';
export const STATUS_REFUNDED = 'refunded';
export const STATUS_REVERSED = 'reversed';
export const STATUS_EXPIRED = 'expired';
export const STATUS_IN_ANALYSIS = 'in_analysis';
export const STATUS_CANCELED = 'canceled';

export const PAYMENT_TYPE_CREDIT_CARD = 'creditcard';
export const PAYMENT_TYPE_INVOICE = 'invoice';
export const PAYMENT_TYPE_BANKSLIP = 'bankslip';
export const PAYMENT_TYPE_BALANCE = 'balance';
export const PAYMENT_TYPE_PIX = 'pix';
export const PAYMENT_TYPE_DONATION = 'free';
export const PAYMENT_TYPE_FREE = 'free';

export const PAYMENT_STATUSES = [
  STATUS_CREATED,
  STATUS_PAID,
  STATUS_AWAITING_PAYMENT,
  STATUS_PAYMENT_CANCELED,
  STATUS_WAITING,
  STATUS_PENDING,
  STATUS_PAYMENT_PENDING,
  STATUS_REVERSAL,
  STATUS_REFUNDED,
  STATUS_REVERSED,
  STATUS_EXPIRED,
  STATUS_IN_ANALYSIS,
  STATUS_CANCELED,
];
