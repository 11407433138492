import styled from 'styled-components';
import { Layout, Drawer } from 'antd';
import { TAB_SIZE } from '~/constants/Global';

const { Sider } = Layout;

export const MenuContainer = styled(Sider)`
  border-right: 1px solid #e8e8e8;
  background-color: #fff;
  min-width: 280px !important;
  transition: all 0.3s;
  overflow: hidden;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  &.menu-mobile {
    display: none;
  }

  @media screen and (max-width: 1366px) {
    min-width: 240px !important;
  }

  @media screen and (max-width: ${TAB_SIZE}px) {
    &.menu-desktop {
      display: none;
    }
    &.menu-mobile {
      min-width: 0px !important;
      flex: 0 0 0px !important;
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  overflow: hidden;

  .ant-drawer-body {
    padding: 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  @media screen and (min-width: ${TAB_SIZE}px) {
    display: none;
  }
`;

export const MenuContentWrap = styled.div`
  flex: 1;
`;
