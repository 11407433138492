import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const XML_NFSE_LIST = gql`
  query useXmlNfseList {
    xmlNfseList {
      nodes {
        createdAt
        current
        error
        fileName
        id
      }
      totalCount
    }
  }
`;

export function useXmlNfseList(options = {}) {
  const { data } = useQuery(XML_NFSE_LIST, {
    fetchPolicy: 'network-only',
    ...options,
  });

  return { xmlNfseList: data?.xmlNfseList };
}
