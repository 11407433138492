import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Select } from 'antd';
import Button from '~/components/Button';
import Color from '../Color';
import BrandSearch from './BrandSearch';
import dataOngPercentage from './data/OngPercentageFilter';
import dataDiscount from './data/DiscountFilter';
import dataPrice from './data/PriceFilter';
import dataCondition from './data/ConditionFilter';

import { notifyError } from '~/util/Functions';
import useFilter from './useFilter';
import { addConditionRule, addFilterRule, addPriceRule } from '../../actions';
import {
  TABLE_KEY_BRAND,
  TABLE_KEY_DISCOUNT,
} from '../../RulesReducer/constants';

const { Option, OptGroup } = Select;

function Filter({ form, dispatch, tables }) {
  const [selectedFilter, setSelectedFilter] = useState({
    id: '',
    presentation: 'Filtro',
    optionValues: [],
  });
  const [selectedBrands, setSelectedBrands] = useState([]);

  const [arrProperty, setArrProperty] = useState([]);
  const [getOptionTypes] = useFilter({
    getter: arrProperty,
    setter: setArrProperty,
    onError: errorSearch => {
      notifyError('Erro ao buscar tipos de opção', errorSearch);
      setArrProperty([]);
    },
  });

  useEffect(() => {
    getOptionTypes({
      orderBy: 'NAME_ASC',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeProperty = value => {
    // Pega o id da propriedade e carrega os valores nos filtros para adicionar na table
    let selectedProperty = [];
    switch (value) {
      case 'brand':
        setSelectedFilter({});
        break;
      case 'color':
        setSelectedFilter({});
        break;
      case 'discount':
        setSelectedFilter(dataDiscount);
        break;
      case 'price':
        setSelectedFilter(dataPrice);
        break;
      case 'condition':
        setSelectedFilter(dataCondition);
        break;
      case 'ongPercentage':
        setSelectedFilter(dataOngPercentage);
        break;
      default:
        selectedProperty = arrProperty.filter(item => item.id === value);
        if (selectedProperty.length > 0) {
          setSelectedFilter(selectedProperty[0]);
        }
        break;
    }

    form.setFieldsValue({
      property: value,
      filters: [],
    });
  };

  const handleAddFilter = (selectedProperty, brands) => {
    addFilterRule(selectedProperty, {
      tables,
      dispatch,
      newRows: brands,
      tableKey: TABLE_KEY_BRAND,
      title: TABLE_KEY_BRAND,
      setSelectedBrands,
    });
  };

  const handleChangeFilter = values => {
    form.setFieldsValue({
      filters: values,
    });
  };

  const getFilters = (filters, selectFilter) => {
    let selectedOptions = filters.optionValues.filter(item => {
      return selectFilter.includes(item.id);
    });

    selectedOptions = selectedOptions.map(item => {
      if (item.lte || item.gte) {
        return {
          id: item.presentation,
          key: item.presentation,
          name: item.presentation,
          presentation: item.presentation,
          lte: item.lte,
          gte: item.gte,
          ruleId: null,
        };
      }
      return {
        id: item.presentation,
        key: item.presentation,
        name: item.presentation,
        presentation: item.presentation,
        ruleId: null,
      };
    });

    return selectedOptions;
  };

  const handleRemoveFilter = value => {
    const { property } = form.getFieldsValue('discount');

    if (property) {
      const itemIndex = tables.findIndex(
        ({ key }) => key === TABLE_KEY_DISCOUNT
      );
      const itemExists = tables[itemIndex].data.find(item => item.id === value);

      if (itemExists) {
        const filteredValues = tables[itemIndex].data.filter(
          item => item.id !== itemExists.id
        );

        addFilterRule('discount', {
          tables,
          dispatch,
          newRows: filteredValues,
          tableKey: TABLE_KEY_DISCOUNT,
          title: TABLE_KEY_DISCOUNT,
        });
      }
    }
  };

  const handleAdd = () => {
    const filters = form.getFieldValue('filters');
    const selectedProperty = form.getFieldValue('property');

    let property = [];

    switch (selectedProperty) {
      case 'brand':
        handleAddFilter(selectedProperty, selectedBrands);
        break;
      case 'discount':
        property.push(dataDiscount);
        break;
      case 'condition':
        addConditionRule({
          dispatch,
          tables,
          tableKey: 'condition',
          title: 'condition',
          newRows: [form.getFieldsValue().filters],
        });
        break;
      case 'price':
        addPriceRule({
          dispatch,
          tables,
          newRows: getFilters(selectedFilter, filters).map(item => {
            return {
              key: `price-${Number(item.gte).toFixed(2)}-${Number(
                item.lte
              ).toFixed(2)}`,
              price: {
                gte: Number(item.gte).toFixed(2),
                lte: Number(item.lte).toFixed(2),
              },
              quantity: 0,
            };
          }),
          tableKey: 'promotionPrice',
          title: 'Preço',
        });
        break;
      case 'ongPercentage':
        property.push(dataOngPercentage);
        break;
      default:
        property = arrProperty.filter(item => item.id === selectedProperty);
        break;
    }

    if (property.length > 0) {
      const newRows = getFilters(selectedFilter, filters);

      addFilterRule(property[0].kind, {
        tables,
        dispatch,
        newRows,
        tableKey: property[0].presentation,
        title: property[0].presentation,
        form,
      });
    }
  };

  const renderFilterOptions = () => {
    const property = form.getFieldValue('property');

    if (property === 'brand') {
      return (
        <Form.Item label="Marca">
          <BrandSearch
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            handleAddFilter={handleAddFilter}
            tables={tables}
            form={form}
          />
        </Form.Item>
      );
    }
    if (property === 'color') {
      return <Color form={form} dispatch={dispatch} tables={tables} />;
    }

    return (
      <Form.Item
        name="filters"
        label={selectedFilter.presentation}
        key={selectedFilter.id}
      >
        <Select
          data-testid="filters"
          mode={selectedFilter.kind !== 'condition' && 'multiple'}
          style={{ width: '100%' }}
          onChange={handleChangeFilter}
          onDeselect={handleRemoveFilter}
        >
          {selectedFilter.optionValues.map(option => (
            <Option
              key={option.id}
              value={option.id}
              data-testid={option?.dataTestId}
            >
              {option.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <>
      <Col span={24}>
        <Form.Item label="Propriedade" name="property">
          <Select
            data-testid="property"
            style={{ width: '100%' }}
            onChange={handleChangeProperty}
          >
            <OptGroup label="Filtros">
              <Option value="brand">Marca</Option>
              <Option value="color">Cor</Option>
              <Option value="condition">Estado de uso</Option>
              <Option value="discount">Desconto</Option>
              <Option value="ongPercentage">Repasse Solidário</Option>
            </OptGroup>
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>{renderFilterOptions()}</Col>

      {/* Não renderiza o botão se for formulário de cor, pois ele tem o próprio botão */}
      {form.getFieldValue('property') !== 'color' && (
        <Col span={24}>
          <Form.Item>
            <Button
              data-testid="addFilterRuleBtn"
              type="primary"
              onClick={handleAdd}
            >
              Adicionar
            </Button>
          </Form.Item>
        </Col>
      )}
    </>
  );
}

Filter.propTypes = {
  form: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  promotion: PropTypes.shape({
    property: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
};

export default Filter;
