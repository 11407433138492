import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

const CustomScrollbars = props => (
  <Scrollbars
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    autoHide
    renderTrackHorizontal={props2 => (
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props2}
        style={{ display: 'none' }}
        className="track-horizontal"
      />
    )}
  />
);

export default CustomScrollbars;
