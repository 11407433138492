export const COUPON_OPERATOR_AND = '_and';
export const COUPON_OPERATOR_OR = '_or';

export const COUPON_CATEGORY_ATTENDANCE = 'attendance';
export const COUPON_CATEGORY_PROMOTION = 'promotion';

export const COUPON_KIND_BAG_ORDER = 'bag_order';
export const COUPON_KIND_BOTH = 'both';
export const COUPON_KIND_CART = 'cart';

export const COUPON_RULE_PRODUCT = 'product';
export const COUPON_RULE_USER = 'user';
export const COUPON_RULE_FIRST_ORDER = 'firstOrder';
export const COUPON_RULE_ORDER_TOTAL = 'orderTotal';
export const COUPON_RULE_QUANTITY = 'quantityItems';

export const COUPON_ACTION_ORDER_ADJUST = 'orderAdjust';
export const COUPON_ACTION_FREE_SHIPPING = 'freeShipping';
