import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';

import { toCurrencyFormat } from '~/util/Functions';
import {
  getPaymentStatus,
  getShipmentStatus,
  isRepurchaseOrder,
} from '~/util/statusConverter';

const { Text, Paragraph } = Typography;

function ExpandableProduct({ product }) {
  // Somente se for "true" NÃO vai renderizar, se for "false", "null" ou "undefined" renderiza da
  // mesmo forma de antes do campo "repurchase" existir
  const [renderIfNotRepurchase] = useState(product?.repurchase !== true);

  return (
    <Row gutter={[50, 0]} justify="space-between">
      <Col xs={24} sm={8}>
        <Paragraph>
          <Text strong>Número do pedido: </Text>
          <Text> {product.orderId || 'Não consta'}</Text>
        </Paragraph>

        <Paragraph>
          <Text strong>Status da Entrega: </Text>
          <Text>
            {product?.currentState &&
              getShipmentStatus(product?.shipmentStatus?.kind)}
          </Text>
        </Paragraph>

        <Paragraph>
          <Text strong>Status do seu pagamento: </Text>
          <Text>{getPaymentStatus(product.paymentStatus?.kind)}</Text>
        </Paragraph>

        <Paragraph>
          <Text strong>Recompra: </Text>
          <Text>{isRepurchaseOrder(product.repurchase)}</Text>
        </Paragraph>
      </Col>
      <Col xs={24} sm={8}>
        <Paragraph>
          <Text strong>Preço inicial: </Text>
          <Text>{toCurrencyFormat(product.userPrice)}</Text>
        </Paragraph>

        {renderIfNotRepurchase && (
          <Paragraph>
            <Text strong>Subsídio de frete: </Text>
            <Text>{toCurrencyFormat(product.subsidy)}</Text>
          </Paragraph>
        )}

        {product.currentSale || product.sellerSale ? (
          <Paragraph>
            <Text strong>
              Promoção {product?.sellerSale?.name || product.currentSale?.name}:{' '}
            </Text>
            <Text>
              {toCurrencyFormat(
                product.sellerSaleDiscount || product.saleDiscount
              )}
            </Text>
          </Paragraph>
        ) : null}
      </Col>

      <Col xs={24} sm={8}>
        <Paragraph>
          <Text strong>Preço de Venda: </Text>
          <Text>{toCurrencyFormat(product.sellerPrice || product?.price)}</Text>
        </Paragraph>

        {renderIfNotRepurchase && (
          <Paragraph>
            <Text strong>
              Participação do Cupom{' '}
              {product.couponSellerPercentage
                ? `${product.couponSellerPercentage * 100}%`
                : '0%'}
              :{' '}
            </Text>
            <Text>{toCurrencyFormat(product.couponShare || 0)}</Text>
          </Paragraph>
        )}

        {renderIfNotRepurchase && (
          <Paragraph>
            <Text strong>
              Comissão Repassa {product.commissionPercentage}%:{' '}
            </Text>
            <Text>{toCurrencyFormat(product.commission)}</Text>
          </Paragraph>
        )}

        {product.ongPercentage && product.ongDonationValue ? (
          <Paragraph>
            <Text strong>
              Doação {product.ong?.last_name} {product.ongPercentage}
              %:{' '}
            </Text>
            <Text>{toCurrencyFormat(product.ongDonationValue)}</Text>
          </Paragraph>
        ) : null}

        {renderIfNotRepurchase && (
          <Paragraph>
            <Text strong>Total pago ao vendedor: </Text>
            <Text>{toCurrencyFormat(product.profit)}</Text>
          </Paragraph>
        )}
      </Col>
    </Row>
  );
}

ExpandableProduct.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ExpandableProduct;
