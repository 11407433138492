import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Input, Select, Form } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import iconList from '~/assets/svg/SvgIcons';
import { triggerErrorNotification } from '~/util/Functions';
import SetIcon from '~/components/SetIcon';
import { IconSelectStyle, IconListIcon } from './styles';

function DefaultForm({ handleSubmit, initialData, isEdit }) {
  const [form] = Form.useForm();
  const { Option } = Select;

  const onFinish = values => {
    handleSubmit(values);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar categoria',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="defaultCategoryForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        subject: initialData.subject,
        icon: initialData.icon,
      }}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Grupo"
            name="subject"
            rules={[
              {
                required: true,
                message: 'Preencha o grupo da categoria ',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="subject" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          <IconSelectStyle />
          <Form.Item
            label="Ícone"
            xs={24}
            name="icon"
            rules={[
              {
                type: 'string',
                required: true,
                message: 'Selecione o ícone',
                whitespace: true,
              },
            ]}
          >
            <Select
              showSearch
              data-testid="icon"
              placeholder="Selecione um icone"
              dropdownMatchSelectWidth={false}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {iconList.map(({ name, description }) => (
                <Option key={name} value={name} label={description}>
                  <IconListIcon>
                    <SetIcon name={name} size={5} />
                    <span>{description}</span>
                  </IconListIcon>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16} justify="end">
        <Col>
          <Form.Item>
            <Button
              data-testid="defaultSubmitBtn"
              type="success"
              htmlType="submit"
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
              style={{ margin: 0 }}
            >
              {isEdit ? 'Salvar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

DefaultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    subject: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    weightPosition: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default DefaultForm;
