import React from 'react';
import { Card, Table } from 'antd';
import { stubFalse } from 'lodash';
import { useXmlNfseList } from '~/hooks/financial/useXmlNfseList';
import { generateColumns, generateErrorColumns } from './columns';

function StatusConciliation() {
  const { xmlNfseList } = useXmlNfseList();
  const totalCompleted = xmlNfseList?.nodes.reduce(
    (total, item) => (item.current === 'completed' ? total + 1 : total),
    0
  );

  return (
    <Card title="Status da Conciliação">
      <Table
        dataSource={xmlNfseList?.nodes}
        columns={generateColumns()}
        loading={stubFalse}
        rowKey={record => record.id}
        bordered
        title={() =>
          `Arquivos conciliados com sucesso: ${totalCompleted} de ${
            xmlNfseList?.totalCount || 0
          } solicitados`
        }
        scroll={{
          x: true,
        }}
        pagination={false}
        expandable={{
          expandedRowRender: record => (
            <Table
              rowKey={rcd => rcd.numberNfse}
              dataSource={record.error}
              columns={generateErrorColumns()}
              pagination={false}
              bordered
            />
          ),
          rowExpandable: record => record?.error?.length,
        }}
      />
    </Card>
  );
}

export default StatusConciliation;
