import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Button from '~/components/Button';
import LoadingBar from '~/components/LoadingBar';

import { getTodayISODate } from '~/util/Functions';
import history from '~/services/history';
import { CATEGORIES_LIST, CATEGORY_DELETE } from '~/apollo/bag/category';
import Filter from './Filter';
import TableCategory from './TableCategory';

import { handleAddCategory } from './actions';

function CategoryList({ match }) {
  const [category, setCategory] = useState([]);
  const [table, setTable] = useState({
    pageSize: 10,
    currentPage: 1,
    skip: 0,
    totalCount: 0,
    searchText: '',
    searchDate: getTodayISODate({
      seconds: false,
      minutes: false,
      hours: false,
    }),
    searchStatus: 'pending',
    loading: false,
    refetch: false,
  });
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState({
    flag: false,
    message: '',
    obj: {},
  });

  function updateHooks(searchCategory, totalCount) {
    setCategory(searchCategory);
    setTable({
      ...table,
      loading: false,
      refetch: false,
      totalCount,
    });
    setLoading(false);
  }

  const [listCategories] = useLazyQuery(CATEGORIES_LIST, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    variables: {
      first: table.pageSize,
      skip: table.skip,
      onlyActive: false,
      parentId: Number(match.params.id) || null,
      orderBy: 'NAME_ASC',
      name: table.searchText,
    },
    onCompleted: ({
      categoriesProductSearchCategories: { nodes: searchCategory, totalCount },
    }) => {
      updateHooks(searchCategory, totalCount); /* totalCount */
    },
    onError: err => {
      setError({
        flag: true,
        message: 'Erro ao buscar categoria',
        obj: err,
      });
      setLoading(false);
    },
  });

  const [newCategoryId, setNewCategoryId] = useState('');
  const [deleteCategory] = useMutation(CATEGORY_DELETE, {
    context: {
      newBackend: true,
    },
    refetchQueries: ['listCategories'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    listCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.loading]);

  if (loading) return <LoadingBar />;
  if (error.flag) return `Error! ${error.message}`;

  return (
    <Card
      data-testid="categoryCard"
      title={
        <>
          {match.params.id ? (
            <Tooltip placement="bottom" title="Voltar">
              <ArrowLeftOutlined
                onClick={history.goBack}
                style={{ marginRight: 10 }}
                data-testid="backToBtn"
              />
            </Tooltip>
          ) : null}
          CATEGORIA
        </>
      }
      extra={
        <Button
          onClick={() => handleAddCategory(match.params.id)}
          style={{ marginBottom: 0 }}
          type="primary"
          icon={<PlusOutlined />}
          data-testid="addBtn"
        >
          Adicionar
        </Button>
      }
    >
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter setTable={setTable} />
      </Card.Grid>
      <Card.Grid style={{ width: '100%' }}>
        <TableCategory
          category={category}
          deleteCategory={deleteCategory}
          newCategoryId={newCategoryId}
          setNewCategoryId={setNewCategoryId}
          table={table}
          setTable={setTable}
        />
      </Card.Grid>
    </Card>
  );
}

CategoryList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CategoryList;
