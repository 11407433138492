import React from 'react';
import { Tooltip, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from '../../../../../components/Button';
import ProductColors from '../../../../../constants/ProductColors';
import { removeRulesRow, promotionFieldIsDisabled } from '~/util/Promotion';

import { ContainerColor, ColorCircle } from './styles';
import { PROMOTION_EDIT_MODE } from './constants';

const { Text } = Typography;

const getStyle = color => {
  let background = '';

  if (color.url) {
    background = `url(${process.env.PUBLIC_URL}/images/colors/${color.url})`;
  } else {
    background = color.hex;
  }

  return {
    marginRight: 10,
    width: '20px',
    height: '20px',
    border: '1px solid rgba(0,0,0,0.2)',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    borderRadius: '50%',
    background,
  };
};

export function generateColorColumns({ tableKey, dispatch }) {
  const isEdit = window.location.pathname.includes(PROMOTION_EDIT_MODE);

  const data = [
    {
      title: 'Cores',
      dataIndex: 'title',
      key: 'name',
      render: (text, record) => {
        return (
          <ContainerColor key={record.key}>
            <ColorCircle
              showDelete={false}
              style={getStyle(ProductColors[record.color])}
            />
            <Text>{ProductColors[record.color].label}</Text>
          </ContainerColor>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (text, record) => (
        <div key={record.key}>
          <Tooltip placement="bottom" title="Remover">
            <Button
              data-testid="deleteBtn"
              type="link"
              icon={<CloseOutlined />}
              size="small"
              disabled={isEdit}
              style={{ margin: 0, color: promotionFieldIsDisabled() }}
              onClick={removeRulesRow(dispatch, tableKey, record.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return data;
}
