export const CKEDITOR_CONFIG = {
  toolbarGroups: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools'],
    },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    {
      name: 'editing',
      groups: ['find', 'selection', 'spellchecker', 'editing'],
    },
    { name: 'forms', groups: ['forms'] },
    {
      name: 'basicstyles',
      groups: ['basicstyles', 'cleanup'],
    },
    {
      name: 'paragraph',
      groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
    },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
  ],
  removeButtons:
    'Source,Save,Templates,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language,CreateDiv,Blockquote,Outdent,Indent,BulletedList,NumberedList,Image,Flash,Table,Smiley,SpecialChar,PageBreak,Iframe,ShowBlocks,Maximize,About,PasteFromWord,Anchor,Print,Preview,NewPage',
};
