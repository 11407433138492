import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, DatePicker, Switch, Select, Radio } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  COUPON_CATEGORY_ATTENDANCE,
  COUPON_CATEGORY_PROMOTION,
  COUPON_KIND_BAG_ORDER,
  COUPON_KIND_CART,
} from '~/constants/Coupon';

const { Option } = Select;
const { TextArea } = Input;

function DefaultForm({ coupon, form, setCouponType }) {
  const [isUnlimeted, setIsUnlimeted] = useState(!(coupon?.usageLimit > 0));
  const [isUnlimetedCpf, setIsUnlimetedCpf] = useState(
    !(coupon?.usageLimitCpf > 0)
  );
  const [withoutExpiration, setWithoutExpiration] = useState(!coupon.expiresAt);

  useEffect(() => {
    if (withoutExpiration) form.resetFields(['expiresAt']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withoutExpiration]);

  useEffect(() => {
    if (isUnlimeted) form.resetFields(['usageLimit']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnlimeted]);

  useEffect(() => {
    if (isUnlimetedCpf) form.resetFields(['usageLimitCpf']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnlimetedCpf]);

  const handleToggleChange = (
    value,
    changeValue,
    formFieldName,
    formFieldNameToReset = undefined
  ) => {
    const newValue = !value;
    changeValue(newValue);
    if (formFieldNameToReset) form.resetFields([formFieldNameToReset]);
    form.setFieldValue(formFieldName, newValue);
  };

  const handleCouponType = ({ target: { value } }) => setCouponType(value);

  return (
    <>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col md={8} xs={24}>
          <Form.Item
            label="Nome"
            name="name"
            initialValue={coupon.name}
            rules={[
              {
                required: true,
                message: 'Digite o nome',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="defaultCouponFormName" />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label="Cupom"
            name="code"
            initialValue={coupon.code}
            rules={[
              {
                required: true,
                message: 'Digite o cupom',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="defaultCouponFormCode" />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label="Área"
            name="category"
            initialValue={coupon.category}
            rules={[
              {
                required: true,
                message: 'Escolha qual área',
              },
            ]}
          >
            <Select
              data-testid="defaultCouponFormArea"
              style={{ width: '100%' }}
            >
              <Option
                data-testid="defaultCouponFormArea-option"
                value={COUPON_CATEGORY_ATTENDANCE}
              >
                Atendimento
              </Option>
              <Option value={COUPON_CATEGORY_PROMOTION}>Promoção</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="center"
        style={{ marginTop: '10px' }}
      >
        <Col md={8} xs={24}>
          <Form.Item
            label="Início em"
            name="startsAt"
            initialValue={
              coupon.startsAt ? moment(coupon.startsAt, 'YYYY-MM-DD') : null
            }
            rules={[
              {
                required: true,
                message: 'Escolha a data de início',
              },
            ]}
          >
            <DatePicker
              data-testid="defaultCouponFormStartsAt"
              format="DD/MM/YYYY"
              disabledDate={day => day.isBefore(moment(), 'day')}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label="Válido até"
            name="expiresAt"
            initialValue={
              !withoutExpiration && coupon.expiresAt
                ? moment(coupon.expiresAt, 'YYYY-MM-DD')
                : null
            }
            rules={[
              {
                required: !withoutExpiration,
                message: 'Escolha a data de validade',
              },
            ]}
          >
            <DatePicker
              data-testid="defaultCouponFormExpiresAt"
              format="DD/MM/YYYY"
              disabledDate={day => day.isBefore()}
              disabled={withoutExpiration}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label=" "
            labelCol={{ md: { span: 24 }, sm: { span: 0 }, xs: { span: 0 } }}
            name="withoutExpiration"
            initialValue={withoutExpiration}
            valuePropName="checked"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <>
              <Switch
                data-testid="defaultCouponFormExpirationToggle"
                onChange={() =>
                  handleToggleChange(
                    withoutExpiration,
                    setWithoutExpiration,
                    'withoutExpiration',
                    'expiresAt'
                  )
                }
                defaultChecked={withoutExpiration}
                style={{ margin: '0px 10px 0px 0px' }}
              />{' '}
              Sem data de validade
            </>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" style={{ marginTop: '10px' }}>
        <Col md={8} xs={24}>
          <Form.Item
            label="Limite de uso"
            name="usageLimit"
            initialValue={
              !isUnlimeted && coupon.usageLimit ? coupon.usageLimit : ''
            }
            rules={[
              {
                pattern: new RegExp(/^[0-9]*$/),
                required: !isUnlimeted,
                message: 'Digite o limite de uso',
                whitespace: true,
              },
            ]}
          >
            <Input
              type="number"
              data-testid="defaultCouponFormUsageLimit"
              disabled={isUnlimeted}
              min={1}
              addonAfter={
                <Form.Item
                  name="usageLimitInfinity"
                  valuePropName="checked"
                  initialValue={isUnlimeted}
                  style={{ marginBottom: '0px' }}
                >
                  <>
                    <Switch
                      data-testid="defaultCouponFormUsageToggle"
                      onChange={() =>
                        handleToggleChange(
                          isUnlimeted,
                          setIsUnlimeted,
                          'usageLimitInfinity'
                        )
                      }
                      defaultChecked={isUnlimeted}
                    />{' '}
                    Sem limite
                  </>
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label="Limite de uso por CPF"
            name="usageLimitCpf"
            initialValue={
              !isUnlimetedCpf && coupon.usageLimitCpf
                ? coupon.usageLimitCpf
                : ''
            }
            rules={[
              {
                pattern: new RegExp(/^[0-9]*$/),
                required: !isUnlimetedCpf,
                message: 'Digite o limite de uso por CPF',
                whitespace: true,
              },
              ({ getFieldValue }) => ({
                validator() {
                  const usageLimitValue = parseInt(
                    getFieldValue('usageLimit'),
                    10
                  );
                  const usageLimitCpfValue = parseInt(
                    getFieldValue('usageLimitCpf'),
                    10
                  );
                  if (usageLimitValue && usageLimitCpfValue > usageLimitValue) {
                    return Promise.reject(
                      new Error(
                        'O limite de uso por CPF não pode ser maior que o limite de uso do cupom.'
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              type="number"
              data-testid="defaultCouponFormUsageLimitCpf"
              disabled={isUnlimetedCpf}
              min={1}
              addonAfter={
                <Form.Item
                  name="usageLimitInfinityCpf"
                  valuePropName="checked"
                  initialValue={isUnlimetedCpf}
                  style={{ marginBottom: '0px' }}
                >
                  <>
                    <Switch
                      data-testid="defaultCouponFormUsageCpfToggle"
                      onChange={() =>
                        handleToggleChange(
                          isUnlimetedCpf,
                          setIsUnlimetedCpf,
                          'usageLimitInfinityCpf'
                        )
                      }
                      defaultChecked={isUnlimetedCpf}
                    />{' '}
                    Sem limite
                  </>
                </Form.Item>
              }
            />
          </Form.Item>
        </Col>
        <Col md={8} xs={24}>
          <Form.Item
            label="Tipo de cupom"
            name="kind"
            initialValue={coupon.kind}
            rules={[
              {
                message: 'Selecione um tipo de cupom',
              },
            ]}
          >
            <Radio.Group
              data-testid="defaultCouponFormKindSelect"
              style={{ display: 'flex', widht: '100%' }}
              onChange={handleCouponType}
            >
              {/* <Radio.Button value={COUPON_KIND_BOTH}>Ambos</Radio.Button> */}
              <Radio.Button
                data-testid="cart"
                value={COUPON_KIND_CART}
                style={{ flex: 1, textAlign: 'center' }}
              >
                Pedido
              </Radio.Button>
              <Radio.Button
                data-testid="bag_order"
                value={COUPON_KIND_BAG_ORDER}
                style={{ flex: 1, textAlign: 'center' }}
                disabled
              >
                Sacola
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="end">
        <Col span={24}>
          <Form.Item
            label="Descrição"
            name="description"
            initialValue={coupon.description}
            rules={[
              {
                message: 'Digite a descrição',
                whitespace: true,
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 4, maxRows: 10 }}
              data-testid="defaultCouponFormDescription"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

DefaultForm.propTypes = {
  coupon: PropTypes.shape(),
  form: PropTypes.instanceOf(Object).isRequired,
  setCouponType: PropTypes.func.isRequired,
};

DefaultForm.defaultProps = {
  coupon: {},
};

export default DefaultForm;
