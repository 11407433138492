import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Tooltip, Typography, Divider } from 'antd';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import LoadingBar from '~/components/LoadingBar';
import history from '~/services/history';
import { triggerErrorNotification } from '~/util/Functions';

import {
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
  CATEGORY_BY_ID,
} from '~/apollo/bag/category';
import DefaultForm from './DefaultForm';

import { loadCategories, handleSubmitDefaultForm } from './actions';

const { Title } = Typography;

function CategoryForm({ match }) {
  const [isEdit, setIsEdit] = useState(match.url.includes('edit'));
  const [error, setError] = useState(null);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [category, setCategory] = useState({
    id: isEdit ? Number(match.params.id) || null : null,
    default: {
      active: false,
      fixed: false,
      metaDescription: '',
      metaKeywords: '',
      metaTitle: '',
      name: '',
      parentId: !isEdit ? Number(match.params.id) || null : null,
      permalink: '',
      productCount: 0,
    },
    optionTypes: [],
    properties: [],
  });

  const [createCategory] = useMutation(CATEGORY_CREATE, {
    context: {
      newBackend: true,
    },
  });

  const [updateCategory] = useMutation(CATEGORY_UPDATE, {
    context: {
      newBackend: true,
    },
  });

  const [categoryById, { data }] = useLazyQuery(CATEGORY_BY_ID, {
    context: {
      newBackend: true,
    },
    variables: {
      id: Number(match.params.id),
    },
    onError: errorSearch => {
      triggerErrorNotification(
        'Erro ao buscar dados da categoria',
        errorSearch
      );
      setError(errorSearch);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (isEdit) {
      categoryById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    loadCategories({ data, setCategory, setLoading });
  }, [data]);

  useEffect(() => {
    if (redirectToEdit) {
      setIsEdit(true);
      setRedirectToEdit(false);
    }
  }, [redirectToEdit]);

  if (isEdit && loading) return <LoadingBar />;
  if (isEdit && error) return `Error! ${error.message}`;

  if (redirectToEdit) {
    return <Redirect to={`/manage/content/category/edit/${category.id}`} />;
  }

  const callSubmitDefaultForm = values => {
    handleSubmitDefaultForm(values, {
      isEdit,
      createCategory,
      updateCategory,
      category,
      setCategory,
      setRedirectToEdit,
    });
  };

  return (
    <Card
      data-testid="categoryCardForm"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para categoria">
            <ArrowLeftOutlined
              onClick={history.goBack}
              style={{ marginRight: 10 }}
              data-testid="backToBtn"
            />
          </Tooltip>
          {isEdit ? 'EDITAR CATEGORIA' : 'NOVA CATEGORIA'}
        </>
      }
    >
      <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
        Informações
      </Title>
      <Divider />
      <DefaultForm
        handleSubmit={callSubmitDefaultForm}
        initialData={category ? category.default : undefined}
        optionTypes={category ? category.optionTypes : undefined}
        properties={category ? category.properties : undefined}
        setCategory={setCategory}
        isEdit={isEdit}
      />
    </Card>
  );
}

CategoryForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default CategoryForm;
