export const THEME_BEHAVIOR = {
  theme: {
    // default color: #3acbc7
    color: '#3acbc7',
    borderRadiusHard: '8px',
    borderRadius: '6px',
    borderRadiusLow: '4px',
  },

  menu: {
    // if '' => color = theme.color
    color: '',
  },

  mobileHeader: {
    bgColor: '#FFF',
    icon: {
      color: '#535353',
      size: '20px',
    },
  },

  formField: {
    height: '36px',
    outline: 0,
    boxShadow: 'inherit',
  },

  form: {
    field: {
      height: '36px',
      outline: 0,
      boxShadow: 'inherit',
    },
    formSwitch: {
      // if '' => color = theme.color
      color: '',
    },
  },

  card: {
    title: {
      fontSize: '16px',
      fontWeight: 400,
      color: '#535353',
    },
    icon: {
      color: '#535353',
      size: '1em',
    },
  },

  button: {
    height: '36px',

    default: {
      // if color = '' => color = theme.color
      color: '',
      borderColor: '',
      bgColor: '#FFF',
    },

    success: {
      // if bgColor = '' => color = theme.color
      bgColor: '',
      color: '#FFF',
    },

    primary: {
      bgColor: '#0095ff',
      color: '#FFF',
    },

    danger: {
      bgColor: '#ff4d4f',
      color: '#FFF',
    },

    dangerous: {
      bgColor: '#FFF',
      color: '#ff4d4f',
    },

    icon: {
      bgColor: '#fafafa',
      color: '#000',
    },

    disabled: {
      bgColor: '#9c9c9c',
      bgColorSecondary: 'rgba(26, 26, 26, 0.5)',
      color: '#1a1a1a',
    },
  },
};
