import React from 'react';
import { Button as ButtonAnt } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getStyle } from './utils';

const Button = ({ style, type, spacing, disabled, ...restOfProps }) => {
  return (
    <ButtonAnt
      data-testid="button-ant"
      type={type}
      style={{ ...getStyle({ type, spacing, disabled }), ...style }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restOfProps}
    />
  );
};

export const CustomButton = styled(Button)`
  @media (max-width: 400px) {
    width: ${props => (props.full ? `${props.full}%` : '')};
  }
`;

Button.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  spacing: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  style: {},
  spacing: true,
  disabled: false,
};

export default Button;
