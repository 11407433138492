import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import { CustomButton, CustomCard } from './styles';

function DefaultForm(props) {
  const { fieldName, fieldId, initialValue, handleSubmit, placeholder } = props;

  const [form] = Form.useForm();

  const onFinish = values => {
    handleSubmit(values);
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar conteudo de Header Padrão',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="defaultHeaderForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <CustomCard hoverable>
        <Row gutter={16} type="flex" align="middle" justify="center">
          <Col xl={18} lg={18} md={18} xs={24}>
            <Form.Item
              label={fieldName}
              name={fieldId}
              initialValue={initialValue.title}
              rules={[
                {
                  required: true,
                  message: `${fieldName} é obrigatório e precisa ter 255 ou menos caracteres`,
                  whitespace: true,
                },
              ]}
            >
              <Input
                data-testid="defaultHeaderInput"
                placeholder={placeholder}
              />
            </Form.Item>
          </Col>
          <Col xl={6} lg={6} md={6} xs={24}>
            <Form.Item>
              <CustomButton>
                <Button
                  data-testid="defaultHeaderButton"
                  type="success"
                  block
                  htmlType="submit"
                  icon={<SaveOutlined />}
                  spacing={false}
                >
                  Salvar
                </Button>
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </CustomCard>
    </Form>
  );
}

DefaultForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  initialValue: PropTypes.shape({
    title: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  handleSubmit: props => {
    const { handleSubmit } = props;
    if (handleSubmit === undefined || typeof handleSubmit !== 'function') {
      return new Error('Please provide a handleSubmit function!');
    }
    return null;
  },
};

DefaultForm.defaultProps = {
  handleSubmit: undefined,
  placeholder: '',
  initialValue: {
    text: '',
  },
};

export default DefaultForm;
