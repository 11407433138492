import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Tooltip, Row, Col, Typography, Alert } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Button from '~/components/Button';

import history from '~/services/history';
import { formatActionsForm } from '~/pages/marketing/coupon/CouponForm/utils/actions';
import { formattedRulesForm } from '~/pages/marketing/coupon/CouponForm/utils/rules';
import useCreateCoupon from '~/hooks/coupon/useCreateCoupon';
import {
  COUPON_CATEGORY_ATTENDANCE,
  COUPON_KIND_CART,
} from '~/constants/Coupon';
import useUpdateCoupon from '~/hooks/coupon/useUpdateCoupon';
import {
  closeAllNotifications,
  getErrorMessage,
  notifyError,
  notifySuccess,
} from '~/util/Functions';
import useSearchCoupon from '~/hooks/coupon/useSearchCoupon';
import LoadingBar from '~/components/LoadingBar';
import ActionsForm from './ActionsForm';
import RulesForm from './RulesForm';
import DefaultForm from './DefaultForm';

const { Title } = Typography;

const titleCss = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
  width: '100%',
  display: 'block',
  marginBottom: '10px',
  fontWeight: 'normal',
};

const actions = ['free_shipping', 'order_adjust'];
const rules = [
  'category',
  'first_order',
  'location',
  'order_total',
  'product',
  'single_use',
  'user',
];

const CompleteForm = ({ coupon: initialCouponValues, isEdit }) => {
  const [form] = Form.useForm();
  const [coupon, setCoupon] = useState({
    category: COUPON_CATEGORY_ATTENDANCE,
    kind: COUPON_KIND_CART,
    ...initialCouponValues,
  });
  const [couponType, setCouponType] = useState(
    initialCouponValues?.kind || COUPON_KIND_CART
  );
  const [redirectToList, setRedirectToList] = useState(false);
  const [ruleFlags, setRuleFlags] = useState({
    orderTotal: false,
    product: false,
    user: false,
    firstOrder: false,
    quantityItems: false,
  });
  const [actionsFlags, setActionsFlags] = useState({
    orderAdjust: false,
    freeShipping: false,
  });
  const [errors, setErrors] = useState(null);

  const getErrorMessages = err => {
    const errorMessage = getErrorMessage(err);
    const actionsErrors = errorMessage[0]
      ?.split(' ')
      .filter(word => actions.includes(word));
    const rulesErrors = errorMessage[0]
      ?.split(' ')
      .filter(word => rules.includes(word));
    return {
      actionsErrors,
      rulesErrors,
    };
  };
  const [createCoupon, { loadingCreate }] = useCreateCoupon({
    onCompleted: ({ couponCreateCoupon }) => {
      setCoupon({ id: coupon ? coupon.id : -1, ...couponCreateCoupon });
      setRedirectToList(true);
      notifySuccess('Sucesso!', 'Cupom criado com sucesso');
    },
    onError: err => {
      notifyError('Erro ao criar cupom', err);
      const errorMessages = getErrorMessages(err);
      setErrors(errorMessages);
    },
  });

  const [updateCoupon, { loadingUpdate }] = useUpdateCoupon({
    onCompleted: () => {
      setRedirectToList(true);
      notifySuccess('Sucesso!', 'Cupom atualizado com sucesso');
    },
    onError: err => {
      notifyError('Erro ao atualizar cupom', err);
      const errorMessages = getErrorMessages(err);
      setErrors(errorMessages);
    },
  });

  useEffect(() => {
    if (redirectToList) {
      history.replace(`/manage/marketing/coupons`);
    }

    return () => setRedirectToList(false);
  }, [redirectToList]);

  const handleSubmitForm = () => form.submit();

  const onFinish = () => {
    const values = form.getFieldsValue(true);
    closeAllNotifications();
    if (!isEdit) {
      createCoupon({
        actions: formatActionsForm(values.actions),
        andOrOperator: values.ruleOption,
        category: values.category,
        code: values.code,
        description: values.description,
        expiresAt: moment(values.expiresAt).toISOString() || null,
        kind: values.kind,
        name: values.name,
        rules: formattedRulesForm(values.rules),
        startsAt: moment(values.startsAt).toISOString(),
        usageLimit: !values.usageLimitInfinity
          ? parseInt(values.usageLimit, 10)
          : 0,
        usageLimitCpf: !values.usageLimitInfinityCpf
          ? parseInt(values.usageLimitCpf, 10)
          : 0,
      });
    } else {
      updateCoupon({
        couponId: coupon.id,
        actions: formatActionsForm(values.actions),
        andOrOperator: values.ruleOption,
        category: values.category,
        code: values.code,
        description: values.description,
        expiresAt: moment(values.expiresAt).toISOString() || null,
        kind: values.kind,
        name: values.name,
        rules: formattedRulesForm(values.rules),
        startsAt: moment(values.startsAt).toISOString(),
        usageLimit: !values.usageLimitInfinity
          ? parseInt(values.usageLimit, 10)
          : 0,
        usageLimitCpf: !values.usageLimitInfinityCpf
          ? parseInt(values.usageLimitCpf, 10)
          : 0,
      });
    }
  };

  const onFinishFailed = ({ errorFields }) => {
    closeAllNotifications();
    errorFields.forEach(errorField => {
      notifyError('Um erro ocorreu', errorField.errors);
    });
  };
  return (
    <Form
      form={form}
      data-testid="defaultCouponForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <DefaultForm form={form} coupon={coupon} setCouponType={setCouponType} />
      <Row type="flex" align="top" justify="center" gutter={[16, 16]}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Title level={4} style={titleCss}>
            Regras
          </Title>
          <RulesForm
            errors={errors?.rulesErrors}
            couponType={couponType}
            form={form}
            rules={{
              initialRules: coupon?.rules,
              initialOperator: coupon?.andOrOperator,
            }}
            ruleFlags={ruleFlags}
            setRuleFlags={setRuleFlags}
          />
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Title level={4} style={titleCss}>
            Ações
          </Title>
          <ActionsForm
            isEdit={isEdit}
            errors={errors?.actionsErrors}
            form={form}
            actions={{
              initialActions: coupon?.actions,
            }}
            actionsFlags={actionsFlags}
            setActionsFlags={setActionsFlags}
          />
        </Col>
      </Row>
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="end"
        style={{ marginTop: '10px' }}
      >
        <Col>
          <Button
            loading={loadingCreate || loadingUpdate}
            data-testid="defaultCouponFormSubmit"
            type="success"
            onClick={handleSubmitForm}
            htmlType="button"
            icon={<SaveOutlined />}
            block
            style={{ margin: 0 }}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

CompleteForm.defaultProps = {
  coupon: {},
  isEdit: false,
};

CompleteForm.propTypes = {
  coupon: PropTypes.shape(),
  isEdit: PropTypes.bool,
};

function CouponForm({ match }) {
  const [isEdit] = useState(match.url.includes('edit'));
  const [searchCoupon, { data, loading, error }] = useSearchCoupon();
  const [couponId] = useState(Number(match.params.id));

  useEffect(() => {
    if (isEdit) {
      searchCoupon({
        first: 1,
        id: couponId,
        rulesAndActions: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingBar />;

  return (
    <Card
      data-testid="createCouponPage"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para listagem de coupons">
            <Link to="/manage/marketing/coupons">
              <ArrowLeftOutlined data-testid="backToListBtn" />
            </Link>
          </Tooltip>
          <span style={{ marginLeft: '10px' }}>
            {isEdit ? 'Editar cupom' : 'Criar novo cupom'}
          </span>
        </>
      }
    >
      {(isEdit && data?.length === 0) || error ? (
        <Alert
          message="Cupom não encontrado"
          description={
            error
              ? getErrorMessage(error)
              : 'Não foi encontrado nenhum cupom com o ID especificado!'
          }
          type={error ? 'error' : 'info'}
          showIcon
        />
      ) : (
        <CompleteForm isEdit={isEdit} coupon={data?.[0]} />
      )}
    </Card>
  );
}

CouponForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default CouponForm;
