import React, { useEffect, useState } from 'react';
import {
  Select,
  Form,
  Row,
  Col,
  TreeSelect,
  Typography,
  Divider,
  Badge,
} from 'antd';
import PropTypes from 'prop-types';
import {
  SEO_KIND_BRAND,
  SEO_KIND_CATEGORY,
  SEO_KIND_LIST,
} from '~/constants/Seo';
import useDebounce from '~/util/useDebounce';
import useSearchCategories from '~/hooks/categories/useSearchCategories';
import useSearchBrands from '~/hooks/brands/useSearchBrands';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';
import { groupCategoriesByParentId, translateKind } from '~/util/Seo';
import {
  BRAND_ID_NAME,
  CATEGORY_ID_NAME,
  KIND_NAME,
  getFieldLabelByName,
} from '../formLabels';

const { Option } = Select;
const { Text, Title } = Typography;

const BRAND_SEARCH_BY_NAME = 'search_by_name';
const BRAND_SEARCH_BY_SLUG = 'search_by_slug';
const BRAND_SEARCH_BY_ID = 'search_by_id';
function KindSelector({ initialData, form }) {
  const debounce = useDebounce();
  const [selectedKind, setSelectedKind] = useState(initialData?.kind);
  const [selectedBrandSearchType, setSelectedBrandSearchType] =
    useState(BRAND_SEARCH_BY_NAME);
  const [treeData, setTreeData] = useState(null);

  const kindQueryValidator = ({ error, items, message }) => {
    return (_rule, value) => {
      if (error || (items?.length === 0 && !value)) {
        return Promise.reject(new Error(message));
      }
      return Promise.resolve();
    };
  };

  const {
    data: brands,
    loading: loadingBrands,
    error: errorBrands,
    refetch: refetchBrands,
  } = useSearchBrands({
    variables: {
      full: false,
      first: DEFAULT_PAGE_SIZE,
      id:
        initialData?.kind === SEO_KIND_BRAND
          ? initialData?.contentId
          : undefined,
    },
  });

  const {
    data: categories,
    loading: loadingCategories,
    error: errorCategories,
  } = useSearchCategories({
    variables: {
      full: false,
      onlyActive: false,
    },
  });

  useEffect(() => {
    if (!loadingCategories && !errorCategories) {
      if (categories?.length > 0) {
        const categoryMenu = groupCategoriesByParentId(categories);
        setTreeData(categoryMenu);
      } else {
        setTreeData([]);
      }
    }

    if (!loadingCategories) {
      form.validateFields([CATEGORY_ID_NAME]);
    }
  }, [categories, loadingCategories, errorCategories]);

  useEffect(() => {
    if (!loadingBrands) {
      form.validateFields([BRAND_ID_NAME]);
    }
  }, [loadingBrands]);

  const onSearchBrand = searchText => {
    if (!searchText) return;

    debounce(() => {
      refetchBrands({
        full: false,
        first: DEFAULT_PAGE_SIZE,
        name:
          selectedBrandSearchType === BRAND_SEARCH_BY_NAME
            ? searchText
            : undefined,
        slugs:
          selectedBrandSearchType === BRAND_SEARCH_BY_SLUG
            ? [searchText]
            : undefined,
        id:
          selectedBrandSearchType === BRAND_SEARCH_BY_ID
            ? Number(searchText)
            : undefined,
      })
        .then(() => {
          /* Não faz nada, pois estou usando o "error" e "data" retornandos no hooks para tratar */
        })
        .catch(() => {
          /* Não faz nada, pois estou usando o "error" e "data" retornandos no hooks para tratar */
        });
    }, 500);
  };

  const handleChangeKind = value => {
    setSelectedKind(value);
  };

  const handleChangeBrandSearchType = value => {
    setSelectedBrandSearchType(value);
  };

  return (
    <>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Conteúdo Associado
          </Title>
          <Divider style={{ marginTop: '5px' }} />
        </Col>
      </Row>
      <Row gutter={[16, 0]} align="top">
        <Col xl={4} lg={4} md={4} xs={24}>
          <Form.Item
            label={getFieldLabelByName(KIND_NAME)}
            name={KIND_NAME}
            rules={[
              {
                required: true,
                whitespace: false,
              },
            ]}
          >
            <Select
              data-testid="selectSeoType"
              onChange={handleChangeKind}
              loading={loadingCategories}
            >
              {SEO_KIND_LIST.map(kindOption => (
                <Option key={kindOption} value={kindOption}>
                  {translateKind(kindOption)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={20} lg={20} md={20} xs={24}>
          {selectedKind === SEO_KIND_CATEGORY && (
            <Form.Item
              initialValue={
                initialData?.kind === SEO_KIND_CATEGORY
                  ? initialData?.contentId
                  : undefined
              }
              label={getFieldLabelByName(CATEGORY_ID_NAME)}
              name={CATEGORY_ID_NAME}
              rules={[
                {
                  validator: kindQueryValidator({
                    error: errorCategories,
                    items: categories,
                    message: !errorCategories
                      ? `Selecione uma ${translateKind(selectedKind)}`
                      : `Erro na requisição ao buscar ${translateKind(
                          selectedKind
                        )}`,
                  }),
                },
              ]}
            >
              <TreeSelect
                data-testid="selectedCategory"
                loading={loadingCategories || !treeData}
                multiple={false}
                treeData={treeData}
                showCheckedStrategy="SHOW_PARENT"
                placeholder="Please select"
              />
            </Form.Item>
          )}
          {selectedKind === SEO_KIND_BRAND && (
            <Row gutter={[16, 0]} align="top" justify="start">
              <Col xl={6} lg={6} md={6} xs={24}>
                <Form.Item
                  label="Busca por"
                  name="brandSearchBy"
                  initialValue={BRAND_SEARCH_BY_NAME}
                  rules={[
                    {
                      required: true,
                      message: `Selecione uma opção de busca`,
                    },
                  ]}
                >
                  <Select
                    data-testid="selectBrandSearchType"
                    onChange={handleChangeBrandSearchType}
                    loading={loadingBrands}
                  >
                    <Option value={BRAND_SEARCH_BY_NAME}>Nome</Option>
                    <Option value={BRAND_SEARCH_BY_SLUG}>Slug</Option>
                    <Option value={BRAND_SEARCH_BY_ID}>ID</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={18} lg={18} md={18} xs={24}>
                <Form.Item
                  label={getFieldLabelByName(BRAND_ID_NAME)}
                  name={BRAND_ID_NAME}
                  initialValue={
                    initialData?.kind === SEO_KIND_BRAND
                      ? initialData?.contentId
                      : undefined
                  }
                  rules={[
                    {
                      validator: kindQueryValidator({
                        error: errorBrands,
                        items: brands,
                        message: !errorBrands
                          ? `Selecione uma ${translateKind(selectedKind)}`
                          : `Erro na requisição ao buscar ${translateKind(
                              selectedKind
                            )}`,
                      }),
                    },
                  ]}
                >
                  <Select
                    data-testid="selectedBrand"
                    loading={loadingBrands}
                    showSearch
                    filterOption={false}
                    onSearch={onSearchBrand}
                    placeholder={`Selecione uma ${translateKind(selectedKind)}`}
                    options={brands.map(b => ({
                      value: Number(b.id),
                      label: (
                        <Text>
                          {b.name}{' '}
                          <Badge
                            status={b.active ? 'success' : 'error'}
                            title={b.active ? 'Ativa' : 'Inativa'}
                          />{' '}
                          <small>
                            <Text type="secondary">
                              ({b.active ? 'Ativa' : 'Inativa'})
                            </Text>
                          </small>{' '}
                          <small>
                            <Text type="secondary">
                              (<b>Slug:</b> {b.slug}){' '}
                            </Text>
                            <Text type="secondary">
                              (<b>Produtos:</b> {b.productCount})
                            </Text>
                          </small>
                        </Text>
                      ),
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
}

KindSelector.propTypes = {
  initialData: PropTypes.shape({
    id: PropTypes.number,
    contentId: PropTypes.number,
    contentName: PropTypes.string,
    kind: PropTypes.oneOf(SEO_KIND_LIST),
  }).isRequired,
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
  }).isRequired,
};

export default KindSelector;
