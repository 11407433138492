import gql from 'graphql-tag';

export const OPTIONTYPES_PROPERTIES_LIST = gql`
  query optionTypesPropertiesList($first: Int, $name: String) {
    propertyProductSearchProperties(first: $first, name: $name) {
      nodes {
        id
        name
        kind
        position
        presentation
        required
      }
    }
    optionTypesProductSearchOptionTypes(first: $first, name: $name) {
      nodes {
        id
        kind
        name
        position
        presentation
        required
      }
    }
  }
`;

export const PRODUCT_CREATE = gql`
  mutation createProduct($bagId: Int!, $qty: Int) {
    productCreateProduct(bagId: $bagId, qty: $qty) {
      product {
        bagId
        id
        userId
      }
    }
  }
`;

export const PRODUCT_SEARCH_BY_ID = gql`
  query searchProductById($id: Int) {
    productsProductDatabaseSearchProducts(id: $id) {
      nodes {
        age
        bagId
        barcode
        brand {
          id
          name
        }
        categories {
          id
          name
          optionTypes
          parentId
          properties
        }
        clicks
        costPrice
        currentState
        description
        discount
        gender
        id
        images {
          id
          imageUrl
          position
        }
        kind
        name
        ongId
        ongPercentage
        originalPrice
        price
        properties {
          kind
          name
          position
          presentation
          required
        }
        salePercent
        salePrice
        saleState
        slug
        subsidy
        userId
        warehouse
      }
      totalCount
    }
  }
`;

export const PRODUCT_SEARCH_BY_BARCODE = gql`
  query searchProductByBarcode($barcode: String) {
    productsProductDatabaseSearchProducts(barcode: $barcode) {
      nodes {
        age
        bagId
        barcode
        brand {
          id
          name
        }
        categories {
          id
          name
        }
        clicks
        costPrice
        currentState
        description
        discount
        gender
        id
        images {
          id
          imageUrl
          position
        }
        name
        ongId
        ongPercentage
        originalPrice
        price
        salePercent
        salePrice
        saleState
        slug
        subsidy
        userId
        warehouse
      }
      totalCount
    }
  }
`;

export const PRODUCT_SEARCH = gql`
  query listProducts(
    $first: Int
    $name: String
    $before: String
    $after: String
    $barcode: String
    $bagId: Int
    $skip: Int
    $orderBy: ProductOrder
    $returnUser: Boolean!
    $ids: [Int!]
  ) {
    productsProductDatabaseSearchProducts(
      first: $first
      name: $name
      barcode: $barcode
      bagId: $bagId
      before: $before
      after: $after
      skip: $skip
      orderBy: $orderBy
      ids: $ids
    ) {
      nodes {
        id
        name
        bagId
        barcode
        userId @include(if: $returnUser)
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
    }
  }
`;
