import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Descriptions, Row, Steps } from 'antd';

import {
  getOrderStatus,
  getPaymentStatus,
  getShipmentDispatchStatus,
  getShipmentStatus,
} from '~/util/statusConverter';
import { convertDate, convertHour } from '~/util/Functions';

import {
  SHIPMENT_STATUSES,
  SHIPMENT_COMPLETED as S_COMPLETED,
  SHIPMENT_DELIVERED as S_DELIVERED,
  SHIPMENT_DELIVERY_NOT_MADE as S_DELIVERY_NOT_MADE,
  SHIPMENT_ON_ROUTE as S_ON_ROUTE,
  SHIPMENT_SEND as S_SEND,
  SHIPMENT_WAITING_SENT as S_WAITING_SENT,
} from '~/constants/Shipment';
import {
  PAYMENT_STATUSES,
  STATUS_CREATED as P_CREATED,
  STATUS_IN_ANALYSIS as P_IN_ANALYSIS,
  STATUS_PAID as P_PAID,
  STATUS_REFUNDED as P_REFUNDED,
  STATUS_REVERSED as P_REVERSED,
  STATUS_WAITING as P_WAITING,
  STATUS_CANCELED as P_CANCELED,
  STATUS_EXPIRED as P_EXPIRED,
} from '~/constants/Payment';
import {
  ORDER_KIND_DEVOLUTION,
  ORDER_KIND_ORIGINAL,
  ORDER_STATUSES,
  STATUS_CANCELED as O_CANCELED,
  STATUS_COMPLETED as O_COMPLETED,
  STATUS_PENDING as O_PENDING,
} from '~/constants/Order';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_CANCELED,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
  SHIPMENT_DISPATCH_STATUS,
} from '~/constants/ShipmentDispatch';

const { Step } = Steps;

const STEP_WAIT = 'wait';
const STEP_PROCESS = 'process';
const STEP_FINISH = 'finish';
const STEP_ERROR = 'error';

const STEP_LAYOUT_HORIZONTAL = 'horizontal';
const STEP_LAYOUT_VERTICAL = 'vertical';

const INITIAL_STEP_STATE = {
  status: STEP_WAIT,
  date: null,
};

function OrderStatusSteps({ order, showDescription }) {
  const [step1, setStep1] = useState(INITIAL_STEP_STATE);
  const [step2, setStep2] = useState(INITIAL_STEP_STATE);
  const [step3, setStep3] = useState(INITIAL_STEP_STATE);
  const [step4, setStep4] = useState(INITIAL_STEP_STATE);
  const [step5, setStep5] = useState(INITIAL_STEP_STATE);
  const [stepLayout, setStepLayout] = useState(STEP_LAYOUT_HORIZONTAL);

  const orderStatus = useMemo(
    () =>
      order?.orderStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        order?.orderStatus[0]
      ),
    [order?.orderStatus]
  );

  const orderStatusCreated = order?.orderStatus.find(
    item => item.kind === O_PENDING
  );

  const paymentStatus = useMemo(
    () =>
      order?.paymentStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        order?.paymentStatus[0]
      ),
    [order?.paymentStatus]
  );

  const shipmentStatus = useMemo(
    () =>
      order?.shipmentStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        order?.shipmentStatus[0]
      ),
    [order?.shipmentStatus]
  );

  const dispatchStatus = useMemo(
    () =>
      order?.shipmentDispatchStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        order?.shipmentDispatchStatus[0]
      ),
    [order?.shipmentStatus]
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window?.innerWidth < 1366) {
        setStepLayout(STEP_LAYOUT_VERTICAL);
      } else {
        setStepLayout(STEP_LAYOUT_HORIZONTAL);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const getDispatchStatusDescription = () => {
    if (
      [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
      !dispatchStatus?.kind &&
      !!shipmentStatus?.kind
    )
      return getShipmentDispatchStatus(SHIPMENT_DISPATCH_PROCESSED);

    return getShipmentDispatchStatus(dispatchStatus?.kind);
  };

  useEffect(() => {
    if (order) {
      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_CREATED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_PROCESS });
      }

      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_WAITING, P_IN_ANALYSIS].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_PAID].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
      }

      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_PAID].includes(paymentStatus?.kind) &&
        [S_WAITING_SENT].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [O_PENDING, O_CANCELED, O_COMPLETED].includes(orderStatus?.kind) &&
        [P_CANCELED, P_EXPIRED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_ERROR });
      }

      if (
        [O_PENDING, O_CANCELED, O_COMPLETED].includes(orderStatus?.kind) &&
        [P_PAID].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
      }

      if (
        [O_PENDING, O_CANCELED].includes(orderStatus?.kind) &&
        [P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
      }

      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_SEND, S_ON_ROUTE].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [O_PENDING, O_CANCELED].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERY_NOT_MADE].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_ERROR });
      }

      if (
        [O_PENDING].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED, S_COMPLETED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: null, status: STEP_PROCESS });
      }

      if (
        [O_COMPLETED].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED, S_COMPLETED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: orderStatus?.date, status: STEP_FINISH });
      }

      if (
        [O_CANCELED].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED, S_COMPLETED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: orderStatusCreated?.date, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: orderStatus?.date, status: STEP_ERROR });
      }

      if (
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        !dispatchStatus?.kind &&
        !!shipmentStatus?.kind
      ) {
        setStep3({ date: paymentStatus?.date, status: STEP_FINISH });
      }

      if (
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [SHIPMENT_DISPATCH_PROCESSED].includes(dispatchStatus?.kind)
      ) {
        setStep3({ date: dispatchStatus.date, status: STEP_FINISH });
      }

      if (
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [SHIPMENT_DISPATCH_AWAITING, SHIPMENT_DISPATCH_PROCESSING].includes(
          dispatchStatus?.kind
        )
      ) {
        setStep3({ date: dispatchStatus.date, status: STEP_PROCESS });
      }

      if (
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [SHIPMENT_DISPATCH_CANCELED].includes(dispatchStatus?.kind)
      ) {
        setStep3({ date: dispatchStatus.date, status: STEP_ERROR });
      }

      if ([P_CANCELED, P_EXPIRED].includes(paymentStatus?.kind)) {
        setStep3(INITIAL_STEP_STATE);
      }
    }
  }, [order]);

  const renderLastStepLabel = kind => {
    if (kind === O_CANCELED) return 'Pedido Cancelado';
    if (kind === O_COMPLETED) return 'Pedido Finalizado';
    return 'Pedido Aguardando Finalização';
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Steps
          data-testid="orderStep"
          responsive
          labelPlacement="vertical"
          size="small"
          direction={stepLayout}
        >
          <Step
            title="Pedido Realizado"
            description={
              step1?.date && (
                <>
                  {convertDate(step1?.date)} <br /> {convertHour(step1?.date)}
                </>
              )
            }
            status={step1?.status}
          />
          <Step
            title="Pagamento"
            subTitle={getPaymentStatus(paymentStatus?.kind)}
            description={
              step2?.date && (
                <>
                  {convertDate(step2?.date)} <br /> {convertHour(step2?.date)}
                </>
              )
            }
            status={step2?.status}
          />
          {order?.kind !== ORDER_KIND_DEVOLUTION && (
            <Step
              title="Expedição"
              subTitle={getDispatchStatusDescription()}
              description={
                step3?.date && (
                  <>
                    {convertDate(step3?.date)} <br /> {convertHour(step3?.date)}
                  </>
                )
              }
              status={step3?.status}
            />
          )}
          <Step
            title="Envio"
            subTitle={getShipmentStatus(
              shipmentStatus?.kind,
              order?.kind || ORDER_KIND_ORIGINAL
            )}
            description={
              step4?.date && (
                <>
                  {convertDate(step4?.date)} <br /> {convertHour(step4?.date)}
                </>
              )
            }
            status={step4?.status}
          />
          <Step
            title={
              step5?.status === STEP_FINISH
                ? 'Pedido Finalizado'
                : renderLastStepLabel(orderStatus.kind)
            }
            description={
              step5?.date && (
                <>
                  {convertDate(step5?.date)} <br /> {convertHour(step5?.date)}
                </>
              )
            }
            status={step5?.status}
          />
        </Steps>
      </Col>
      {showDescription && (
        <Col span={24}>
          <Descriptions
            layout="vertical"
            bordered
            column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
          >
            <Descriptions.Item label="Status do pedido">
              {getOrderStatus(orderStatus?.kind)}
            </Descriptions.Item>
            <Descriptions.Item label="Status do pagamento">
              {getPaymentStatus(paymentStatus?.kind)}
            </Descriptions.Item>
            <Descriptions.Item label="Status de expedição">
              {getShipmentDispatchStatus(dispatchStatus?.kind)}
            </Descriptions.Item>
            <Descriptions.Item label="Status do envio">
              {getShipmentStatus(
                shipmentStatus?.kind,
                order?.kind || ORDER_KIND_ORIGINAL
              )}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      )}
    </Row>
  );
}

OrderStatusSteps.defaultProps = {
  showDescription: true,
};

OrderStatusSteps.propTypes = {
  showDescription: PropTypes.bool,
  order: PropTypes.shape({
    kind: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    currentStatus: PropTypes.oneOf(ORDER_STATUSES).isRequired,
    orderStatus: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        kind: PropTypes.oneOf(ORDER_STATUSES).isRequired,
      }).isRequired
    ).isRequired,
    currentPaymentStatus: PropTypes.oneOf(PAYMENT_STATUSES).isRequired,
    paymentStatus: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        kind: PropTypes.oneOf(PAYMENT_STATUSES).isRequired,
      }).isRequired
    ).isRequired,
    currentShipmentStatus: PropTypes.oneOf(SHIPMENT_STATUSES),
    shipmentStatus: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        kind: PropTypes.oneOf(SHIPMENT_STATUSES).isRequired,
      }).isRequired
    ),
    currentShipmentDispatchStatus: PropTypes.oneOf(SHIPMENT_DISPATCH_STATUS),
    shipmentDispatchStatus: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        kind: PropTypes.oneOf(SHIPMENT_DISPATCH_STATUS).isRequired,
      }).isRequired
    ),
  }).isRequired,
};

/*
PAYMENT STATUS
------
canceled
created
expired
in_analysis
paid
refunded
reversed
waiting

ORDER STATUS
-----
pending
canceled
completed

SHIPMENT STATUS
------
waiting_sent
send
on_route
delivered
completed
delivery_not_made
*/

export default OrderStatusSteps;
