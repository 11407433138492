import { Modal } from 'antd';
import history from '~/services/history';
import { notifyError, notifySuccess } from '~/util/Functions';

export function handleAddPromotion() {
  history.push('/manage/marketing/promotion/create');
}

function doCancelPromotion(id, { cancelPromotion }) {
  cancelPromotion({
    variables: {
      input: {
        id,
      },
    },
  })
    .then(data => {
      const {
        data: { salesSaleCancel: cancelData },
      } = data;

      notifySuccess(
        'Promoção cancelada com sucesso',
        `Promoção "${cancelData.name}" cancelada com sucesso.`
      );
    })
    .catch(err => {
      notifyError('Erro ao cancelar a promoção', err);
    });
}

function doDeletePromotion(id, { deletePromotion }) {
  deletePromotion({
    variables: {
      input: {
        id,
      },
    },
  })
    .then(data => {
      const {
        data: { salesSaleDelete: cancelData },
      } = data;

      notifySuccess(
        'Promoção deletada com sucesso',
        `Promoção "${cancelData.name}" deletada com sucesso.`
      );
    })
    .catch(err => {
      notifyError('Erro ao deletar a promoção', err);
    });
}

export function updateHooks(
  queryPromotions,
  totalCount,
  { setPromotions, setTable, setLoading }
) {
  setPromotions(queryPromotions);
  setTable(prevState => {
    return {
      ...prevState,
      loading: false,
      refetch: false,
      totalCount,
    };
  });
  setLoading(false);
}

export function handleCancelPromotion(id, { cancelPromotion }) {
  const { confirm } = Modal;

  confirm({
    title: 'Você tem certeza que deseja cancelar esta promoção?',
    content: 'Ao cancelar esta promoção, ela será cancelada permanentemente.',
    okText: 'Sim',
    okType: 'danger',
    cancelText: 'Não',
    onOk() {
      doCancelPromotion(id, { cancelPromotion });
    },
  });
}

export function handleDeletePromotion(id, { deletePromotion }) {
  const { confirm } = Modal;

  confirm({
    title: 'Você tem certeza que deseja deletar esta promoção?',
    content: 'Ao deletar esta promoção, ela será deletada permanentemente.',
    okText: 'Sim',
    okType: 'danger',
    cancelText: 'Não',
    onOk() {
      doDeletePromotion(id, { deletePromotion });
    },
  });
}

export function handleAnticipatePromotion(id, { anticipatePromotion }) {
  const { confirm } = Modal;

  confirm({
    title:
      'Você tem certeza que deseja antecipar a data de opt-in desta promoção?',
    content:
      'Ao clicar em "Antecipar" a promoção ficará disponível para os usuários.',
    okText: 'Antecipar',
    okType: 'danger',
    cancelText: 'Não',
    onOk() {
      anticipatePromotion({ id });
    },
  });
}

export function handleVisibleState(id, visible, { updatePromotionVisible }) {
  const { confirm } = Modal;

  if (!visible) {
    confirm({
      title: 'Você tem certeza que deseja mostrar a promoção no site?',
      content:
        'Ao clicar em "Visível no site", a promoção irá aparecer na lista de promoções.',
      okText: 'Visível no site',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        updatePromotionVisible({ id });
      },
    });
  }

  if (visible) {
    confirm({
      title: 'Você tem certeza que deseja ocultar a promoção no site?',
      content:
        'Ao clicar em "Oculto no site", a promoção não irá aparecer na lista de promoções. Obs: Ocultar não desativa a promoção.',

      okText: 'Oculto no site',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        updatePromotionVisible({ id });
      },
    });
  }
}
