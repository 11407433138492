import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Navbar } from './styles';
import { useToggleMenuContext } from '~/providers/ToggleMenu';

function TopBar() {
  const { menuActive, setMenuActive } = useToggleMenuContext();

  return (
    <Navbar>
      <MenuOutlined
        onKeyPress={() => setMenuActive(!menuActive)}
        onClick={() => setMenuActive(!menuActive)}
      />
    </Navbar>
  );
}

export default TopBar;
