// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { clearCookies } from '../util/Functions';

// Msal Configurations
const config = {
  auth: {
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'profile',
    'user.read',
    'calendars.read',
    'Group.Read.All',
    'GroupMember.Read.All',
  ],
};

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: process.env.REACT_APP_AZURE_TOKEN_REFRESH_URI,
};

clearCookies();

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
