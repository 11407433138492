// eslint-disable-next-line no-unused-vars
import gql from 'graphql-tag';

// TODO: adicionar campos de kind, area, searchText a filtragem e ao type Coupon

export const LIST_COUPONS = gql`
  query listCoupons(
    $first: Int
    $skip: Int
    $couponFilter: String
    $category: String
    $kind: String
  ) {
    couponSearchCoupons(
      first: $first
      skip: $skip
      couponFilter: $couponFilter
      category: $category
      kind: $kind
    ) {
      nodes {
        andOrOperator
        category
        code
        description
        expiresAt
        id
        kind
        name
        startsAt
        usageLimit
        # usageQuantity
      }
      totalCount
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($input: DeleteCouponInput!) {
    couponDeleteCoupon(input: $input) {
      id
      name
      code
    }
  }
`;

export const RULE_OPERATOR = ['_and', '_or'];
