import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import { getImageUrl } from '~/util/Functions';

import { IMG_BAG_CARD } from '~/constants/Images';
import { BAG_ORDER_TYPE_REPROVED } from '~/constants/BagOrder';
import Image from '~/components/Image';
import { CardContainer, CardID, CardDescription } from './styles';

function CardItem({ bagId, approvedItems, reprovedItems, orderType }) {
  return (
    <Card
      hoverable
      size="small"
      bodyStyle={{ margin: 0 }}
      cover={
        <Image
          alt="Sem dados"
          style={{ objectFit: 'cover', height: '200px' }}
          src={getImageUrl(IMG_BAG_CARD)}
        />
      }
    >
      <CardContainer>
        <CardID>ID: {bagId}</CardID>
        {orderType === BAG_ORDER_TYPE_REPROVED && (
          <>
            <CardDescription>Peças aprovadas: {approvedItems}</CardDescription>
            <CardDescription>Peças reprovadas: {reprovedItems}</CardDescription>
          </>
        )}
      </CardContainer>
    </Card>
  );
}

CardItem.defaultProps = {
  approvedItems: null,
  reprovedItems: null,
};

CardItem.propTypes = {
  bagId: PropTypes.number.isRequired,
  approvedItems: PropTypes.number,
  reprovedItems: PropTypes.number,
  orderType: PropTypes.string.isRequired,
};

export default CardItem;
