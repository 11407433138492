import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Switch } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { triggerErrorNotification } from '~/util/Functions';
import Button from '~/components/Button';
import CustomFields from './CustomFields';

function DefaultForm({
  handleSubmit,
  initialData,
  optionTypes,
  properties,
  setCategory,
  isEdit,
}) {
  const [form] = Form.useForm();

  const onFinish = values => {
    handleSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar categoria',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="default"
      data-testid="defaultForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        name: initialData.name,
        active: initialData.active,
        permalink: initialData.permalink,
        metaTitle: initialData.metaTitle,
        metaDescription: initialData.metaDescription,
        metaKeywords: initialData.metaKeywords,
      }}
    >
      <Row gutter={16} type="flex" align="middle" justify="center">
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: 'Campo obrigatório' }]}
          >
            <Input
              data-testid="name"
              key="addCategoryName"
              name="name"
              placeholder="Digite o nome"
            />
          </Form.Item>
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="Categoria está ativa?"
            name="active"
            valuePropName="checked"
            rules={[{ message: 'Campo obrigatório', type: 'boolean' }]}
          >
            <Switch
              data-testid="active"
              key="addCategoryActive"
              name="active"
            />
          </Form.Item>
        </Col>

        {isEdit ? (
          <Col xl={24} lg={24} md={24} xs={24}>
            <Form.Item label="Permalink" name="permalink">
              <Input
                data-testid="permalink"
                key="addCategoryPermalink"
                name="permalink"
                disabled
              />
            </Form.Item>
          </Col>
        ) : null}

        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item label="Título" name="metaTitle">
            <Input
              data-testid="metaTitle"
              key="addCategoryMetaTitle"
              name="metaTitle"
              placeholder="Digite o título"
            />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item label="Descrição" name="metaDescription">
            <Input
              data-testid="metaDescription"
              key="addCategoryMetaDescription"
              name="metaDescription"
              placeholder="Digite a descrição"
            />
          </Form.Item>
        </Col>

        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item
            label="Palavras-Chave (separadas por vírgula)"
            name="metaKeywords"
          >
            <Input
              data-testid="metaKeywords"
              key="addCategoryMetaKeywords"
              name="metaKeywords"
              placeholder="Digite as palavras-chave"
            />
          </Form.Item>
        </Col>
      </Row>

      <CustomFields
        optionTypes={optionTypes}
        properties={properties}
        setCategory={setCategory}
        form={form}
        isEdit={isEdit}
      />

      <Row gutter={16} style={{ marginTop: '10px' }} type="flex" justify="end">
        <Col>
          <Form.Item>
            <Button
              data-testid="defaultSubmitBtn"
              type="success"
              htmlType="submit"
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            >
              {isEdit ? 'Salvar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

DefaultForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    active: PropTypes.bool,
    fixed: PropTypes.bool,
    metaDescription: PropTypes.string,
    metaKeywords: PropTypes.string,
    metaTitle: PropTypes.string,
    name: PropTypes.string,
    parentId: PropTypes.string,
    permalink: PropTypes.string,
    productCount: PropTypes.number,
  }).isRequired,
  optionTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCategory: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default DefaultForm;
