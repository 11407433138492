import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Typography, Divider, Select } from 'antd';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';

import Button from '~/components/Button';

import { notifyError, notifySuccess } from '~/util/Functions';

import { states, cepRegex } from '~/constants/GeneralForms';
import useUserCreateAddress from '~/hooks/user/useUserCreateAddress';
import useUserUpdateAddress from '~/hooks/user/useUserUpdateAddress';
import {
  USER_ADDRESS_KIND_BILLING,
  USER_ADDRESS_KIND_SHIPPING,
  USER_KIND_CUSTOMER,
  USER_KIND_ONG,
  USER_KIND_PARTNER,
  userKindLabels,
} from '~/constants/Users';

function AddressForm({ initialData, userId, userKind, isEdit, title }) {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;

  const userLabel = userKindLabels[userKind];

  const [createAddress, { loading: loadingCreate }] = useUserCreateAddress({
    onCompleted: () => {
      notifySuccess(
        'Endereço registrado',
        `Endereço do ${userLabel} registrado com sucesso!`
      );
    },
    onError: err => {
      notifyError(`Erro ao registrar endereço do ${userLabel}`, err);
    },
  });

  const [updateAddress, { loading: loadingUpdate }] = useUserUpdateAddress({
    onCompleted: () => {
      notifySuccess(
        'Endereço atualizado',
        `Endereço do ${userLabel} atualizado com sucesso!`
      );
    },
    onError: err => {
      notifyError(`Erro ao atualizar endereço do ${userLabel}`, err);
    },
  });

  const onFinish = values => {
    if (!isEdit) {
      createAddress({
        street: values.street,
        complement: values.complement,
        city: values.city,
        neighborhood: values.neighborhood,
        number: values.number,
        stateCode: values.state,
        userId,
        zipcode: values.zipcode,
      });
    } else {
      const addressId = initialData.id;

      updateAddress({
        street: values.street,
        complement: values.complement,
        city: values.city,
        neighborhood: values.neighborhood,
        number: values.number,
        stateCode: values.state,
        addressId,
        zipcode: values.zipcode,
      });
    }
  };

  const onFinishFailed = () => {
    notifyError('Erro ao registrar endereço');
  };

  useEffect(() => form.resetFields(), [initialData, form]);

  return (
    <Form
      form={form}
      name="address"
      data-testid="addressForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        zipcode: initialData?.zipcode,
        street: initialData?.address1,
        number: initialData?.number,
        complement: initialData?.address2,
        neighborhood: initialData?.neighborhood,
        city: initialData?.city,
        state: initialData?.state?.code,
      }}
    >
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            {title}
          </Title>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="CEP"
            name="zipcode"
            rules={[
              {
                required: true,
                message: 'Digite um CEP válido',
                whitespace: false,
                pattern: cepRegex,
              },
            ]}
          >
            <Input
              placeholder="Insira somente números"
              data-testid="zipcodeInput"
            />
          </Form.Item>
        </Col>
        <Col xl={14} lg={14} md={14} xs={24}>
          <Form.Item
            label="Rua"
            name="street"
            rules={[
              {
                required: true,
                message: 'Digite a rua',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="street" />
          </Form.Item>
        </Col>
        <Col xl={4} lg={4} md={4} xs={24}>
          <Form.Item label="Número" name="number">
            <Input data-testid="number" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item label="Complemento" name="complement">
            <Input data-testid="complement" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={9} lg={9} md={9} xs={24}>
          <Form.Item
            label="Bairro"
            name="neighborhood"
            rules={[
              {
                required: true,
                message: 'Digite o bairro',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="neighborhood" />
          </Form.Item>
        </Col>
        <Col xl={9} lg={9} md={9} xs={24}>
          <Form.Item
            label="Cidade"
            name="city"
            rules={[
              {
                required: true,
                message: 'Digite a cidade',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="city" />
          </Form.Item>
        </Col>
        <Col xl={6} lg={6} md={6} xs={24}>
          <Form.Item
            label="Estado"
            name="state"
            rules={[
              {
                required: true,
                message: 'Selecione um estado',
              },
            ]}
          >
            <Select data-testid="state" placeholder="Selecione um estado">
              {states.map(item => (
                <Option
                  key={item.code}
                  value={item.code}
                  data-testid={`optionState-${item.code}`}
                >
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="end"
        style={{ marginTop: '10px' }}
      >
        <Col>
          <Button
            type="success"
            htmlType="submit"
            icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            block
            style={{ margin: 0 }}
            loading={loadingCreate || loadingUpdate}
          >
            {isEdit ? 'Salvar' : 'Adicionar'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

AddressForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userKind: PropTypes.oneOf([
    USER_KIND_CUSTOMER,
    USER_KIND_ONG,
    USER_KIND_PARTNER,
  ]).isRequired,
  title: PropTypes.string,
  initialData: PropTypes.shape({
    id: PropTypes.number,
    zipcode: PropTypes.string,
    address1: PropTypes.string,
    number: PropTypes.string,
    address2: PropTypes.string,
    neighborhood: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.shape({
      abbr: PropTypes.string,
      code: PropTypes.number,
      country: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    kind: PropTypes.oneOf([
      USER_ADDRESS_KIND_BILLING,
      USER_ADDRESS_KIND_SHIPPING,
    ]),
  }),
};

AddressForm.defaultProps = {
  title: 'Informações de Endereço',
  initialData: {},
};

export default AddressForm;
