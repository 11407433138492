import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

export const QUERY_BALANCE_RESUME = gql`
  query useBalanceResume($userId: Int!) {
    balanceAdminShowBalance(userId: $userId) {
      credit
      debit
      totalBalance
      totalCredit
      totalDebit
      totalRestrictedBalance
    }
  }
`;

function useBalanceResume(options = {}) {
  const [searchBalanceResume, { error, data, loading, refetch }] = useLazyQuery(
    QUERY_BALANCE_RESUME,
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  return [
    ({ userId }) => {
      searchBalanceResume({
        variables: {
          userId,
        },
      });
    },
    {
      data: data?.balanceAdminShowBalance || {},
      loading,
      error,
      refetch,
    },
  ];
}

export default useBalanceResume;
