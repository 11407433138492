export const ACTION_ADD_TABLE = 'ADD_TABLE';
export const ACTION_REMOVE_TABLE = 'REMOVE_TABLE';
export const ACTION_ADD_ROW = 'ADD_ROW';
export const ACTION_ADD_MULTROW = 'ADD_MULTROW';
export const ACTION_REMOVE_ROW = 'REMOVE_ROW';
export const ACTION_ADD_COLOR = 'ADD_COLOR';
export const ACTION_ADD_MULTCOLOR = 'ADD_MULTCOLOR';
export const ACTION_REMOVE_COLOR = 'REMOVE_COLOR';
export const ACTION_CLEAR = 'ACTION_CLEAR';

export const TABLE_TYPE_CATEGORY = 'TYPE_CATEGORY';
export const TABLE_TYPE_FILTER_BRAND = 'TYPE_FILTER_BRAND';
export const TABLE_TYPE_FILTER_OPTION_TYPES = 'TYPE_FILTER_OPTION_TYPES';
export const TABLE_TYPE_FILTER_DISCOUNT = 'TYPE_FILTER_DISCOUNT';
export const TABLE_TYPE_FILTER_ONG_PERCENTAGE = 'TYPE_FILTER_ONG_PERCENTAGE';
export const TABLE_TYPE_PRICE = 'TYPE_PRICE';
export const TABLE_TYPE_TIME = 'TYPE_TIME';
export const TABLE_TYPE_COLOR = 'TYPE_COLOR';
export const TABLE_TYPE_CONDITION = 'TYPE_CONDITION';

export const TABLE_KEY_BRAND = 'Marca';
export const TABLE_KEY_DISCOUNT = 'Faixa de descontos';

export const PROMOTION_EDIT_MODE = 'promotion/edit/';
