import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useAnticipatePromotion } from '~/hooks/promotion/useAnticipatePromotion';

import {
  PROMOTION_LIST,
  PROMOTION_CANCEL,
  PROMOTION_DELETE,
} from '~/apollo/marketing/promotion';
import { handleAddPromotion, updateHooks } from './actions';
import TablePromotion from './TablePromotion';
import Button from '~/components/Button';
import LoadingBar from '~/components/LoadingBar';
// TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
// import { useUpdateVisibleById } from '~/hooks/promotion/useUpdatePromotionVisible';

function Promotion() {
  const [promotions, setPromotions] = useState([]);
  const [table, setTable] = useState({
    pageSize: 10,
    currentPage: 1,
    skip: 0,
    totalCount: 0,
    loading: false,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    flag: false,
    message: '',
    obj: {},
  });

  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // const [updatePromotionVisible] = useUpdateVisibleById();

  const [cancelPromotion] = useMutation(PROMOTION_CANCEL, {
    context: {
      newBackend: true,
    },
    refetchQueries: ['listPromotions'],
    awaitRefetchQueries: true,
  });

  const [deletePromotion] = useMutation(PROMOTION_DELETE, {
    context: {
      newBackend: true,
    },
    refetchQueries: ['listPromotions'],
    awaitRefetchQueries: true,
  });

  const [anticipatePromotion] = useAnticipatePromotion();

  const [listPromotions] = useLazyQuery(PROMOTION_LIST, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    variables: {
      first: table.pageSize,
      skip: table.skip,
    },
    onCompleted: ({
      salesSaleSearch: { nodes: queryPromotions, totalCount },
    }) => {
      updateHooks(queryPromotions, totalCount, {
        setPromotions,
        setTable,
        setLoading,
      });
    },
    onError: err => {
      setError({
        flag: true,
        message: 'Erro ao buscar promoções',
        obj: err,
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    listPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.loading]);

  if (loading) return <LoadingBar />;
  if (error.flag) return `Error! ${error.message}`;

  return (
    <Card
      title="Promoções"
      data-testid="promotionList"
      extra={
        <Button
          type="primary"
          data-testid="addPromotionBtn"
          onClick={handleAddPromotion}
        >
          <PlusOutlined />
          Nova Promoção
        </Button>
      }
    >
      <TablePromotion
        promotions={promotions}
        table={table}
        setTable={setTable}
        cancelPromotion={cancelPromotion}
        deletePromotion={deletePromotion}
        anticipatePromotion={anticipatePromotion}
        // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
        // updatePromotionVisible={updatePromotionVisible}
      />
    </Card>
  );
}

export default Promotion;
