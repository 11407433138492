import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';
import { clearEmptyAttrs } from '~/util/Functions';

const usePaginationController = ({
  initialPage = 1,
  initialPageSize = DEFAULT_PAGE_SIZE,
} = {}) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const current = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    const page = Number(searchParams.get('page'));
    const pageSize = Number(searchParams.get('pageSize'));

    const currentPage = page || initialPage;
    const currentPageSize = pageSize || initialPageSize;

    return {
      page: currentPage,
      pageSize: currentPageSize,
      skip: (currentPage - 1) * currentPageSize,
    };
  }, [search]);

  const currentFilters = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const filters = JSON.parse(searchParams.get('filters'));
    return filters;
  });

  const onChange = useCallback(
    ({ page, pageSize, filters }) => {
      const searchParams = new URLSearchParams(search);
      const clearedFilters = clearEmptyAttrs(filters);

      if (page) searchParams.set('page', page);
      if (pageSize) searchParams.set('pageSize', pageSize);

      if (clearedFilters && Object?.keys?.(clearedFilters)?.length) {
        searchParams.set('filters', JSON.stringify(filters));
      } else {
        searchParams.delete('filters');
      }

      history.replace({ pathname, search: searchParams.toString() });
    },
    [history, pathname, search]
  );

  const reset = useCallback(
    newValues => {
      const searchParams = new URLSearchParams(search);

      searchParams.set('page', newValues?.page || initialPage);
      searchParams.set('pageSize', newValues?.pageSize || initialPageSize);
      const clearedFilters = clearEmptyAttrs(newValues?.filters);

      if (clearedFilters && Object?.keys?.(clearedFilters)?.length) {
        searchParams.set('filters', JSON.stringify(newValues?.filters));
      } else {
        searchParams.delete('filters');
      }

      history.replace({ pathname, search: searchParams.toString() });
    },
    [history, pathname, search]
  );

  return {
    current,
    onChange,
    reset,
    filters: currentFilters,
  };
};

export default usePaginationController;
