import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select, Row, Col } from 'antd';
import { useDragSortTableContext } from '~/providers/DragSortTable';

import { FOOTER_BRANDS_SEARCH } from '~/apollo/content/footer';

import LoadingPlaceholder from '~/components/LoadingPlaceholder';

import useDebounce from '~/util/useDebounce';

const { Option } = Select;

function BrandSearch() {
  const { tableData, setTableData } = useDragSortTableContext();
  const [options, setOptions] = useState([]);
  const [rawOptions, setRawOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  const debounce = useDebounce();

  const [searchBrands] = useLazyQuery(FOOTER_BRANDS_SEARCH, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ productsProductSearchBrands: { nodes: brands } }) => {
      const newOptions = brands.map(item => {
        return {
          value: item.id,
          text: item.name,
        };
      });
      setOptions(newOptions);
      setRawOptions(brands);
      setLoading(false);
    },
    onError: () => {
      setOptions([]);
      setRawOptions([]);
      setLoading(false);
    },
  });

  useEffect(() => {
    searchBrands({
      variables: {
        first: 30,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = text =>
    debounce(
      () =>
        searchBrands({
          variables: {
            name: text,
            first: 30,
          },
        }),
      500
    );

  const onSelect = id => {
    const selected = rawOptions.filter(item => item.id === id);
    if (selected.length > 0) {
      selected.forEach(item => {
        if (
          !tableData.some(
            dataItem => dataItem.dynamicContentable.id === item.id
          )
        )
          setTableData(prevData => [
            ...prevData,
            ...[{ id: prevData.length + 1, dynamicContentable: item }],
          ]);
      });
    }
  };

  if (loading)
    return (
      <LoadingPlaceholder
        style={{ width: '100%', height: '36px', marginBottom: 10 }}
      />
    );

  if (error) return `Error! ${error.message}`;

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Select
          showSearch
          style={{ width: '100%', marginBottom: '10px' }}
          optionFilterProp="children"
          onSelect={id => onSelect(Number(id))}
          placeholder="Busque uma marca"
          data-testid="footerAddBrandInput"
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map(opt => (
            <Option key={opt.value}>{opt.text}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}

export default BrandSearch;
