import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

function TableFilter({ table }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <Table
        data-testid={`tableFilter-${table.key}`}
        dataSource={table.data}
        columns={table.columns}
        rowKey={record => record.id}
        pagination={false}
        bordered
        scroll={{
          x: true,
        }}
      />
    </div>
  );
}

TableFilter.propTypes = {
  table: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
};

export default TableFilter;
