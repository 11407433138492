import {
  ITEM_TYPE_BAG,
  ITEM_TYPE_BAG_ORDER,
  ITEM_TYPE_BONUS,
  ITEM_TYPE_DONATION,
  ITEM_TYPE_ORDER,
  ITEM_TYPE_ORDER_ITEM,
  ITEM_TYPE_ORDER_PAYOUT,
  ITEM_TYPE_PAYOUT_DONATION,
  ITEM_TYPE_USER,
  ITEM_TYPE_VOUCHER,
  KIND_ADVANCE_PAYMENT,
  KIND_BAG_ORDER_BALANCE_BAG_BUY,
  KIND_BAG_ORDER_BALANCE_BAG_REFUND,
  KIND_BAG_ORDER_PARTIAL_BAG_PAYMENT,
  KIND_BAG_ORDER_REFUND_PARTIAL_BAG_PAYMENT,
  KIND_BAG_ORDER_REFUND_REVERSE_BAG_TAX,
  KIND_BONUS_SHARE_GOOD,
  KIND_BONUS_TRANSFER,
  KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT_BY_ITEM,
  KIND_DONATION,
  KIND_ORDER_BALANCE_BUY,
  KIND_ORDER_BALANCE_REFUND,
  KIND_ORDER_ITEM_REFUND_PAYMENT,
  KIND_ORDER_PARTIAL_PAYMENT,
  KIND_ORDER_PAYOUT,
  KIND_PAYOUT_FIXED_SALES_FEE,
  KIND_ORDER_REFUND_PARTIAL_PAYMENT,
  KIND_ORDER_REFUND_REVERSE_TAX,
  KIND_PAYOUT_DONATION,
  KIND_VOUCHER,
  KIND_WITHDRAW,
} from '~/constants/Balance';

export const getRelativeItemTypeFromKind = kind => {
  switch (kind) {
    case KIND_ADVANCE_PAYMENT:
      return ITEM_TYPE_BAG;
    case KIND_BAG_ORDER_BALANCE_BAG_BUY:
    case KIND_BAG_ORDER_BALANCE_BAG_REFUND:
    case KIND_BAG_ORDER_PARTIAL_BAG_PAYMENT:
    case KIND_BAG_ORDER_REFUND_PARTIAL_BAG_PAYMENT:
    case KIND_BAG_ORDER_REFUND_REVERSE_BAG_TAX:
      return ITEM_TYPE_BAG_ORDER;
    case KIND_BONUS_SHARE_GOOD:
      return ITEM_TYPE_BONUS;
    case KIND_BONUS_TRANSFER:
    case KIND_WITHDRAW:
      return ITEM_TYPE_USER;
    case KIND_DONATION:
      return ITEM_TYPE_DONATION;
    case KIND_ORDER_BALANCE_BUY:
    case KIND_ORDER_BALANCE_REFUND:
    case KIND_ORDER_PARTIAL_PAYMENT:
    case KIND_ORDER_REFUND_PARTIAL_PAYMENT:
    case KIND_ORDER_REFUND_REVERSE_TAX:
      return ITEM_TYPE_ORDER;
    case KIND_PAYOUT_FIXED_SALES_FEE:
    case KIND_ORDER_PAYOUT:
      return ITEM_TYPE_ORDER_PAYOUT;
    case KIND_PAYOUT_DONATION:
      return ITEM_TYPE_PAYOUT_DONATION;
    case KIND_VOUCHER:
      return ITEM_TYPE_VOUCHER;
    case KIND_ORDER_ITEM_REFUND_PAYMENT:
    case KIND_DEBIT_OVERPAID_REIMBURSEMENT_ADJUSTMENT_BY_ITEM:
      return ITEM_TYPE_ORDER_ITEM;
    default:
      return kind;
  }
};
