import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import ptBR from 'antd/es/locale/pt_BR';
import 'moment/locale/pt-br';

import MainApp from './MainApp';
import SignIn from '../SignIn';
import RestrictedRoute from './RestrictedRoute';

const App = ({ match }) => {
  return (
    <ConfigProvider
      locale={ptBR}
      getPopupContainer={triggerNode => {
        if (
          triggerNode &&
          [...triggerNode?.classList]?.some(
            className =>
              className.includes('ant-select') ||
              className.includes('ant-picker')
          )
        ) {
          return triggerNode?.parentNode;
        }

        return document.body;
      }}
    >
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <RestrictedRoute path={`${match.url}`} component={MainApp} />
      </Switch>
    </ConfigProvider>
  );
};

App.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default App;
