import ApolloClient from 'apollo-client';
import decode from 'jwt-decode';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

import {
  BACKEND_LOGIN_SUCCESS,
  BACKEND_LOGOUT_SUCCESS,
} from '~/constants/ActionTypes';
import { TECNOLOGIA } from '~/constants/UserGroups';
import introspectionQueryResultData from '~/apollo/config/fragmentTypes.json';
import { store } from '~/appRedux/store';
import { authProvider } from '~/services/msalAuthProvider';

// Faz um fetch para o backend, refazendo o login para gerar um novo token
const refreshTokenBackend = (dispatch, azureAccessToken) => {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BACKEND_URI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        variables: {
          accessToken: azureAccessToken,
        },
        operationName: 'refreshToken',
        query: `
          mutation refreshToken($accessToken: String!) {
            userAuthAzureLogin(input: {
              accessToken: $accessToken
            }) {
              id
              groups
              token
              profilePic
            }
          }`,
      }),
    })
      .then(response => response.json())
      .then(response => {
        // Atualiza token do backend salvo no redux
        dispatch({
          type: BACKEND_LOGIN_SUCCESS,
          payload: response,
        });
        resolve(response?.data?.userAuthAzureLogin);
      })
      .catch(err => {
        reject(
          new Error({
            message: 'Não foi possível renovar o token do backend',
            error: err,
          })
        );
      });
  });
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Pode ser alterado para um backend de teste (homologação)
// Hoje ambos, REACT_APP_BACKEND_URI e REACT_APP_NEW_BACKEND_URI estão apontando para o mesmo endpoint
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    provider: 'employee-provider',
  },
});

const httpLinkNewBackend = new HttpLink({
  uri: process.env.REACT_APP_NEW_BACKEND_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
    provider: 'employee-provider',
  },
});

const authMiddlewareLink = setContext((_operation, _forward) => {
  return new Promise((resolve, reject) => {
    const state = store.getState();

    let config = {
      headers: {
        groups: state.auth.loggedIn ? state.auth.account.groups : [],
        Authorization: state.auth.loggedIn ? state.auth.account.token : null,
      },
    };

    // Se for com bypass ou usando o nome backend
    // (somente para não quebrar todas as integrações já feitas)
    if (process.env.REACT_APP_BYPASS_LOGIN === 'true') {
      config = {
        headers: {
          groups: [TECNOLOGIA],
          Authorization: process.env.REACT_APP_BYPASS_TOKEN,
        },
      };
    }

    // REFRESH TOKEN
    if (config.headers.Authorization) {
      const currentTime = Math.trunc(Date.now() / 1000);
      const repassaExpiration = decode(config.headers.Authorization).exp;

      // Checa se token do backend ainda é válido, se tiver expirado
      if (currentTime > repassaExpiration) {
        if (state.auth.azure) {
          const azureAccessToken = state.auth.azure.accessToken;
          const azureExpiration =
            new Date(state.auth.azure.expiresOn).getTime() / 1000;

          // verifica se o token da azure está expirado também.
          if (currentTime > azureExpiration) {
            // Se token do azure e do backend estiverem expirados, tenta renovar ambos os tokens.
            authProvider
              .getAccessToken()
              .then(newAzureToken => {
                refreshTokenBackend(store.dispatch, newAzureToken.accessToken)
                  .then(newBackendToken => {
                    resolve({
                      headers: {
                        groups: newBackendToken ? newBackendToken.groups : [],
                        Authorization: newBackendToken
                          ? newBackendToken.token
                          : null,
                      },
                    });
                  })
                  .catch(err => reject(err));
              })
              .catch(err => {
                reject(
                  new Error({
                    message: 'Não foi possível renovar o token do Azure',
                    error: err,
                  })
                );
              });
          } else {
            // Se token do backend estiver expirado, e token do azure não, renova o token do backend.
            refreshTokenBackend(store.dispatch, azureAccessToken)
              .then(newBackendToken => {
                resolve({
                  headers: {
                    groups: newBackendToken ? newBackendToken.groups : [],
                    Authorization: newBackendToken
                      ? newBackendToken.token
                      : null,
                  },
                });
              })
              .catch(err => reject(err));
          }
        } else {
          // Se token do backend estiver expirado e não houver dados do azure, desloga o usuário.
          store.dispatch({
            type: BACKEND_LOGOUT_SUCCESS,
            payload: null,
          });
          reject(
            new Error({
              message:
                'Não há dados suficientes para realizar a renovação dos tokens',
              error: null,
            })
          );
        }
      } else {
        // Se não estiver expirado, continua normalmente
        resolve(config);
      }
    } else {
      resolve(config);
    }
  });
});

// Caso venho com o context "newBackend" igual a "true",
// Utiliza o URI do backend REACT_APP_BACKEND_URI
// Senão utiliza o backend REACT_APP_NEW_BACKEND_URI;
const link = new RetryLink().split(
  operation => {
    if (process.env.REACT_APP_BYPASS_LOGIN === 'true') return false;
    return operation.getContext().newBackend;
  },
  httpLinkNewBackend,
  httpLink
);

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authMiddlewareLink, link]),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
});
