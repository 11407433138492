import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { AutoComplete, Col, Form, Input, Row, Select, Tag } from 'antd';

import Button from '~/components/Button';
import useDebounce from '~/util/useDebounce';
import { useListUserByDatabase } from '~/hooks/user/useListUserByDatabase';
import { notifyError } from '~/util/Functions';
import { createObj } from '~/util/Auxiliary';
import { Card } from '../styles';

const arrayField = ['rules', 'user'];
const { Search } = Input;

function Users({ form, deleteHandler, error: ruleError }) {
  const debounce = useDebounce();
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);

  const setFormFieldsValue = (customFieldName, value) => {
    form.setFieldsValue(createObj(customFieldName, value, arrayField));
  };

  const [queryUsers, { users }] = useListUserByDatabase({
    onError: err => {
      notifyError('Erro ao buscar usuários', err);
    },
  });

  const searchUsers = email => {
    debounce(
      () =>
        queryUsers({
          first: 10,
          skip: 0,
          email,
        }),
      500
    );
  };

  const removeTag = id => {
    const newSelectedItens = tags.filter(item => item.id !== id);
    setFormFieldsValue('userIds', newSelectedItens);
    setTags(newSelectedItens);
  };

  const onSelect = value => {
    const exists = tags.filter(item => `${item.id}` === value).length;

    if (!exists) {
      const selectedItem = users
        .filter(item => `${item.id}` === value)
        .map(item => ({ ...item, label: item.email, value: item.email }));
      const newTags = [...tags, ...selectedItem];

      setError(false);
      setTags(newTags);
      setFormFieldsValue('userIds', newTags);
      form.resetFields([[...arrayField, 'searchUsersRule']]);
    } else {
      setError(
        'Email inválido ou duplicado. Por favor verifique os dados preenchidos!'
      );
    }
  };

  return (
    <Card
      $error={ruleError}
      data-testid="usersRule"
      size="small"
      title="Quais usuários são válidos?"
      extra={
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
        />
      }
    >
      <Row gutter={[16, 16]} type="flex" align="top" justify="start">
        <Col span={tags?.length ? 24 : 0}>
          {tags?.map(item => (
            <Tag
              key={item.id}
              closable
              onClose={() => removeTag(item.id)}
              style={{ marginBottom: '4px', whiteSpace: 'normal' }}
            >
              <span style={{ color: 'rgba(0, 0, 0, 0.85' }}>{item.email}</span>
            </Tag>
          ))}
        </Col>
      </Row>
      <Form.Item name={[...arrayField, 'userIds']} hidden>
        <Select mode="multiple" />
      </Form.Item>
      <Form.Item
        name={[...arrayField, 'searchUsersRule']}
        validateStatus={error ? 'error' : undefined}
        style={{ marginBottom: 0 }}
        rules={[
          () => ({
            validator() {
              if (tags.length) {
                setError(false);
                return Promise.resolve();
              }
              return Promise.reject(new Error('Adicione ao menos um usuário'));
            },
          }),
        ]}
      >
        <Row gutter={[16, 16]} type="flex" align="top" justify="start">
          <Col span={24}>
            <span style={{ fontSize: '12px' }}>
              Procure pelo e-mail do usuário
            </span>
            <AutoComplete
              style={{ width: '100%' }}
              placeholder="Selecione os usuários"
              onSearch={searchUsers}
              mode="multiple"
              onSelect={onSelect}
              options={users.map(item => {
                return {
                  value: `${item.id}`,
                  label: item.email,
                };
              })}
            >
              <Search style={{ marginBottom: 0 }} />
              {error && <span style={{ color: '#ff4d4f' }}>{error}</span>}
            </AutoComplete>
          </Col>
        </Row>
      </Form.Item>
    </Card>
  );
}

Users.defaultProps = {
  error: false,
};

Users.propTypes = {
  form: PropTypes.shape().isRequired,
  deleteHandler: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default Users;
