import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import { useDragSortTableContext } from '~/providers/DragSortTable';

const AlertMessage = styled(Alert)`
  &.ant-alert {
    margin-top: 10px;

    &[data-show='false'] {
      display: none;
    }
  }
`;

const DataChangeAlert = ({ dataChangedTimes, setDataChangedTimes }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { tableData } = useDragSortTableContext();

  useEffect(() => {
    if (dataChangedTimes > 0) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
    setDataChangedTimes(dataChangedTimes + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <AlertMessage
      data-show={showAlert}
      description="Os dados desta tabela foram alterados, para concluir, clique em Salvar."
      type="info"
      showIcon
    />
  );
};

DataChangeAlert.propTypes = {
  dataChangedTimes: PropTypes.number.isRequired,
  setDataChangedTimes: PropTypes.func.isRequired,
};

export default DataChangeAlert;
