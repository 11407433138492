export const DDDs = [
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '31',
  '32',
  '33',
  '34',
  '35',
  '37',
  '38',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '51',
  '53',
  '54',
  '55',
  '61',
  '62',
  '63',
  '64',
  '65',
  '66',
  '67',
  '68',
  '69',
  '71',
  '73',
  '74',
  '75',
  '77',
  '79',
  '81',
  '82',
  '83',
  '84',
  '85',
  '86',
  '87',
  '88',
  '89',
  '91',
  '92',
  '93',
  '94',
  '95',
  '96',
  '97',
  '98',
  '99',
];

export const states = [
  { id: 1, code: 55, name: 'Acre' },
  { id: 2, code: 56, name: 'Alagoas' },
  { id: 3, code: 57, name: 'Amapá' },
  { id: 4, code: 58, name: 'Amazonas' },
  { id: 5, code: 59, name: 'Bahia' },
  { id: 6, code: 60, name: 'Ceará' },
  { id: 7, code: 61, name: 'Distrito Federal' },
  { id: 8, code: 62, name: 'Espírito Santo' },
  { id: 9, code: 63, name: 'Goiás' },
  { id: 10, code: 64, name: 'Maranhão' },
  { id: 11, code: 66, name: 'Mato Grosso' },
  { id: 12, code: 65, name: 'Mato Grosso do Sul' },
  { id: 13, code: 67, name: 'Minas Gerais' },
  { id: 14, code: 70, name: 'Pará' },
  { id: 15, code: 69, name: 'Paraíba' },
  { id: 16, code: 68, name: 'Paraná' },
  { id: 17, code: 71, name: 'Pernambuco' },
  { id: 18, code: 72, name: 'Piauí' },
  { id: 19, code: 73, name: 'Rio de Janeiro' },
  { id: 20, code: 74, name: 'Rio Grande do Norte' },
  { id: 21, code: 75, name: 'Rio Grande do Sul' },
  { id: 22, code: 76, name: 'Rondônia' },
  { id: 23, code: 77, name: 'Roraima' },
  { id: 24, code: 78, name: 'Santa Catarina' },
  { id: 25, code: 80, name: 'São Paulo' },
  { id: 26, code: 79, name: 'Sergipe' },
  { id: 27, code: 81, name: 'Tocantins' },
];

export const banks = [
  { code: '341', name: 'Itaú' },
  { code: '237', name: 'Bradesco' },
  { code: '104', name: 'Caixa Econômica' },
  { code: '001', name: 'Banco do Brasil' },
  { code: '033', name: 'Santander' },
  { code: '070', name: 'BRB' },
  { code: '077', name: 'Inter' },
  { code: '735', name: 'Neon' },
  { code: '756', name: 'Sicoob' },
  { code: '748', name: 'Sicredi' },
  { code: '085', name: 'Via Credi' },
  { code: '260', name: 'Nubank' },
];

export const addressKinds = [
  {
    code: 'billing',
    name: 'Cobrança',
  },
  {
    code: 'shipping',
    name: 'Entrega',
  },
];

export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[[{}?;:^º°.>,<%&*()_=+$@!#|\]-])(?:([0-9a-zA-Z[{}?;:^º°.>,<%&*()_=+$@!#|\]-])(?!\1)){8,}$/;
export const passwordRegexSequence = /(012|123|234|345|456|567|678|789)/g;

export const validationFormatMessages = {
  passwordSequence: 'Não deve possuir números sequenciais',
  passwordFormatIncorret:
    'Deve possuir ao menos um caractere maiúsculo, um caractere minúsculo, não pode conter números sequenciais, não pode conter letras ou números repetidos em sequência, e deve conter um dos seguintes caracteres [{}?;:^º°.>,<%&*()_=+$@!#|]-',
};

export const phoneRegex = /^\d{1}\s?\d{4}[\s-]?\d{4}$/;
export const cnpjRegex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;
export const cpfRegex = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;
export const cepRegex = /^\d{5}-?\d{3}$/;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
