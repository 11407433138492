import { Modal } from 'antd';

const { confirm } = Modal;

export function deleteItem(id, deleteFooter) {
  deleteFooter(id);
}

export function handleDelete(id, deleteFooter) {
  confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content: 'Ao excluir este item, ele será apagado permanentemente.',
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteItem(id, deleteFooter);
    },
    onCancel() {
      // Cancel
    },
  });
}
