export const MAIN_TITLE_NAME = 'mainTitle';
export const MAIN_TITLE_LABEL = 'Título';

export const SECONDARY_TITLE_NAME = 'secondaryTitle';
export const SECONDARY_TITLE_LABEL = 'Título Alternativo';

export const META_TITLE_NAME = 'metaTitle';
export const META_TITLE_LABEL = 'Meta Título';

export const META_DESCRIPTION_NAME = 'metaDescription';
export const META_DESCRIPTION_LABEL = 'Meta Descrição';

export const META_KEYWORDS_NAME = 'metaKeywords';
export const META_KEYWORDS_LABEL = 'Meta Palavras-Chave';

export const MAIN_CONTENT_NAME = 'mainContent';
export const MAIN_CONTENT_LABEL = 'Texto descritivo exibido em tela';

export const KIND_NAME = 'kind';
export const KIND_LABEL = 'Tipo';

export const CATEGORY_ID_NAME = 'categoryId';
export const CATEGORY_ID_LABEL = 'Categoria';

export const BRAND_ID_NAME = 'brandId';
export const BRAND_ID_LABEL = 'Marca';

export const LOGO_NAME = 'newLogo';
export const LOGO_LABEL = 'Logo';

export const IMAGE_NAME = 'newImage';
export const IMAGE_LABEL = 'Plano de Fundo';

export const getFieldLabelByName = fieldName => {
  switch (fieldName) {
    case MAIN_TITLE_NAME:
      return MAIN_TITLE_LABEL;
    case SECONDARY_TITLE_NAME:
      return SECONDARY_TITLE_LABEL;
    case META_TITLE_NAME:
      return META_TITLE_LABEL;
    case META_DESCRIPTION_NAME:
      return META_DESCRIPTION_LABEL;
    case META_KEYWORDS_NAME:
      return META_KEYWORDS_LABEL;
    case MAIN_CONTENT_NAME:
      return MAIN_CONTENT_LABEL;
    case KIND_NAME:
      return KIND_LABEL;
    case CATEGORY_ID_NAME:
      return CATEGORY_ID_LABEL;
    case BRAND_ID_NAME:
      return BRAND_ID_LABEL;
    case LOGO_NAME:
      return LOGO_LABEL;
    case IMAGE_NAME:
      return IMAGE_LABEL;
    default:
      return 'Sem Label';
  }
};
