import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const UPDATE_USER_STATUS = gql`
  mutation userUpdateStatusUser($input: UpdateStatusUserInput!) {
    userUpdateStatusUser(input: $input) {
      id
      kind
      status
    }
  }
`;

function useUpdateStatusUser(options = {}) {
  const [updateStatus, { loading, data, error }] = useMutation(
    UPDATE_USER_STATUS,
    {
      ...options,
    }
  );

  return [
    ({ userId } = {}) => {
      updateStatus({
        variables: {
          input: {
            id: userId,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUpdateStatusUser;
