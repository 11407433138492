import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthenticationState } from 'react-aad-msal';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import { authProvider as defaultAuthProvider } from '../../services/msalAuthProvider';
import { backendLogin } from '../../appRedux/actions/Auth';

import LoginCard from './LoginCard';
import Authenticating from './Authenticating';
import { AZURE_LOGIN } from '../../apollo/login';

function SignIn({
  loggedIn,
  azureAccessToken,
  location,
  authProvider,
  doBackendLogin,
}) {
  const [azureLogin] = useMutation(AZURE_LOGIN, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'no-cache',
  });
  const [loadingBackend, setLoadingBackend] = useState(false);
  const [error, setError] = useState({ flag: false, message: '' });
  const locationState = location.state ? location.state.from : null;

  function authenticatedState() {
    if (loggedIn) {
      return <Redirect to={locationState || '/home'} />;
    }

    if (azureAccessToken && !loadingBackend) {
      setLoadingBackend(true);
      azureLogin({
        variables: { input: { accessToken: azureAccessToken } },
      })
        .then(data => {
          doBackendLogin(data, 'success');
        })
        .catch(err => {
          doBackendLogin(err, 'error');
          setError({
            flag: true,
            message: err.message,
          });
        });
    }

    if (!error.flag) {
      return <Authenticating />;
    }

    return <LoginCard login={authProvider.login} error={error} />;
  }

  function getComponent() {
    switch (authProvider.authenticationState) {
      case AuthenticationState.Authenticated:
        return authenticatedState();
      case AuthenticationState.Unauthenticated:
        return (
          <LoginCard
            login={authProvider.login}
            error={authProvider.getError()}
          />
        );
      case AuthenticationState.InProgress:
        return <Authenticating />;
      default:
        return (
          <LoginCard
            login={authProvider.login}
            error={authProvider.getError()}
          />
        );
    }
  }

  return (
    <div style={{ height: '100%' }} data-testid="signInPage">
      {getComponent()}
    </div>
  );
}

SignIn.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  azureAccessToken: PropTypes.string,
  doBackendLogin: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape(),
  }),
  authProvider: PropTypes.shape(),
};

SignIn.defaultProps = {
  location: {},
  authProvider: defaultAuthProvider,
  azureAccessToken: null,
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  azureAccessToken: state.auth.azure
    ? state.auth.azure.accessToken
    : state.auth.azure,
});

export default connect(mapStateToProps, {
  doBackendLogin: backendLogin,
})(SignIn);
