import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { ORDER_BY_CREATED_AT_ASC } from '~/constants/Product';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const FRAGMENT_FULL_PRODUCT = gql`
  fragment FullProductFragment on Product {
    age
    availableOn
    brand {
      id
      name
      slug
      active
    }
    categories {
      id
      name
      permalink
      active
    }
    clicks
    color {
      hex
      id
      label
      name
      tones
      url
    }
    condition
    description
    details
    gender
    likes
    maximumPrice
    minimumPrice
    sizeName
    style
    userId
    # TODO: Quebrando no Backend
    # warehouse
  }
`;

export const FRAGMENT_BASIC_PRODUCT = gql`
  fragment BasicProductFragment on Product {
    anticipated
    bagId
    barcode
    commission
    commissionPercentage
    couponShare
    couponSellerPercentage
    costPrice
    currentSale {
      id
      name
      slug
    }
    currentState
    repurchase
    discount
    id
    images {
      id
      imageUrl
      position
    }
    name
    ongDonationValue
    ongId
    ongPercentage
    ong
    orderId
    orderNumber
    originalPrice
    paymentStatus {
      date
      kind
    }
    price
    sellerPrice
    sellerSale {
      id
      name
      startedAt
      kind
      expiredAt
      discount
    }
    sellerSaleDiscount
    profit
    saleDiscount
    salePercent
    salePrice
    saleState
    shipmentStatus {
      date
      kind
    }
    slug
    stateLogs {
      # TODO: Quebrando no Backend (está implentado incorreto)
      # createdBy
      date
      state
    }
    subsidy
    userPrice
  }
`;

export const FRAGMENT_ONLYNAME_PRODUCT = gql`
  fragment OnlyNameProductFragment on Product {
    id
    name
  }
`;

export const QUERY_SEARCH_PRODUCTS = gql`
  query useSearchProducts(
    $bagId: Int
    $barcode: String
    $first: Int
    $id: Int
    $ids: [Int!]
    $name: String
    $orderBy: ProductOrder = ${ORDER_BY_CREATED_AT_ASC}
    $skip: Int
    $slug: String
    $slugs: [String!]
    $userId: Int
    $full: Boolean = false
    $onlyName: Boolean = false
    $basic: Boolean = true
    $status: ProductStatus
  ) {
    productsProductDatabaseSearchProducts(
      bagId: $bagId
      barcode: $barcode
      first: $first
      id: $id
      ids: $ids
      name: $name
      orderBy: $orderBy
      skip: $skip
      slug: $slug
      slugs: $slugs
      userId: $userId
      status: $status
    ) {
      nodes {
        ...OnlyNameProductFragment @include(if: $onlyName)
        ...BasicProductFragment @include(if: $basic)
        ...FullProductFragment @include(if: $full)
      }
      totalCount
    }
  }
  ${FRAGMENT_ONLYNAME_PRODUCT}
  ${FRAGMENT_BASIC_PRODUCT}
  ${FRAGMENT_FULL_PRODUCT}
`;

function useSearchProducts(options = {}) {
  const [searchProducts, { error, data, loading, called, refetch }] =
    useLazyQuery(QUERY_SEARCH_PRODUCTS, {
      fetchPolicy: 'network-only',
      ...options,
    });

  const search = ({
    bagId = undefined,
    barcode = undefined,
    first = DEFAULT_PAGE_SIZE,
    id = undefined,
    ids = undefined,
    name = undefined,
    orderBy = ORDER_BY_CREATED_AT_ASC,
    skip = 0,
    slug = undefined,
    slugs = undefined,
    userId = undefined,
    full = false,
    basic = true,
    onlyName = false,
    status = undefined,
  }) => {
    return searchProducts({
      variables: {
        bagId,
        barcode,
        first,
        id,
        ids,
        name,
        orderBy,
        skip,
        slug,
        slugs,
        userId,
        full,
        basic,
        onlyName,
        status,
      },
    });
  };

  return [
    search,
    {
      data: data?.productsProductDatabaseSearchProducts?.nodes || [],
      totalCount: data?.productsProductDatabaseSearchProducts?.totalCount || 0,
      loading,
      called,
      error,
      refetch,
    },
  ];
}

export default useSearchProducts;
