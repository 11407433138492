import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import { removeRulesRow, promotionFieldIsDisabled } from '~/util/Promotion';

export function generateTimeColumns({ tableKey, dispatch }) {
  const data = [
    {
      title: 'Tempo',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => {
        return `Tempo - ${record.time.text}`;
      },
    },
    // TODO: Versão 2.0 Backoffice
    // {
    //   title: 'Quantidade',
    //   dataIndex: 'quantity',
    //   key: 'quantity',
    //   width: '50px',
    // },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (text, record) => (
        <div key={record.key}>
          <Tooltip placement="bottom" title="Remover">
            <Button
              data-testid="viewBtn"
              type="link"
              icon={<CloseOutlined />}
              size="small"
              style={{ margin: 0, color: promotionFieldIsDisabled() }}
              onClick={removeRulesRow(dispatch, tableKey, record.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return data;
}
