import styled from 'styled-components';
import { Row, Select as AntSelect } from 'antd';

export const FiltersWrapper = styled(Row).attrs({
  gutter: 16,
  type: 'flex',
  justify: 'start',
})`
  margin-bottom: 24px;
`;

export const Select = styled(AntSelect)`
  width: 100%;
`;

export const ButtonsWrapper = styled(Row).attrs({
  gutter: 16,
  type: 'flex',
  align: 'middle',
  justify: 'end',
})`
  margin-bottom: 24px;
`;
