import React from 'react';
import { Form, Row, Col, Select, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { Card } from '../styles';

function ItemTotal({ deleteHandler, error, initialRules }) {
  const { Option } = Select;

  return (
    <Card
      $error={error}
      data-testid="itemTotalRule"
      size="small"
      title="Qual o valor do pedido?"
      extra={
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
        />
      }
    >
      <Row gutter={[16, 16]} type="flex" align="top" justify="start">
        <Col md={12} xs={24}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={['rules', 'orderTotal', 'majorMinorOperator']}
            initialValue={initialRules?.majorMinorOperator || 'gt'}
            rules={[{ required: true, message: 'Selecione 1 opção' }]}
          >
            <Select data-testid="itemTotalRuleSelect">
              <Option value="lt">Menor que</Option>
              <Option value="gt">Maior que</Option>
              <Option value="gte">Maior ou igual que</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={['rules', 'orderTotal', 'value']}
            initialValue={initialRules?.value}
            rules={[{ required: true, message: 'Informe um valor' }]}
          >
            <Input
              type="number"
              min={1}
              addonBefore="R$"
              placeholder="100"
              data-testid="itemTotalRuleValue"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

ItemTotal.defaultProps = {
  error: false,
  initialRules: {},
};

ItemTotal.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  error: PropTypes.bool,
  initialRules: PropTypes.instanceOf(Object),
};

export default ItemTotal;
