import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import { generateColumns } from './columns';

function TablePromotion({
  promotions,
  table,
  setTable,
  cancelPromotion,
  deletePromotion,
  anticipatePromotion,
  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // updatePromotionVisible,
}) {
  const columns = generateColumns({
    cancelPromotion,
    deletePromotion,
    anticipatePromotion,
    // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
    // updatePromotionVisible,
  });

  function onChangePage(current, size) {
    const skip = (current - 1) * size;

    setTable({
      ...table,
      pageSize: size,
      currentPage: current,
      loading: true,
      skip,
    });
  }

  function onShowSizeChange(oldPage, newPageSize) {
    const newCurrentPage = Math.ceil(
      (table.currentPage * table.pageSize - table.pageSize + 1) / newPageSize
    );
    const skip = (newCurrentPage - 1) * newPageSize;

    setTable({
      ...table,
      pageSize: newPageSize,
      currentPage: newCurrentPage,
      loading: true,
      skip,
    });
  }

  return (
    <Table
      data-testid="promotionTable"
      dataSource={promotions}
      columns={columns}
      loading={table.loading}
      rowKey={record => record.id}
      title={() => (
        <div style={{ textAlign: 'center' }}>
          Total encontrado - {table.totalCount}
        </div>
      )}
      bordered
      scroll={{
        x: 'max-content',
      }}
      pagination={{
        current: table.currentPage,
        total: table.totalCount,
        showSizeChanger: true,
        onShowSizeChange,
        onChange: onChangePage,
        pageSizeOptions: ['10'].concat(
          ['20', '30', '40', '50'].filter(size => {
            return Number(size) <= Math.ceil(table.totalCount / 10) * 10;
          })
        ),
        position: 'both',
        locale: { items_per_page: '' },
      }}
    />
  );
}

TablePromotion.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  table: PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    skip: PropTypes.number,
    totalCount: PropTypes.number,
    loading: PropTypes.bool,
  }).isRequired,
  setTable: PropTypes.func.isRequired,
  cancelPromotion: PropTypes.func.isRequired,
  deletePromotion: PropTypes.func.isRequired,
  anticipatePromotion: PropTypes.func.isRequired,
  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // updatePromotionVisible: PropTypes.func.isRequired,
};

export default TablePromotion;
