import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { oneOfAzureGroups } from '~/util/Auth';
import GroupRoute from '~/components/GroupRoute';

import Footer from '~/pages/content/Footer';
import Header from '~/pages/content/Header';

import SeoList from '~/pages/content/seo/SeoList';
import SeoForm from '~/pages/content/seo/SeoForm';

import FaqCategoryForm from '~/pages/content/faq/CategoryForm';
import FaqQuestionForm from '~/pages/content/faq/QuestionForm';
import FaqCategoryList from '~/pages/content/faq/CategoryList';
import FaqQuestionList from '~./pages/content/faq/QuestionList';

import BrandList from '~/pages/content/brand/BrandList';
import BrandForm from '~/pages/content/brand/BrandForm';
import CategoryList from '~/pages/content/category/CategoryList';
import CategoryForm from '~/pages/content/category/CategoryForm';
import NotFound from '~/containers/NotFound';

import {
  TECNOLOGIA,
  ATENDIMENTO,
  MARKETING,
  CADASTRO,
} from '~/constants/UserGroups';

const Content = ({ match, groups }) => {
  return (
    <Switch>
      {/* Redirect para lista de categorias quando acessa o /faq */}
      <Redirect
        exact
        from={`${match.url}/faq`}
        to={`${match.url}/faq/category`}
      />

      {/* Lista de categorias */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category`}
        component={FaqCategoryList}
      />
      {/* Form criar uma nova categoria */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category/create`}
        component={FaqCategoryForm}
      />
      {/* Form editar uma categoria :id */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category/edit/:id`}
        component={FaqCategoryForm}
      />
      {/* Lista de perguntas de uma categoria :id */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category/:id/question`}
        component={FaqQuestionList}
      />
      {/* Form criar uma nova pergunta na categoria :id */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category/:id/question/create`}
        component={FaqQuestionForm}
      />
      {/* Form editar uma pergunta :idQuestion na categoria :id */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/faq/category/:id/question/edit/:idQuestion`}
        component={FaqQuestionForm}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        path={`${match.url}/footer`}
        component={Footer}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        path={`${match.url}/header`}
        component={Header}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/seo`}
        component={SeoList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/seo/create`}
        component={SeoForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, MARKETING]}
        exact
        path={`${match.url}/seo/edit/:id`}
        component={SeoForm}
      />

      {/* Marca */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/brand`}
        component={BrandList}
        exact
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/brand/create`}
        component={BrandForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/brand/edit/:id`}
        component={BrandForm}
      />

      {/* Categoria */}
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/category`}
        component={CategoryList}
        exact
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/category/create/:id`}
        component={CategoryForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/category/create`}
        component={CategoryForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        exact
        path={`${match.url}/category/:id`}
        component={CategoryList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, CADASTRO]}
        path={`${match.url}/category/edit/:id`}
        component={CategoryForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Content.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Content.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Content);
