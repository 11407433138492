import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { NetworkStatus } from 'apollo-client';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const BRAND_FRAGMENT = gql`
  fragment BrandFragment on ElasticBrand {
    active
    id
    name
    productCount
    seoDescription
    seoKeywords
    seoTitle
    slug
  }
`;

export const BRAND_BASIC_FRAGMENT = gql`
  fragment BrandBasicFragment on ElasticBrand {
    id
    name
    slug
    productCount
    active
  }
`;

export const QUERY_SEARCH_BRANDS = gql`
  query useSearchBrands(
    $id: Int
    $ids: [Int!]
    $skip: Int
    $first: Int
    $slugs: [String!]
    $name: String
    $orderBy: String
    $basic: Boolean = true
    $full: Boolean = true
  ) {
    productsProductElasticSearchBrands(
      id: $id
      ids: $ids
      skip: $skip
      first: $first
      slugs: $slugs
      name: $name
      orderBy: $orderBy
    ) {
      nodes {
        ...BrandBasicFragment @include(if: $basic)
        ...BrandFragment @include(if: $full)
      }
      totalCount
    }
  }
  ${BRAND_FRAGMENT}
  ${BRAND_BASIC_FRAGMENT}
`;

function useSearchBrands(options = {}) {
  const { data, loading, error, refetch, networkStatus } = useQuery(
    QUERY_SEARCH_BRANDS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        first: DEFAULT_PAGE_SIZE,
        skip: 0,
      },
      ...options,
    }
  );

  return {
    refetch,
    loading: loading || networkStatus === NetworkStatus.refetch,
    data: data?.productsProductElasticSearchBrands?.nodes || [],
    totalCount: data?.productsProductElasticSearchBrands?.totalCount || 0,
    error,
  };
}

export default useSearchBrands;
