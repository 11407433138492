import React from 'react';
import { Row, Col } from 'antd';

function Authenticating() {
  return (
    <Row
      data-testid="authenticating"
      style={{ height: '100%' }}
      gutter={16}
      type="flex"
      justify="center"
      align="middle"
    >
      <Col>Autenticando...</Col>
    </Row>
  );
}

export default Authenticating;
