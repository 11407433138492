export const STATUS_LABEL = {
  completed: 'Realizada com sucesso',
  created: 'Em andamento',
  failed: 'Erro na conciliação',
  processing: 'Em andamento',
};

export function generateColumns() {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: 'Nome do Arquivo',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Status da Conciliação',
      dataIndex: 'current',
      key: 'current',
      render: current => STATUS_LABEL[current],
    },
    {
      title: 'Status das NFS-e',
      dataIndex: 'error',
      key: 'nfseStatus',
      render: (error, record) => {
        const length = error?.length;

        if (length) {
          return `${length} não ${length === 1 ? 'processada' : 'processadas'}`;
        }

        return STATUS_LABEL[record?.current];
      },
    },
  ];
}

export function generateErrorColumns() {
  return [
    {
      title: 'NFS-e',
      dataIndex: 'numberNfse',
      key: 'numberNfse',
    },
    {
      title: 'RPS',
      dataIndex: 'numberRps',
      key: 'numberRps',
    },
    {
      title: 'Descrição',
      dataIndex: 'error',
      key: 'error',
    },
  ];
}
