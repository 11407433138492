import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { oneOfAzureGroups } from '~/util/Auth';
import GroupRoute from '~/components/GroupRoute';

import ProductOrderForm from '~/pages/orders/product/OrderForm';
import ProductOrderList from '~/pages/orders/product/OrderList';

import BagOrderList from '~/pages/orders/bag/OrderList';
import BagOrderForm from '~/pages/orders/bag/OrderForm';
import OrderShipmentList from '~/pages/orders/bag/OrderShipmentList';

import ReturnManager from '~/pages/orders/product/Return';

import { TECNOLOGIA, ATENDIMENTO, LOGISTICA } from '~/constants/UserGroups';
import NotFound from '~/containers/NotFound';

const Orders = ({ match, groups }) => {
  return (
    <Switch>
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/products`}
        component={ProductOrderList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/products/edit/:id`}
        component={ProductOrderForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/bag`}
        component={BagOrderList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/bag/dispatch`}
        component={OrderShipmentList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/bag/:id`}
        component={BagOrderForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, LOGISTICA]}
        exact
        path={`${match.url}/product/return`}
        component={ReturnManager}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Orders.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Orders.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Orders);
