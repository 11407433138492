import React from 'react';
import { Tooltip, Tag } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlayCircleOutlined,
  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // EyeInvisibleOutlined,
  // EyeOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Button from '~/components/Button';
import { convertDate } from '~/util/Functions';
import {
  handleCancelPromotion,
  handleDeletePromotion,
  handleAnticipatePromotion,
  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // handleVisibleState,
} from './actions';
import { CurrentStatus } from './styles';

const getStatusButton = currentStatus => {
  switch (currentStatus) {
    case 'started':
      return {
        icon: <StopOutlined />,
        title: 'Cancelar',
        disabled: false,
      };
    case 'pending':
      return {
        icon: <PlayCircleOutlined />,
        title: 'Antecipar',
        disabled: false,
      };
    default:
      return {
        icon: <PlayCircleOutlined />,
        title: 'Iniciar',
        disabled: true,
      };
  }
};

export function generateColumns({
  cancelPromotion,
  deletePromotion,
  anticipatePromotion,
  // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
  // updatePromotionVisible,
}) {
  const data = [
    {
      title: 'Campanha',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: (a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        return 0;
      },
    },
    {
      title: 'Categoria',
      dataIndex: 'kind',
      key: 'kind',
      sorter: (a, b) => {
        if (a.kind.toUpperCase() < b.kind.toUpperCase()) return -1;
        if (a.kind.toUpperCase() > b.kind.toUpperCase()) return 1;
        return 0;
      },
      render: text => {
        switch (text) {
          case 'macro':
            return <Tag>Macro</Tag>;
          case 'segmented':
            return <Tag>Segmentada</Tag>;
          case 'seasonal':
            return <Tag>Sazonal</Tag>;
          case 'registration_time':
            return <Tag>Promoção por tempo</Tag>;
          default:
            return <Tag>Sem categoria</Tag>;
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      sorter: (a, b) => {
        if (a.currentStatus.toUpperCase() < b.currentStatus.toUpperCase())
          return -1;
        if (a.currentStatus.toUpperCase() > b.currentStatus.toUpperCase())
          return 1;
        return 0;
      },
      render: (_, { currentStatus }) => {
        switch (currentStatus) {
          case 'canceled':
            return (
              <CurrentStatus status={currentStatus}>Cancelada</CurrentStatus>
            );
          case 'expired':
            return <CurrentStatus>Finalizada</CurrentStatus>;
          case 'pending':
            return (
              <CurrentStatus status={currentStatus}>Pendente</CurrentStatus>
            );
          case 'started':
            return <CurrentStatus status={currentStatus}>Ativa</CurrentStatus>;
          default:
            return currentStatus;
        }
      },
    },
    {
      title: 'Início Opt-in',
      dataIndex: 'sellerNotifiedAt',
      key: 'sellerNotifiedAt',
      sorter: (a, b) =>
        new Date(a.sellerNotifiedAt) - new Date(b.sellerNotifiedAt),
      render: text => convertDate(text),
    },
    {
      title: 'Publicado',
      dataIndex: 'startedAt',
      key: 'startedAt',
      sorter: (a, b) => new Date(a.startedAt) - new Date(b.startedAt),
      render: text => convertDate(text),
    },
    {
      title: 'Fim',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      sorter: (a, b) => new Date(a.expiredAt) - new Date(b.expiredAt),
      render: text => convertDate(text),
    },
    // TODO: Versão 2.0 Backoffice
    // {
    //   title: 'Total Produtos',
    //   dataIndex: 'totalProducts',
    //   key: 'totalProducts',
    //   render: (_text, _record) => {
    //     return null;
    //   },
    // },
    // {
    //   title: 'Participantes',
    //   dataIndex: 'participantsCount',
    //   key: 'participantsCount',
    //   render: (_text, _record) => {
    //     return null;
    //   },
    // },
    // {
    //   title: 'Vendidos',
    //   dataIndex: 'itemsSold',
    //   key: 'itemsSold',
    //   render: (_text, _record) => {
    //     return null;
    //   },
    // },
    // {
    //   title: 'Resultado',
    //   dataIndex: 'result',
    //   key: 'result',
    //   render: (_text, _record) => {
    //     return null;
    //   },
    // },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
      render: (_, { currentStatus, id, kind, key /* , visible */ }) => {
        const statusBtn = getStatusButton(currentStatus);
        const isKindRegistrationTime = kind === 'registration_time';

        const handlePromotionState = () =>
          currentStatus === 'started'
            ? handleCancelPromotion(id, { cancelPromotion })
            : handleAnticipatePromotion(id, { anticipatePromotion });

        return (
          <div style={{ minWidth: 80 }} key={key}>
            {/* TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda */}
            {/* <Tooltip
              placement="bottom"
              title={visible ? 'Visível no site' : 'Oculto no site'}
            >
              <Button
                size="small"
                icon={visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                onClick={() =>
                  handleVisibleState(id, visible, { updatePromotionVisible })
                }
              />
            </Tooltip> */}
            <Tooltip placement="bottom" title={statusBtn.title}>
              <Button
                data-testid="statusBtn"
                type="link"
                icon={statusBtn.icon}
                size="small"
                onClick={() => {
                  if (!statusBtn.disabled && !isKindRegistrationTime) {
                    handlePromotionState();
                  }
                }}
                disabled={statusBtn.disabled || isKindRegistrationTime}
                spacing={false}
                danger={currentStatus === 'started'}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Editar">
              <Link
                to={
                  isKindRegistrationTime === true
                    ? '#'
                    : `/manage/marketing/promotion/edit/${id}`
                }
              >
                <Button
                  data-testid="editBtn"
                  type="link"
                  icon={<EditOutlined />}
                  size="small"
                  spacing={false}
                  disabled={isKindRegistrationTime}
                />
              </Link>
            </Tooltip>
            <Tooltip placement="bottom" title="Excluir">
              <Button
                data-testid="deleteBtn"
                type="link"
                icon={<DeleteOutlined />}
                size="small"
                danger
                onClick={() => {
                  if (currentStatus === 'pending') {
                    handleDeletePromotion(id, { deletePromotion });
                  }
                }}
                spacing={false}
                disabled={currentStatus !== 'pending'}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return data;
}
