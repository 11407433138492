import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Tooltip, Row, Col, Tabs, Alert, Space } from 'antd';
import {
  ArrowLeftOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';

import Button from '~/components/Button';
import InfoCard from '~/components/InfoCard';
import LoadingBar from '~/components/LoadingBar';
import BankForm from '~/pages/service/user/components/BankForm';
import AddressForm from '~/pages/service/user/components/AddressForm';
import TableBalance from '~/pages/service/user/components/TableBalance';
import BalanceResume from '~/pages/service/user/components/BalanceResume';

import {
  USER_KIND_ONG,
  USER_STATUS_ACTIVE,
  USER_TAB_BALANCE,
  USER_TAB_DATA,
} from '~/constants/Users';
import { handleBlockUser } from '~/pages/service/user/actions';
import { getErrorMessage, notifyError, notifySuccess } from '~/util/Functions';

import useTabController from '~/hooks/utils/useTabController';
import useUpdateStatusUser from '~/hooks/user/useUpdateStatusUser';
import { useListUserByDatabase } from '~/hooks/user/useListUserByDatabase';

import BasicForm from './BasicForm';

function OngForm({ match }) {
  const userId = useMemo(() => {
    return Number(match?.params?.id) || null;
  }, [match]);

  const { currentTab, onChange: onChangeTab } = useTabController({
    tabKeys: [USER_TAB_DATA, USER_TAB_BALANCE],
  });

  const [queryUserById, { users, loading, error }] = useListUserByDatabase();

  const [updateStatusUser] = useUpdateStatusUser({
    onCompleted: updateStatusData => {
      notifySuccess(
        'Status do cliente atualizado',
        `ONG ${
          updateStatusData?.userUpdateStatusUser?.status === USER_STATUS_ACTIVE
            ? 'desbloqueada'
            : 'bloqueada'
        } com sucesso`
      );
    },
    onError: err => {
      notifyError('Erro ao atualizar status da ONG', err);
    },
  });

  useEffect(() => {
    if (userId) {
      queryUserById({
        kind: USER_KIND_ONG,
        first: 1,
        skip: 0,
        ids: [userId],
        address: true,
        bank: true,
        full: true,
      });
    }
  }, [userId]);

  const { user, renderBankForm } = useMemo(() => {
    return {
      user: users?.[0],
      renderBankForm: users?.[0]?.addresses?.[0]?.id,
    };
  }, [users]);

  const callBlock = () => {
    if (userId) {
      handleBlockUser({
        onOk: () => updateStatusUser({ userId }),
        currentStatus: user?.status,
        userKind: USER_KIND_ONG,
      });
    }
  };

  const currentTabs = useMemo(() => {
    let tabItems = [
      {
        label: 'Dados',
        key: USER_TAB_DATA,
        children: (
          <>
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
              {!!userId && (
                <InfoCard
                  itemsList={[
                    {
                      id: 1,
                      title: 'Valor repassado',
                      value: user?.amountTransferred,
                    },
                    {
                      id: 3,
                      title: 'Peças a venda',
                      value: user?.sellingCount,
                    },
                    {
                      id: 4,
                      title: 'Peças vendidas',
                      value: user?.soldCount,
                    },
                    {
                      id: 6,
                      title: 'Criado em',
                      value: user?.createdAt
                        ? moment(user.createdAt).format('DD/MM/YYYY')
                        : undefined,
                    },
                    {
                      id: 7,
                      title: 'Último login',
                      value: user?.lastLogin
                        ? moment(user.lastLogin).format('DD/MM/YYYY')
                        : undefined,
                    },
                  ]}
                />
              )}
              <BasicForm
                initialData={user || {}}
                isEdit={!!userId}
                userKind={USER_KIND_ONG}
                userId={userId}
              />
            </Space>

            {!!userId && (
              <>
                <AddressForm
                  initialData={user?.addresses?.[0] || undefined}
                  isEdit={!!user?.addresses?.[0]?.id}
                  userKind={USER_KIND_ONG}
                  userId={userId}
                />
                {renderBankForm ? (
                  <BankForm
                    initialData={user?.bankingAccount || undefined}
                    isEdit={!!user?.bankingAccount?.id}
                    userKind={USER_KIND_ONG}
                    userId={userId}
                  />
                ) : (
                  <Row
                    gutter={16}
                    type="flex"
                    align="top"
                    justify="center"
                    style={{ marginTop: '20px' }}
                  >
                    <Col span={24}>
                      <Alert
                        message="Preencha as informações de endereço para liberar o formulário de dados bancários"
                        banner
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        ),
      },
    ];

    if (userId) {
      const editTabItems = [
        {
          label: 'Saldo',
          key: USER_TAB_BALANCE,
          children: (
            <div data-testid="tabPaneBalance">
              <BalanceResume userId={userId} />
              <TableBalance userId={userId} />
            </div>
          ),
        },
      ];

      tabItems = tabItems.concat(editTabItems);
    }

    return tabItems;
  }, [userId, user]);

  if (userId && loading) return <LoadingBar />;

  return (
    <Card
      data-testid="ongsCardForm"
      title={
        <Row gutter={16} type="flex" align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Tooltip placement="bottom" title="Voltar para ONGs">
              <Link to="/manage/service/ong">
                <ArrowLeftOutlined data-testid="backToListBtn" />
              </Link>
            </Tooltip>
            <span style={{ marginLeft: '10px' }}>
              {userId ? 'EDITAR ONG' : 'ADICIONAR ONG'}
            </span>
          </Col>
          <Col md={12} xs={24}>
            <Row type="flex" justify="end" align="middle">
              {user ? (
                <Button
                  data-testid="blockUserBtn"
                  type={
                    user.status === USER_STATUS_ACTIVE ? 'danger' : 'primary'
                  }
                  icon={
                    user.status === USER_STATUS_ACTIVE ? (
                      <LockOutlined />
                    ) : (
                      <UnlockOutlined />
                    )
                  }
                  onClick={callBlock}
                  style={{ margin: 0 }}
                >
                  {user.status === USER_STATUS_ACTIVE
                    ? 'Bloquear'
                    : 'Desbloquear'}
                </Button>
              ) : null}
            </Row>
          </Col>
        </Row>
      }
    >
      {error ? (
        <Alert message={getErrorMessage(error)} type="error" showIcon />
      ) : (
        <Tabs
          type="card"
          activeKey={currentTab}
          defaultActiveKey={USER_TAB_DATA}
          onChange={onChangeTab}
          data-testid="tabs"
          items={currentTabs}
        />
      )}
    </Card>
  );
}

OngForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default OngForm;
