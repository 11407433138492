export const BAG_RECEIVEMENT = 'receivement';
export const BAG_SCREENING = 'screening';
export const BAG_REGISTER = 'register';
export const BAG_PHOTOGRAPHY = 'photography';
export const BAG_REPROVED = 'reproved';

export const BAG_STAGES = [
  BAG_RECEIVEMENT,
  BAG_SCREENING,
  BAG_REGISTER,
  BAG_PHOTOGRAPHY,
  BAG_REPROVED,
];

export const BAG_SEARCHTYPE_BARCODE = 'barcode';
export const BAG_SEARCHTYPE_NAME = 'name';
export const BAG_SEARCHTYPE_EMAIL = 'email';

export const BAG_SEARCHTYPES = [
  BAG_SEARCHTYPE_BARCODE,
  BAG_SEARCHTYPE_NAME,
  BAG_SEARCHTYPE_EMAIL,
];

export const STATUS_NOT_RECEIVED = 'not_received'; // Não recebido
export const STATUS_PROCESSING_PENDING = 'processing_pending'; // Recebida (pode fazer triagem)
export const STATUS_APPROVED_PROCESSING = 'approved_processing'; // Em triagem
export const STATUS_APPROVED_PROCESSED = 'approved_processed'; // Triagem concluída (pode faze cadastro)
export const STATUS_PHOTOGRAPHY_REVIEW = 'photography_review'; // Em fotografia

export const BAG_STATUSES = [
  STATUS_NOT_RECEIVED,
  STATUS_PROCESSING_PENDING,
  STATUS_APPROVED_PROCESSING,
  STATUS_APPROVED_PROCESSED,
  STATUS_PHOTOGRAPHY_REVIEW,
];

export const REPROVED_STATUS_ALLOWED_DONATE = 'allowed_donate';
export const REPROVED_STATUS_ALLOWED_RETURN = 'allowed_return';
export const REPROVED_STATUS_DONATED = 'donated';
export const REPROVED_STATUS_NONE_REPROVED = 'none_reproved';
export const REPROVED_STATUS_RETURNED = 'returned';

export const BAG_REPROVED_STATUSES = [
  REPROVED_STATUS_ALLOWED_DONATE,
  REPROVED_STATUS_ALLOWED_RETURN,
  REPROVED_STATUS_DONATED,
  REPROVED_STATUS_NONE_REPROVED,
  REPROVED_STATUS_RETURNED,
];
