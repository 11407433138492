import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { DragHandleIcon } from './styles';

const DragHandle = () => {
  return (
    <span
      data-testid="draggable-icon"
      id="dragIcon"
      className="gx-draggable-icon gx-pt-2"
    >
      <DragHandleIcon />
    </span>
  );
};

export default SortableHandle(DragHandle);
