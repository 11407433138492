import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const SEO_CONTENTS_FRAGMENT = gql`
  fragment SeoContentFragment on SeoContent {
    contentId
    contentName
    id
    image
    logo
    kind
    mainContent
    mainTitle
    metaDescription
    metaKeywords
    metaTitle
    secondaryTitle
  }
`;

export const QUERY_SEARCH_SEO_CONTENTS = gql`
  query useSearchSeoContents(
    $id: Int
    $kind: SeoContentKind
    $skip: Int
    $first: Int
  ) {
    seoSeoContentsSearchSeoContents(
      id: $id
      kind: $kind
      skip: $skip
      first: $first
    ) {
      nodes {
        ...SeoContentFragment
      }
      totalCount
    }
  }
  ${SEO_CONTENTS_FRAGMENT}
`;

function useSearchSeoContents(options = {}) {
  const { data, loading, error } = useQuery(QUERY_SEARCH_SEO_CONTENTS, {
    fetchPolicy: 'network-only',
    variables: {
      first: DEFAULT_PAGE_SIZE,
      skip: 0,
    },
    ...options,
  });

  return {
    loading,
    data: data?.seoSeoContentsSearchSeoContents?.nodes || [],
    totalCount: data?.seoSeoContentsSearchSeoContents?.totalCount || 0,
    error,
  };
}

export default useSearchSeoContents;
