import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  Select,
} from 'antd';
import moment from 'moment';
import BannerPromotion from './BannerPromotion';

const { TextArea } = Input;
const { Option } = Select;

const disablePastDates = current => moment().add(-1, 'days') >= current;

function DetailsPromotion({ form, promotion, isDisabled }) {
  return (
    <>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Divider orientation="left">Detalhes</Divider>
        </Col>
        <Col span={24}>
          <div style={{ marginBottom: 20 }}>
            <Form.Item name="banner" hidden>
              <Input type="hidden" />
            </Form.Item>
            <BannerPromotion
              thumb={isDisabled ? promotion.bannerUrl : promotion.banner}
              form={form}
              formFieldId="banner"
              isDisabled={isDisabled}
            />
          </div>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Campanha"
            data-testid="promotionName"
            name="name"
            initialValue={promotion.name}
            rules={[{ required: true, message: 'Campanha é obrigatório' }]}
          >
            <Input
              data-testid="promotionNameInput"
              placeholder="Digite um nome de Campanha"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            data-testid="promotionCategory"
            name="category"
            label="Categoria"
            initialValue={promotion.category}
            rules={[{ required: true, message: 'Selecione 1 opção' }]}
          >
            <Select
              style={{ width: '100%' }}
              data-testid="promotionCategoryInput"
            >
              <Option value="macro">Macro</Option>
              <Option value="segmented">Segmentada</Option>
              <Option value="seasonal">Sazonal</Option>
              <Option value="registration_time">Tempo de cadastro</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Descrição para o vendedor"
            data-testid="promotionSellerDescription"
            name="sellerDescription"
            initialValue={promotion.sellerDescription}
            rules={[{ required: true, message: 'Descrição é obrigatório' }]}
          >
            <TextArea rows={4} data-testid="promotionSellerDescriptionInput" />
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Form.Item
            label="Descrição para o Comprador"
            data-testid="promotionBuyerDescription"
          >
            {form.getFieldDecorator('buyerDescription', {
              initialValue: promotion.buyerDescription,
              rules: [
                {
                  message: 'Digite a descrição',
                  whitespace: true,
                },
              ],
            })(
              <TextArea rows={4} data-testid="promotionBuyerDescriptionInput" />
            )}
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item
            label="Desconto"
            data-testid="promotionDiscount"
            name="discount"
            rules={[{ required: true, message: 'Desconto é obrigatório' }]}
            initialValue={promotion.discount || undefined}
          >
            <InputNumber
              data-testid="promotionDiscountInput"
              style={{ width: '100%' }}
              min={0}
              max={100}
              formatter={value => value && `${value}%`}
              parser={value => value.replace('%', '')}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
        {/* TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda */}
        {/* <Col span={24}>
          <Form.Item
            name="visible"
            label="Mostrar promoção nos filtros?"
            initialValue={promotion.visible}
          >
            <Select style={{ width: '100%' }}>
              <Option value data-testid="optionVisible">
                Sim
              </Option>
              <Option value={false} data-testid="optionNotVisible">
                Não
              </Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={8}>
          <Form.Item
            label="Início do Opt-In"
            data-testid="promotionSellerNotifiedAt"
            name="sellerNotifiedAt"
            initialValue={promotion.sellerNotifiedAt}
            rules={[{ required: true, message: 'Opt-In é Obrigatório' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              data-testid="promotionSellerNotifiedAtInput"
              disabledDate={disablePastDates}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="startedAt"
            label="Publicação"
            data-testid="promotionStartedAt"
            initialValue={promotion.startedAt}
            rules={[{ required: true, message: 'Publicação é obrigatório' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              data-testid="promotionStartedAtInput"
              disabledDate={disablePastDates}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="expiredAt"
            label="Fim"
            data-testid="promotionExpiredAt"
            initialValue={promotion.expiredAt}
            rules={[{ required: true, message: 'Data final é obrigatório' }]}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              data-testid="promotionExpiredAtInput"
              disabledDate={disablePastDates}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

DetailsPromotion.propTypes = {
  form: PropTypes.shape().isRequired,
  promotion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    sellerDescription: PropTypes.string,
    buyerDescription: PropTypes.string,
    discount: PropTypes.number,
    startedAt: PropTypes.shape(),
    expiredAt: PropTypes.shape(),
    sellerNotifiedAt: PropTypes.shape(),
    banner: PropTypes.string,
    bannerUrl: PropTypes.string,
    // TODO: PR 276 - Comentando para não subir na release. Não está no Backend de produção ainda
    // visible: PropTypes.bool,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default DetailsPromotion;
