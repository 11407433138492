import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Input } from 'antd';
import Button from '~/components/Button';

import { addPriceRule } from '../../actions';

function Price({ form, _promotion, dispatch, tables }) {
  const handleAddTable = (key, data) => {
    form.setFieldsValue({
      gte: 0,
      lte: 0,
    });

    addPriceRule({
      tables,
      dispatch,
      newRows: [
        {
          key: `price-${data.gte}-${data.lte}`,
          price: data,
          quantity: 0,
        },
      ],
      tableKey: key,
      title: 'Preço',
    });
  };

  const handleAdd = () => {
    form.validateFields(['gte', 'lte']).then(values => {
      if (values.lte >= 1 && values.gte >= 1) {
        handleAddTable(`promotionPrice`, {
          gte: Number(values.gte).toFixed(2),
          lte: Number(values.lte).toFixed(2),
        });
      }
    });
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}
          label="De"
          name="gte"
        >
          <Input addonBefore="R$" type="number" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}
          label="Até"
          name="lte"
        >
          <Input addonBefore="R$" type="number" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item>
          <Button
            data-testid="addPriceRuleBtn"
            type="primary"
            onClick={handleAdd}
          >
            Adicionar
          </Button>
        </Form.Item>
      </Col>
    </>
  );
}

Price.propTypes = {
  form: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  _promotion: PropTypes.shape({
    gte: PropTypes.number,
    lte: PropTypes.number,
  }).isRequired,
};
export default Price;
