import styled from 'styled-components';
import Button from '~/components/Button';

export const ContainerColor = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColorCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${props =>
    props.showDelete &&
    `
    &:hover {
      &:after {
        content: 'X';
        color: white;
        font-weight: bold;
      }
    }
  `}
`;

ColorCircle.defaultProps = {
  showDelete: true,
};

export const ButtonAddColor = styled(Button).attrs({
  shape: 'circle',
  icon: 'plus',
  type: 'dashed',
  size: 'small',
  spacing: false,
})``;
