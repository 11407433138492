import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { oneOfAzureGroups } from '~/util/Auth';
import { USER_GROUPS, TECNOLOGIA } from '../../constants/UserGroups';
import Forbidden from '../../containers/Forbidden';

export default function GroupRoute({
  currentGroups,
  routeGroups,
  ...restOfProps
}) {
  const allowedGroups = currentGroups.filter(current =>
    routeGroups.includes(current)
  );

  if (allowedGroups.length > 0) {
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restOfProps}
      />
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...restOfProps} component={Forbidden} />;
}

GroupRoute.propTypes = {
  routeGroups: PropTypes.arrayOf(PropTypes.oneOf(USER_GROUPS)),
  currentGroups: oneOfAzureGroups,
};

GroupRoute.defaultProps = {
  routeGroups: [TECNOLOGIA],
  currentGroups: [],
};
