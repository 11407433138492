/*
 * //TODO: Versão 2.0 Backoffice
 * Tudo que está comentado nesse arquivo, é que não vai mais ser implementado
 * na primeira versão do BackOffice, somente em uma versão futura.
 */

import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import Button from '~/components/Button';
// import ProductColors from '~/constants/ProductColors';

import { removeRulesRow, promotionFieldIsDisabled } from '~/util/Promotion';

// import { ContainerColor, ColorCircle, ButtonAddColor } from './styles';

// const getStyle = color => {
//   let background = '';

//   if (color.url) {
//     background = `url(${process.env.PUBLIC_URL}/images/colors/${color.url})`;
//   } else {
//     background = color.hex;
//   }

//   return {
//     marginRight: 10,
//     width: '20px',
//     height: '20px',
//     border: '1px solid rgba(0,0,0,0.2)',
//     boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
//     borderRadius: '50%',
//     background,
//   };
// };

const generateColumnTitle = title => {
  return `Categoria: ${title}`;
};

export function generateCategoryColumns({
  title,
  // setAddColor,
  tableKey,
  dispatch,
}) {
  const data = [
    {
      title: generateColumnTitle(title),
      dataIndex: 'title',
      key: 'category',
      render: (text, record) => {
        if (record.parentName) {
          return `${record.parentName}/${text}`;
        }
        return text;
      },
    },
    // {
    //   title: 'Cor',
    //   dataIndex: 'color',
    //   key: 'color',
    //   render: (text, record) => {
    //     return (
    //       <ContainerColor key={record.key}>
    //         {record.color.map(item => {
    //           return (
    //             <ColorCircle
    //               key={item}
    //               style={getStyle(ProductColors[item])}
    //               onClick={() => {
    //                 dispatch({
    //                   type: ACTION_REMOVE_COLOR,
    //                   payload: {
    //                     tableKey,
    //                     rowKey: record.key,
    //                     colorKey: item,
    //                   },
    //                 });
    //               }}
    //             />
    //           );
    //         })}
    //         <ButtonAddColor
    //           onClick={() => {
    //             setAddColor({ visible: true, record, tableKey });
    //           }}
    //         />
    //       </ContainerColor>
    //     );
    //   },
    // },
    // {
    //   title: 'Quantidade',
    //   dataIndex: 'quantity',
    //   key: 'quantity',
    //   width: '50px',
    // },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (text, record) => (
        <div key={record.key}>
          <Tooltip placement="bottom" title="Remover">
            <Button
              data-testid="deleteBtn"
              type="link"
              icon={<CloseOutlined />}
              size="small"
              style={{ margin: 0, color: promotionFieldIsDisabled() }}
              onClick={removeRulesRow(dispatch, tableKey, record.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return data;
}
