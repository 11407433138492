import React from 'react';
import PropTypes from 'prop-types';
import { SaveOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input } from 'antd';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';

function DefaultForm({ handleSubmit, initialData, isEdit }) {
  const [form] = Form.useForm();

  const onFinish = values => {
    handleSubmit(values);
  };
  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar marca',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      name="default"
      data-testid="defaultForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        name: initialData.name,
        seoTitle: initialData.seoTitle,
        seoDescription: initialData.seoDescription,
        seoKeywords: initialData.seoKeywords,
      }}
    >
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: 'Digite o nome',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="name" placeholder="Digite o nome" />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item label="Título SEO" name="seoTitle">
            <Input data-testid="seoTitle" placeholder="Digite o título SEO" />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item label="Descrição SEO" name="seoDescription">
            <Input
              data-testid="seoDescription"
              placeholder="Digite a descrição SEO"
            />
          </Form.Item>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form.Item
            label="Palavras-Chave SEO (separadas por vírgula)"
            name="seoKeywords"
            rules={[{ type: 'string', message: 'Digite uma string' }]}
          >
            <Input
              data-testid="seoKeywords"
              placeholder="Digite as palavras-chave"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="end" align="middle">
        <Col>
          <Form.Item>
            <Button
              data-testid="defaultSubmitBtn"
              type="success"
              htmlType="submit"
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
            >
              {isEdit ? 'Salvar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

DefaultForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    seoTitle: PropTypes.string,
    seoDescription: PropTypes.string,
    seoKeywords: PropTypes.string,
  }).isRequired,
};

export default DefaultForm;
