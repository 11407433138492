// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';

// Microsoft Azure
export const AAD_LOGIN_SUCCESS = 'AAD_LOGIN_SUCCESS';
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS =
  'AAD_ACQUIRED_ACCESS_TOKEN_SUCCESS';
export const AAD_INITIALIZING = 'AAD_INITIALIZING';
export const AAD_ACQUIRED_ID_TOKEN_SUCCESS = 'AAD_ACQUIRED_ID_TOKEN_SUCCESS';
export const AAD_ACQUIRED_ACCESS_TOKEN_ERROR =
  'AAD_ACQUIRED_ACCESS_TOKEN_ERROR';
export const AAD_AUTHENTICATED_STATE_CHANGED =
  'AAD_AUTHENTICATED_STATE_CHANGED';
export const AAD_GET_ACCESS_TOKEN = 'AAD_GET_ACCESS_TOKEN';

// Backend Employee
export const BACKEND_LOGIN_SUCCESS = 'BACKEND_LOGIN_SUCCESS';
export const BACKEND_LOGOUT_SUCCESS = 'BACKEND_LOGOUT_SUCCESS';
export const BACKEND_LOGIN_ERROR = 'BACKEND_LOGIN_ERROR';
