import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SearchOutlined } from '@ant-design/icons';
import { Input, Col, Radio } from 'antd';

import Button from '~/components/Button';

import { handleSearch } from '~/pages/marketing/coupon/CouponList/actions';

import { COUPON_KIND_BAG_ORDER } from '~/constants/Coupon';
import { FiltersWrapper, ButtonsWrapper, Select } from './styles';

function Filter({ setTable }) {
  const [couponFilters, setCouponFilters] = useState({
    searchText: '',
    kind: '',
    category: 'all',
  });

  const couponCategories = [
    {
      id: 1,
      name: 'Todos',
      value: 'all',
      dataTestId: 'couponCategorySelectOption',
    },
    {
      id: 2,
      name: 'Atendimento',
      value: 'attendance',
      dataTestId: 'couponCategorySelectAttendance',
    },
    {
      id: 3,
      name: 'Promoção',
      value: 'promotion',
      dataTestId: 'couponCategorySelectPromotion',
    },
  ];

  const couponKinds = [
    // {
    //   id: 1,
    //   name: 'Ambos',
    //   value: 'both',
    // },
    {
      id: 2,
      name: 'Pedido',
      value: 'cart',
    },
    {
      id: 3,
      name: 'Sacola',
      value: 'bag_order',
    },
  ];

  return (
    <>
      <FiltersWrapper>
        <Col span={12}>
          Busca
          <Input
            data-testid="searchCouponInput"
            placeholder="Busque por nome ou cupom"
            value={couponFilters.searchText}
            onChange={e =>
              setCouponFilters({ ...couponFilters, searchText: e.target.value })
            }
          />
        </Col>
        <Col span={6}>
          Área
          <Select
            data-testid="couponCategorySelect"
            value={couponFilters.category}
            onSelect={value =>
              setCouponFilters({ ...couponFilters, category: value })
            }
          >
            {couponCategories.map(category => (
              <Select.Option
                key={category.id}
                value={category.value}
                data-testid={category.dataTestId}
              >
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          Tipo de cupom <br />
          <Radio.Group
            onChange={e =>
              setCouponFilters({ ...couponFilters, kind: e.target.value })
            }
            value={couponFilters.kind}
          >
            {couponKinds.map(kind => (
              <Radio.Button
                key={kind.id}
                value={kind.value}
                disabled={kind.value === COUPON_KIND_BAG_ORDER}
              >
                {kind.name}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
      </FiltersWrapper>
      <ButtonsWrapper>
        <Col span={4}>
          <Button
            data-testid="searchCouponClearBtn"
            type="link"
            onClick={() =>
              setCouponFilters({
                searchText: '',
                kind: '',
                category: 'all',
              })
            }
          >
            Limpar busca
          </Button>
        </Col>
        <Col span={4}>
          <Button
            data-testid="searchCouponBtn"
            type="success"
            htmlType="submit"
            icon={<SearchOutlined />}
            block
            style={{ margin: 0 }}
            onClick={() => handleSearch(couponFilters, { setTable })}
          >
            Buscar
          </Button>
        </Col>
      </ButtonsWrapper>
    </>
  );
}

Filter.propTypes = {
  setTable: PropTypes.func.isRequired,
};

export default Filter;
