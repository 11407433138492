import gql from 'graphql-tag';

export const SEARCH_CATEGORIES_BY_ID = gql`
  query listCategories(
    $skip: Int!
    $onlyActive: Boolean!
    $orderBy: CategoryOrder!
    $ids: [Int!]
  ) {
    categoriesProductSearchCategories(
      skip: $skip
      onlyActive: $onlyActive
      orderBy: $orderBy
      ids: $ids
    ) {
      nodes {
        active
        fixed
        id
        name
        parentId
        permalink
        productCount
      }
      totalCount
    }
  }
`;

export const CATEGORIES_LIST = gql`
  query listCategories(
    $first: Int
    $skip: Int!
    $onlyActive: Boolean!
    $orderBy: CategoryOrder!
    $parentId: Int
    $name: String
  ) {
    categoriesProductSearchCategories(
      first: $first
      skip: $skip
      onlyActive: $onlyActive
      orderBy: $orderBy
      parentId: $parentId
      name: $name
    ) {
      nodes {
        active
        fixed
        id
        metaDescription
        metaKeywords
        metaTitle
        name
        parentId
        permalink
        productCount
        optionTypes {
          id
          name
          presentation
        }
        properties {
          id
          name
          presentation
        }
      }
      totalCount
    }
  }
`;

export const CATEGORIES_SHORT_LIST = gql`
  query listCategories($onlyActive: Boolean!) {
    categoriesProductSearchCategories(onlyActive: $onlyActive) {
      nodes {
        id
        name
        parentId
        permalink
      }
      totalCount
    }
  }
`;

export const CATEGORY_BY_ID = gql`
  query categoryById($id: Int) {
    categoriesProductSearchCategories(id: $id, onlyActive: false) {
      nodes {
        active
        fixed
        id
        metaDescription
        metaKeywords
        metaTitle
        name
        parentId
        permalink
        productCount
        optionTypes {
          id
          name
          presentation
        }
        properties {
          id
          name
          presentation
        }
      }
      totalCount
    }
  }
`;

export const CATEGORY_CREATE = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    categoriesProductCreateCategory(input: $input) {
      id
      active
      metaDescription
      metaKeywords
      metaTitle
      name
      parentId
      permalink
      productCount
      optionTypes {
        id
        name
        presentation
      }
      properties {
        id
        name
        presentation
      }
    }
  }
`;

export const CATEGORY_UPDATE = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    categoriesProductUpdateCategory(input: $input) {
      id
      active
      metaDescription
      metaKeywords
      metaTitle
      name
      parentId
      permalink
      productCount
      optionTypes {
        id
        name
        presentation
      }
      properties {
        id
        name
        presentation
      }
    }
  }
`;

export const CATEGORY_DELETE = gql`
  mutation deleteCategory($input: DeleteCategoryInput!) {
    categoriesProductDeleteCategory(input: $input) {
      id
      name
      productCount
      active
      fixed
    }
  }
`;
