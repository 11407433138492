import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Card,
  Col,
  List,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd';

import { getErrorMessage, toCurrencyFormat } from '~/util/Functions';
import useBalanceResume from '~/hooks/balance/useBalanceResume';
import { balanceLabelConverter } from '~/util/statusConverter';
import { BALANCE_TYPE_CREDIT, BALANCE_TYPE_DEBIT } from '~/constants/Balance';

const { Text } = Typography;

const createFlow = (data, type, key) => {
  return {
    id: `${type}-${key}`,
    description: balanceLabelConverter(key, type),
    value: data[type][key],
  };
};

function BalanceResume({ userId }) {
  const [inflows, setInflows] = useState([]);
  const [outflows, setOutFlows] = useState([]);

  const [searchBalanceResume, { data, loading, error }] = useBalanceResume();
  const search = useCallback(searchBalanceResume, [userId]);

  useEffect(() => {
    search({
      userId,
      full: true,
    });
  }, [search, userId]);

  useEffect(() => {
    if (data) {
      if (data[BALANCE_TYPE_CREDIT]) {
        const newInflows = Object.keys(data[BALANCE_TYPE_CREDIT]).map(key => {
          return createFlow(data, BALANCE_TYPE_CREDIT, key);
        });
        setInflows(newInflows);
      }

      if (data[BALANCE_TYPE_DEBIT]) {
        const newOutFlows = Object.keys(data[BALANCE_TYPE_DEBIT]).map(key => {
          return createFlow(data, BALANCE_TYPE_DEBIT, key);
        });

        setOutFlows(newOutFlows);
      }
    }
  }, [data]);

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      {error && (
        <Alert message={getErrorMessage(error)} type="error" showIcon />
      )}
      <Row gutter={16}>
        <Col span={12}>
          <Card data-testid="balanceResumeTotalBalance">
            <Statistic
              title="Saldo"
              loading={loading}
              value={toCurrencyFormat(data.totalBalance)}
              precision={2}
              valueStyle={{
                color: data.totalBalance >= 0 ? '#52c41a' : '#ff4d4f',
              }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card data-testid="balanceResumeRestrictedBalance">
            <Statistic
              title="Saldo Restrito"
              loading={loading}
              value={toCurrencyFormat(data.totalRestrictedBalance)}
              precision={2}
              valueStyle={{
                color: data.totalRestrictedBalance >= 0 ? '#52c41a' : '#ff4d4f',
              }}
            />
            <small>
              <Text type="secondary">
                Saldo Repassa exclusivo para usar no Repassa
              </Text>
            </small>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <List
              header="Entradas de Saldo"
              footer={
                <List.Item>
                  <List.Item.Meta title="Total" />
                  <Text type={data.totalCredit >= 0 ? 'success' : 'danger'}>
                    {toCurrencyFormat(data.totalCredit)}
                  </Text>
                </List.Item>
              }
              loading={loading}
              dataSource={inflows}
              renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta description={item.description} />
                  <Text type={item.value >= 0 ? 'success' : 'danger'}>
                    {toCurrencyFormat(item.value)}
                  </Text>
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <List
              header="Saídas de Saldo"
              footer={
                <List.Item>
                  <List.Item.Meta title="Total" />
                  <Text type={data.totalDebit >= 0 ? 'success' : 'danger'}>
                    {toCurrencyFormat(data.totalDebit)}
                  </Text>
                </List.Item>
              }
              loading={loading}
              dataSource={outflows}
              renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta description={item.description} />
                  <Text type={item.value >= 0 ? 'success' : 'danger'}>
                    {toCurrencyFormat(item.value)}
                  </Text>
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  );
}

BalanceResume.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default BalanceResume;
