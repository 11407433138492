import React from 'react';
import { Form, Switch } from 'antd';
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { Card } from '../styles';

function FirstOrder({ deleteHandler, initialRules, error }) {
  return (
    <Card
      $error={error}
      data-testid="firstOrderRule"
      size="small"
      title="Válido apenas para o primeiro pedido do usuário?"
      extra={
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
        />
      }
    >
      <Form.Item
        name={['rules', 'firstOrder', 'isFirstOrder']}
        valuePropName="checked"
        initialValue={initialRules?.isFirstOrder === 'active'}
        style={{ marginBottom: 0 }}
      >
        <Switch
          data-testid="firstOrderRuleSwitch"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>
    </Card>
  );
}

FirstOrder.defaultProps = {
  error: false,
  initialRules: {},
};

FirstOrder.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  initialRules: PropTypes.instanceOf(Object),
  error: PropTypes.bool,
};

export default FirstOrder;
