import React from 'react';
import { Tooltip, Space } from 'antd';
import {
  FileSearchOutlined,
  ClockCircleOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  getPaymentStatus,
  getShipmentDispatchStatus,
  getShipmentStatus,
  getBagOrderStatus,
} from '~/util/statusConverter';
import { getBagOrderTypeLabel } from '~/util/BagOrder';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
} from '~/constants/ShipmentDispatch';
import {
  convertDate,
  toCurrencyFormat,
  formatPascalCase,
  convertHour,
} from '~/util/Functions';
import { PARTICLES_OF_NAME } from '~/constants/Users';
import Button from '~/components/Button';
import {
  ORDER_ORIGIN_BAG_ORDER_LISTING,
  BAG_ORDER_TYPE_RETURN,
} from '~/constants/BagOrder';

export function generateColumns(
  selectedOrders,
  setSelectedOrder,
  handleUpdateStatus
) {
  const getColorAndTextCustomBadge = status => {
    let text = getShipmentDispatchStatus(status);
    let color;
    switch (status) {
      case SHIPMENT_DISPATCH_AWAITING:
        color = '#fccd72';
        break;
      case SHIPMENT_DISPATCH_PROCESSED:
        color = '#75cf47';
        break;
      case SHIPMENT_DISPATCH_PROCESSING:
        color = '#46a6ff';
        break;
      default:
        color = '#b8b8b8';
        text = 'Sem status';
    }
    return { color, text };
  };

  const data = [
    {
      title: 'N° do Pedido',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Status do Pedido',
      dataIndex: 'status',
      key: 'status',
      render: record => getBagOrderStatus(record),
    },
    {
      title: 'Quantidade de Sacolas',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 150,
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: record => convertDate(record),
    },
    {
      title: 'Nome Completo',
      dataIndex: 'user',
      key: 'user',
      render: record =>
        formatPascalCase(
          `${record?.firstName} ${record?.lastName}`,
          PARTICLES_OF_NAME
        ),
    },
    {
      title: 'E-mail do Cliente',
      dataIndex: 'user',
      key: 'user',
      render: record => record?.email,
    },
    {
      title: 'Valor Total',
      dataIndex: 'total',
      key: 'total',
      render: record => toCurrencyFormat(record),
    },
    {
      title: 'Status do Pagamento',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      width: 150,
      render: record => getPaymentStatus(record),
    },
    {
      title: 'Status de Expedição',
      key: 'action',
      render: record => {
        if (record?.kind !== BAG_ORDER_TYPE_RETURN) {
          const shipmentDispatchStatus = record?.shipmentDispatch?.status;
          const customBadgeStyle = getColorAndTextCustomBadge(
            shipmentDispatchStatus
          );

          const isEnableHistoric = [
            SHIPMENT_DISPATCH_AWAITING,
            SHIPMENT_DISPATCH_PROCESSED,
            SHIPMENT_DISPATCH_PROCESSING,
          ].includes(shipmentDispatchStatus);

          const isEnabledUpdate = () => {
            if (!record.shipmentDispatch) return false;
            if (shipmentDispatchStatus === SHIPMENT_DISPATCH_PROCESSED) {
              return false;
            }
            return (
              selectedOrders.selectedRows.length === 0 ||
              selectedOrders.selectedRows.some(row => row.id === record.id)
            );
          };

          return (
            <div
              key={record.id}
              style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
            >
              <Space>
                <CustomBadge
                  statusColor={customBadgeStyle.color}
                  status={shipmentDispatchStatus}
                >
                  {customBadgeStyle.text}
                </CustomBadge>
                <Tooltip placement="bottom" title="Atualizar status">
                  <Button
                    data-testid="viewBtn"
                    type="link"
                    icon={<DoubleRightOutlined />}
                    size="small"
                    style={{ margin: 0 }}
                    onClick={() => {
                      if (isEnabledUpdate()) {
                        handleUpdateStatus(record);
                      }
                    }}
                    disabled={!isEnabledUpdate()}
                  />
                </Tooltip>
                <Tooltip placement="bottom" title="Histórico">
                  <Button
                    onClick={() => {
                      if (isEnableHistoric) {
                        setSelectedOrder(prevState => ({
                          ...prevState,
                          selected: record.id,
                        }));
                      }
                    }}
                    data-testid="viewBtn"
                    type="link"
                    icon={<ClockCircleOutlined />}
                    size="small"
                    style={{ margin: 0 }}
                    disabled={!isEnableHistoric}
                  />
                </Tooltip>
              </Space>
            </div>
          );
        }
        return null;
      },
    },
    {
      title: 'Status do Envio',
      dataIndex: 'shipmentStatus',
      key: 'shipmentStatus',
      render: record => getShipmentStatus(record),
    },
    {
      title: 'Transportadora',
      dataIndex: 'shipmentCompany',
      key: 'shipmentCompany',
      render: record => record?.name || 'Não consta',
    },
    {
      title: 'Tipo do Pedido',
      key: 'kind',
      render: record => getBagOrderTypeLabel(record?.kind),
    },
    {
      title: 'Detalhes do Pedido',
      width: 100,
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: record => (
        <Tooltip key={record.id} placement="bottom" title="Visualizar">
          <Link
            to={`/manage/orders/bag/${record.id}?from=${ORDER_ORIGIN_BAG_ORDER_LISTING}`}
          >
            <Button
              data-testid="viewBtn"
              type="link"
              icon={<FileSearchOutlined />}
              size="small"
              style={{ margin: 0 }}
            />
          </Link>
        </Tooltip>
      ),
    },
  ];

  return data;
}

export const CustomBadge = styled.div`
  padding: 2px 5px 2px;
  background-color: ${props => props.statusColor};
  font-size: 11px;
  border-radius: 5px;
  text-align: center;
  color: ${props =>
    props.status !== SHIPMENT_DISPATCH_AWAITING ? 'white' : null};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const historicColumns = [
  {
    title: '',
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: 'Data',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: record => convertDate(record),
  },
  {
    title: 'Hora',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: record => convertHour(record),
  },
  {
    title: 'Usuário',
    dataIndex: 'employee',
    key: 'employee',
    render: record => record || 'Criado pelo sistema',
  },
  {
    title: 'Situação',
    dataIndex: 'status',
    key: 'status',
    render: record => getShipmentDispatchStatus(record),
  },
];
