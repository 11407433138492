import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Tooltip,
  notification,
} from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getErrorMessage, notifyError } from '~/util/Functions';
import useUpdateBagDispatchStatus from '~/hooks/orders/useUpdateBagDispatchStatus';
import useSearchBagOrder from '~/hooks/orders/useSearchBagOrder';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
} from '~/constants/ShipmentDispatch';
import history from '~/services/history';
import Filter from './Filter';
import { generateColumns } from './columns';
import {
  addData,
  clearData,
  deleteData,
  getStoreData,
  initDB,
} from './functions';

function OrderShipmentList() {
  const [orders, setOrders] = useState([]);

  const [searchBagOrder, { loading, error }] = useSearchBagOrder({
    onCompleted: async ({ bagsBagAdminListBagOrders }) => {
      const order = bagsBagAdminListBagOrders?.nodes[0] || null;
      if (!order) {
        notification.warn({
          message: 'Pedido não encontrado.',
          placement: 'topRight',
        });
        return;
      }

      if (
        orders?.length &&
        order?.shipmentDispatch?.status !== orders[0].shipmentDispatch?.status
      ) {
        notification.warn({
          message:
            'Todos os pedidos devem estar com o mesmo Status de Expedição.',
          placement: 'topRight',
        });
        return;
      }

      if (!order?.shipmentDispatch?.status) {
        notification.warn({
          message: 'O Status de Expedição não pode estar vazio.',
          placement: 'topRight',
        });
        return;
      }

      if (order?.shipmentDispatch?.status === SHIPMENT_DISPATCH_PROCESSED) {
        notification.warn({
          message: 'Pedido com Status de Expedição finalizado.',
          placement: 'topRight',
        });
        return;
      }

      setOrders(prev => [...prev, order]);
      await addData('orders', order);
    },
  });

  const handleSubmit = id => {
    if (orders.some(order => order.id === Number(id))) {
      notification.warn({
        message: 'Pedido já adicionado.',
        placement: 'topRight',
      });
      return;
    }

    searchBagOrder({
      bagOrderId: Number(id),
      size: 1,
      skip: 0,
    });
  };

  useEffect(() => {
    (async () => {
      await initDB();
      const storage = await getStoreData('orders');
      setOrders(storage);
    })();
  }, []);

  const removeOrder = id => {
    Modal.confirm({
      title:
        'Deseja remover este pedido da atualização do status de expedição?',
      okText: 'confirmar',
      cancelText: 'cancelar',
      onOk: async () => {
        await deleteData('orders', id);
        setOrders(prev => prev.filter(item => item.id !== id));
      },
    });
  };

  const [updateStatus, { loading: submitLoading }] = useUpdateBagDispatchStatus(
    {
      onCompleted: async () => {
        await clearData('orders');
        notification.success({
          message: 'Status de Expedição dos pedidos atualizados.',
          placement: 'topRight',
          duration: 1,
          onClose: () => {
            history.replace('/manage/orders/bag');
          },
        });
      },
      onError: approveError => {
        notifyError('Um erro ocorreu', approveError);
      },
    }
  );

  const getNextDispatchStatus = status => {
    switch (status) {
      case SHIPMENT_DISPATCH_AWAITING:
        return SHIPMENT_DISPATCH_PROCESSING;
      case SHIPMENT_DISPATCH_PROCESSING:
        return SHIPMENT_DISPATCH_PROCESSED;
      default:
        return null;
    }
  };

  const handleUpdateStatus = () => {
    const modalTitle =
      'Deseja atualizar o status de expedição dos pedidos selecionados?';
    const ids = orders.map(row => row.id);
    const status = orders[0].shipmentDispatch?.status;

    Modal.confirm({
      title: modalTitle,
      okText: 'confirmar',
      cancelText: 'cancelar',
      onOk: () => {
        updateStatus({
          status: getNextDispatchStatus(status),
          ids,
        });
      },
    });
  };

  const columns = generateColumns(removeOrder);

  return (
    <Card
      data-testid="orderCard"
      title={
        <Row gutter={16} type="flex" align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Tooltip
              placement="bottom"
              title="Voltar para a listagem dos pedidos"
            >
              <Link to="/manage/orders/bag">
                <ArrowLeftOutlined data-testid="backToListBtn" />
              </Link>
            </Tooltip>
            <span data-testid="orderTitle" style={{ marginLeft: 10 }}>
              GERAR REMESSA - Pedidos de Sacola do Bem
            </span>
          </Col>
          <Col md={12} xs={24}>
            <Row type="flex" justify="end" align="middle">
              <Button
                data-testid="goToInvoiceBtn"
                type="primary"
                href={process.env.REACT_APP_BI_REPORT_URL}
                target="_blank"
                style={{
                  padding: '0px 15px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Exibir Relatório
              </Button>
            </Row>
          </Col>
        </Row>
      }
    >
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter onSubmit={handleSubmit} loading={loading} />
      </Card.Grid>

      <Card.Grid style={{ width: '100%' }}>
        {error ? (
          <Alert message={getErrorMessage(error)} type="error" showIcon />
        ) : (
          <>
            <Table
              data-testid="table"
              dataSource={orders}
              columns={columns}
              loading={loading}
              rowKey={record => record.id}
              bordered
              scroll={{
                x: true,
              }}
              pagination={false}
            />
            <Row gutter={16} type="flex" align="middle" justify="space-between">
              <Col md={24}>
                <Row type="flex" justify="end" align="middle">
                  <Button
                    data-testid="goToInvoiceBtn"
                    type="primary"
                    loading={submitLoading}
                    disabled={!orders.length}
                    onClick={handleUpdateStatus}
                    style={{
                      marginTop: 30,
                      padding: '0px 15px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Enviar
                  </Button>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Card.Grid>
    </Card>
  );
}

export default OrderShipmentList;
