import React from 'react';
import PropTypes from 'prop-types';

import SvgIcons from '~/assets/svg/SvgIcons';
import { ICON_SIZE } from '~/constants/IconSize';

const SetIcon = ({ name, color, size, title, ...props }) => {
  const icon = SvgIcons.find(item => item.name === name);
  if (!icon) return null;

  function svgShapes(svgData) {
    const svgElements = Object.keys(svgData).map(svgProp => {
      const data = svgData[svgProp];

      if (svgProp === 'paths' && data.length > 0) {
        return data.map((path, index) => {
          const AnimationTransform = () => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <animateTransform {...path.animation} />
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <path d={path.d} key={`key${index}`}>
              {path.animation ? AnimationTransform : null}/
            </path>
          );
        });
      }

      return '';
    });

    return svgElements;
  }

  const svgContent = svgShapes(icon.svgData);

  const setSize = sizeValue => {
    // eslint-disable-next-line no-nested-ternary
    const givenSize = sizeValue < 1 ? 1 : sizeValue > 9 ? 9 : sizeValue;
    const iconSIze = ICON_SIZE.find(s => s.key === givenSize);
    return iconSIze.size;
  };

  return (
    <svg
      data-testid="set-icon-component"
      width={setSize(size)}
      height={setSize(size)}
      viewBox={icon.viewBox}
      aria-label={title || icon.description}
      fill={color}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {svgContent}
    </svg>
  );
};

SetIcon.defaultProps = {
  height: 10,
  width: 10,
  color: '#000',
  size: 3,
  title: null,
  style: {},
  onClick: () => {},
};

SetIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default SetIcon;
