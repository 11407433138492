import React, { useState } from 'react';
import { Row, Col, Input } from 'antd';
import PropTypes from 'prop-types';

function Filter({ onSubmit, loading }) {
  const [orderId, setOrderId] = useState(null);
  const handleSubmit = () => {
    onSubmit(orderId);
    setOrderId(null);
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
        <Col xl={24} lg={24} xs={24}>
          <Input.Search
            placeholder="Buscar por número do pedido"
            onSearch={handleSubmit}
            onPressEnter={handleSubmit}
            value={orderId}
            loading={loading}
            onChange={e => {
              setOrderId(e.target.value);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

Filter.defaultProps = {
  onSubmit: () => {},
  loading: false,
};

Filter.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default Filter;
