import React from 'react';
import PropTypes from 'prop-types';
import { DragSortTableProvider } from '~/providers/DragSortTable';
import BannerForm from './BannerForm';

export const BlockCarousel = ({ block, isEdit }) => (
  <DragSortTableProvider>
    <BannerForm block={block} isEdit={isEdit} />
  </DragSortTableProvider>
);

BlockCarousel.propTypes = {
  isEdit: PropTypes.bool,
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    weightPosition: PropTypes.number.isRequired,
  }),
};

BlockCarousel.defaultProps = {
  isEdit: false,
  block: {
    id: 0,
    kind: 'banner_carousel',
    title: '',
    published: false,
    weightPosition: 0,
    blockBanners: [],
  },
};
