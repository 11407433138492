import React, { useEffect } from 'react';
import { Row, Col, Typography, Select, Form } from 'antd';
import PropTypes from 'prop-types';

import {
  COUPON_ACTION_ORDER_ADJUST,
  COUPON_ACTION_FREE_SHIPPING,
} from '~/constants/Coupon';
import OrderAdjust from './actions/OrderAdjust';
// import FreeShipping from './actions/FreeShipping';

const { Option } = Select;

function ActionsForm({
  form,
  actions,
  actionsFlags,
  setActionsFlags,
  errors,
  isEdit,
}) {
  const { Text } = Typography;

  function handleChangeActions(values) {
    const aux = { ...actionsFlags };
    Object.keys(aux).forEach(key => {
      aux[key] = values.includes(key);
    });
    setActionsFlags(aux);
  }

  useEffect(() => {
    if (actions && actions?.initialActions) {
      handleChangeActions(Object.keys(actions.initialActions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newActions = Object.keys(actionsFlags).filter(
      actionKey => actionsFlags[actionKey] && actionKey
    );

    const formActions = form.getFieldsValue(true)?.actions;

    if (formActions && newActions.length) {
      Object.keys(formActions).forEach(
        action => !newActions.includes(action) && delete formActions[action]
      );
      form.setFieldsValue({ actions: { ...formActions } });
    }
    form.setFieldsValue({ Acoes: newActions });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionsFlags]);

  function removeSelectedAction(action) {
    setActionsFlags({ ...actionsFlags, [action]: false });
  }

  return (
    <div data-testid="couponActions">
      <Row gutter={16} type="flex" align="top" justify="start">
        <Col span={24} style={{ marginBottom: '10px' }}>
          <Text>Seleciona as ações do cupom</Text>
        </Col>
        <Col span={24} style={{ marginBottom: '10px' }}>
          <Form.Item
            name="Acoes"
            rules={[
              {
                required: true,
                message: 'Selecione ao menos uma ação',
              },
            ]}
          >
            <Select
              data-testid="couponActionsSelector"
              mode="multiple"
              onChange={values => handleChangeActions(values)}
              style={{ width: '100%' }}
            >
              <Option
                value={COUPON_ACTION_ORDER_ADJUST}
                disabled={Boolean(actions?.initialActions?.orderAdjust)}
              >
                Desconto no pedido
              </Option>
              {/*
                <Option
                  value={COUPON_ACTION_FREE_SHIPPING}
                  data-testid={COUPON_ACTION_FREE_SHIPPING}
                >
                  Oferecer frete grátis?
                </Option>
              */}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        {actionsFlags.orderAdjust ? (
          <Col span={24}>
            <OrderAdjust
              initialActions={actions?.initialActions?.orderAdjust}
              error={errors.includes('order_adjust')}
              isEdit={isEdit}
              form={form}
              deleteHandler={() => {
                removeSelectedAction(COUPON_ACTION_ORDER_ADJUST);
              }}
            />
          </Col>
        ) : null}
        {/* actionsFlags.freeShipping ? (
          <Col span={24}>
            <FreeShipping
              initialActions={actions?.initialActions?.freeShipping?.isActive}
              error={errors.includes('free_shipping')}
              form={form}
              deleteHandler={() => {
                removeSelectedAction(COUPON_ACTION_FREE_SHIPPING);
              }}
            />
          </Col>
        ) : null */}
      </Row>
    </div>
  );
}

ActionsForm.propTypes = {
  form: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object),
  actionsFlags: PropTypes.instanceOf(Object).isRequired,
  setActionsFlags: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  isEdit: PropTypes.bool.isRequired,
};

ActionsForm.defaultProps = {
  actions: {},
  errors: [],
};

export default ActionsForm;
