import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip, Row, Col } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';

import { useMutation } from '@apollo/react-hooks';
import { useDragSortTableContext } from '~/providers/DragSortTable';

import history from '~/services/history';

import Button from '~/components/Button';
import {
  BLOCK_CREATE,
  BLOCK_BANNER_CREATE,
  BLOCK_UPDATE,
  BLOCK_BANNER_UPDATE,
  BLOCK_BANNER_DELETE,
} from '~/apollo/marketing/home';
import useUpdateBlockAttribute from '~/hooks/marketing/attributes/useUpdateAttributes';
import FormBanner from './FormBanner';
import FormBlock from './FormBlock';
import ListBanners from './ListBanners';

import { onCreateBlock, onUpdateBlock } from './actions';
import FormAttribute from './FormAttribute';
import ListAttributes from './ListAttributes';

function BannerForm(props) {
  const { isEdit, block: propsBlock } = props;
  const [loading, setLoading] = useState(false);
  const [block, setBlock] = useState(propsBlock);
  const [blockBannerItem, setBlockBannerItem] = useState({});
  const [blockAttributeItem, setBlockAttributeItem] = useState({});
  const [attributeData, setAttributeData] = useState([]);

  const { tableData, setTableData } = useDragSortTableContext();

  const isCarousel = block?.kind === 'banner_carousel';

  const [navigationCreateBlockBanner] = useMutation(BLOCK_BANNER_CREATE, {
    refetchQueries: ['homeSearchBlockByID'],
  });
  const [navigationUpdateBlock] = useMutation(BLOCK_UPDATE, {
    refetchQueries: ['homeSearchBlockByID'],
  });
  const [homeBlocksCreateBlock] = useMutation(BLOCK_CREATE, {
    refetchQueries: ['homeBlocksSearchBlocks'],
  });

  const [navigationDeleteBlockBanner] = useMutation(BLOCK_BANNER_DELETE, {
    refetchQueries: ['homeSearchBlockByID'],
  });
  const [navigationUpdateBlockBanner] = useMutation(BLOCK_BANNER_UPDATE, {
    refetchQueries: ['homeSearchBlockByID'],
  });

  const [updateAttribute] = useUpdateBlockAttribute({
    refetchQueries: ['homeSearchBlockByID'],
  });

  useEffect(() => {
    setBlock(propsBlock);
  }, [propsBlock]);

  function handleUpdateBlock() {
    onUpdateBlock(tableData, attributeData, {
      setLoading,
      navigationUpdateBlock,
      navigationUpdateBlockBanner,
      updateAttribute,
      block,
    });
  }

  const buttonSaveBlock = (
    <Col>
      <Button
        spacing
        type="primary"
        htmlType="button"
        icon={<SaveOutlined />}
        onClick={e => {
          e.preventDefault();
          handleUpdateBlock();
        }}
        data-testid="homeAddBannerBlockBtn"
        style={{ margin: 0 }}
        loading={loading}
      >
        {loading ? 'Salvando...' : 'Salvar'}
      </Button>
    </Col>
  );

  const headerCard = (
    <Row gutter={16} type="flex" justify="space-between" align="middle">
      <Col>
        <Tooltip placement="bottom" title="Voltar para blocos">
          <ArrowLeftOutlined
            onClick={history.goBack}
            data-testid="homeBackToBlocksBtn"
          />
        </Tooltip>
        <span style={{ marginLeft: '10px' }}>
          Home - Bloco de {isCarousel ? 'carrossel' : 'banner'}
        </span>
      </Col>
      {isEdit ? buttonSaveBlock : false}
    </Row>
  );

  const updateBlockBanner = id => {
    const selectedBanner = block.blockBanners.find(banner => banner.id === id);

    setBlockBannerItem(selectedBanner);
  };

  const listBanners = () => (
    <ListBanners
      block={block}
      setBlock={setBlock}
      navigationDeleteBlockBanner={navigationDeleteBlockBanner}
      navigationUpdateBlockBanner={navigationUpdateBlockBanner}
      tableData={tableData}
      setTableData={setTableData}
      updateBlockBanner={updateBlockBanner}
    />
  );

  const managerBanner = () => (
    <>
      <FormBanner
        block={block}
        navigationCreateBlockBanner={navigationCreateBlockBanner}
        blockBannerItem={blockBannerItem}
        navigationUpdateBlockBanner={navigationUpdateBlockBanner}
        setBlockBannerItem={setBlockBannerItem}
      />
      {block.blockBanners.length ? listBanners() : false}
    </>
  );

  const updateBlockAttribute = id => {
    const selectedAttribute = block.blockAttributesBar.find(
      attr => attr.id === id
    );
    setBlockAttributeItem(selectedAttribute);
  };

  const manageAttributeBar = () => {
    if (!isCarousel) return null;
    return (
      <>
        <FormAttribute
          block={block}
          updateAttribute={updateAttribute}
          blockAttributeItem={blockAttributeItem}
          setBlockAttributeItem={setBlockAttributeItem}
        />
        {block?.blockAttributesBar?.length ? (
          <ListAttributes
            block={block}
            setBlock={setBlock}
            attributeData={attributeData}
            setAttributeData={setAttributeData}
            updateBlockAttribute={updateBlockAttribute}
          />
        ) : null}
      </>
    );
  };

  const render = (
    <Card data-testid="homeCreateBannerBlockForm" title={headerCard}>
      <FormBlock
        title={block.title}
        block={block}
        createNewBlockBanner={values => {
          onCreateBlock(values, {
            block,
            homeBlocksCreateBlock,
            setLoading,
          });
        }}
        isEdit={isEdit}
        setBlock={setBlock}
      />
      {manageAttributeBar()}
      {managerBanner()}
    </Card>
  );

  return render;
}

BannerForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    published: PropTypes.bool.isRequired,
    weightPosition: PropTypes.number.isRequired,
  }),
};

BannerForm.defaultProps = {
  block: {
    id: 0,
    kind: 'banner',
    title: '',
    published: false,
    weightPosition: 0,
    blockBanners: [],
  },
};

export default BannerForm;
