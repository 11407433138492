import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { TECNOLOGIA, USER_GROUPS } from '~/constants/UserGroups';
import { oneOfAzureGroups } from '~/util/Auth';

const MenuItemIcon = styled.span`
  margin-right: 5px;
`;

function SidebarItem({
  itemKey,
  to,
  icon,
  label,
  currentGroups,
  routeGroups,
  ...restOfProps
}) {
  const MenuItem = Menu.Item;
  const ItemIcon = () => icon;

  const allowedGroups = currentGroups.filter(current =>
    routeGroups.includes(current)
  );

  if (allowedGroups.length > 0) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <MenuItem key={itemKey} {...restOfProps}>
        <MenuItemIcon>
          <ItemIcon />
        </MenuItemIcon>
        <Link to={to}>{label}</Link>
      </MenuItem>
    );
  }

  return null;
}

SidebarItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  routeGroups: PropTypes.arrayOf(PropTypes.oneOf(USER_GROUPS)),
  currentGroups: oneOfAzureGroups,
};

SidebarItem.defaultProps = {
  routeGroups: [TECNOLOGIA],
  currentGroups: [],
};

export default SidebarItem;
