import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from '../../../../../components/Button';
import { removeRulesRow, promotionFieldIsDisabled } from '~/util/Promotion';
import { PROMOTION_EDIT_MODE, TABLE_KEY_DISCOUNT } from './constants';

const generateColumnTitle = title => {
  return `Filtro: ${title}`;
};

export function generateFilterColumns({
  title,
  tableKey,
  dispatch,
  setSelectedBrands,
  form,
}) {
  const isEdit = window.location.pathname.includes(PROMOTION_EDIT_MODE);

  const handleRemoveRow = id => {
    removeRulesRow(dispatch, tableKey, id)();
    if (setSelectedBrands) {
      setSelectedBrands(prevState =>
        prevState.filter(brand => brand.id !== id)
      );
    }

    if (tableKey === TABLE_KEY_DISCOUNT) {
      const { property, filters } = form.getFieldsValue('discount');

      if (property) {
        const filteredValues = filters.filter(item => item !== id);

        form.setFieldsValue({
          filters: filteredValues,
        });
      }
    }
  };

  const data = [
    {
      title: generateColumnTitle(title),
      dataIndex: 'presentation',
      key: 'presentation',
      render: (text, record) => {
        if (tableKey === 'REPASSE SOLIDÁRIO') {
          return `doação de ${text}%`;
        }
        if (tableKey === 'DESCONTOS') {
          if (record.gte && !record.lte) {
            return `mais de ${record.gte}%`;
          }
          if (record.lte && !record.gte) {
            return `até ${record.lte}%`;
          }
          return `de ${record.gte}% até ${record.lte}%`;
        }
        if (tableKey === 'Marca') {
          return record.name;
        }
        return text;
      },
    },
    // TODO: Versão 2.0 Backoffice
    // {
    //   title: 'Quantidade',
    //   dataIndex: 'quantity',
    //   key: 'quantity',
    //   width: '50px',
    //   render: () => {
    //     return 0;
    //   },
    // },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (text, record) => {
        return (
          <div key={record.id}>
            <Tooltip placement="bottom" title="Remover">
              <Button
                data-testid="deleteBtn"
                type="link"
                icon={<CloseOutlined />}
                size="small"
                style={{ margin: 0, color: promotionFieldIsDisabled() }}
                disabled={isEdit}
                onClick={() => handleRemoveRow(record.id)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return data;
}
