import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

export const QUERY_SEARCH_DISPATCH_HISTORIC = gql`
  query useSearchDispatchHistoric($bagOrderId: Int) {
    bagsBagAdminFindDispatchBagOrder(bagOrderId: $bagOrderId) {
      id
      status
      updatedAt
      historic
    }
  }
`;

function useSearchDispatchHistoric(options = {}) {
  const [searchDispatchHistoric, { data, error, loading, refetch }] =
    useLazyQuery(QUERY_SEARCH_DISPATCH_HISTORIC, {
      fetchPolicy: 'no-cache',
      ...options,
    });

  return [
    ({ bagOrderId = 0 }) => {
      searchDispatchHistoric({
        variables: {
          bagOrderId,
        },
      });
    },
    {
      data: data?.bagsBagAdminFindDispatchBagOrder || [],
      historic: data?.bagsBagAdminFindDispatchBagOrder?.historic || [],
      loading,
      error,
      refetch,
    },
  ];
}

export default useSearchDispatchHistoric;
