import React from 'react';
import { Tooltip } from 'antd';
import {
  DeleteOutlined,
  SkinOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import DragHandle from '~/components/DragSortTable/DragHandle';
import Button from '~/components/Button';
import { getImageUrl } from '~/util/Functions';
import Image from '~/components/Image';
import { handleDelete } from './actions';

export function generateColumns(deleteBlockProduct, tableData, setTableData) {
  return [
    {
      title: '#',
      dataIndex: 'drag',
      key: 'drag',
      fixed: 'left',
      render: () => <DragHandle />,
    },
    {
      title: 'Imagem',
      dataIndex: 'images',
      key: 'images',
      render: (text, obj) => {
        const [source] = obj.product.images;
        if (source) {
          return (
            <Image
              isAntd
              width={50}
              src={getImageUrl(source.imageUrl)}
              preview={{
                // src: source.imageUrl,
                mask: <ZoomInOutlined />,
              }}
            />
          );
        }
        return <Image width={50} icon={<SkinOutlined />} />;
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (text, obj) => {
        return <span>{obj.product.name}</span>;
      },
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      render: (text, obj) => {
        const [source] = obj.product.categories;
        if (obj.product.categories.length === 0) return 'Sem categoria';
        return source.name;
      },
    },
    {
      title: 'Preço',
      key: 'price',
      render: (text, obj) =>
        obj.product.saleState === 'active' ? (
          <>
            <span>R$ {obj.product.price}</span>
            <span
              style={{
                marginLeft: '5px',
                color: '#CCC',
                textDecoration: 'line-through',
              }}
            >
              R$ {obj.product.salePrice}
            </span>
          </>
        ) : (
          <span>R$ {obj.product.price}</span>
        ),
    },
    {
      title: 'Promoção',
      dataIndex: 'sale',
      key: 'sale',
      render: (text, obj) =>
        obj.product.saleState === 'active' ? 'Sim' : 'Não',
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 80,
      render: (text, obj) => (
        <>
          <Tooltip placement="bottom" title="Excluir">
            <Button
              data-testid="homeRemoveProductItemBtn"
              type="link"
              icon={<DeleteOutlined />}
              onClick={() =>
                handleDelete(
                  obj.id,
                  deleteBlockProduct,
                  tableData,
                  setTableData
                )
              }
              size="small"
              style={{ marginBottom: 0, color: 'red' }}
            />
          </Tooltip>
        </>
      ),
    },
  ];
}
