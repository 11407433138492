import React from 'react';
import { notification } from 'antd';
import LoadingBar from '~/components/LoadingBar';

export const notificationInfoWithTimeBar = ({
  message,
  duration = 10,
  key = 'infoMessage',
  onClose = () => {},
  ...props
} = {}) => {
  let secondsElapsed = 0;
  let updateInterval;

  const customOnClose = () => {
    clearInterval(updateInterval);
    onClose();
  };

  updateInterval = setInterval(() => {
    const newPercent = 100 - secondsElapsed * 10;
    secondsElapsed += 1;

    notification.info({
      key,
      message: (
        <>
          {message}
          <LoadingBar
            color="#40a9ff"
            percent={newPercent < 0 ? 0 : newPercent}
            animation={false}
          />
        </>
      ),
      duration: duration - secondsElapsed,
      onClose: customOnClose,
      ...props,
    });
  }, 1000);

  notification.info({
    key,
    message: (
      <>
        {message}
        <LoadingBar color="#40a9ff" percent={100} animation={false} />
      </>
    ),
    onClose: customOnClose,
    ...props,
  });
};
