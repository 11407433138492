import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import { removeRulesRow, promotionFieldIsDisabled } from '~/util/Promotion';
import { PROMOTION_EDIT_MODE } from './constants';

export function generateConditionColumns({ tableKey, dispatch }) {
  const isEdit = window.location.pathname.includes(PROMOTION_EDIT_MODE);

  const data = [
    {
      title: 'Estado de uso',
      dataIndex: 'condition',
      key: 'condition',
      render: (text, record) => {
        const values = {
          new_with_tag: 'Nova com etiqueta',
          kindly_used: 'Gentilmente usada',
        }[record];
        return `Estado de uso - ${values}`;
      },
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: '50px',
      render: (text, record) => (
        <div key={record.key}>
          <Tooltip placement="bottom" title="Remover">
            <Button
              data-testid="viewBtn"
              type="link"
              icon={<CloseOutlined />}
              size="small"
              disabled={isEdit}
              style={{ margin: 0, color: promotionFieldIsDisabled() }}
              onClick={removeRulesRow(dispatch, tableKey, record.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return data;
}
