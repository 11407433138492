import React from 'react';
import { Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import DragHandle from '~/components/DragSortTable/DragHandle';

export function generateColumns(handleDelete, deleteFooter) {
  return [
    {
      title: '#',
      dataIndex: 'drag',
      key: 'drag',
      width: 40,
      render: () => <DragHandle />,
    },
    {
      title: 'Categoria',
      dataIndex: 'title',
      key: 'title',
      render: (_, record) => {
        return (
          <span>
            {record.dynamicContentable
              ? record.dynamicContentable.name
              : 'Sem título'}
          </span>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      width: 100,
      render: (_, record) => (
        <Tooltip placement="bottom" title="Deletar categoria">
          <Button
            type="link"
            icon={<DeleteOutlined />}
            data-testid="deleteBtn"
            onClick={() => handleDelete(record.id, deleteFooter)}
            size="small"
            style={{ marginBottom: 0, color: 'red' }}
          />
        </Tooltip>
      ),
    },
  ];
}
