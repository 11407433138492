export const STATE_ACTIVE = 'active';
export const STATE_CART = 'cart';
export const STATE_IN_ORDER = 'in_order';
export const STATE_IN_PAYMENT = 'in_payment';
export const STATE_IN_PAYOUT = 'in_payout';
export const STATE_IN_RETURN = 'in_return';
export const STATE_IN_TRANSIT = 'in_transit';
export const STATE_NEW = 'new';
export const STATE_PENDING = 'pending';
export const STATE_RETURNED = 'returned';
export const STATE_CANCELED = 'canceled';
export const STATE_DONATED = 'donated';
export const STATE_SHORTAGE = 'shortage';

export const TRANSLATED_STATE_ACTIVE = 'À venda';
export const TRANSLATED_STATE_CART = 'Peça está reservada';
export const TRANSLATED_STATE_IN_ORDER = 'Vendido - Aguardando finalização';
export const TRANSLATED_STATE_IN_PAYMENT = 'Aguardando Pagamento';
export const TRANSLATED_STATE_IN_PAYOUT = 'Vendido - Finalizado';
export const TRANSLATED_STATE_IN_RETURN = 'Vendido - Aguardando devolução';
export const TRANSLATED_STATE_IN_TRANSIT = 'Vendido - Aguardando entrega';
export const TRANSLATED_STATE_NEW = 'Novo';
export const TRANSLATED_STATE_PENDING = 'Aguardando aprovação';
export const TRANSLATED_STATE_RETURNED = 'Devolvido';
export const TRANSLATED_STATE_CANCELED = 'Cancelado';
export const TRANSLATED_STATE_DONATED = 'Doado';
export const TRANSLATED_STATE_SHORTAGE = 'Ruptura';

export const PRODUCT_STATES = [
  STATE_ACTIVE,
  STATE_CART,
  STATE_IN_ORDER,
  STATE_IN_PAYMENT,
  STATE_IN_PAYOUT,
  STATE_IN_RETURN,
  STATE_IN_TRANSIT,
  STATE_NEW,
  STATE_PENDING,
  STATE_RETURNED,
  STATE_DONATED,
];

export const PRODUCT_STATUS = [
  { key: STATE_ACTIVE, label: TRANSLATED_STATE_ACTIVE },
  { key: STATE_IN_PAYMENT, label: TRANSLATED_STATE_IN_PAYMENT },
  { key: STATE_CART, label: TRANSLATED_STATE_CART },
  { key: STATE_CANCELED, label: TRANSLATED_STATE_CANCELED },
  { key: STATE_IN_ORDER, label: TRANSLATED_STATE_IN_ORDER },
  { key: STATE_IN_PAYOUT, label: TRANSLATED_STATE_IN_PAYOUT },
  { key: STATE_IN_RETURN, label: TRANSLATED_STATE_IN_RETURN },
  { key: STATE_IN_TRANSIT, label: TRANSLATED_STATE_IN_TRANSIT },
  { key: STATE_NEW, label: TRANSLATED_STATE_NEW },
  { key: STATE_PENDING, label: TRANSLATED_STATE_PENDING },
  { key: STATE_RETURNED, label: TRANSLATED_STATE_RETURNED },
  { key: STATE_DONATED, label: TRANSLATED_STATE_DONATED },
  { key: STATE_SHORTAGE, label: TRANSLATED_STATE_SHORTAGE },
];

export const IMAGE_POSITION_BACK = 'back';
export const IMAGE_POSITION_DETAIL = 'detail';
export const IMAGE_POSITION_LABEL = 'label';
export const IMAGE_POSITION_PRINCIPAL = 'principal';

export const PRODUCT_IMAGE_POSITIONS = [
  IMAGE_POSITION_BACK,
  IMAGE_POSITION_DETAIL,
  IMAGE_POSITION_LABEL,
  IMAGE_POSITION_PRINCIPAL,
];

export const PARTICLES_OF_PRODUCT_NAME = [
  'dos',
  'da',
  'das',
  'de',
  'com',
  'sem',
  'para',
  'e',
];

export const CONDITION_KINDLY_USED = 'kindly_used';
export const CONDITION_NEW_WITH_TAG = 'new_with_tag';

export const PRODUCT_CONDITIONS = [
  CONDITION_KINDLY_USED,
  CONDITION_NEW_WITH_TAG,
];

export const ORDER_BY_CREATED_AT_ASC = 'CREATED_AT_ASC';
export const ORDER_BY_CREATED_AT_DESC = 'CREATED_AT_DESC';
export const ORDER_BY_NAME_ASC = 'NAME_ASC';
export const ORDER_BY_NAME_DESC = 'NAME_DESC';
