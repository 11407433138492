import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import Button from '~/components/Button';

import { convertDate, formatCnpj, toCurrencyFormat } from '~/util/Functions';

export function generateColumns() {
  return [
    {
      title: 'Nome Fantasia',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (_, record) => {
        return <div style={{ width: '120px' }}>{record.lastName}</div>;
      },
    },
    {
      title: 'CNPJ',
      dataIndex: 'document',
      key: 'document',
      render: (_, record) =>
        `${record.document ? formatCnpj(record.document) : 'N/A'}`,
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (_, record) => {
        return <div style={{ width: '120px' }}>{record.mobilePhone}</div>;
      },
    },
    {
      title: 'Quantia Repassada',
      dataIndex: 'amountTransferred',
      key: 'amountTransferred',
      render: amount => toCurrencyFormat(amount),
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => convertDate(date),
    },
    // {
    //   title: 'Último login',
    //   dataIndex: 'dateLastLogin',
    //   key: 'dateLastLogin',
    //   render: date => convertDate(date),
    // }, task 5377 - comentar porque os dados estão mockados no backend
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <>{record.status === 'active' ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 40,
      render: (_, record) => (
        <>
          <Tooltip placement="bottom" title="Visualizar">
            <Link to={`/manage/service/ong/edit/${record.id}`}>
              <Button
                data-testid="viewBtn"
                type="link"
                icon={<FileSearchOutlined />}
                style={{ margin: 0 }}
              />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];
}
