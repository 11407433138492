import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GraphQLOperator from '~/pages/tech/GraphQL';
import { oneOfAzureGroups } from '~/util/Auth';
import NotFound from '~/containers/NotFound';
import GroupRoute from '../../../components/GroupRoute';

import { TECNOLOGIA } from '../../../constants/UserGroups';

const Tech = ({ match, groups }) => {
  return (
    <Switch>
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA]}
        exact
        path={`${match.url}/graphql`}
        component={GraphQLOperator}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Tech.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Tech.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Tech);
