import {
  ACTION_ADD_TABLE,
  ACTION_REMOVE_TABLE,
  ACTION_ADD_ROW,
  ACTION_ADD_MULTROW,
  ACTION_REMOVE_ROW,
  ACTION_ADD_COLOR,
  ACTION_ADD_MULTCOLOR,
  ACTION_REMOVE_COLOR,
  TABLE_TYPE_CATEGORY,
  ACTION_CLEAR,
  TABLE_KEY_BRAND,
  TABLE_KEY_DISCOUNT,
} from './constants';
import { categoryExists, addNewColors } from './actions';

export const initialState = [];

export function RulesReducer(state, action) {
  switch (action.type) {
    case ACTION_CLEAR: {
      return [];
    }
    case ACTION_ADD_TABLE: {
      if (action.payload.data.length > 0) {
        return [...state, action.payload];
      }
      return state;
    }
    case ACTION_REMOVE_TABLE: {
      return state.filter(table => table.key !== action.payload.tableKey);
    }
    case ACTION_ADD_MULTROW: {
      return state.map(table => {
        if (table.key === action.payload.tableKey) {
          if (
            action.payload.tableKey === TABLE_KEY_BRAND ||
            action.payload.tableKey === TABLE_KEY_DISCOUNT
          ) {
            table.data = [...action.payload.newRows];
            return table;
          }

          action.payload.newRows = action.payload.newRows.filter(newRow => {
            if (categoryExists(newRow, table.data)) {
              return false;
            }

            if (newRow.children) {
              table.data = table.data.filter(row => {
                return !categoryExists(row, newRow.children);
              });
            }
            return true;
          });

          table.data = [...table.data, ...action.payload.newRows];
        }
        return table;
      });
    }
    case ACTION_ADD_ROW: {
      return state.map(table => {
        if (table.key === action.payload.tableKey) {
          table.data = [...table.data, action.payload.newRow];
        }
        return table;
      });
    }
    case ACTION_REMOVE_ROW: {
      let newRows = [];
      return state.filter(table => {
        if (table.key === action.payload.tableKey) {
          table.data = table.data.filter(row => {
            let childrenChanged = false;
            if (row.children) {
              const rowChildrenSize = row.children.length;

              row.children = row.children.filter(children => {
                return children.key !== action.payload.rowKey;
              });
              childrenChanged = rowChildrenSize > row.children.length;
              if (childrenChanged) {
                const arr = row.children.map(children => {
                  children.parentName = row.title;
                  return children;
                });

                newRows = [...newRows, ...arr];
              }
            }
            return row.key !== action.payload.rowKey && !childrenChanged;
          });

          table.data = [...table.data, ...newRows];
        }
        return table.data.length > 0;
      });
    }
    case ACTION_ADD_MULTCOLOR: {
      return state.map(table => {
        if (
          table.key === action.payload.tableKey &&
          table.type === TABLE_TYPE_CATEGORY
        ) {
          table.data = table.data.map(row => {
            let added = false;
            if (row.key === action.payload.rowKey) {
              addNewColors(row.color, action.payload.newColors);
              added = true;
            }
            if (row.children) {
              row.children = row.children.map(children => {
                if (children.key === action.payload.rowKey || added) {
                  addNewColors(children.color, action.payload.newColors);
                }
                return children;
              });
            }
            return row;
          });
        }
        return table;
      });
    }
    case ACTION_ADD_COLOR: {
      return state.map(table => {
        if (
          table.key === action.payload.tableKey &&
          table.type === TABLE_TYPE_CATEGORY
        ) {
          table.data = table.data.map(row => {
            if (row.key === action.payload.rowKey) {
              row.color = [...row.color, action.payload.newColor];
            }
            if (row.children) {
              row.children = row.children.map(children => {
                if (children.key === action.payload.rowKey) {
                  children.color = [...children.color, action.payload.newColor];
                }
                return children;
              });
            }
            return row;
          });
        }
        return table;
      });
    }
    case ACTION_REMOVE_COLOR: {
      return state.map(table => {
        if (
          table.key === action.payload.tableKey &&
          table.type === TABLE_TYPE_CATEGORY
        ) {
          table.data = table.data.map(row => {
            let removed = false;
            if (row.key === action.payload.rowKey) {
              row.color = row.color.filter(
                color => color !== action.payload.colorKey
              );
              removed = true;
            }
            if (row.children) {
              row.children = row.children.map(children => {
                if (children.key === action.payload.rowKey || removed) {
                  children.color = children.color.filter(
                    color => color !== action.payload.colorKey
                  );
                }
                return children;
              });
            }
            return row;
          });
        }
        return table;
      });
    }

    default:
      throw new Error(`Not supported action ${action.type}`);
  }
}
