import styled from 'styled-components';
import { TAB_SIZE } from '~/constants/Global';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { mobileHeader } = THEME_BEHAVIOR;

export const Navbar = styled.div`
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: ${mobileHeader.bgColor};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 12px;
  display: none;
  z-index: 999;

  .anticon {
    svg {
      fill: ${mobileHeader.icon.color};
      width: ${mobileHeader.icon.size};
      height: ${mobileHeader.icon.size};
    }
  }

  @media screen and (max-width: ${TAB_SIZE}px) {
    display: flex;
  }
`;
