import React from 'react';
import { Card, Row, Col } from 'antd';

import BrandCard from './BrandCard';
import CategoryCard from './CategoryCard';
import { DragSortTableProvider } from '~/providers/DragSortTable';

function Footer() {
  return (
    <>
      <Card
        data-testid="footerCardTitle"
        title="Footer - Links"
        style={{ marginBottom: 15 }}
      >
        Adicione, remova e ordene os links que irão aparecer no footer da loja.
      </Card>
      <Row gutter={16} data-testid="footerCard">
        <Col lx={12} lg={12} md={12} xs={24}>
          <DragSortTableProvider>
            <BrandCard />
          </DragSortTableProvider>
        </Col>
        <Col lx={12} lg={12} md={12} xs={24}>
          <DragSortTableProvider>
            <CategoryCard />
          </DragSortTableProvider>
        </Col>
      </Row>
    </>
  );
}

export default Footer;
