import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Statistic } from 'antd';

const InfoCard = ({ itemsList }) => (
  <Row gutter={[16, 16]}>
    {itemsList
      .filter(item => item.value !== undefined)
      .map(item => {
        return (
          <Col
            xl={6}
            lg={8}
            md={12}
            xs={24}
            key={item.id}
            data-testid="statisticCard"
          >
            <Card>
              <Statistic title={item.title} value={item.value} precision={0} />
            </Card>
          </Col>
        );
      })}
  </Row>
);

InfoCard.propTypes = {
  itemsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ]),
    })
  ).isRequired,
};

export default InfoCard;
