import React from 'react';
import { Form, InputNumber, Row, Col, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { Card } from '../styles';

function QuantityRule({ deleteHandler, initialRules, error }) {
  const { Option } = Select;

  const formFieldValue = ['rules', 'quantityItems', 'value'];
  const formFieldOperator = ['rules', 'quantityItems', 'majorMinorOperator'];
  return (
    <Card
      $error={error}
      data-testid="quantityItems"
      size="small"
      title="Quantidade de peças:"
      extra={
        <Button
          type="link"
          size="small"
          icon={<DeleteOutlined />}
          onClick={deleteHandler}
        />
      }
    >
      <Row type="flex" align="top" justify="start">
        <span style={{ fontSize: '12px' }}>
          Insira a quantidade de peças para o cupom ser válido
        </span>
      </Row>
      <Row gutter={[16, 16]} type="flex" align="top" justify="start">
        <Col md={12} xs={24}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={formFieldOperator}
            initialValue={initialRules?.majorMinorOperator || 'eq'}
            rules={[{ required: true, message: 'Selecione uma opção' }]}
          >
            <Select data-testid="quantityItemsRuleSelect">
              <Option value="lt">Menor que</Option>
              <Option value="gt">Maior que</Option>
              <Option value="eq">Igual a</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={formFieldValue}
            initialValue={initialRules?.value}
            rules={[
              {
                required: true,
                message: 'Informe a quantidade de peças',
              },
              ({ getFieldValue }) => ({
                validator() {
                  const operator = getFieldValue(formFieldOperator);
                  const value = parseInt(getFieldValue(formFieldValue), 10);
                  if (operator === 'lt' && value < 2) {
                    return Promise.reject(
                      new Error('Quantidade escolhida insuficiente')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              min={1}
              style={{
                width: '100%',
              }}
              placeholder="Quantidade de peças"
              data-testid="quantityItemsRuleValue"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

QuantityRule.defaultProps = {
  error: false,
  initialRules: {},
};

QuantityRule.propTypes = {
  deleteHandler: PropTypes.func.isRequired,
  initialRules: PropTypes.instanceOf(Object),
  error: PropTypes.bool,
};

export default QuantityRule;
