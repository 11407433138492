import React from 'react';
import { Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { handleDelete } from './actions';
import Button from '../../../../components/Button';

export function generateColumns(hooks) {
  return [
    {
      title: 'Nome',
      dataIndex: 'name',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
        if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
        return 0;
      },
    },
    {
      title: 'Quantidade de produtos',
      dataIndex: 'productCount',
      width: 200,
      align: 'center',
    },
    {
      title: 'Ações',
      dataIndex: '',
      key: 'x',
      width: 120,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Tooltip placement="bottom" title="Editar">
              <Link to={`/manage/content/brand/edit/${record.id}`}>
                <Button
                  data-testid="editBtn"
                  type="link"
                  icon={<EditOutlined />}
                  style={{ margin: 0 }}
                />
              </Link>
            </Tooltip>
            <Tooltip placement="bottom" title="Excluir">
              <Button
                data-testid="deleteBtn"
                type="link"
                onClick={() => handleDelete(record.id, hooks)}
                style={{ margin: 0, padding: 0, color: 'red' }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];
}
