import React, { useEffect } from 'react';
import PropType from 'prop-types';
import { Typography, Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import DragSortTable from '~/components/DragSortTable';

import { onChangePublished, checkDelete } from './actions';

import { generateColumns } from './columns';

const { Text, Paragraph } = Typography;

function ListBanners({
  block,
  setBlock,
  navigationDeleteBlockBanner,
  navigationUpdateBlockBanner,
  tableData,
  setTableData,
  updateBlockBanner,
}) {
  const { Title } = Typography;

  const setOrderedTableData = currentData => {
    let newData = [];

    if (currentData?.length > 0) {
      newData = [...currentData];

      const orderedNewData = newData.sort((a, b) =>
        a.weightPosition > b.weightPosition ? 1 : -1
      );

      setTableData(orderedNewData);
    }
  };

  useEffect(() => {
    setOrderedTableData(block.blockBanners);
    // eslint-disable-next-line
  }, [block.blockBanners]);

  const expandedRowRender = {
    expandedRowRender: record => (
      <Row justify="center">
        <Col xs={24} sm={7}>
          <Paragraph style={{ padding: '0 15px' }}>
            <Text>Texto do botão: </Text>
            <Text strong>{record.buttonText || '-'}</Text>
          </Paragraph>
        </Col>
        <Col xs={24} sm={5}>
          <CustomParagraph>
            <Text>Cor do botão: </Text>
            <CustomBadge color={record.buttonBackgroundColor} />
            <Text strong> {record.buttonBackgroundColor || '-'}</Text>
          </CustomParagraph>
        </Col>
        <Col xs={24} sm={7}>
          <CustomParagraph>
            <Text>Cor do texto do botão: </Text>
            <CustomBadge color={record.buttonTextColor} />
            <Text strong>{record.buttonTextColor || '-'}</Text>
          </CustomParagraph>
        </Col>
        <Col xs={24} sm={5}>
          <CustomParagraph>
            <Text>Cor das setas: </Text>
            <CustomBadge color={record.navigationBackgroundColor} />
            <Text strong>{record.navigationBackgroundColor || '-'}</Text>
          </CustomParagraph>
        </Col>
      </Row>
    ),
  };

  const columns = generateColumns({
    block,
    setBlock,
    navigationDeleteBlockBanner,
    navigationUpdateBlockBanner,
    onChangePublished,
    checkDelete,
    updateBlockBanner,
  });

  const renderForm = () => {
    return (
      <>
        <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
          Listagem
        </Title>
        <Divider />
        <Row gutter={16}>
          <Col span={24}>
            <DragSortTable
              data={tableData}
              columns={columns}
              rowKey={item => item.id}
              pagination={false}
              title={() => `Total encontrado - ${tableData?.length}`}
              scroll={{
                x: 'max-content',
              }}
              bordered
              expandable={block.kind === 'banner' ? false : expandedRowRender}
            />
          </Col>
        </Row>
      </>
    );
  };

  return renderForm();
}

const CustomParagraph = styled(Paragraph)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
`;

const CustomBadge = styled.div`
  background: ${({ color }) => color || '#000'};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
  border: 1px #cecece solid;
`;

ListBanners.propTypes = {
  block: PropType.shape().isRequired,
  setBlock: PropType.func.isRequired,
  navigationUpdateBlockBanner: PropType.func.isRequired,
  navigationDeleteBlockBanner: PropType.func.isRequired,
};

export default ListBanners;
