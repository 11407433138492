import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  button {
    margin-right: 10px;
  }
`;

export const UploadIcon = styled.p`
  color: #707486;
  font-size: 40px;
`;
export const UploadText = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: #707486;
`;
export const UploadObs = styled.p`
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #707486;
`;

export const StatusMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 300px;

  > span {
    font-size: 40px;
    color: ${({ success }) => (success ? '#28a745' : '#dc3545')};
  }

  h3 {
    margin: 15px 0;
    font-weight: normal;
    font-size: 20px;
  }
`;
