import React, { useState, useEffect, useMemo } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import history from '~/services/history';
import LoadingBar from '~/components/LoadingBar';
import { BRAND_CREATE, BRAND_UPDATE, BRAND_BY_ID } from '~/apollo/bag/brand';
import DefaultForm from './DefaultForm';

import {
  triggerErrorNotification,
  loadBrands,
  handleSubmitDefaultForm,
} from './actions';

function BrandForm({ match }) {
  const brandId = useMemo(() => {
    return Number(match.params.id) || null;
  }, [match]);

  const [error, setError] = useState(null);
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState({
    id: brandId,
    default: {
      name: '',
      active: true,
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
    },
  });

  const [createBrand] = useMutation(BRAND_CREATE, {
    context: {
      newBackend: true,
    },
  });
  const [updateBrand] = useMutation(BRAND_UPDATE, {
    context: {
      newBackend: true,
    },
  });

  const [brandById, { data }] = useLazyQuery(BRAND_BY_ID, {
    context: {
      newBackend: true,
    },
    variables: {
      id: brandId,
    },
    onError: errorSearch => {
      triggerErrorNotification('Erro ao buscar dados da marca', errorSearch);
      setError(errorSearch);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (brandId) {
      brandById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  useEffect(() => {
    loadBrands({ data, setBrand, setLoading });
  }, [data]);

  useEffect(() => {
    if (redirectToEdit) {
      setRedirectToEdit(false);
    }
  }, [redirectToEdit]);

  if (brandId && loading) return <LoadingBar />;
  if (brandId && error) return `Error! ${error.message}`;

  if (redirectToEdit) {
    return <Redirect to={`/manage/content/brand/edit/${brand.id}`} />;
  }

  const callSubmitDefaultForm = values => {
    handleSubmitDefaultForm(values, {
      isEdit: Boolean(brandId),
      createBrand,
      updateBrand,
      brand,
      setBrand,
      setRedirectToEdit,
    });
  };

  return (
    <Card
      data-testid="brandCardForm"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para marca">
            <ArrowLeftOutlined
              onClick={history.goBack}
              style={{ marginRight: 10 }}
              data-testid="backToBtn"
            />
          </Tooltip>
          {brandId ? 'EDITAR MARCA' : 'ADICIONAR MARCA'}
        </>
      }
    >
      <DefaultForm
        handleSubmit={callSubmitDefaultForm}
        initialData={brand ? brand.default : undefined}
        isEdit={Boolean(brandId)}
      />
    </Card>
  );
}

BrandForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default BrandForm;
