import React from 'react';
import { Col, Row, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { convertDate } from '~/util/Functions';
import {
  compareBagOrderByKind,
  getBagOrderTypeLabel,
  getLegacyTriageSituation,
  isSellerCenterData,
} from '~/util/BagOrder';
import {
  BAG_ORDER_TYPE_PURCHASE,
  BAG_ORDER_TYPE_REPROVED,
  BAG_ORDER_TYPE_RETURN,
  ORDER_ORIGIN_CLIENT_BAG_LISTING,
} from '~/constants/BagOrder';

const { Text } = Typography;

export function generateColumns(userId) {
  const data = [
    {
      title: 'Sacola',
      dataIndex: 'barcode',
      key: 'barcode',
      render: (_, { id, barcode, partnerBag }) => (
        <Text>
          # {id}
          <br />
          <small>
            <Text type="secondary">({barcode})</Text>
            <br />
            {partnerBag && <Text>{partnerBag?.partner?.firstName}</Text>}
          </small>
        </Text>
      ),
    },
    {
      title: 'Pedido',
      dataIndex: 'bagOrders',
      key: 'bagOrders',
      render: (_, { bagOrders }) => {
        if (bagOrders?.length > 0) {
          const purchase = bagOrders?.find(order =>
            compareBagOrderByKind(order, BAG_ORDER_TYPE_PURCHASE)
          );
          const returnBagOrders = bagOrders?.filter(order =>
            compareBagOrderByKind(order, BAG_ORDER_TYPE_RETURN)
          );
          const reproved = bagOrders?.findLast(order =>
            compareBagOrderByKind(order, BAG_ORDER_TYPE_REPROVED)
          );

          const orders = [purchase, returnBagOrders, reproved];

          return orders.map(order => {
            if (!order || order?.length === 0 || order?.paymentStatus === null)
              return null;

            if (Array.isArray(order)) order = order?.[0];

            return (
              <Row
                key={`clientBagsBagOrder${order.id}`}
                gutter={0}
                type="flex"
                align="top"
                justify="center"
              >
                <Col xs={24}>
                  <Text type="secondary">
                    {order.id} ({getBagOrderTypeLabel(order?.kind)})
                  </Text>
                  {order?.kind !== BAG_ORDER_TYPE_REPROVED && (
                    <Tooltip
                      placement="bottom"
                      title={`Ir para detalhe do pedido ${order.id}`}
                    >
                      <Link
                        to={`/manage/orders/bag/${order.id}?from=${ORDER_ORIGIN_CLIENT_BAG_LISTING}&userId=${userId}`}
                      >
                        {' '}
                        <ExportOutlined />
                      </Link>
                    </Tooltip>
                  )}
                </Col>
              </Row>
            );
          });
        }
        return 'Não consta';
      },
    },
    {
      title: 'Curadoria',
      dataIndex: 'triageSituation',
      key: 'triageSituation',
      render: (
        _,
        { status, shipmentReceivedAt, qtyApprovedItems, qtyReprovedItems }
      ) => {
        if (isSellerCenterData(status, shipmentReceivedAt)) {
          return (
            <a
              rel="noopener noreferrer"
              href={process.env.REACT_APP_SELLER_CENTER_URL}
              target="_blank"
            >
              Seller Center <ExportOutlined />
            </a>
          );
        }

        const triageSituation = getLegacyTriageSituation(
          qtyApprovedItems,
          qtyReprovedItems
        );

        if (!triageSituation) {
          return 'Não Consta';
        }
        return (
          <Row gutter={0} type="flex" align="top" justify="center">
            <Col xs={24}>
              <Text>Aprovadas:</Text>{' '}
              <Text type="secondary">{qtyApprovedItems}</Text>
            </Col>
            <Col xs={24}>
              <Text>Reprovadas:</Text>{' '}
              <Text type="secondary">{qtyReprovedItems}</Text>
            </Col>
          </Row>
        );
      },
    },
    {
      title: 'Localização',
      dataIndex: 'warehouse',
      key: 'warehouse',
      render: (_, { warehouse }) => {
        return warehouse || 'Não Consta';
      },
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, { createdAt, updatedAt }) => (
        <>
          {convertDate(createdAt)}
          <br />
          <small>
            <Text type="secondary">
              (Última atualização: {convertDate(updatedAt)})
            </Text>
          </small>
        </>
      ),
    },
  ];

  return data;
}
