import { STATUS_NOT_RECEIVED } from '~/constants/Bag';
import {
  BAG_ORDER_TYPE_PURCHASE,
  BAG_ORDER_TYPE_REPROVED,
  BAG_ORDER_TYPE_RETURN,
  SELLER_CENTER_BAG_TRIAGEM_APROVADA,
  SELLER_CENTER_BAG_TRIAGEM_PARCIALMENTE_APROVADA,
  SELLER_CENTER_BAG_TRIAGEM_REPROVADA,
} from '~/constants/BagOrder';

export const getBagOrderTypeLabel = type => {
  switch (type) {
    case BAG_ORDER_TYPE_PURCHASE:
      return 'Adesão';
    case BAG_ORDER_TYPE_REPROVED:
      return 'Reprovadas';
    case BAG_ORDER_TYPE_RETURN:
      return 'Reversa';
    default:
      return type;
  }
};

export const getLegacyTriageSituation = (approved, reproved) => {
  if (approved > 0 && reproved === 0) return SELLER_CENTER_BAG_TRIAGEM_APROVADA;
  if (reproved > 0 && approved === 0)
    return SELLER_CENTER_BAG_TRIAGEM_REPROVADA;
  if (approved > 0 && reproved > 0)
    return SELLER_CENTER_BAG_TRIAGEM_PARCIALMENTE_APROVADA;

  return null;
};

export const isSellerCenterData = (bagStatus, shipmentReceivedAt) => {
  const receivedDate = new Date(shipmentReceivedAt).getTime();
  const cutoffDate = new Date('2024-04-01T14:00:00-03:00').getTime();
  if (bagStatus !== STATUS_NOT_RECEIVED && receivedDate < cutoffDate)
    return false;

  return true;
};

// Como não sei como vão estar as BagOrder antigas (antes do 'kind' estar implementado)
// Caso ele venha nulo, continuo olhando para o devolution.
export const compareBagOrderByKind = (order, kind) => {
  // Pedidos abandonados
  if (order?.shipmentStatus === null && order?.paymentStatus === null) {
    return false;
  }

  if (order?.kind === kind) return true;
  if (order?.kind === null && kind === BAG_ORDER_TYPE_PURCHASE) {
    // Nesse caso não há como diferenciar entre compra e reversa
    return order?.devolution === false;
  }
  if (order?.kind === null && kind === BAG_ORDER_TYPE_REPROVED)
    return order?.devolution === true;
  return false;
};
