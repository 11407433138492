import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  ArrowLeftOutlined,
  PlusOutlined,
  SyncOutlined,
  DragOutlined,
} from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import history from '~/services/history';
import Button from '~/components/Button';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import DragSortTable from '~/components/DragSortTable';

import {
  QUERY_FAQ_QUESTIONS_CATEGORIES,
  MUTATION_UPDATE_FAQ_CATEGORY_QUESTION,
  MUTATION_DELETE_FAQ_CATEGORY_QUESTION,
} from '~/apollo/content/faq';
import LoadingBar from '~/components/LoadingBar';
import { generateColumns } from './columns';
import { updateTable, handleDelete, handleSavePosition } from './actions';

function QuestionList({ match, location }) {
  const [totalCount, setTotalCount] = useState(0);
  const { tableData, setTableData } = useDragSortTableContext();
  const [subject, setSubject] = useState(
    location.state ? location.state.subjectName : ''
  );

  const catSubjectName = subject.subject;

  const [updateFaqCategoryQuestions] = useMutation(
    MUTATION_UPDATE_FAQ_CATEGORY_QUESTION,
    {
      context: {
        newBackend: true,
      },
    }
  );
  const [deleteFaqCategoryQuestion] = useMutation(
    MUTATION_DELETE_FAQ_CATEGORY_QUESTION,
    {
      context: {
        newBackend: true,
      },
      update(cache, { data: { faqDeleteFaqQuestion: removeQuestion } }) {
        try {
          const { faqSearchFaqQuestions } = cache.readQuery({
            query: QUERY_FAQ_QUESTIONS_CATEGORIES,
            variables: {
              faqSubjectId: Number(match.params.id),
            },
          });

          faqSearchFaqQuestions.nodes = faqSearchFaqQuestions.nodes.filter(
            obj => {
              return obj.id !== removeQuestion.id;
            }
          );

          cache.writeQuery({
            query: QUERY_FAQ_QUESTIONS_CATEGORIES,
            variables: {
              faqSubjectId: Number(match.params.id),
            },
            data: {
              faqSearchFaqQuestions,
            },
          });
        } catch {
          // Cache doesn't exists, do nothing
        }
      },
    }
  );
  const { loading, error, refetch } = useQuery(QUERY_FAQ_QUESTIONS_CATEGORIES, {
    context: {
      newBackend: true,
    },
    variables: {
      faqSubjectId: Number(match.params.id),
    },
    onCompleted: ({
      faqSearchFaqQuestions: { nodes: faqQuestions, totalCount: total },
    }) => {
      if (faqQuestions.length > 0) {
        faqQuestions.sort((a, b) =>
          a.weightPosition > b.weightPosition ? 1 : -1
        );

        if (faqQuestions[0].faqSubject) {
          setSubject(faqQuestions[0].faqSubject);
        }
        setTableData(faqQuestions);
        setTotalCount(total);
      }
    },
  });

  if (loading) return <LoadingBar />;
  if (error) return `Error! ${error.message}`;

  const columns = generateColumns(
    Number(match.params.id),
    handleDelete,
    tableData,
    setTableData,
    deleteFaqCategoryQuestion
  );

  const callUpdateTable = () => {
    updateTable(refetch, setSubject, setTableData);
  };

  const callHandleSavePosition = () => {
    handleSavePosition(tableData, updateFaqCategoryQuestions);
  };

  return (
    <Card
      data-testid="faqQuestionCard"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para categorias">
            <ArrowLeftOutlined
              onClick={history.goBack}
              data-testid="backToBtn"
            />
          </Tooltip>
          <span
            style={{ marginLeft: '10px' }}
          >{`FAQ - Perguntas da Categoria: ${catSubjectName}`}</span>
        </>
      }
      extra={
        <>
          <Button
            tooltip={{
              title: 'Atualizar tabela',
              placement: 'bottom',
            }}
            data-testid="updateBtn"
            type="link"
            icon={<SyncOutlined />}
            size="default"
            onClick={callUpdateTable}
            style={{ marginBottom: '0px' }}
          />
          <Link
            to={`/manage/content/faq/category/${match.params.id}/question/create`}
          >
            <Tooltip placement="bottom" title="Adicionar nova questão">
              <Button
                data-testid="addBtn"
                type="primary"
                icon={<PlusOutlined />}
                size="default"
                style={{ marginBottom: '0px' }}
              >
                Adicionar
              </Button>
            </Tooltip>
          </Link>
          <Tooltip placement="bottom" title="Salvar ordem das perguntas">
            <Button
              data-testid="saveBtn"
              icon={<DragOutlined />}
              size="default"
              type="success"
              onClick={callHandleSavePosition}
              style={{
                margin: 0,
                marginLeft: '5px',
              }}
            >
              Salvar
            </Button>
          </Tooltip>
        </>
      }
    >
      <DragSortTable
        data-testid="table"
        data={tableData}
        columns={columns}
        rowKey={record => record.id}
        bordered
        title={() => `Total encontrado - ${totalCount}`}
        scroll={{
          x: 'max-content',
        }}
        pagination={false}
      />
    </Card>
  );
}

QuestionList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,

  location: PropTypes.shape({
    state: PropTypes.shape({
      subjectName: PropTypes.shape().isRequired,
      subject: PropTypes.shape({
        subject: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default QuestionList;
