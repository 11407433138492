import React from 'react';
import { useToggleMenuContext } from '~/providers/ToggleMenu';
import MenuHeader from './MenuHeader';
import MenuContent from './MenuContent';
import { MenuContainer, StyledDrawer, MenuContentWrap } from './styles';

const MainMenu = () => {
  const { menuActive, setMenuActive } = useToggleMenuContext();

  return (
    <>
      <MenuContainer className="menu-desktop">
        <MenuHeader />
        <MenuContentWrap>
          <MenuContent />
        </MenuContentWrap>
      </MenuContainer>

      <MenuContainer className="menu-mobile">
        <StyledDrawer
          placement="left"
          closable
          onClose={() => setMenuActive(false)}
          open={menuActive}
        >
          <MenuHeader />
          <MenuContent />
        </StyledDrawer>
      </MenuContainer>
    </>
  );
};

export default MainMenu;
