import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { notifyError } from '~/util/Functions';
import {
  COUPON_OPERATOR_AND,
  COUPON_CATEGORY_PROMOTION,
  COUPON_KIND_BOTH,
} from '~/constants/Coupon';

export const COUPON_FRAGMENT = gql`
  fragment CouponFragment on Coupon {
    category
    code
    description
    expiresAt
    id
    kind
    name
    startsAt
    usageLimit
    usageLimitCpf
  }
`;

export const COUPON_RULES_ACTIONS_FRAGMENT = gql`
  fragment CouponRulesActionsFragment on Coupon {
    andOrOperator
    rules
    actions
  }
`;

export const CREATE_COUPON = gql`
  mutation useCreateCoupon(
    $actions: CouponActionKindInput!
    $andOrOperator: CouponOptionVerifyRule
    $category: CouponCategory!
    $code: String!
    $description: String
    $expiresAt: ISO8601Date
    $kind: CouponKind!
    $name: String!
    $rules: CouponRuleKindInput!
    $startsAt: ISO8601Date!
    $usageLimit: Int
    $usageLimitCpf: Int
  ) {
    couponCreateCoupon(
      input: {
        actions: $actions
        andOrOperator: $andOrOperator
        category: $category
        code: $code
        description: $description
        expiresAt: $expiresAt
        kind: $kind
        name: $name
        rules: $rules
        startsAt: $startsAt
        usageLimit: $usageLimit
        usageLimitCpf: $usageLimitCpf
      }
    ) {
      ...CouponFragment
      ...CouponRulesActionsFragment
    }
  }
  ${COUPON_FRAGMENT}
  ${COUPON_RULES_ACTIONS_FRAGMENT}
`;

function useCreateCoupon(options = {}) {
  const [createCoupon, { error, data, loading }] = useMutation(CREATE_COUPON, {
    onError: errCreate => {
      notifyError('Erro ao criar cupom', errCreate);
    },
    ...options,
  });

  return [
    ({
      andOrOperator = COUPON_OPERATOR_AND,
      category = COUPON_CATEGORY_PROMOTION,
      kind = COUPON_KIND_BOTH,
      code = '',
      description = undefined,
      startsAt = undefined,
      expiresAt = undefined,
      name = '',
      usageLimit = undefined,
      usageLimitCpf = undefined,
      rules = {},
      actions = {},
    }) => {
      createCoupon({
        variables: {
          andOrOperator,
          category,
          kind,
          code,
          description,
          startsAt,
          expiresAt,
          name,
          usageLimit,
          usageLimitCpf,
          rules,
          actions,
        },
      });
    },
    {
      data: data?.couponCreateCoupon,
      loading,
      error,
    },
  ];
}

export default useCreateCoupon;
