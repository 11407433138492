import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { notifyError } from '~/util/Functions';

export const MUTATION_UPDATE_BAG_DISPATCH_STATUS = gql`
  mutation updateBagDispatchStatus(
    $status: BagOrderShipmentDispatch!
    $ids: [Int!]!
  ) {
    bagBagOrdersUpdateDispatch(input: { status: $status, ids: $ids }) {
      id
    }
  }
`;

function useUpdateBagDispatchStatus(options = {}) {
  const [updateStatus, { error, data, loading }] = useMutation(
    MUTATION_UPDATE_BAG_DISPATCH_STATUS,
    {
      onError: errUpdate => {
        notifyError('Um erro ocorreu', errUpdate);
      },
      ...options,
    }
  );

  return [
    ({ status = undefined, ids = [] }) => {
      updateStatus({
        variables: {
          status,
          ids,
        },
      });
    },
    {
      data,
      loading,
      error,
    },
  ];
}

export default useUpdateBagDispatchStatus;
