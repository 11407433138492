import { notification } from 'antd';
import React from 'react';
import {
  regexIsDateConverted,
  regexIsDateWithTimezone,
} from '~/constants/Global';

export function isJsonEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function createErrorDescription(error) {
  return error.errors
    ? error.errors.map(objError => objError.message).join(';')
    : error.message;
}

// Valida digito de verificação CNPJ
export function verifyCNPJDigit(cnpj, firstDigit) {
  const digito = Number(cnpj.charAt(cnpj.length - (firstDigit ? 2 : 1)));
  const tamanho = cnpj.length - (firstDigit ? 2 : 1);
  const numeros = cnpj.substring(0, tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }

  const resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digito) return false;

  return true;
}

export function validateCNPJ(cnpj) {
  if (!cnpj) return false;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs inválidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  if (!verifyCNPJDigit(cnpj, true)) return false;
  if (!verifyCNPJDigit(cnpj, false)) return false;
  return true;
}

export function isValidCPF(cpf) {
  if (!cpf) return false;
  // Verifica se é uma string (importante, pois pode começar com "0")
  if (typeof cpf !== 'string') return false;
  // Remove tudo que não for um dígito
  cpf = cpf.replace(/[^\d]+/g, '');
  // Verifica se o CPF tem o tamanho certo e não é formado por
  // digitos iguais. Ex.: 55555555555555
  if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
    return false;
  }
  // Faz o cálculo de verificação do CPF
  // http://www.macoratti.net/alg_cpf.htm
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i += 1)
    soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10), 10)) return false;
  soma = 0;
  for (let i = 1; i <= 10; i += 1)
    soma += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11), 10)) return false;
  return true;
}

export const formatCpf = cpf => {
  cpf = cpf.replace(/[^\d]/g, ''); // limpa a string

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // formata a string com as pontuações
};

export const formatCnpj = cnpj => {
  cnpj = cnpj.replace(/[^\d]/g, '');

  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

export const clearCpfMask = cpf => cpf.replace(/\D/g, '');

export const clearCnpjMask = cnpj => cnpj.replace(/[^\d]+/g, '');

export function getTodayISODate(time) {
  const date = new Date();

  if (time) {
    const { milliseconds, seconds, minutes, hours } = time;

    if (!milliseconds) {
      date.setMilliseconds(0);
    } else {
      date.setMilliseconds(milliseconds);
    }
    if (!seconds) {
      date.setSeconds(0);
    } else {
      date.setSeconds(seconds);
    }
    if (!minutes) {
      date.setMinutes(0);
    } else {
      date.setMinutes(minutes);
    }
    if (!hours) {
      date.setHours(0);
    } else {
      date.setHours(hours);
    }
  }

  return date.toISOString();
}

export function getToday() {
  const data = new Date();
  const dia = data.getDate().toString();
  const diaF = dia.length === 1 ? `0${dia}` : dia;
  const mes = (data.getMonth() + 1).toString();
  const mesF = mes.length === 1 ? `0${mes}` : mes;
  const anoF = data.getFullYear();
  return `${diaF}/${mesF}/${anoF}`;
}

export function clearCookies() {
  document.cookie.split(';').forEach(c => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });
}

export const getErrorMessage = err => {
  try {
    const { graphQLErrors } = err;
    if (graphQLErrors?.length)
      return graphQLErrors.map(({ message }) => message);
    throw Error('No GraphQL errors found');
  } catch {
    if (!err?.errors && !err?.message) {
      if (typeof err === 'string') return err;
      if (React.isValidElement(err)) return err;
      return '';
    }
    return err?.errors
      ? err?.errors
          .map(e => e.message.replace(/GraphQL error:/g, '').trim())
          .join('; ')
      : err?.message.replace(/GraphQL error:/g, '').trim();
  }
};

export const getGraphQLErrorCode = ({ graphQLErrors } = {}) => {
  if (graphQLErrors?.length > 0) {
    return graphQLErrors.map(e => {
      return e?.extensions?.code;
    })[0];
  }

  return null;
};

export const notifyError = (title, err) => {
  notification.error({
    message: title,
    duration: 10,
    description: getErrorMessage(err),
    placement: 'topRight',
  });
};

export const notifySuccess = (title, description) => {
  notification.success({
    message: title,
    duration: 10,
    description,
    placement: 'topRight',
  });
};

export const triggerErrorNotification = (title, err) => {
  notification.error({
    message: title,
    duration: 10,
    description: err.errors
      ? err.errors.map(objError => objError.message).join(';')
      : err.message,
    placement: 'topRight',
  });
};

export const closeAllNotifications = () => {
  notification.destroy();
};

export const triggerSuccessNotification = (title, text) => {
  notification.success({
    message: title,
    duration: 10,
    description: text,
    placement: 'topRight',
  });
};

export const convertDate = text => {
  if (!text || text === '') {
    return text;
  }

  const isDateInstance = text instanceof Date;

  if (!isDateInstance && text.match(regexIsDateConverted)) {
    return text;
  }

  if (!isDateInstance && !text.match(regexIsDateWithTimezone)) {
    text = `${text}T00:00:00-03:00`;
  }

  const date = new Date(text);
  return `
    ${date.getDate() < 10 ? '0' : ''}${date.getDate()}/
    ${date.getMonth() < 9 ? '0' : ''}${date.getMonth() + 1}/
    ${date.getFullYear()}
  `.replace(/[\\n\s]/g, '');
};

export const convertHour = text => {
  if (!text || text === '') {
    return text;
  }

  const isDateInstance = text instanceof Date;

  if (!isDateInstance && text.match(regexIsDateConverted)) {
    return text;
  }

  const date = new Date(text);
  return `
    ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:
    ${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}
  `;
};

export const addDays = (date, days) => {
  return date.setDate(date.getDate() + days);
};

export const toCurrencyFormat = amount => {
  if (!amount && amount !== 0) return 'Não consta';
  if (Number.isNaN(Number(amount))) return 'Não consta';

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(amount);
};

export const returnValueOrUndefined = (comparison, valueToReturn) =>
  valueToReturn === comparison ? undefined : valueToReturn;

export const createPaginationOptions = (
  currentPageSize,
  totalCount,
  customOptions = [10, 20, 30, 40, 50]
) => {
  return [...new Set([currentPageSize, ...customOptions])]
    .filter(size => {
      return size <= totalCount;
    })
    .sort();
};

export const FILTER_SHARPEN = 'filters:sharpen(0.5,0.5,true)';
export const FILTER_NO_UPSCALE = 'filters:no_upscale()';
export const DEFAULT_DIMENSION = '255x0';

export const normalizeAssetsUri = uri => {
  if (!uri) return '/';
  const slashUri = uri?.endsWith('/') ? '' : '/';
  return `${uri}${slashUri}`;
};

export const getImageUrl = (
  originalUrl,
  dimension = DEFAULT_DIMENSION,
  filters = false,
  resize = true
) => {
  if (!originalUrl || process.env.REACT_APP_ASSETS_LOCAL_BUCKET === 'true') {
    return originalUrl;
  }

  const imageUrl = originalUrl?.split(/\/?spree|\?/g);

  if (!imageUrl[1]) {
    return originalUrl;
  }

  const slashImage = imageUrl[1].startsWith('/') ? '' : '/';

  if (!resize) {
    return `${normalizeAssetsUri(
      process.env.REACT_APP_ASSETS_URI_RAW
    )}spree${slashImage}${imageUrl[1]}`;
  }

  if (filters) {
    return `${normalizeAssetsUri(
      process.env.REACT_APP_ASSETS_URI_FILTER
    )}fit-in/${dimension}/${filters}/spree${slashImage}${imageUrl[1]}`;
  }

  return `${normalizeAssetsUri(
    process.env.REACT_APP_ASSETS_URI_FILTER
  )}fit-in/${dimension}/spree${slashImage}${imageUrl[1]}`;
};

export const clearCepMask = cep => {
  return cep.replace('-', '');
};

export const getDDDandNumber = phoneNumber => {
  const phone = phoneNumber?.replace(/[^\d]+/gi, '');

  if (phone) {
    return {
      ddd: phone.substr(0, 2),
      phone: phone.substr(2, phone.length),
    };
  }

  return { ddd: '11', phone: '' };
};

export const formatPascalCase = (name, particles = []) => {
  if (!name?.length || !Array.isArray(particles)) return name;
  return name
    ?.toLowerCase()
    .split(' ')
    .map(item => {
      if (item?.length) {
        return particles.includes(item)
          ? item
          : `${item[0]?.toUpperCase()}${item?.slice(1)}`;
      }
      return null;
    })
    .toString()
    .replaceAll(',', ' ');
};

export const renderShipping = shipping => {
  if (!shipping) return null;
  let shippingString = '';
  if (shipping?.address1) shippingString += shipping.address1;
  if (shipping?.address2) shippingString += `, ${shipping.address2}`;
  if (shipping?.number) shippingString += `, ${shipping.number}`;
  if (shipping?.city || shipping?.state?.name) shippingString += ' - ';
  if (shipping?.city) shippingString += shipping.city;
  if (shipping?.state?.name)
    shippingString += `${shipping?.city ? '/' : null}${shipping.state.name}`;
  return shippingString || 'Não consta';
};

// Remove all undefined, null, empty strings, empty arrays, empty objects
// from root and nested
export const clearEmptyAttrs = obj => {
  if (typeof obj === 'string' && obj.length === 0) return null;
  if (obj instanceof Object) {
    if (Object.keys(obj).length === 0) {
      return null;
    }

    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        delete obj[key];
      }
      if (
        (typeof obj[key] === 'string' || obj[key] instanceof Array) &&
        obj[key].length === 0
      ) {
        delete obj[key];
      }
      if (obj[key] instanceof Object) {
        obj[key] = clearEmptyAttrs(obj[key]);
        if (!obj[key]) delete obj[key];
        if (obj[key] instanceof Object && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      if (obj[key] instanceof Array) {
        obj[key] = obj[key].filter(e => clearEmptyAttrs(e));
        if (obj[key].length === 0) delete obj[key];
      }
    });
  }
  return obj;
};
