import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  /* Switch, */
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import Button from '~/components/Button';
import { Card, Slider } from '../styles';

function OrderAdjust({ form, initialActions, deleteHandler, error, isEdit }) {
  const [activeOption, setActiveOption] = useState(
    initialActions?.discountType || 'percentage'
  );
  const { Option } = Select;
  const [percentageAdjust, setPercentageAdjust] = useState(
    initialActions?.repassaPercentage >= 0
      ? initialActions.repassaPercentage
      : 100
  );
  const isDisabledField = isEdit && Object.values(initialActions).length;

  const marks = {
    0: `${100 - percentageAdjust}% Vendedor`,
    100: `${percentageAdjust}% Repassa`,
  };

  // o slider retorna o valor referente ao campo repassaPercentage
  // a partir desse valor o campo sellerPercentage é adicionado ao formulário:
  const addSellerPercentageField = value => {
    const orderAdjust = form.getFieldsValue(['actions', 'orderAdjust'])?.actions
      ?.orderAdjust;
    const newOrderAdjust = {
      ...orderAdjust,
      sellerPercentage: 100 - value,
    };
    form.setFieldValue(['actions', 'orderAdjust'], newOrderAdjust);
  };

  useEffect(() => {
    addSellerPercentageField(percentageAdjust);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentageAdjust]);

  const onChange = value => setPercentageAdjust(value);

  return (
    <Card
      $error={error}
      data-testid="orderAdjustAction"
      size="small"
      title="Qual o método e valor do desconto?"
      extra={
        isDisabledField ? null : (
          <Button
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={deleteHandler}
          />
        )
      }
    >
      <Row gutter={16} type="flex" align="top" justify="start">
        <Col span={24}>
          <Form.Item
            label="Método"
            name={['actions', 'orderAdjust', 'discountType']}
            initialValue={activeOption}
            rules={[{ required: true, message: 'Selecione 1 opção' }]}
          >
            <Select
              style={{ width: '100%' }}
              data-testid="orderAdjustActionOption"
              onChange={value => setActiveOption(value)}
              disabled={isDisabledField}
            >
              <Option value="percentage">Porcentagem</Option>
              <Option value="value" data-testid="discountTypeValueOption">
                Valor
              </Option>
              {/* <Option value="flexAliquot">Alíquota flexível</Option>
                <Option value="orderDiscount">
                  Desconto baseado no valor total do pedido
                </Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          {activeOption === 'percentage' && (
            <Form.Item
              label="Porcentagem"
              name={['actions', 'orderAdjust', 'value']}
              initialValue={initialActions?.value}
              rules={[
                {
                  pattern: new RegExp(/^0*(?:[0-9][0-9]?|100)$/),
                  required: true,
                  message: 'Informe um valor entre 0 e 100',
                },
              ]}
            >
              <Input
                type="number"
                addonAfter="%"
                data-testid="percentageValue"
                placeholder="0"
                disabled={isDisabledField}
              />
            </Form.Item>
          )}
          {activeOption === 'value' && (
            <Form.Item
              label="Valor"
              name={['actions', 'orderAdjust', 'value']}
              initialValue={initialActions?.value}
              rules={[
                {
                  required: true,
                  message: 'Informe um valor',
                },
              ]}
            >
              <InputNumber
                decimalSeparator=","
                precision={2}
                addonBefore="R$"
                placeholder="10,00"
                disabled={isDisabledField}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={24}>
          <Form.Item
            label="De onde será descontado o cupom?"
            name={['actions', 'orderAdjust', 'repassaPercentage']}
            initialValue={percentageAdjust}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Slider
              data-testid="repassaPercentageAdjust"
              step={5}
              disabled={isDisabledField}
              trackStyle={{ backgroundColor: '#3acbc6' }}
              railStyle={{ backgroundColor: '#3acbc6' }}
              handleStyle={{ borderColor: '#3acbc6' }}
              dotStyle={{ borderColor: '#3acbc6' }}
              onChange={value => onChange(value)}
              marks={marks}
              tooltip={{
                open: false,
              }}
              style={{ margin: '0 40px 15px' }}
            />
          </Form.Item>
        </Col>
        {/* TODO: Bug 32124: Comentado até correção dessa ação do cupom */}
        {/*
        <Col span={24}>
          <Form.Item
            precision={2}
            label="Considerar valor do frete?"
            style={{ marginTop: 20 }}
            name={['actions', 'orderAdjust', 'includeShipping']}
            initialValue={initialActions?.includeShipping || false}
            valuePropName="checked"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch
              data-testid="orderAdjustIncludeShipping"
              disabled={isDisabledField}
            />
          </Form.Item>
        </Col> */}
      </Row>
    </Card>
  );
}

OrderAdjust.defaultProps = {
  error: false,
  initialActions: {
    includeShipping: false,
  },
};

OrderAdjust.propTypes = {
  initialActions: PropTypes.instanceOf(Object),
  deleteHandler: PropTypes.func.isRequired,
  error: PropTypes.bool,
  isEdit: PropTypes.bool.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

export default OrderAdjust;
