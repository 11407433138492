import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { oneOfAzureGroups } from '~/util/Auth';
import NotFound from '~/containers/NotFound';
import GroupRoute from '../../../components/GroupRoute';

import PartnerList from '../../../pages/service/partner/PartnerList';
import PartnerForm from '../../../pages/service/partner/PartnerForm';

import OngList from '../../../pages/service/ongs/OngList';
import OngForm from '../../../pages/service/ongs/OngForm';

import ClientList from '../../../pages/service/clients/ClientList';
import ClientForm from '../../../pages/service/clients/ClientForm';

import {
  TECNOLOGIA,
  ATENDIMENTO,
  LOGISTICA,
  CADASTRO,
} from '../../../constants/UserGroups';

const Service = ({ match, groups }) => {
  return (
    <Switch>
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/partner`}
        component={PartnerList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/partner/create`}
        component={PartnerForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/partner/edit/:id`}
        component={PartnerForm}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/ong`}
        component={OngList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/ong/create`}
        component={OngForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/ong/edit/:id`}
        component={OngForm}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/client`}
        component={ClientList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/client/create`}
        component={ClientForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, ATENDIMENTO, CADASTRO, LOGISTICA]}
        exact
        path={`${match.url}/client/edit/:id`}
        component={ClientForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Service.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Service.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Service);
