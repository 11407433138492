import { notification } from 'antd';
import arrayMove from 'array-move';

export const blocksOrdination = (arr, { setTableData }) => {
  arr.sort((x, y) => {
    if (x.weightPosition > y.weightPosition) return 1;
    if (x.weightPosition < y.weightPosition) return -1;
    return false;
  });

  setTableData(arr);
};

export const mutationCallback = (isCatch, newData, { setTableData }, err) => {
  switch (isCatch) {
    case false:
      notification.success({
        message: 'Alteração realizada com sucesso.',
        placement: 'topRight',
      });
      if (newData) blocksOrdination(newData, { setTableData });
      break;
    case true:
      notification.error({
        message: `Erro ao processar esta requisição.`,
        description: err?.errors
          ? err?.errors
              .map(e => e.message.replace(/GraphQL error:/g, '').trim())
              .join('; ')
          : err?.message.replace(/GraphQL error:/g, '').trim(),
        placement: 'topRight',
      });
      break;
    default:
  }
};

export const onOrdinationIndex = (
  { oldIndex, newIndex },
  { tableData, setTableData }
) => {
  const changeIndex = arrayMove(tableData, oldIndex, newIndex);
  setTableData(changeIndex);
};

export const onSaveList = ({ tableData, setTableData, updateBlockList }) => {
  const newData = [...tableData];
  const blocksChange = [];
  let statusCatch = false;

  newData.forEach((block, index) => {
    if (block.weightPosition !== index) {
      block.weightPosition = index;
      blocksChange.push(block);
    }
  });

  blocksChange.map((block, index) => {
    updateBlockList({
      context: {
        newBackend: true,
      },
      variables: {
        input: {
          id: block.id,
          weightPosition: block.weightPosition,
          kind: block.kind,
        },
      },
    }).catch(() => {
      statusCatch = true;
      return statusCatch;
    });
    if (blocksChange.length === index + 1)
      return mutationCallback(statusCatch, newData, { setTableData });
    return false;
  });
};

export const handleDelete = ({
  block,
  setTableData,
  blocksList,
  navigationDeleteBlock,
}) => {
  navigationDeleteBlock({
    variables: {
      input: {
        id: block.id,
      },
    },
  })
    .then(() => {
      const newBlockList = blocksList.filter(obj => obj.id !== block.id);
      mutationCallback(false, newBlockList, { setTableData });
    })
    .catch(err => {
      mutationCallback(true, blocksList, { setTableData }, err);
    });
};

export const handlePublished = (
  id,
  { blocksList, updateBlockList, setTableData }
) => {
  const changeVisibility = [...blocksList];
  let isCatch = false;
  let changeBlock;

  changeVisibility.forEach(block => {
    if (block.id === id) {
      block.published = !block.published;
      changeBlock = block;
    }
  });

  updateBlockList({
    context: {
      newBackend: true,
    },
    variables: {
      input: {
        id: changeBlock.id,
        published: changeBlock.published,
        kind: changeBlock.kind,
      },
    },
  })
    .catch(() => {
      isCatch = true;
      return isCatch;
    })
    .finally(() =>
      mutationCallback(isCatch, changeVisibility, { setTableData })
    );
};
