import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import NotFound from '~/containers/NotFound';
import Service from './manage/service';
import Orders from './manage/orders';
import Content from './manage/content';
import Marketing from './manage/marketing';
import Tech from './manage/tech';
import UnderConstruction from '../containers/UnderConstruction';
import Financial from './manage/financial';

function App({ match }) {
  return (
    <Switch>
      <Route path={`${match.url}manage/service`} component={Service} />
      <Route path={`${match.url}manage/orders`} component={Orders} />
      <Route path={`${match.url}manage/content`} component={Content} />
      <Route path={`${match.url}manage/marketing`} component={Marketing} />
      <Route path={`${match.url}manage/financial`} component={Financial} />
      <Route path={`${match.url}manage/tech`} component={Tech} />
      {/* Dashboard */}
      <Route path={`${match.url}`} exact component={UnderConstruction} />
      <Route component={NotFound} />
    </Switch>
  );
}

App.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default App;
