export const SHIPMENT_WAITING_SENT = 'waiting_sent';
export const SHIPMENT_SEND = 'send';
export const SHIPMENT_ON_ROUTE = 'on_route';
export const SHIPMENT_DELIVERED = 'delivered';
export const SHIPMENT_COMPLETED = 'completed';
export const SHIPMENT_DELIVERY_NOT_MADE = 'delivery_not_made';

export const SHIPMENT_DONE = 'shipment_done';
export const SHIPMENT_PENDING = 'shipment_pending';
export const SHIPMENT_PROCESSED = 'shipment_processed';
export const SHIPMENT_RECEIVED = 'shipment_received';

export const SHIPMENT_STATUSES = [
  SHIPMENT_WAITING_SENT,
  SHIPMENT_SEND,
  SHIPMENT_ON_ROUTE,
  SHIPMENT_DELIVERED,
  SHIPMENT_COMPLETED,
  SHIPMENT_DELIVERY_NOT_MADE,
  SHIPMENT_DONE,
  SHIPMENT_PENDING,
  SHIPMENT_PROCESSED,
  SHIPMENT_RECEIVED,
];

export const CORREIOS = 'post_offices';
export const TRANSPORTADORA = 'carrying';

export const SHIPMENT_STATUS = [
  { key: 0, label: 'Aguardando envio' },
  { key: 1, label: 'Encaminhado' },
  { key: 2, label: 'À caminho' },
  { key: 3, label: 'Entregue' },
  { key: 4, label: 'Finalizado' },
  { key: 5, label: 'Entrega não realizada' },
];
