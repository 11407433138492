import styled, { createGlobalStyle } from 'styled-components';

export const IconSelectStyle = createGlobalStyle`
  /* .ant-select-dropdown-hidden {
    display: block !important;
  } */

  .rc-virtual-list {
    width: 100%;
    box-sizing: border-box;
  }

  .rc-virtual-list-holder {
    width: auto;
  }

  .rc-virtual-list-holder-inner {
    display: grid !important;
    gap: 10px 10px;
    grid-template-areas: '. . . . . . . .';

    @media (max-width: 500px) {
      grid-template-areas: '. .';
    }
    @media (min-width: 501px) and (max-width: 700px) {
      grid-template-areas: '. . .';
    }
    @media (min-width: 701px) and (max-width: 1100px) {
      grid-template-areas: '. . . .';
    }
    @media (min-width: 1101px) and (max-width: 1400px) {
      grid-template-areas: '. . . . .';
    }
    @media (min-width: 1401px) and (max-width: 1700px) {
      grid-template-areas: '. . . . . .';
    }
  }

  .ant-select-item {
    padding: 0px;
    .ant-select-item-option-content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.ant-select-item-option-active {
      background-color: inherit;
    }
    .ant-select-item-option-content {
      white-space: break-spaces;
    }
  }

  .ant-select {
    &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 0px;
    }

    .ant-select-selection-item {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0px;
        width: auto;
        height: auto;
        svg {
          width: 20px;
          height: 20px;
          margin: 0px 10px;
        }
        &:before {
          content: 'Icone selecionado:';
          background-color: #d9d9d9;
          padding: 0px 10px;
        }
      }
    }

    &.ant-select-show-search {
      &.ant-select-open{
        .ant-select-selection-item {
          opacity: 0.5;
        }
      }
    }
  }
`;

export const IconListIcon = styled.div`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #e6faff;
  }
`;
