import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, menu } = THEME_BEHAVIOR;

const menuThemeColor = menu.color ? menu.color : theme.color;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
`;

export const LogoLink = styled(Link)`
  img {
    height: 50px;
    margin: 5px;
  }
`;

export const UserProfileCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  background-color: ${menuThemeColor};
  color: #fff;

  &[data-legacy='true'] {
    display: none;
  }

  > div {
    padding: 5px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  svg {
    margin-right: 5px;
    font-size: 16px;
  }
`;

export const LogoutButton = styled.div`
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: ${darken(0.1, menuThemeColor)};
  }

  &.legacy {
    &:hover {
      color: #38b8b4;
      background-color: transparent;
    }
  }
`;

export const UserProfileLegacy = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px;
  margin: 0px 20px 0px;
  border-bottom: solid 1px #e8e8e8;

  &[data-legacy='false'] {
    display: none;
  }

  .profileIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${menuThemeColor};
    color: #000;
    border-radius: 50%;
    font-size: 18px;
  }

  .profileName {
    font-size: 16px;
    margin-left: 5px;
  }

  .profileButton {
    font-size: 18px;
    border: 0px;

    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      border: 0;
      box-shadow: inherit;
      padding: 0px;
      margin: 0px;
      line-height: inherit;
      width: 15px;
      height: 15px;

      &:hover {
        background-color: transparent;
        color: ${menuThemeColor};
      }

      &:focus {
        background-color: #fff;
        color: #000;
      }

      &:not(.ant-btn-icon-only) {
        display: none;
      }
    }
  }
`;
