import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Row, Col } from 'antd';

import Search from './Search';
import TableCustomFields from './TableCustomFields';

function CustomFields({ optionTypes, properties, setCategory, form }) {
  const { Title } = Typography;

  const [table, setTable] = useState({
    totalCount: 0,
    loading: false,
  });

  return (
    <>
      <Title
        data-testid="customFieldsForm"
        level={4}
        style={{ margin: 0, fontWeight: 'normal' }}
      >
        Campos personalizados
      </Title>
      <Divider />
      <Search
        form={form}
        setCategory={setCategory}
        optionTypes={optionTypes}
        properties={properties}
      />
      <Row
        gutter={16}
        style={{ marginTop: '10px' }}
        type="flex"
        align="top"
        justify="center"
      >
        <Col span={24}>
          <TableCustomFields
            optionTypes={optionTypes}
            properties={properties}
            setCategory={setCategory}
            table={table}
            setTable={setTable}
          />
        </Col>
      </Row>
    </>
  );
}

CustomFields.propTypes = {
  optionTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  properties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCategory: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

export default CustomFields;
