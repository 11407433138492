import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Steps } from 'antd';

import {
  getPaymentStatus,
  getShipmentDispatchStatus,
  getShipmentStatus,
} from '~/util/statusConverter';
import { convertDate, convertHour } from '~/util/Functions';

import {
  BAG_ORDER_TYPE_RETURN,
  BAG_ORDER_STATUSES,
  BAG_ORDER_STATUS_CANCELED,
  BAG_ORDER_STATUS_COMPLETED,
  BAG_ORDER_STATUS_PENDING,
} from '~/constants/BagOrder';

import {
  SHIPMENT_STATUSES,
  SHIPMENT_DELIVERED as S_DELIVERED,
  SHIPMENT_WAITING_SENT as S_WAITING_SENT,
  SHIPMENT_DELIVERY_NOT_MADE as S_DELIVERY_NOT_MADE,
  SHIPMENT_SEND as S_SEND,
  SHIPMENT_ON_ROUTE as S_ON_ROUTE,
} from '~/constants/Shipment';

import {
  PAYMENT_STATUSES,
  STATUS_CREATED as P_CREATED,
  STATUS_IN_ANALYSIS as P_IN_ANALYSIS,
  STATUS_PAID as P_PAID,
  STATUS_REFUNDED as P_REFUNDED,
  STATUS_REVERSED as P_REVERSED,
  STATUS_WAITING as P_WAITING,
  STATUS_CANCELED as P_CANCELED,
  STATUS_EXPIRED as P_EXPIRED,
  STATUS_PAYMENT_PENDING as P_PENDING,
} from '~/constants/Payment';

import {
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
} from '~/constants/ShipmentDispatch';

const { Step } = Steps;

const STEP_WAIT = 'wait';
const STEP_PROCESS = 'process';
const STEP_FINISH = 'finish';
const STEP_ERROR = 'error';

const STEP_LAYOUT_HORIZONTAL = 'horizontal';
const STEP_LAYOUT_VERTICAL = 'vertical';

const INITIAL_STEP_STATE = {
  status: STEP_WAIT,
  date: null,
};

function OrderStatusSteps({
  bagOrderType,
  createdAt,
  bagOrderStatuses,
  bagOrderPaymentStatus,
  bagOrderShipmentStatus,
  bagOrderDispatchStatus,
}) {
  const [step1, setStep1] = useState(INITIAL_STEP_STATE);
  const [step2, setStep2] = useState(INITIAL_STEP_STATE);
  const [step3, setStep3] = useState(INITIAL_STEP_STATE);
  const [step4, setStep4] = useState(INITIAL_STEP_STATE);
  const [step5, setStep5] = useState(INITIAL_STEP_STATE);
  const [stepLayout, setStepLayout] = useState(STEP_LAYOUT_HORIZONTAL);

  const orderStatus = useMemo(
    () =>
      bagOrderStatuses?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        bagOrderStatuses[0]
      ),
    [bagOrderStatuses]
  );

  const paymentStatus = useMemo(
    () =>
      bagOrderPaymentStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        bagOrderPaymentStatus[0]
      ),
    [bagOrderPaymentStatus]
  );

  const shipmentStatus = useMemo(
    () =>
      bagOrderShipmentStatus?.reduce(
        (acc, cur) =>
          Date.parse(acc?.date) <= Date.parse(cur?.date) ? cur : acc,
        bagOrderShipmentStatus[0]
      ),
    [bagOrderShipmentStatus]
  );

  useEffect(() => {
    const handleWindowResize = () => {
      if (window?.innerWidth < 1366) {
        setStepLayout(STEP_LAYOUT_VERTICAL);
      } else {
        setStepLayout(STEP_LAYOUT_HORIZONTAL);
      }
    };

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (createdAt && bagOrderStatuses && bagOrderPaymentStatus) {
      if (
        [BAG_ORDER_STATUS_PENDING].includes(orderStatus?.kind) &&
        [P_CREATED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_PROCESS });
      }

      if (
        [BAG_ORDER_STATUS_PENDING].includes(orderStatus?.kind) &&
        [P_WAITING, P_IN_ANALYSIS, P_PENDING].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [BAG_ORDER_STATUS_PENDING].includes(orderStatus?.kind) &&
        [P_PAID].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        if ([S_WAITING_SENT].includes(shipmentStatus?.kind))
          setStep4({ date: shipmentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [BAG_ORDER_STATUS_PENDING, BAG_ORDER_STATUS_CANCELED].includes(
          orderStatus?.kind
        ) &&
        [P_CANCELED, P_EXPIRED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_ERROR });
      }

      if (
        [BAG_ORDER_STATUS_PENDING, BAG_ORDER_STATUS_CANCELED].includes(
          orderStatus?.kind
        ) &&
        [P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
      }

      if (
        [BAG_ORDER_STATUS_PENDING].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        if ([S_SEND, S_ON_ROUTE].includes(shipmentStatus?.kind))
          setStep4({ date: shipmentStatus?.date, status: STEP_PROCESS });
      }

      if (
        [BAG_ORDER_STATUS_PENDING, BAG_ORDER_STATUS_CANCELED].includes(
          orderStatus?.kind
        ) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        if ([S_DELIVERY_NOT_MADE].includes(shipmentStatus?.kind))
          setStep4({ date: shipmentStatus?.date, status: STEP_ERROR });
      }

      if (
        [BAG_ORDER_STATUS_PENDING].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: orderStatus?.date, status: STEP_PROCESS });
      }

      if (
        [BAG_ORDER_STATUS_COMPLETED].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: orderStatus?.date, status: STEP_FINISH });
      }

      if (
        [BAG_ORDER_STATUS_CANCELED].includes(orderStatus?.kind) &&
        [P_PAID, P_REFUNDED, P_REVERSED].includes(paymentStatus?.kind) &&
        [S_DELIVERED].includes(shipmentStatus?.kind)
      ) {
        setStep1({ date: createdAt, status: STEP_FINISH });
        setStep2({ date: paymentStatus?.date, status: STEP_FINISH });
        setStep4({ date: shipmentStatus?.date, status: STEP_FINISH });
        setStep5({ date: orderStatus?.date, status: STEP_ERROR });
      }

      if (bagOrderDispatchStatus?.status === SHIPMENT_DISPATCH_PROCESSING) {
        setStep3({
          date: bagOrderDispatchStatus?.updatedAt,
          status: STEP_PROCESS,
        });
      }

      if (bagOrderDispatchStatus?.status === SHIPMENT_DISPATCH_PROCESSED) {
        setStep3({
          date: bagOrderDispatchStatus?.updatedAt,
          status: STEP_FINISH,
        });
      }
    }
  }, [
    createdAt,
    bagOrderStatuses,
    bagOrderPaymentStatus,
    bagOrderDispatchStatus,
  ]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Steps
          data-testid="orderStep"
          responsive
          labelPlacement="vertical"
          size="small"
          direction={stepLayout}
        >
          <Step
            title="Pedido Realizado"
            description={
              step1?.date && (
                <>
                  {convertDate(step1.date)} <br /> {convertHour(step1.date)}
                </>
              )
            }
            status={step1?.status}
          />
          <Step
            title="Pagamento"
            subTitle={`${getPaymentStatus(paymentStatus?.kind)}`}
            description={
              step2?.date && (
                <>
                  {convertDate(step2.date)} <br /> {convertHour(step2.date)}
                </>
              )
            }
            status={step2?.status}
          />
          {bagOrderType !== BAG_ORDER_TYPE_RETURN && (
            <Step
              title="Expedição"
              subTitle={`${getShipmentDispatchStatus(
                bagOrderDispatchStatus?.status
              )}`}
              description={
                step3?.date && (
                  <>
                    {convertDate(step3.date)} <br /> {convertHour(step3.date)}
                  </>
                )
              }
              status={step3?.status}
            />
          )}
          <Step
            title="Envio"
            subTitle={`${getShipmentStatus(shipmentStatus?.kind)}`}
            description={
              step4?.date && (
                <>
                  {convertDate(step4.date)} <br /> {convertHour(step4.date)}
                </>
              )
            }
            status={step4?.status}
          />
          <Step
            title={
              step5?.status === STEP_FINISH
                ? 'Pedido Finalizado'
                : 'Pedido Aguardando Finalização'
            }
            description={
              step5?.date && (
                <>
                  {convertDate(step5.date)} <br /> {convertHour(step5.date)}
                </>
              )
            }
            status={step5?.status}
          />
        </Steps>
      </Col>
    </Row>
  );
}

OrderStatusSteps.defaultProps = {
  bagOrderDispatchStatus: {
    id: null,
    status: null,
    updatedAt: null,
  },
};

OrderStatusSteps.propTypes = {
  bagOrderType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  bagOrderStatuses: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      kind: PropTypes.oneOf(BAG_ORDER_STATUSES).isRequired,
    }).isRequired
  ).isRequired,
  bagOrderPaymentStatus: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      kind: PropTypes.oneOf(PAYMENT_STATUSES).isRequired,
    }).isRequired
  ).isRequired,
  bagOrderShipmentStatus: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      kind: PropTypes.oneOf(SHIPMENT_STATUSES).isRequired,
    }).isRequired
  ).isRequired,
  bagOrderDispatchStatus: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
};

export default OrderStatusSteps;
