let request;
let db;
let version = 1;

export const Stores = {
  Orders: 'orders',
};

export const initDB = () => {
  return new Promise(resolve => {
    request = indexedDB.open('localDB');
    request.onupgradeneeded = () => {
      db = request.result;
      if (!db.objectStoreNames.contains('orders')) {
        db.createObjectStore('orders', { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      resolve(true);
    };
    request.onerror = () => {
      resolve(false);
    };
  });
};

export const addData = (storeName, data) => {
  return new Promise(resolve => {
    request = indexedDB.open('localDB', version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
};

export const getStoreData = storeName => {
  return new Promise(resolve => {
    request = indexedDB.open('localDB', version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
};

export const deleteData = (storeName, key) => {
  return new Promise(resolve => {
    request = indexedDB.open('localDB', version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(key);

      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};

export const clearData = storeName => {
  return new Promise(resolve => {
    request = indexedDB.open('localDB', version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.clear();

      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
};
