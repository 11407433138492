import styled from 'styled-components';
import { Card } from 'antd';

export const CustomButton = styled.div`
  @media (min-width: 768px) {
    margin-top: 27px;
  }
`;
export const CustomButtonStickyBar = styled.div`
  margin-top: 5px;
`;

export const CustomCard = styled(Card)`
  margin-bottom: 20px;
  cursor: default;
`;
