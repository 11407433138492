import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Input } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';

import Button from '~/components/Button';

const { TextArea, Password } = Input;

function GraphQLRequester({ initialValues }) {
  const [form] = Form.useForm();

  const doRequest = ({ url, token, request }) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        query: request,
      }),
    })
      .then(response => response.json())
      .then(response => {
        form.setFieldsValue({ response: JSON.stringify(response) });
      })
      .catch(err => {
        form.setFieldsValue({ response: JSON.stringify(err) });
      });
  };

  const onFinish = values => {
    doRequest(values);
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      initialValues={initialValues}
    >
      <Row type="flex" justify="center" align="bottom" gutter={8}>
        <Col span={20}>
          <Form.Item name="url" label="URL" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button type="repassa" htmlType="submit" block>
              Enviar
            </Button>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="token"
            label="Token"
            rules={[{ required: true }]}
            tooltip={{
              title:
                'O valor inicial dessa tela é o token do seu usuário do BackOffice, para requisições do Storefront use um token do Storefront.',
              icon: <InfoCircleOutlined />,
            }}
          >
            <Password
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="center" align="middle">
        <Col span={12}>
          <Form.Item
            name="request"
            label="GraphQL"
            rules={[{ required: true }]}
          >
            <TextArea rows={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="response" label="Response">
            <TextArea rows={30} />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Form.Item>
            <Button type="repassa" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

GraphQLRequester.propTypes = {
  initialValues: PropTypes.shape({
    url: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default GraphQLRequester;
