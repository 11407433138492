import React from 'react';
import { Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Button from '../../../../../components/Button';
import { handleDelete } from '../actions';

export function generateColumns(hooks) {
  return [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apresentação',
      dataIndex: 'presentation',
      key: 'apresentation',
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <Tooltip placement="bottom" title="Excluir">
          <Button
            data-testid="homeRemoveProductItemBtn"
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record, hooks)}
            size="small"
            style={{ marginBottom: 0, color: 'red' }}
          />
        </Tooltip>
      ),
    },
  ];
}
