import React, { useEffect, useMemo } from 'react';
import { Card, Tooltip, Row, Col, Tabs, Alert, Space } from 'antd';
import {
  ArrowLeftOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '~/components/Button';
import InfoCard from '~/components/InfoCard';
import LoadingBar from '~/components/LoadingBar';
import BankForm from '~/pages/service/user/components/BankForm';
import AddressForm from '~/pages/service/user/components/AddressForm';
import TableBalance from '~/pages/service/user/components/TableBalance';
import BalanceResume from '~/pages/service/user/components/BalanceResume';

import { useListUserByDatabase } from '~/hooks/user/useListUserByDatabase';
import useUpdateStatusUser from '~/hooks/user/useUpdateStatusUser';

import { getErrorMessage, notifyError, notifySuccess } from '~/util/Functions';
import { handleBlockUser } from '~/pages/service/user/actions';
import useTabController from '~/hooks/utils/useTabController';

import {
  USER_KIND_CUSTOMER,
  USER_STATUS_ACTIVE,
  USER_TAB_BAG,
  USER_TAB_BALANCE,
  USER_TAB_DATA,
  USER_TAB_ORDER,
  USER_TAB_PRODUCTS,
} from '~/constants/Users';
import TableBag from './TableBag';
import TableProducts from './TableProducts';
import TableOrder from './TableOrder';
import BasicForm from './BasicForm';

function ClientForm({ match }) {
  const userId = useMemo(() => {
    return Number(match?.params?.id) || null;
  }, [match]);

  const existingTabs = useMemo(() => {
    if (userId) {
      return [
        USER_TAB_DATA,
        USER_TAB_ORDER,
        USER_TAB_PRODUCTS,
        USER_TAB_BAG,
        USER_TAB_BALANCE,
      ];
    }
    return [USER_TAB_DATA];
  }, [userId]);

  const { currentTab, onChange: onChangeTab } = useTabController({
    tabKeys: existingTabs,
  });

  const [queryUserById, { users, loading, error }] = useListUserByDatabase();

  const [updateStatusUser, { loading: loadingBlock }] = useUpdateStatusUser({
    onCompleted: data => {
      notifySuccess(
        'Status do cliente atualizado',
        `Cliente ${
          data?.userUpdateStatusUser?.status === USER_STATUS_ACTIVE
            ? 'desbloqueado'
            : 'bloqueado'
        } com sucesso`
      );
    },
    onError: err => {
      notifyError('Erro ao atualizar status do cliente', err);
    },
  });

  useEffect(() => {
    if (userId) {
      queryUserById({
        kind: USER_KIND_CUSTOMER,
        first: 1,
        skip: 0,
        ids: [userId],
        address: true,
        bank: true,
        full: true,
      });
    }
  }, [userId]);

  const { user, renderBankForm } = useMemo(() => {
    return {
      user: users?.[0],
      renderBankForm: users?.[0]?.addresses?.[0]?.id,
    };
  }, [users]);

  const callBlock = () => {
    if (userId) {
      handleBlockUser({
        onOk: () => updateStatusUser({ userId }),
        currentStatus: user?.status,
        userKind: USER_KIND_CUSTOMER,
      });
    }
  };

  let tabItems = [
    {
      label: 'Dados',
      key: USER_TAB_DATA,
      children: (
        <>
          <Space direction="vertical" size={32} style={{ width: '100%' }}>
            {!!userId && (
              <InfoCard
                itemsList={[
                  {
                    id: 1,
                    title: 'Pedidos',
                    value: user?.ordersCount || 0,
                  },
                  {
                    id: 2,
                    title: 'Peças compradas',
                    value: user?.purchasedCount || 0,
                  },
                  {
                    id: 3,
                    title: 'Peças a venda',
                    value: user?.sellingCount || 0,
                  },
                  {
                    id: 4,
                    title: 'Peças vendidas',
                    value: user?.soldCount || 0,
                  },
                  {
                    id: 5,
                    title: 'Sacolas',
                    value: user?.bagsGeneratedCount || 0,
                  },
                  {
                    id: 6,
                    title: 'Criado em',
                    value: user?.createdAt
                      ? moment(user?.createdAt).format('DD/MM/YYYY')
                      : undefined,
                  },
                  // {
                  //   id: 7,
                  //   title: 'Último login',
                  //   value: client.dateLastLogin
                  //     ? moment(client.dateLastLogin).format('DD/MM/YYYY')
                  //     : undefined,
                  // }, TODO: Dado mockado no backend
                ]}
              />
            )}
            <BasicForm
              initialData={user || {}}
              isEdit={!!userId}
              userKind={USER_KIND_CUSTOMER}
              userId={userId}
            />
          </Space>

          {!!userId && (
            <>
              <AddressForm
                initialData={user?.addresses?.[0] || undefined}
                isEdit={!!user?.addresses?.[0]?.id}
                userKind={USER_KIND_CUSTOMER}
                userId={userId}
              />
              {renderBankForm ? (
                <BankForm
                  initialData={user?.bankingAccount || undefined}
                  isEdit={!!user?.bankingAccount?.id}
                  userKind={USER_KIND_CUSTOMER}
                  userId={userId}
                />
              ) : (
                <Row
                  gutter={16}
                  type="flex"
                  align="top"
                  justify="center"
                  style={{ marginTop: '20px' }}
                >
                  <Col span={24}>
                    <Alert
                      message="Preencha as informações de endereço para liberar o formulário de dados bancários"
                      banner
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  if (userId) {
    const editTabItems = [
      {
        label: 'Pedidos',
        key: USER_TAB_ORDER,
        children: <TableOrder data-testid="tabPaneOrder" userId={userId} />,
      },
      {
        label: 'Produtos',
        key: USER_TAB_PRODUCTS,
        children: (
          <TableProducts data-testid="tabPaneProducts" userId={userId} />
        ),
      },
      {
        label: 'Sacolas do Bem',
        key: USER_TAB_BAG,
        children: <TableBag data-testid="tabPaneBags" userId={userId} />,
      },
      {
        label: 'Saldo',
        key: USER_TAB_BALANCE,
        children: (
          <div data-testid="tabPaneBalance">
            <BalanceResume userId={userId} />
            <TableBalance userId={userId} />
          </div>
        ),
      },
    ];

    tabItems = tabItems.concat(editTabItems);
  }

  if (userId && loading) return <LoadingBar />;

  return (
    <Card
      data-testid="cliestsCardForm"
      title={
        <Row gutter={16} type="flex" align="middle" justify="space-between">
          <Col md={12} xs={24}>
            <Tooltip placement="bottom" title="Voltar para clientes">
              <Link to="/manage/service/client">
                <ArrowLeftOutlined data-testid="backToListBtn" />
              </Link>
            </Tooltip>
            <span style={{ marginLeft: '10px' }}>
              {userId ? 'EDITAR CLIENTES' : 'ADICIONAR CLIENTE'}
            </span>
          </Col>
          <Col md={12} xs={24}>
            <Row type="flex" justify="end" align="middle">
              {user ? (
                <Button
                  data-testid="blockUserBtn"
                  loading={loadingBlock}
                  type={
                    user.status === USER_STATUS_ACTIVE ? 'danger' : 'primary'
                  }
                  icon={
                    user.status === USER_STATUS_ACTIVE ? (
                      <LockOutlined />
                    ) : (
                      <UnlockOutlined />
                    )
                  }
                  onClick={callBlock}
                  style={{ margin: 0 }}
                >
                  {user?.status === USER_STATUS_ACTIVE
                    ? 'Bloquear'
                    : 'Desbloquear'}
                </Button>
              ) : null}
            </Row>
          </Col>
        </Row>
      }
    >
      {error ? (
        <Alert message={getErrorMessage(error)} type="error" showIcon />
      ) : (
        <Tabs
          type="card"
          activeKey={currentTab}
          defaultActiveKey={USER_TAB_DATA}
          onChange={onChangeTab}
          data-testid="tabs"
          items={tabItems}
        />
      )}
    </Card>
  );
}

ClientForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default ClientForm;
