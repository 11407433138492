import { generateCategoryColumns } from './columnsCategory';
import { generateFilterColumns } from './columnsFilter';
import { generatePriceColumns } from './columnsPrice';
import { generateTimeColumns } from './columnsTime';
import { generateColorColumns } from './columnsColor';
import { generateConditionColumns } from './columnsCondition';
import {
  TABLE_TYPE_CATEGORY,
  TABLE_TYPE_FILTER_OPTION_TYPES,
  TABLE_TYPE_FILTER_BRAND,
  TABLE_TYPE_FILTER_ONG_PERCENTAGE,
  TABLE_TYPE_FILTER_DISCOUNT,
  TABLE_TYPE_PRICE,
  TABLE_TYPE_TIME,
  TABLE_TYPE_COLOR,
  TABLE_TYPE_CONDITION,
} from './constants';

export const initialTableState = ({
  key,
  type,
  title,
  dispatch,
  setAddColor,
  data,
  setSelectedBrands,
  form,
}) => {
  let columns = [];
  if (type === TABLE_TYPE_CATEGORY) {
    columns = generateCategoryColumns({
      title,
      setAddColor,
      tableKey: key,
      dispatch,
    });
  }
  if (
    type === TABLE_TYPE_FILTER_OPTION_TYPES ||
    type === TABLE_TYPE_FILTER_BRAND ||
    type === TABLE_TYPE_FILTER_DISCOUNT ||
    type === TABLE_TYPE_FILTER_ONG_PERCENTAGE
  ) {
    columns = generateFilterColumns({
      title,
      tableKey: key,
      dispatch,
      setSelectedBrands,
      form,
    });
  }
  if (type === TABLE_TYPE_PRICE) {
    columns = generatePriceColumns({
      tableKey: key,
      dispatch,
    });
  }
  if (type === TABLE_TYPE_COLOR) {
    columns = generateColorColumns({
      tableKey: key,
      dispatch,
    });
  }
  if (type === TABLE_TYPE_TIME) {
    columns = generateTimeColumns({
      tableKey: key,
      dispatch,
    });
  }

  if (type === TABLE_TYPE_CONDITION) {
    columns = generateConditionColumns({
      tableKey: key,
      dispatch,
    });
  }

  return {
    key,
    type,
    columns,
    data,
  };
};

export const categoryExists = (category, categoryList) => {
  return (
    categoryList.filter(item => {
      if (category.key === item.key) {
        return true;
      }

      let existsInChildren = [];
      if (item.children) {
        existsInChildren = item.children.filter(
          children => children.key === category.key
        );
      }

      return existsInChildren.length > 0;
    }).length > 0
  );
};

export const addNewColors = (currentColors, newColors) => {
  newColors.forEach(newColor => {
    if (currentColors.indexOf(newColor) === -1) {
      currentColors.push(newColor);
    }
  });

  return currentColors;
};
