export const homeBlocksKind = kind => {
  switch (kind) {
    case 'product':
      return 'Produto';
    case 'banner':
      return 'Banner';
    case 'banner_carousel':
      return 'Carrossel';
    default:
      return kind;
  }
};
