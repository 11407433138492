import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { ORDER_BY_CREATED_AT, ORDER_DIRECTION_ASC } from '~/constants/Order';
import { DEFAULT_PAGE_SIZE } from '~/constants/Global';

export const FRAGMENT_ORDER_SHIPPING_ADDRESS = gql`
  fragment OrderShippingAddressFragment on ShippingAddress {
    address1
    address2
    city
    id
    kind
    neighborhood
    number
    shipmentMethod
    state
    zipcode
  }
`;

export const FRAGMENT_ORDER_ITEM_PRODUCT = gql`
  fragment OrderItemProductFragment on ElasticProduct {
    id
    currentState
    slug
    price
    paymentStatus {
      date
      kind
    }
    images {
      id
      imageUrl
      position
    }
    name
    barcode
    shipmentStatus {
      date
      kind
    }
  }
`;

export const FRAGMENT_BASIC_ORDER = gql`
  fragment BasicOrderFragment on ElasticOrder {
    currentPaymentStatus
    currentShipmentDispatchStatus
    currentShipmentStatus
    currentStatus
    id
    number
    orderDate
    total
    kind
    repurchase
    shippingAddress {
      shipmentMethod
    }
    buyer {
      firstName
      lastName
      email
    }
  }
`;

export const FRAGMENT_FULL_ORDER = gql`
  fragment FullOrderFragment on ElasticOrder {
    adjustmentTotal
    bankSlipCode
    bonusTotal
    couponCode
    couponDiscount
    balanceUsed
    balanceDiscount
    currentShipmentOrderSubType
    finalShipmentTotal
    orderOriginId
    installments
    interestRate
    invoiceDueDate
    invoiceUrl
    itemTotal
    paymentTermIds
    paymentType
    shipmentMethodId
    shipmentStatus {
      date
      kind
    }
    shipmentTotal
    shippingAddress {
      ...OrderShippingAddressFragment
    }
    subsidyTotal
    trackingCode
    trackingUrl
    userId
    paymentStatus {
      date
      kind
    }
    orderStatus {
      date
      kind
    }
    shipmentDispatchStatus {
      date
      kind
    }
    orderItems {
      devolutionItem {
        currentShipmentStatus
        currentShipmentStatusDate
        id
        reverseCode
        reverseCodeExpiresAt
        shipmentCompanyName
        shipmentOrderSubType
      }
      id
      isReturnable
      itemId
      itemValue
      itemTotal
      kind
      moneySaved
      saleDiscount
      orderItemAdjustments {
        id
        label
        value
      }
      adjustmentTotal
      reverseCode
      reverseShipping
      orderItemStatus {
        kind
        date
      }
      item {
        ...OrderItemProductFragment
      }
    }
    orderAdjustments {
      id
      label
      value
    }
    buyer {
      id
      kind
      firstName
      lastName
      email
      slug
    }
  }
  ${FRAGMENT_ORDER_SHIPPING_ADDRESS}
  ${FRAGMENT_ORDER_ITEM_PRODUCT}
`;

export const QUERY_SEARCH_ORDERS = gql`
  query useSearchOrder(
    $endDate: String
    $initDate: String
    $id: Int
    $kind: OrderDevolution
    $orderBy: OrderByAtributes = ${ORDER_BY_CREATED_AT}
    $orderDirection: OrderDirection = ${ORDER_DIRECTION_ASC}
    $orderNumber: String
    $size: Int
    $skip: Int
    $status: OrderStatuses
    $currentPaymentStatus: OrderPaymentStatuses
    $currentShipmentDispatchStatus: OrderShipmentDispatchStatuses
    $currentShipmentStatus: OrderShipmentStatuses
    $repurchase: Boolean
    $userEmail: String
    $userId: Int
    $userSlug: String
    $full: Boolean = false
  ) {
    adminOrdersOrderSearchOrder(
      endDate: $endDate
      initDate: $initDate
      id: $id
      kind: $kind
      orderBy: $orderBy
      orderDirection: $orderDirection
      orderNumber: $orderNumber
      size: $size
      skip: $skip
      status: $status
      repurchase: $repurchase
      currentPaymentStatus: $currentPaymentStatus
      currentShipmentDispatchStatus: $currentShipmentDispatchStatus
      currentShipmentStatus: $currentShipmentStatus
      userEmail: $userEmail
      userId: $userId
      userSlug: $userSlug
    ) {
      nodes {
        ...BasicOrderFragment
        ...FullOrderFragment @include(if: $full)
      }
      totalCount
    }
  }
  ${FRAGMENT_BASIC_ORDER}
  ${FRAGMENT_FULL_ORDER}
`;

function useSearchOrder(options = {}) {
  const [searchOrder, { error, data, loading, refetch }] = useLazyQuery(
    QUERY_SEARCH_ORDERS,
    {
      fetchPolicy: 'no-cache',
      ...options,
    }
  );

  return [
    ({
      endDate = undefined,
      initDate = undefined,
      id = undefined,
      orderBy = ORDER_BY_CREATED_AT,
      orderDirection = ORDER_DIRECTION_ASC,
      orderNumber = undefined,
      size = DEFAULT_PAGE_SIZE,
      skip = 0,
      status = undefined,
      currentPaymentStatus = undefined,
      currentShipmentDispatchStatus = undefined,
      currentShipmentStatus = undefined,
      userEmail = undefined,
      userId = undefined,
      userSlug = undefined,
      kind = undefined,
      repurchase = undefined,
      full = false,
    }) => {
      searchOrder({
        variables: {
          endDate,
          initDate,
          id,
          orderBy,
          orderDirection,
          orderNumber,
          size,
          skip,
          status,
          currentPaymentStatus,
          currentShipmentDispatchStatus,
          currentShipmentStatus,
          userEmail,
          userId,
          userSlug,
          kind,
          repurchase,
          full,
        },
      });
    },
    {
      data: data?.adminOrdersOrderSearchOrder?.nodes || [],
      totalCount: data?.adminOrdersOrderSearchOrder?.totalCount || 0,
      loading,
      error,
      refetch,
    },
  ];
}

export default useSearchOrder;
