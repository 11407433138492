import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const IMPORT_ZIP_FILE_NFSE = gql`
  mutation useImportZipfileNfse($zipfile: [String!]!) {
    importZipfileNfse(input: { zipfile: $zipfile }) {
      totalFile
    }
  }
`;

export function useImportZipfileNfse(options = {}) {
  const [importZipfileNfse, { loading }] = useMutation(IMPORT_ZIP_FILE_NFSE, {
    awaitRefetchQueries: true,
    ...options,
  });

  return [
    ({ zipfile }) => {
      importZipfileNfse({ variables: { zipfile } });
    },
    { loading },
  ];
}
