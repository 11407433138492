import React from 'react';
import { Tooltip, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  getPaymentStatus,
  getShipmentDispatchStatus,
  getBagOrderStatus,
} from '~/util/statusConverter';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
} from '~/constants/ShipmentDispatch';
import { getBagOrderTypeLabel } from '~/util/BagOrder';
import {
  convertDate,
  toCurrencyFormat,
  formatPascalCase,
  convertHour,
} from '~/util/Functions';
import { PARTICLES_OF_NAME } from '~/constants/Users';
import Button from '~/components/Button';

export function generateColumns(removeOrder) {
  const getColorAndTextCustomBadge = status => {
    let text = getShipmentDispatchStatus(status);
    let color;
    switch (status) {
      case SHIPMENT_DISPATCH_AWAITING:
        color = '#fccd72';
        break;
      case SHIPMENT_DISPATCH_PROCESSED:
        color = '#75cf47';
        break;
      case SHIPMENT_DISPATCH_PROCESSING:
        color = '#46a6ff';
        break;
      default:
        color = '#b8b8b8';
        text = 'Sem status';
    }
    return { color, text };
  };

  const data = [
    {
      title: 'N° do Pedido',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status do Pedido',
      dataIndex: 'status',
      key: 'status',
      render: record => getBagOrderStatus(record),
    },
    {
      title: 'Quantidade de Sacolas',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Data de Criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: record => convertDate(record),
    },
    {
      title: 'Nome Completo',
      dataIndex: 'user',
      key: 'user',
      render: record =>
        formatPascalCase(
          `${record?.firstName} ${record?.lastName}`,
          PARTICLES_OF_NAME
        ),
    },
    {
      title: 'Valor Total',
      dataIndex: 'total',
      key: 'total',
      render: record => toCurrencyFormat(record),
    },
    {
      title: 'Status do Pagamento',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: record => getPaymentStatus(record),
    },
    {
      title: 'Status de Expedição',
      key: 'action',
      render: record => {
        const shipmentDispatchStatus = record?.shipmentDispatch?.status;
        const customBadgeStyle = getColorAndTextCustomBadge(
          shipmentDispatchStatus
        );

        return (
          <div
            key={record.id}
            style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
          >
            <Space>
              <CustomBadge
                statusColor={customBadgeStyle.color}
                status={shipmentDispatchStatus}
              >
                {customBadgeStyle.text}
              </CustomBadge>
            </Space>
          </div>
        );
      },
    },
    {
      title: 'Tipo do Pedido',
      key: 'kind',
      dataIndex: 'kind',
      render: record => getBagOrderTypeLabel(record),
    },
    {
      title: 'Transportadora',
      key: 'shipmentCompany',
      render: record => record?.name || 'Não costa',
    },
    {
      title: 'Ações',
      key: 'action',
      render: record => (
        <div
          key={record.id}
          style={{
            display: 'flex',
            gap: 10,
          }}
        >
          <Tooltip placement="bottom" title="Remover da seleção">
            <Button
              data-testid="viewBtn"
              type="link"
              onClick={() => removeOrder(record.id)}
              icon={<CloseOutlined />}
              size="small"
              style={{
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'red',
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return data;
}

export const CustomBadge = styled.div`
  padding: 2px 5px 2px;
  background-color: ${props => props.statusColor};
  font-size: 11px;
  border-radius: 5px;
  text-align: center;
  color: ${props =>
    props.status !== SHIPMENT_DISPATCH_AWAITING ? 'white' : null};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const historicColumns = [
  {
    title: '',
    render: (text, record, index) => {
      return index + 1;
    },
  },
  {
    title: 'Data',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: record => convertDate(record),
  },
  {
    title: 'Hora',
    dataIndex: 'updated_at',
    key: 'updated_at',
    render: record => convertHour(record),
  },
  {
    title: 'Usuário',
    dataIndex: 'employee',
    key: 'employee',
    render: record => record || 'Criado pelo sistema',
  },
  {
    title: 'Situação',
    dataIndex: 'status',
    key: 'status',
    render: record => getShipmentDispatchStatus(record),
  },
];
