import React from 'react';
import { Modal, notification } from 'antd';

import { returnValueOrUndefined } from '~/util/Functions';

export const deleteModal = ({ deleteCoupon, couponId, couponName }) => {
  Modal.confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content: 'Ao excluir este item, ele será apagado permanentemente.',
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      deleteCoupon({
        variables: {
          input: {
            id: couponId,
          },
        },
      })
        .then(() =>
          notification.success({
            message: `Cupom deletado`,
            description: (
              <div>
                O cupom <b>{couponName}</b> foi deletado com sucesso!
              </div>
            ),
            placement: 'topRight',
          })
        )
        .catch(() =>
          notification.error({
            message: `Erro ao deletar cupom`,
            description: (
              <div>
                O cupom <b>{couponName}</b> não pôde ser deletado!
              </div>
            ),
            placement: 'topRight',
          })
        );
    },
    onCancel() {
      // Cancel
    },
  });
};

export function handleSearch(searchCoupon, { setTable }) {
  setTable(table => {
    return {
      ...table,
      pageSize: 10,
      currentPage: 1,
      skip: 0,
      totalCount: 0,
      searchText: returnValueOrUndefined('', searchCoupon.searchText),
      category: returnValueOrUndefined('all', searchCoupon.category),
      kind: returnValueOrUndefined('', searchCoupon.kind),
      loading: true,
      refetch: false,
    };
  });
}
