import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Row, Col, Typography } from 'antd';
import productColors from '~/constants/ProductColors';
import { CheckColorLabel } from './styles';

const { Text } = Typography;

function ColorsModal({ newColors, setNewColors }) {
  const getStyle = color => {
    let background = '';

    if (color.url) {
      background = `url(${process.env.PUBLIC_URL}/images/colors/${color.url})`;
    } else {
      background = color.hex;
    }

    return {
      marginRight: 10,
      width: '20px',
      height: '20px',
      border: '1px solid rgba(0,0,0,0.2)',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
      borderRadius: '50%',
      background,
    };
  };

  const onChange = checkedValues => {
    setNewColors(checkedValues);
  };

  return (
    <Checkbox.Group
      style={{ width: '100%' }}
      onChange={onChange}
      value={newColors}
    >
      <Row gutter={16} type="flex" justify="start" align="top">
        {Object.keys(productColors).map(key => {
          return (
            <Col span={8} style={{ marginBottom: 15 }} key={key}>
              <Row gutter={16} type="flex" align="top" justify="start">
                <Col>
                  <Checkbox id={productColors[key].label} value={key} />
                </Col>
                <Col>
                  <CheckColorLabel htmlFor={productColors[key].label}>
                    <div key={key} style={getStyle(productColors[key])} />
                    <Text>{productColors[key].label}</Text>
                  </CheckColorLabel>
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );
}

ColorsModal.propTypes = {
  newColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setNewColors: PropTypes.func.isRequired,
};

export default ColorsModal;
