import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const UPDATE_ONG = gql`
  mutation updateOng($input: UpdateOngInput!) {
    userUpdateOng(input: $input) {
      id
      firstName
      lastName
      slug
      mobilePhone
      document
      email
      miniBio
      website
      responsible
      status
    }
  }
`;

function useUpdateOng(options = {}) {
  const [updateOng, { loading, data, error }] = useMutation(UPDATE_ONG, {
    ...options,
  });

  return [
    ({
      ongId = undefined,
      document = undefined,
      firstName = undefined,
      lastName = undefined,
      ddd = undefined,
      mobilePhone = undefined,
      responsible = undefined,
      miniBio = undefined,
      website = undefined,
    } = {}) => {
      updateOng({
        variables: {
          input: {
            userId: ongId,
            document: document ? document.replace(/[^\d]+/gi, '') : undefined,
            firstName,
            lastName,
            mobilePhone:
              ddd && mobilePhone
                ? `${ddd}${mobilePhone.replace(/[^\d]+/gi, '')}`
                : '',
            responsible,
            miniBio,
            website,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUpdateOng;
