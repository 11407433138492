import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { Form, Row, Col, Typography, Divider, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { PlusOutlined } from '@ant-design/icons';

import DragSortTable from '~/components/DragSortTable';
import LoadingPlaceholder from '~/components/LoadingPlaceholder';

import { useDragSortTableContext } from '~/providers/DragSortTable';
import {
  PRODUCTS_LIST_BY_SLUGS,
  BLOCK_PRODUCT_DELETE,
  BLOCK_PRODUCT_CREATE,
} from '~/apollo/marketing/home';
import { addProductToBlock } from './actions';

import { generateColumns } from './columns';

const ProductList = ({ form, block }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);
  const [loadingAddProductButton, setLoadingAddProductButton] = useState(false);
  const { tableData, setTableData } = useDragSortTableContext();

  const { Title } = Typography;

  const setOrderedTableData = (currentData, dataToAdd) => {
    let newData = [];

    if (dataToAdd) {
      newData.push(dataToAdd);
    }

    if (currentData?.length > 0) {
      newData = [...currentData];

      const uniqueArray = currentData.filter(
        (data, index) =>
          index ===
          currentData.findIndex(
            object =>
              JSON.stringify(object.product.name) ===
              JSON.stringify(data.product.name)
          )
      );

      const orderedNewData = uniqueArray.sort((a, b) =>
        a.weightPosition > b.weightPosition ? 1 : -1
      );

      setTableData(orderedNewData);
    }
  };

  const [createBlockProduct] = useMutation(BLOCK_PRODUCT_CREATE, {
    context: {
      newBackend: true,
    },
  });

  const [deleteBlockProduct] = useMutation(BLOCK_PRODUCT_DELETE, {
    context: {
      newBackend: true,
    },
  });

  const [getProducts] = useLazyQuery(PRODUCTS_LIST_BY_SLUGS, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: result => {
      const {
        productsProductDatabaseSearchProducts: { nodes: products },
      } = result;

      if (products.length > 0) {
        addProductToBlock(
          block.id,
          createBlockProduct,
          tableData,
          setOrderedTableData,
          products
        ).finally(() => {
          setLoadingAddProductButton(false);
          form.setFieldsValue({
            listprod: [],
          });
          setCharacterCount(0);
          window.scrollTo(0, document.body.scrollHeight);
        });
      }
      setLoading(false);
    },
    onError: errorSearch => {
      setError(errorSearch);
      setLoading(false);
    },
  });

  const columns = generateColumns(
    deleteBlockProduct,
    tableData,
    setOrderedTableData
  );

  const getSlugsFromInput = urls => {
    return urls.split('\n').map(url => {
      const slug = url.split('/');
      return slug[slug.length - 1];
    });
  };

  const handleCharacterCounter = e => setCharacterCount(e.target.value.length);

  const handleAddNewProdToBlock = () => {
    setLoading(true);
    const input = form.getFieldValue('listprod');
    const slugs = getSlugsFromInput(input);
    setLoadingAddProductButton(true);

    getProducts({
      variables: {
        slugs,
      },
    });
  };

  useEffect(() => {
    setOrderedTableData(block.blockProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);

  if (loading)
    return (
      <div data-testid="placeholders">
        <Row gutter={16}>
          <Col span={24}>
            <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
              Listagem
            </Title>
            <Divider style={{ marginTop: 5 }} />
          </Col>
          <Col xl={18} lg={17} md={16} sm={24} xs={24}>
            <LoadingPlaceholder
              style={{ width: '100%', height: '36px', marginBottom: 20 }}
            />
          </Col>
          <Col xl={6} lg={7} md={8} sm={24} xs={24}>
            <LoadingPlaceholder
              style={{ width: '100%', height: '36px', marginBottom: 20 }}
            />
          </Col>
        </Row>

        <LoadingPlaceholder
          style={{ width: '100%', height: '150px', marginBottom: 20 }}
        />
      </div>
    );
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Listagem
          </Title>
          <Divider style={{ marginTop: 5 }} />
        </Col>
        <Col xl={18} lg={17} md={16} sm={24} xs={24}>
          <Form.Item name="listprod">
            <TextArea
              onChange={handleCharacterCounter}
              data-testid="productBannerTextArea"
            />
          </Form.Item>
        </Col>
        <Col xl={6} lg={7} md={8} sm={24} xs={24}>
          <Button
            disabled={loadingAddProductButton || characterCount === 0}
            type="success"
            loading={loadingAddProductButton}
            icon={<PlusOutlined />}
            style={{ marginBottom: 20, width: '100%' }}
            onClick={handleAddNewProdToBlock}
            data-testid="productIncludeButton"
          >
            Adicionar produto(s)
          </Button>
        </Col>
      </Row>
      <DragSortTable
        data={tableData}
        columns={columns}
        rowKey={record => record.id}
        pagination={false}
        title={() => `Total encontrado - ${tableData?.length}`}
        scroll={{
          x: 'max-content',
        }}
        bordered
      />
    </>
  );
};

ProductList.propTypes = {
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    getFieldValue: PropTypes.func,
  }).isRequired,
  block: PropTypes.shape({
    blockBanners: PropTypes.arrayOf(PropTypes.any),
    blockProducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({
          categories: PropTypes.arrayOf(PropTypes.any),
          id: PropTypes.string,
          images: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              imageUrl: PropTypes.string,
            })
          ),
          name: PropTypes.string,
          price: PropTypes.number,
          salePrice: PropTypes.number,
          saleState: PropTypes.number,
        }),
        weightPosition: PropTypes.number,
      })
    ),
    id: PropTypes.number,
    kind: PropTypes.string,
    published: PropTypes.bool,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    weightPosition: PropTypes.number,
  }).isRequired,
};

export default ProductList;
