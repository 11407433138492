import {
  ACTION_REMOVE_ROW,
  PROMOTION_EDIT_MODE,
} from '~/pages/marketing/promotion/PromotionForm/RulesReducer/constants';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

export const removeRulesRow = (dispatch, tableKey, rowKey) => () => {
  if (!window.location.pathname.includes(PROMOTION_EDIT_MODE)) {
    dispatch({
      type: ACTION_REMOVE_ROW,
      payload: {
        tableKey,
        rowKey,
      },
    });
  }
};

export const promotionFieldIsDisabled = () =>
  window.location.pathname.includes(PROMOTION_EDIT_MODE)
    ? THEME_BEHAVIOR.button.disabled.bgColorSecondary
    : THEME_BEHAVIOR.button.primary.bgColor;
