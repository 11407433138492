import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useTabController = ({ tabKeys = [] }) => {
  const { pathname, search } = useLocation();
  const history = useHistory();

  const tabExists = React.useCallback(
    tab => {
      return tabKeys.includes(tab);
    },
    [tabKeys]
  );

  const onChange = useCallback(
    key => {
      const params = new URLSearchParams({ tab: key });
      history.replace({ pathname, search: params.toString() });
    },
    [history, pathname]
  );

  const currentTab = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    const exists = tabExists(tab);
    const current = exists ? tab : tabKeys[0];
    if (!exists) onChange(current);
    return current;
  }, [onChange, search, tabExists, tabKeys]);

  return {
    currentTab,
    onChange,
  };
};

export default useTabController;
