import React, { useMemo } from 'react';
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomScrollbars from '~/util/CustomScrollbars';

import { oneOfAzureGroups } from '~/util/Auth';
import SidebarItem from '../MenuItem';
import { menuData } from '../menuData';

import {
  SubMenuTitle,
  MenuContainer,
  StyledMenu,
  MenuAntDesignColorStyles,
} from './styles';

const MenuItemGroup = Menu.ItemGroup;
const { SubMenu } = Menu;

const SidebarContent = props => {
  const { pathname, groups } = props;
  const path = pathname.substr(1);

  const { defaultOpenKeys, selectedKeys } = useMemo(() => {
    const splitKeys = path.split('/');

    const newKeys = [];
    let currentKey = '';

    splitKeys.forEach(k => {
      if (currentKey.length > 0) currentKey += '/';
      currentKey += k;
      newKeys.push(currentKey);
    });

    return {
      selectedKeys: newKeys,
      defaultOpenKeys: newKeys?.[0],
    };
  }, [path]);

  return (
    <>
      <MenuAntDesignColorStyles />
      <MenuContainer>
        <CustomScrollbars>
          <StyledMenu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={selectedKeys}
            theme="lite"
            mode="vertical"
          >
            {menuData.map(item => {
              const MenuGroups = item.menuGroup.map(menuGroup => (
                <MenuItemGroup
                  key={menuGroup.key}
                  className="gx-menu-group"
                  title={menuGroup.title}
                >
                  {menuGroup.menuItem.map(menuItem => (
                    <SidebarItem
                      key={menuItem.key}
                      itemKey={menuItem.key}
                      to={menuItem.to}
                      icon={menuItem.icon}
                      label={menuItem.title}
                      currentGroups={groups}
                      routeGroups={menuItem.groups}
                    />
                  ))}
                </MenuItemGroup>
              ));
              const SubMenus = item.subMenu.map(subMenu => {
                const SubMenuIcon = () => subMenu.icon;

                return (
                  <SubMenu
                    key={subMenu.key}
                    title={
                      <SubMenuTitle>
                        <SubMenuIcon />
                        {subMenu.title}
                      </SubMenuTitle>
                    }
                  >
                    {subMenu.menuItem.map(menuItem => {
                      const MenuIcon = () => menuItem.icon;
                      return (
                        <SidebarItem
                          key={menuItem.key}
                          itemKey={menuItem.key}
                          to={menuItem.to}
                          icon={<MenuIcon />}
                          label={menuItem.title}
                          currentGroups={groups}
                          routeGroups={menuItem.groups}
                        />
                      );
                    })}
                  </SubMenu>
                );
              });
              return [MenuGroups, SubMenus];
            })}
          </StyledMenu>
        </CustomScrollbars>
      </MenuContainer>
    </>
  );
};

SidebarContent.propTypes = {
  pathname: PropTypes.string.isRequired,
  groups: oneOfAzureGroups,
};

SidebarContent.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ settings, auth }) => {
  const { pathname } = settings;
  return {
    pathname,
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(SidebarContent);
