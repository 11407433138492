import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const SET_BANK_ACCOUNT = gql`
  mutation useUserSetBankingAccount($input: UpsertBankingAccountInput!) {
    userSetBankingAccount(input: $input) {
      account
      accountType
      agency
      code
      id
      name
      userId
    }
  }
`;

function useUserSetBankingAccount(options = {}) {
  const [setBankingAccount, { loading, data, error }] = useMutation(
    SET_BANK_ACCOUNT,
    {
      ...options,
    }
  );

  return [
    ({ account, accountType, agency, code, name, userId, id } = {}) => {
      setBankingAccount({
        variables: {
          input: {
            account,
            accountType,
            agency,
            code,
            name,
            userId,
            id,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUserSetBankingAccount;
