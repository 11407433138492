import React from 'react';
import styled from 'styled-components';
import { Empty } from 'antd';

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

function NotFound() {
  return (
    <EmptyContainer data-testid="notFound">
      <Empty
        image={`${process.env.PUBLIC_URL}/images/icons/error.png`}
        description={<>Página não encontrada!</>}
      />
    </EmptyContainer>
  );
}

export default NotFound;
