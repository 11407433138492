import styled, { createGlobalStyle } from 'styled-components';
import { Menu } from 'antd';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme, menu } = THEME_BEHAVIOR;

const menuThemeColor = menu.color ? menu.color : theme.color;

export const SubMenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .anticon {
    margin-right: 5px;
  }
`;

export const MenuContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding-bottom: 20px;
`;

export const StyledMenu = styled(Menu)`
  .ant-menu-item-group-title {
    font-size: 16px;
    padding-top: 30px;
    color: #545454;
  }
  .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
`;

export const MenuAntDesignColorStyles = createGlobalStyle`
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-active .ant-menu-submenu-arrow,
  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item a:hover,
  .ant-menu-item:hover,
  .ant-menu-item-active {
    color: ${menuThemeColor};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #f8f8f8;
  }
`;
