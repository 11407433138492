import {
  FIT_TO_SMALL,
  FIT_TOO_BIG,
  FIT_TOO_TIGHT,
  FIT_TOO_LOOSE,
  FIT,
  COLOR_PRINT,
  UNCOMFORTABLE,
  IMAGE_WEIRD,
  BAD_SMELL_DIRTY,
  MOLDY,
  WORN_OUT,
  FADED,
  SIZE,
  DESCRIPTION,
  COLOR,
  MODEL,
  UNSTITCHED,
  TORN_HOLED,
  PEELED_OFF,
  BROKEN_DETACHED,
  DIDNT_FIT,
  DISLIKE,
  QUALITY,
  UNDESCRIBED,
  DEFECT,
  RECEIVED_ANOTHER,
  REGRET,
  TRANSLATED_FIT_TO_SMALL,
  TRANSLATED_FIT_TOO_BIG,
  TRANSLATED_FIT_TOO_TIGHT,
  TRANSLATED_FIT_TOO_LOOSE,
  TRANSLATED_FIT,
  TRANSLATED_COLOR_PRINT,
  TRANSLATED_UNCOMFORTABLE,
  TRANSLATED_IMAGE_WEIRD,
  TRANSLATED_BAD_SMELL_DIRTY,
  TRANSLATED_MOLDY,
  TRANSLATED_WORN_OUT,
  TRANSLATED_FADED,
  TRANSLATED_SIZE,
  TRANSLATED_DESCRIPTION,
  TRANSLATED_COLOR,
  TRANSLATED_MODEL,
  TRANSLATED_UNSTITCHED,
  TRANSLATED_TORN_HOLED,
  TRANSLATED_PEELED_OFF,
  TRANSLATED_BROKEN_DETACHED,
  TRANSLATED_DIDNT_FIT,
  TRANSLATED_DISLIKE,
  TRANSLATED_QUALITY,
  TRANSLATED_UNDESCRIBED,
  TRANSLATED_DEFECT,
  TRANSLATED_RECEIVED_ANOTHER,
  TRANSLATED_REGRET,
} from '~/constants/Return';

export const getReturnReason = reason => {
  if (!reason) return '-';

  switch (reason) {
    case FIT_TO_SMALL:
      return TRANSLATED_FIT_TO_SMALL;
    case FIT_TOO_BIG:
      return TRANSLATED_FIT_TOO_BIG;
    case FIT_TOO_TIGHT:
      return TRANSLATED_FIT_TOO_TIGHT;
    case FIT_TOO_LOOSE:
      return TRANSLATED_FIT_TOO_LOOSE;
    case FIT:
      return TRANSLATED_FIT;
    case COLOR_PRINT:
      return TRANSLATED_COLOR_PRINT;
    case UNCOMFORTABLE:
      return TRANSLATED_UNCOMFORTABLE;
    case IMAGE_WEIRD:
      return TRANSLATED_IMAGE_WEIRD;
    case BAD_SMELL_DIRTY:
      return TRANSLATED_BAD_SMELL_DIRTY;
    case MOLDY:
      return TRANSLATED_MOLDY;
    case WORN_OUT:
      return TRANSLATED_WORN_OUT;
    case FADED:
      return TRANSLATED_FADED;
    case SIZE:
      return TRANSLATED_SIZE;
    case DESCRIPTION:
      return TRANSLATED_DESCRIPTION;
    case COLOR:
      return TRANSLATED_COLOR;
    case MODEL:
      return TRANSLATED_MODEL;
    case UNSTITCHED:
      return TRANSLATED_UNSTITCHED;
    case TORN_HOLED:
      return TRANSLATED_TORN_HOLED;
    case PEELED_OFF:
      return TRANSLATED_PEELED_OFF;
    case BROKEN_DETACHED:
      return TRANSLATED_BROKEN_DETACHED;
    case DIDNT_FIT:
      return TRANSLATED_DIDNT_FIT;
    case DISLIKE:
      return TRANSLATED_DISLIKE;
    case QUALITY:
      return TRANSLATED_QUALITY;
    case UNDESCRIBED:
      return TRANSLATED_UNDESCRIBED;
    case DEFECT:
      return TRANSLATED_DEFECT;
    case RECEIVED_ANOTHER:
      return TRANSLATED_RECEIVED_ANOTHER;
    case REGRET:
      return TRANSLATED_REGRET;
    default:
      return reason;
  }
};
