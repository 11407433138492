import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import Button from '~/components/Button';

import { formatCnpj } from '~/util/Functions';

export function generateColumns() {
  return [
    {
      title: 'Marca',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'CNPJ',
      dataIndex: 'document',
      key: 'document',
      render: (_, record) =>
        `${record.document ? formatCnpj(record.document) : 'N/A'}`,
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (_, record) => (
        <div style={{ width: '110px' }}>
          {record.mobilePhone
            ? record.mobilePhone
                .replace(/[^\d]+/gi, '')
                .replace(/(\d{2})(\d)/, '($1) $2')
            : 'N/A'}
        </div>
      ),
    },
    {
      title: 'Sacolas identificadas',
      dataIndex: 'bagsIdentifiedCount',
      key: 'bagsIdentifiedCount', // TODO: esperar campo bagsIdentifiedCount ser corrigida no backend
    },
    {
      title: 'Vale-compras',
      dataIndex: 'vouchersGeneratedCount',
      key: 'vouchersGeneratedCount', // TODO: esperar campo vouchersGeneratedCount ser corrigida no backend
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <>{record.status === 'active' ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 140,
      render: (_, record) => (
        <>
          <Tooltip placement="bottom" title="Visualizar">
            <Link to={`/manage/service/partner/edit/${record.id}`}>
              <Button
                data-testid="viewBtn"
                type="link"
                icon={<FileSearchOutlined />}
                size="small"
                style={{ margin: 0 }}
              />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];
}
