import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const CREATE_PARTNER = gql`
  mutation useCreatePartner($input: CreatePartnerInput!) {
    userCreatePartner(input: $input) {
      user {
        ... on UserPartner {
          id
          email
          firstName
          lastName
          slug
          mobilePhone
          document
          responsible
        }
      }
    }
  }
`;

function useCreatePartner(options = {}) {
  const [createPartner, { loading, data, error }] = useMutation(
    CREATE_PARTNER,
    {
      ...options,
    }
  );

  return [
    ({
      document = undefined,
      email = undefined,
      firstName = undefined,
      lastName = undefined,
      ddd = undefined,
      mobilePhone = undefined,
      password = undefined,
      passwordConfirmation = undefined,
      responsible = undefined,
    } = {}) => {
      createPartner({
        variables: {
          input: {
            document: document ? document.replace(/[^\d]+/gi, '') : undefined,
            email,
            firstName,
            lastName,
            mobilePhone:
              ddd && mobilePhone
                ? `${ddd}${mobilePhone.replace(/[^\d]+/gi, '')}`
                : undefined,
            password,
            passwordConfirmation,
            responsible,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useCreatePartner;
