import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select, Row, Col } from 'antd';
import { useDragSortTableContext } from '~/providers/DragSortTable';

import { FOOTER_CATEGORIES_SEARCH } from '~/apollo/content/footer';

import LoadingPlaceholder from '~/components/LoadingPlaceholder';

const { Option } = Select;

function CategorySearch() {
  const { tableData, setTableData } = useDragSortTableContext();
  const [options, setOptions] = useState([]);
  const [rawOptions, setRawOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error] = useState(null);

  const [searchCategories] = useLazyQuery(FOOTER_CATEGORIES_SEARCH, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({
      categoriesProductSearchCategories: { nodes: categories },
    }) => {
      const newOptions = categories.map(item => {
        return {
          value: item.id,
          text: item.name,
          link: item.permalink,
        };
      });

      setOptions(newOptions);
      setRawOptions(categories);
      setLoading(false);
    },
    onError: () => {
      setOptions([]);
      setRawOptions([]);
      setLoading(false);
    },
  });

  const onSelect = id => {
    const selected = rawOptions.filter(item => item.id === id);
    if (selected.length > 0) {
      selected.forEach(item => {
        if (
          !tableData.some(
            dataItem => dataItem.dynamicContentable.id === item.id
          )
        )
          setTableData(prevData => [
            ...prevData,
            ...[{ id: prevData.length + 1, dynamicContentable: item }],
          ]);
      });
    }
  };

  useEffect(() => {
    searchCategories();
  }, [searchCategories]);

  if (loading)
    return (
      <LoadingPlaceholder
        style={{ width: '100%', height: '36px', marginBottom: 10 }}
      />
    );
  if (error) return `Error! ${error.message}`;

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Select
          showSearch
          style={{ width: '100%', marginBottom: '10px' }}
          optionFilterProp="children"
          onSelect={id => onSelect(Number(id))}
          placeholder="Busque uma categoria"
          data-testid="footerAddCategoryInput"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map(opt => (
            <Option key={opt.value}>
              {opt.text}
              <span
                style={{
                  fontSize: '12px',
                  paddingLeft: '20px',
                  color: '#bfbfbf',
                }}
              >
                {opt.link}
              </span>
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}

export default CategorySearch;
