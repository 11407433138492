import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { generateColumns, generateColumnsProducts } from './columns';

function TableReturn({ table, orders, setOrders }) {
  const columns = generateColumns();
  const [expandedRows, setExpandedRows] = useState([]);

  const handleRowExpand = (_, record) => {
    setExpandedRows(prev =>
      prev.includes(record.id)
        ? prev.filter(key => key !== record.id)
        : [...prev, record.id]
    );
  };

  return (
    <Table
      data-testid="table"
      dataSource={orders}
      columns={columns}
      loading={table.loading}
      rowKey={record => record.id}
      bordered
      scroll={{ x: 'max-content' }}
      title={() => `Total encontrado - ${orders?.length || 0}`}
      expandable={{
        expandedRowRender: row => (
          <div>
            <Table
              size="small"
              data-testid="table"
              title={() => `Total encontrado - ${row.orderItems?.length || 0}`}
              dataSource={row.orderItems}
              columns={generateColumnsProducts(row, orders, setOrders)}
              rowKey={record => record?.product?.id}
              bordered
              scroll={{ x: 'max-content' }}
              pagination={false}
            />
          </div>
        ),
        onExpand: handleRowExpand,
        expandedRowKeys: expandedRows,
      }}
      pagination={false}
    />
  );
}

TableReturn.propTypes = {
  setOrders: PropTypes.func.isRequired,
  table: PropTypes.shape({
    perPage: PropTypes.number,
    page: PropTypes.number,
    totalCount: PropTypes.number,
    loading: PropTypes.bool,
    refetch: PropTypes.bool,
  }).isRequired,
  // setTable: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      codeProduct: PropTypes.string,
      photo: PropTypes.string,
      name: PropTypes.string,
      solicitationDate: PropTypes.instanceOf(Date),
      trackingCode: PropTypes.string,
      status: PropTypes.string,
      image: PropTypes.shape({
        imageUrl: PropTypes.string,
        position: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default TableReturn;
