import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col } from 'antd';
import LoadingBar from '~/components/LoadingBar';
import { BLOCK_ID_SEARCH } from '~/apollo/marketing/home';
import { BlockBanner } from '../BlockBanner';

function EditBlock({ match }) {
  const isEdit = match.url.includes('edit');
  const { error, loading, data } = useQuery(BLOCK_ID_SEARCH, {
    context: {
      newBackend: true,
    },
    skip: !isEdit,
    variables: { id: Number(match.params.id) },
  });

  const getComponent = block => <BlockBanner block={block} isEdit={isEdit} />;

  const statusInfo = text => (
    <Row
      data-testid="editBlock"
      style={{ height: '100%' }}
      gutter={16}
      type="flex"
      justify="center"
      align="middle"
    >
      <Col>{text}</Col>
    </Row>
  );

  if (error) return statusInfo(`Error: ${error.message}`);
  if (loading) return <LoadingBar />;
  if (!isEdit) return 'Página não foi carregada corretamente';

  const [block] = data.homeBlocksSearchBlocks.nodes;
  return getComponent(block);
}

EditBlock.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default EditBlock;
