import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { Table } from 'antd';
import { DELETE_COUPON } from '~/apollo/marketing/coupon';
import { generateColumns } from './columns';

function TableCoupon({ coupons, table, setTable }) {
  const [deleteCoupon, { loading: deleting }] = useMutation(DELETE_COUPON, {
    context: {
      newBackend: true,
    },
    awaitRefetchQueries: true,
    refetchQueries: ['listCoupons'],
  });

  const columns = generateColumns({ deleteCoupon });

  function onChangePage(current, size) {
    const skip = (current - 1) * size;

    setTable({
      ...table,
      pageSize: size,
      currentPage: current,
      loading: true,
      skip,
    });
  }

  function onShowSizeChange(oldPage, newPageSize) {
    const newCurrentPage = Math.ceil(
      (table.currentPage * table.pageSize - table.pageSize + 1) / newPageSize
    );
    const skip = (newCurrentPage - 1) * newPageSize;

    setTable({
      ...table,
      pageSize: newPageSize,
      currentPage: newCurrentPage,
      loading: true,
      skip,
    });
  }

  return (
    <Table
      data-testid="couponTable"
      dataSource={coupons}
      columns={columns}
      loading={table.loading || deleting}
      rowKey={record => record.id}
      title={() => `Total encontrado - ${table.totalCount}`}
      bordered
      scroll={{
        x: 'max-content',
      }}
      pagination={{
        current: table.currentPage,
        total: table.totalCount,
        showSizeChanger: true,
        onShowSizeChange,
        onChange: onChangePage,
        // Tamanho padrão 10, mostra outros tamanho dependendo do total de itens
        pageSizeOptions: ['10'].concat(
          ['20', '30', '40', '50'].filter(size => {
            return size <= table.totalCount;
          })
        ),
        position: 'both',
        locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
      }}
    />
  );
}

TableCoupon.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  table: PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    skip: PropTypes.number,
    totalCount: PropTypes.number,
    searchText: PropTypes.string,
    searchDate: PropTypes.string,
    searchStatus: PropTypes.string,
    loading: PropTypes.bool,
    refetch: PropTypes.bool,
  }).isRequired,
  setTable: PropTypes.func.isRequired,
};

export default TableCoupon;
