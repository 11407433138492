import { ORDER_ADJUSTMENT_REPURCHASE_LABELS } from '~/constants/Order';

export function calcItemTotalAndAdjustmentForRepurchase(order, orderItem) {
  const qtyOrderItems = order?.orderItems?.length || 1;
  const orderItemsTotal = order?.itemTotal || 0;
  const orderItemValue = orderItem?.itemValue || 0;

  const repurchaseAdjustment = Math.abs(
    order?.orderAdjustments?.find(adjustument =>
      ORDER_ADJUSTMENT_REPURCHASE_LABELS?.includes(adjustument?.label)
    )?.value || 0
  );

  if (repurchaseAdjustment > 0) {
    const repurchaseItemTotal =
      (orderItemsTotal - repurchaseAdjustment) / qtyOrderItems;
    const repurchaseAdjustmentTotal = orderItemValue - repurchaseItemTotal;

    return {
      repurchaseItemTotal,
      repurchaseAdjustmentTotal,
    };
  }

  return {
    repurchaseItemTotal: null,
    repurchaseAdjustmentTotal: null,
  };
}
