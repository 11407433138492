import gql from 'graphql-tag';
import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { SEO_CONTENTS_FRAGMENT } from './useSearchSeoContents';

export const MUTATION_UPDATE_SEO_CONTENTS = gql`
  mutation useUpdateSeoContent(
    $id: Int!
    $contentId: Int! ###
    $kind: SeoContentKind! ###
    $image: String
    $logo: String
    $mainTitle: String!
    $secondaryTitle: String
    $mainContent: String
    $metaTitle: String
    $metaDescription: String
    $metaKeywords: String
  ) {
    seoSeoContentsUpdateSeoContent(
      input: {
        id: $id
        contentId: $contentId
        kind: $kind
        image: $image
        logo: $logo
        mainTitle: $mainTitle
        secondaryTitle: $secondaryTitle
        mainContent: $mainContent
        metaTitle: $metaTitle
        metaDescription: $metaDescription
        metaKeywords: $metaKeywords
      }
    ) {
      ...SeoContentFragment
    }
  }
  ${SEO_CONTENTS_FRAGMENT}
`;

function useUpdateSeoContent(options = {}) {
  const [update, { data, loading, error }] = useMutation(
    MUTATION_UPDATE_SEO_CONTENTS,
    {
      ...options,
    }
  );

  const updateSeoContent = useCallback(
    ({
      id,
      contentId,
      kind,
      mainTitle,
      secondaryTitle,
      image = undefined,
      logo = undefined,
      mainContent = undefined,
      metaTitle = undefined,
      metaDescription = undefined,
      metaKeywords = undefined,
    }) => {
      update({
        variables: {
          id,
          contentId,
          kind,
          image,
          logo,
          mainTitle,
          secondaryTitle,
          mainContent,
          metaTitle,
          metaDescription,
          metaKeywords,
        },
      });
    },
    [update]
  );

  return [
    updateSeoContent,
    {
      loading,
      data: data?.seoSeoContentsUpdateSeoContent || null,
      error,
    },
  ];
}

export default useUpdateSeoContent;
