import React, { useEffect, useState } from 'react';
import { Alert, Card, Modal, Table, notification } from 'antd';
import moment from 'moment';
import useSearchBagOrder from '~/hooks/orders/useSearchBagOrder';
import usePaginationController from '~/hooks/utils/usePaginationController';
import { getErrorMessage, notifyError } from '~/util/Functions';
import { DATE_FORMAT_SIMPLE } from '~/constants/Global';
import useUpdateBagDispatchStatus from '~/hooks/orders/useUpdateBagDispatchStatus';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSING,
  SHIPMENT_DISPATCH_PROCESSED,
} from '~/constants/ShipmentDispatch';
import Filter from './Filter';
import { generateColumns } from './columns';
import DispatchHistoric from './DispatchHistoric';

const initialState = {
  selected: null,
  selectedRows: [],
};

function Orders() {
  const [selectedOrders, setSelectedOrders] = useState(initialState);
  const {
    current: pagination,
    onChange: onPaginationChange,
    reset: paginationReset,
    filters: paginationFilters,
  } = usePaginationController({
    initialPageSize: 10,
    initialPage: 1,
  });

  const [searchBagOrder, { data, loading, totalCount, error, refetch }] =
    useSearchBagOrder();

  const [updateStatus] = useUpdateBagDispatchStatus({
    onCompleted: () => {
      setSelectedOrders(initialState);
      refetch();
    },
    onError: approveError => {
      notifyError('Um erro ocorreu', approveError);
    },
  });

  const fetchBagOrders = newVariables => {
    const startDate = newVariables?.filters?.startDate;
    const endDate = newVariables?.filters?.endDate;
    const emailNameIdBagOrder = newVariables?.filters?.searchText;
    const status = newVariables?.filters?.status;
    const paymentStatus = newVariables?.filters?.paymentStatus;
    const shipmentStatus = newVariables?.filters?.shipmentStatus;
    const shipmentDispatchStatus =
      newVariables?.filters?.shipmentDispatchStatus;
    const deliveryMethodId = newVariables?.filters?.deliveryMethodId;
    const kind = newVariables?.filters?.kind;

    searchBagOrder({
      skip: pagination.skip,
      first: pagination.pageSize,
      emailNameIdBagOrder,
      status,
      paymentStatus,
      shipmentStatus,
      kind,
      dateCreateBagOrder:
        startDate && endDate ? [startDate, endDate] : undefined,
      shipmentDispatchStatus,
      deliveryMethodId,
    });
  };

  useEffect(() => {
    // Faz a requisição baseado nas variaveis iniciais ou presentes na URL
    // para popular a tela inicialmente, ou quando trocar de página/tamanho de página

    const newFilters = {
      filters: {
        startDate: paginationFilters?.startDate,
        endDate: paginationFilters?.endDate,
        searchText: paginationFilters?.searchText,
        status: paginationFilters?.status,
        paymentStatus: paginationFilters?.paymentStatus,
        shipmentStatus: paginationFilters?.shipmentStatus,
        kind: paginationFilters?.kind,
        shipmentDispatchStatus: paginationFilters?.shipmentDispatchStatus,
        deliveryMethodId: paginationFilters?.deliveryMethodId,
      },
    };

    fetchBagOrders({ ...newFilters });
  }, [pagination?.skip, pagination?.pageSize]);

  const onChangePage = (newPage, pageSize) => {
    onPaginationChange({
      page: newPage,
      pageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const onShowSizeChange = (_oldPage, newPageSize) => {
    onPaginationChange({
      pageSize: newPageSize,
      filters: {
        ...paginationFilters,
      },
    });
  };

  const handleResetFilters = () => {
    const newFilters = {
      filters: {
        startDate: undefined,
        endDate: undefined,
        searchText: undefined,
        status: undefined,
        paymentStatus: undefined,
        shipmentStatus: undefined,
        kind: undefined,
        shipmentDispatchStatus: undefined,
        deliveryMethodId: undefined,
      },
    };

    // Atualizo os filtros na URL com o 'paginationReset'. Não atualizo a paginação aqui
    // pois a função 'paginationReset' já faz isso internamente.
    paginationReset(newFilters);
    // Como a chamada da requisição acontece quase instantaneamente, é necessário
    // ele não consegue ler a URL já atualizada, então se faz necessário enviar as
    // variaveis atualizadas via argumento da função.
    fetchBagOrders({ page: 1, pageSize: 10, ...newFilters });
  };

  const handleSubmit = () => {
    let formattedStartDate;
    let formattedEndDate;

    if (paginationFilters?.startDate && paginationFilters?.endDate) {
      formattedStartDate = moment(paginationFilters.startDate).format(
        DATE_FORMAT_SIMPLE
      );
      formattedEndDate = moment(paginationFilters.endDate).format(
        DATE_FORMAT_SIMPLE
      );
    }

    const newFilters = {
      filters: {
        ...paginationFilters,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
    };

    // Reseta a seleção dos pedidos
    setSelectedOrders(initialState);

    // Atualizo os filtros na URL com o 'paginationReset'. Não atualizo a paginação aqui
    // pois a função 'paginationReset' já faz isso internamente.
    paginationReset(newFilters);
    // Como a chamada da requisição acontece quase instantaneamente, é necessário
    // ele não consegue ler a URL já atualizada, então se faz necessário enviar as
    // variaveis atualizadas via argumento da função.
    fetchBagOrders({ page: 1, pageSize: 10, ...newFilters });
  };

  const handleSelect = record => {
    setSelectedOrders(prevState => ({
      ...prevState,
      selectedRows: prevState.selectedRows.find(row => row.id === record.id)
        ? prevState.selectedRows.filter(row => row.id !== record.id)
        : [...prevState.selectedRows, record],
    }));
  };

  const handleSelectAll = (_, selectedRows) => {
    const cantSelect = data
      .map(item => item?.shipmentDispatch?.status)
      .some(item => !item || item !== data[0]?.shipmentDispatch?.status);

    if (cantSelect) {
      notification.warn({
        message: `Selecione uma opção no filtro de Status de Expedição`,
        placement: 'topRight',
      });
      return;
    }

    setSelectedOrders(prevState => ({
      ...prevState,
      selectedRows,
    }));
  };

  const getNextDispatchStatus = status => {
    switch (status) {
      case SHIPMENT_DISPATCH_AWAITING:
        return SHIPMENT_DISPATCH_PROCESSING;
      case SHIPMENT_DISPATCH_PROCESSING:
        return SHIPMENT_DISPATCH_PROCESSED;
      default:
        return null;
    }
  };

  const handleUpdateStatus = record => {
    let ids = [record.id];
    let status = record.shipmentDispatch?.status;
    let modalTitle = `Deseja atualizar o status de expedição do pedido ${ids[0]}?`;

    if (selectedOrders.selectedRows.length > 1) {
      modalTitle =
        'Deseja atualizar o status de expedição dos pedidos selecionados?';
    }

    if (selectedOrders.selectedRows.length > 0) {
      ids = selectedOrders.selectedRows.map(row => row.id);
      status = selectedOrders.selectedRows[0].shipmentDispatch?.status;
    }

    Modal.confirm({
      title: modalTitle,
      okText: 'confirmar',
      cancelText: 'cancelar',
      onOk: () =>
        updateStatus({
          status: getNextDispatchStatus(status),
          ids,
        }),
    });
  };

  const columns = generateColumns(
    selectedOrders,
    setSelectedOrders,
    handleUpdateStatus
  );

  return (
    <Card data-testid="orderCard" title="PEDIDOS DE SACOLA DO BEM">
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter
          onChange={onPaginationChange}
          onSubmit={handleSubmit}
          onReset={handleResetFilters}
          currentFilters={paginationFilters || undefined}
        />
      </Card.Grid>

      <Card.Grid style={{ width: '100%' }}>
        {error ? (
          <Alert message={getErrorMessage(error)} type="error" showIcon />
        ) : (
          <>
            <Table
              data-testid="table"
              dataSource={data}
              columns={columns}
              loading={loading}
              rowKey={record => record.id}
              title={() => `Total encontrado - ${totalCount}`}
              bordered
              scroll={{
                x: 'max-content',
              }}
              pagination={{
                current: pagination?.page,
                total: totalCount,
                showSizeChanger: true,
                onShowSizeChange,
                onChange: onChangePage,
                // Tamanho padrão 10, mostra outros tamanho dependendo do total de itens
                pageSize: pagination?.pageSize,
                pageSizeOptions: ['10'].concat(
                  ['20', '30', '40', '50'].filter(size => {
                    return size - 10 <= totalCount;
                  })
                ),
                position: 'both',
                locale: { items_per_page: '' }, // Remove o '/page' do seletor de quantos itens por página
              }}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedOrders.selectedRows.map(row => row.id),
                onSelect: handleSelect,
                onSelectAll: handleSelectAll,
                getCheckboxProps: record => {
                  // Desabilita o checkbox caso o status de expedição seja Separado ou
                  // caso o status atual não seja igual ao primeiro selecionado
                  const isProcessedDispatch =
                    record?.shipmentDispatch?.status ===
                    SHIPMENT_DISPATCH_PROCESSED;

                  const isSelectedStatusDifferent =
                    selectedOrders?.selectedRows.length &&
                    selectedOrders?.selectedRows[0]?.shipmentDispatch
                      ?.status !== record?.shipmentDispatch?.status;

                  return {
                    disabled: isProcessedDispatch || isSelectedStatusDifferent,
                  };
                },
              }}
            />

            {selectedOrders.selected ? (
              <DispatchHistoric
                bagOrderId={selectedOrders.selected}
                handleClose={() => {
                  setSelectedOrders(prevState => ({
                    ...prevState,
                    selected: null,
                  }));
                }}
              />
            ) : null}
          </>
        )}
      </Card.Grid>
    </Card>
  );
}

export default Orders;
