import { useMutation } from '@apollo/react-hooks';
import { notification } from 'antd';
import { BLOCK_ATTRIBUTE_UPDATE } from '~/apollo/marketing/home';

function useUpdateBlockAttribute(options = {}) {
  const [updateAttribute, { loading, data, error }] = useMutation(
    BLOCK_ATTRIBUTE_UPDATE,
    {
      onCompleted() {
        notification.success({
          message: `Atributo atualizado com sucesso.`,
          placement: 'topRight',
        });
      },
      onError: err => {
        notification.error({
          message: `Erro ao atualizar atributo`,
          description: err.errors
            ? err.errors.map(obj => obj.message).join(';')
            : err.message,
          placement: 'topRight',
        });
      },
      ...options,
    }
  );

  return [
    ({
      blockId = undefined,
      id = undefined,
      text = undefined,
      url = undefined,
      weightPosition = undefined,
    } = {}) => {
      updateAttribute({
        variables: {
          input: {
            blockId,
            id,
            text,
            url,
            weightPosition,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUpdateBlockAttribute;
