import gql from 'graphql-tag';

// =====================
// QUERYS
// =====================

// New Backend
export const PRODUCTS_LIST_BY_SLUGS = gql`
  query homeListProductsBySlug($slugs: [String!]) {
    productsProductDatabaseSearchProducts(slugs: $slugs) {
      nodes {
        id
        name
        price
        salePrice
        saleState
        slug
        images {
          id
          imageUrl
        }
        categories {
          name
        }
      }
    }
  }
`;

// New Backend
export const BLOCKS_LIST = gql`
  query homeListBlocks($published: Boolean, $basic: Boolean = false) {
    homeBlocksSearchBlocks(published: $published) {
      nodes {
        id
        kind
        title
        subtitle
        published
        weightPosition
        startDate
        blockBanners @skip(if: $basic) {
          id
          url
          alt
          banner
          mobileHide
          mobileSize
          desktopSize
          desktopHide
          weightPosition
        }
        blockProducts @skip(if: $basic) {
          id
          weightPosition
          product {
            id
            name
            price
            saleState
            salePrice
            brand {
              name
            }
            images {
              id
              imageUrl
            }
            categories {
              name
              id
            }
          }
        }
      }
      totalCount
    }
  }
`;

// New Backend
export const BLOCK_ID_SEARCH = gql`
  query homeSearchBlockByID($id: Int!) {
    homeBlocksSearchBlocks(id: $id) {
      nodes {
        id
        kind
        urls
        title
        subtitle
        published
        weightPosition
        startDate
        endDate
        blockAttributesBar {
          id
          text
          url
          weightPosition
        }
        blockBanners {
          id
          url
          alt
          banner
          mobileHide
          mobileSize
          desktopSize
          desktopHide
          weightPosition
          buttonBackgroundColor
          buttonText
          buttonTextColor
          navigationBackgroundColor
        }
        blockProducts {
          id
          weightPosition
          product {
            id
            name
            price
            saleState
            salePrice
            images {
              id
              imageUrl
            }
            categories {
              name
              id
            }
          }
        }
      }
    }
  }
`;

// =====================
// MUTATIONS
// =====================

// New Backend
export const BLOCK_PRODUCT_CREATE = gql`
  mutation homeCreateBlockProduct($input: CreateBlockProductInput!) {
    homeBlockProductsCreateBlockProduct(input: $input) {
      id
      weightPosition
      product {
        id
        name
        price
        salePrice
        slug
        saleState
        images {
          id
          imageUrl
        }
        categories {
          name
        }
      }
    }
  }
`;

// New Backend
export const BLOCK_BANNER_CREATE = gql`
  mutation homeCreateBlockBanner($input: CreateBlockBannerInput!) {
    homeBlockBannersCreateBlockBanner(input: $input) {
      id
      block {
        id
      }
      banner
      url
      alt
      desktopHide
      desktopSize
      mobileHide
      mobileSize
      weightPosition
    }
  }
`;

// New Backend
export const BLOCK_CREATE = gql`
  mutation homeCreateBlock($input: CreateBlockInput!) {
    homeBlocksCreateBlock(input: $input) {
      id
      kind
      published
      subtitle
      title
      urls
      weightPosition
      startDate
      endDate
      blockBanners {
        id
      }
      blockProducts {
        id
      }
    }
  }
`;

// New Backend
export const BLOCK_UPDATE = gql`
  mutation homeUpdateBlock($input: UpdateBlockInput!) {
    homeBlocksUpdateBlock(input: $input) {
      id
      kind
      published
      startDate
      endDate
      subtitle
      title
      urls
      weightPosition
    }
  }
`;

// New Backend
export const BLOCK_PRODUCT_UPDATE = gql`
  mutation homeUpdateBlockProduct($input: UpdateBlockProductInput!) {
    homeBlockProductsUpdateBlockProduct(input: $input) {
      id
      weightPosition
    }
  }
`;

// New Backend
export const BLOCK_BANNER_UPDATE = gql`
  mutation homeUpdateBlockBanner($input: UpdateBlockBannerInput!) {
    homeBlockBannersUpdateBlockBanner(input: $input) {
      id
      block {
        id
      }
      banner
      url
      desktopHide
      desktopSize
      mobileHide
      mobileSize
      weightPosition
      alt
    }
  }
`;

// New Backend
export const BLOCKS_LIST_UPDATE = gql`
  mutation homeUpdateBlockWeights($input: UpdateBlockInput!) {
    homeBlocksUpdateBlock(input: $input) {
      id
      kind
      published
      title
      weightPosition
    }
  }
`;

// DELETES
// New Backend
export const BLOCK_DELETE = gql`
  mutation homeDeleteBlock($input: DeleteBlockInput!) {
    homeBlocksDeleteBlock(input: $input) {
      id
      title
      kind
    }
  }
`;

// Old Backend
export const BLOCK_PRODUCT_DELETE = gql`
  mutation homeDeleteBlockProduct($input: DeleteBlockProductInput!) {
    homeBlockProductsDeleteBlockProduct(input: $input) {
      id
    }
  }
`;

// New Backend
export const BLOCK_BANNER_DELETE = gql`
  mutation homeDeleteBlockBanner($input: DeleteBlockBannerInput!) {
    homeBlockBannersDeleteBlockBanner(input: $input) {
      id
    }
  }
`;

export const BLOCK_ATTRIBUTE_CREATE = gql`
  mutation HomeBlockAttributesBarsCreateBlockAttributesBar(
    $input: CreateBlockAttributesBarInput!
  ) {
    homeBlockAttributesBarsCreateBlockAttributesBar(input: $input) {
      id
      text
      url
      weightPosition
    }
  }
`;

export const BLOCK_ATTRIBUTE_UPDATE = gql`
  mutation HomeBlockAttributesBarUpdateBlockAttributesBar(
    $input: UpdateBlockAttributesBarInput!
  ) {
    homeBlockAttributesBarsUpdateBlockAttributesBar(input: $input) {
      id
      text
      url
      weightPosition
    }
  }
`;

export const BLOCK_ATTRIBUTE_DELETE = gql`
  mutation HomeBlockAttributesBarDeleteBlockAttributesBar(
    $input: DeleteBlockAttributesBarInput!
  ) {
    homeBlockAttributesBarsDeleteBlockAttributesBar(input: $input) {
      id
      text
      url
      weightPosition
    }
  }
`;
