import React, { useState } from 'react';
import {
  Card,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Col,
  Switch,
  Typography,
} from 'antd';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  BLOCKS_LIST,
  BLOCKS_LIST_UPDATE,
  BLOCK_DELETE,
} from '~/apollo/marketing/home';

import Button from '~/components/Button';
import { useDragSortTableContext } from '~/providers/DragSortTable';
import DragSortTable from '~/components/DragSortTable';
import LoadingBar from '~/components/LoadingBar';
import Preview from './Preview';

import { blocksOrdination, onSaveList, onOrdinationIndex } from './actions';
import { generateColumns } from './columns';

const { Text } = Typography;

function BlocksList() {
  const [openPreview, setOpenPreview] = useState(false);
  const [variables, setVariables] = useState({ published: true, basic: true });
  const [totalCount, setTotalCount] = useState(0);
  const { tableData, setTableData } = useDragSortTableContext();
  const [navigationDeleteBlock] = useMutation(BLOCK_DELETE, {
    context: {
      newBackend: true,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: BLOCKS_LIST,
        context: {
          newBackend: true,
        },
      },
    ],
  });
  const [updateBlockList] = useMutation(BLOCKS_LIST_UPDATE, {
    context: {
      newBackend: true,
    },
    ignoreResults: true,
  });

  const { error, loading } = useQuery(BLOCKS_LIST, {
    variables: {
      ...variables,
    },
    context: {
      newBackend: true,
    },
    onCompleted: ({
      homeBlocksSearchBlocks: { nodes: responseData, totalCount: total },
    }) => {
      setTotalCount(total);
      blocksOrdination(responseData, { setTableData });
    },
  });

  const columns = generateColumns({
    tableData,
    updateBlockList,
    setTableData,
    navigationDeleteBlock,
  });

  const dropDownOptions = () => (
    <Menu>
      <Menu.Item key="1">
        <Link
          data-testid="homeAddBlockBannerBtn"
          to="/manage/marketing/home/create/banner"
        >
          Banner
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          data-testid="homeAddBlockProductBtn"
          to="/manage/marketing/home/product/create"
        >
          Produto
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          data-testid="homeAddBlockCarouselBtn"
          to="/manage/marketing/home/create/carousel"
        >
          Carrossel
        </Link>
      </Menu.Item>
    </Menu>
  );

  if (error) return `Error! ${error.message}`;
  if (loading) return <LoadingBar />;

  return (
    <>
      <Card data-testid="homeBlocksPage" title="Painel da Home">
        <Row
          gutter={16}
          type="flex"
          align="middle"
          justify="end"
          style={{ paddingBottom: 20 }}
        >
          <Col>
            <Tooltip placement="top" title="Apenas publicados">
              <Text>Apenas publicados</Text>
              <Switch
                checked={variables?.published}
                style={{ margin: 5 }}
                onChange={active =>
                  setVariables(prevState => ({
                    ...prevState,
                    published: active,
                  }))
                }
              />
            </Tooltip>

            <Tooltip placement="top" title="Preview da Home">
              <Button
                type="primary"
                data-testid="homePreviewBtn"
                size="default"
                style={{ marginRight: '5px' }}
                onClick={() => setOpenPreview(true)}
              >
                Preview
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Adicionar novo bloco">
              <Dropdown overlay={dropDownOptions}>
                <Button
                  type="primary"
                  data-testid="homeAddBlockBtn"
                  icon={<PlusOutlined />}
                  size="default"
                  style={{ marginRight: '5px' }}
                >
                  Adicionar
                </Button>
              </Dropdown>
            </Tooltip>
            <Tooltip placement="bottom" title="Salvar ordem dos blocos">
              <Button
                type="success"
                data-testid="homeSaveBlockOrder"
                icon={<SaveOutlined />}
                size="default"
                onClick={() =>
                  onSaveList({ tableData, setTableData, updateBlockList })
                }
              >
                Salvar
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Row gutter={16} type="flex" align="middle" justify="start">
          <Col span={24}>
            <DragSortTable
              rowKey={record => record.id}
              data={tableData}
              columns={columns}
              bordered
              title={() => `Total encontrado - ${totalCount}`}
              scroll={{
                x: 'max-content',
              }}
              loading={loading}
              pagination={false}
              lockAxis="y"
              onSortEnd={sortInfo => {
                onOrdinationIndex(sortInfo, { tableData, setTableData });
              }}
              useDragHandle
            />
          </Col>
        </Row>
      </Card>
      {openPreview && <Preview open={openPreview} setOpen={setOpenPreview} />}
    </>
  );
}

export default BlocksList;
