import { css } from 'styled-components';
import { THEME_BEHAVIOR } from '~/constants/ThemeBehavior';

const { theme } = THEME_BEHAVIOR;

export const AntModal = css`
  body {
    .ant-modal-content {
      border-radius: ${theme.borderRadius};
    }
  }
`;
