import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Row,
  Col,
  Typography,
  Divider,
  Switch,
  Input,
  InputNumber,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import Upload from '~/components/Upload/Image';

import { onSubmitBanner, onUpdateBlockBanner } from './actions';

function FormBanner({
  block,
  navigationCreateBlockBanner,
  navigationUpdateBlockBanner,
  blockBannerItem,
  setBlockBannerItem,
}) {
  const [loadForm, setLoadForm] = useState(false);
  const [form] = Form.useForm();
  const { Title } = Typography;
  const [isDisable, setDisable] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      url: blockBannerItem.url,
      alt: blockBannerItem.alt,
      desktopSize: blockBannerItem.desktopSize,
      desktopHide: blockBannerItem.desktopHide || false,
      mobileSize: blockBannerItem.mobileSize,
      mobileHide: blockBannerItem.mobileHide || false,
      buttonText: blockBannerItem.buttonText || '',
      buttonTextColor: blockBannerItem.buttonTextColor || '#ffffff',
      buttonBackgroundColor: blockBannerItem.buttonBackgroundColor || '#ffffff',
      navigationBackgroundColor:
        blockBannerItem.navigationBackgroundColor || '#ffffff',
    });
  }, [blockBannerItem, form]);

  const renderTitleForm = (
    <>
      <Row gutter={16} type="flex" justify="space-between" align="middle">
        <Col>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Adicionar banner
          </Title>
        </Col>
      </Row>
      <Divider />
    </>
  );

  const handleChange = uplodedImage => {
    setDisable(!block.id);
    form.setFieldsValue({ banner: uplodedImage });
  };

  const onFinish = values => {
    setLoadForm(true);

    if (Object.keys(blockBannerItem).length) {
      onUpdateBlockBanner({
        navigationUpdateBlockBanner,
        id: blockBannerItem.id,
        values,
        setLoadForm,
      });
      setBlockBannerItem({});
    } else {
      onSubmitBanner(values, {
        setLoadForm,
        block,
        navigationCreateBlockBanner,
      });
    }
    form.resetFields();
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao adicionar de banner',
      values,
      errorFields,
      outOfDate
    );
  };

  const buttonTextDisplayed = () => {
    if (loadForm) {
      return 'Aguarde...';
    }
    if (Object.keys(blockBannerItem).length) {
      return 'Salvar';
    }

    return 'Adicionar';
  };

  return (
    <>
      {renderTitleForm}
      <Form
        form={form}
        layout="vertical"
        name="createBannerBlockForm"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        style={{ marginBottom: '40px' }}
      >
        <Row gutter={16} type="flex" justify="center" align="top">
          <Col span={4} xs={22} lg={4}>
            <Row gutter={16}>
              <Col>
                <Form.Item
                  label="Banner"
                  name="banner"
                  data-testid="bannerInput"
                  rules={[
                    {
                      required: !blockBannerItem,
                      message: 'Inserir uma imagem',
                    },
                  ]}
                >
                  <Upload
                    data-testid="bannerInput"
                    accept="image/*"
                    onChange={handleChange}
                    thumb={blockBannerItem?.banner}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Alt"
                  name="alt"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Campo obrigatório.',
                      type: 'string',
                    },
                  ]}
                >
                  <Input data-testid="homeAddBannerBlockAlt" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="URL"
                  name="url"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Campo obrigatório. Deve ser uma URL válida.',
                      type: 'url',
                    },
                  ]}
                >
                  <Input data-testid="homeAddBannerBlockUrl" />
                </Form.Item>
              </Col>
            </Row>

            {block.kind === 'banner' ? (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Tamanho desktop"
                    name="desktopSize"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório. Valor entre 1 e 12.',
                        type: 'number',
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={12}
                      style={{ width: '100%' }}
                      data-testid="homeAddBannerBlockDesktopSize"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Tamanho mobile"
                    name="mobileSize"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório. Valor entre 1 e 12.',
                        type: 'number',
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      max={12}
                      style={{ width: '100%' }}
                      data-testid="homeAddBannerBlockMobileSize"
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={16}>
                <Col span={9}>
                  <Form.Item
                    label="Texto do botão"
                    name="buttonText"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório.',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input data-testid="homeAddBannerBlockButtonText" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="Cor do botão"
                    name="buttonBackgroundColor"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório.',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input
                      type="color"
                      data-testid="homeAddBannerBlockButtonBackgroundColor"
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="Cor do texto do botão"
                    name="buttonTextColor"
                    value="#ffffff"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório.',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input
                      type="color"
                      data-testid="homeAddBannerBlockButtonTextColor"
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    label="Cor das setas"
                    name="navigationBackgroundColor"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Campo obrigatório.',
                        type: 'string',
                      },
                    ]}
                  >
                    <Input
                      type="color"
                      data-testid="homeAddBannerBlockNavigationBackgroundColor"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={16} type="flex" justify="start" align="middle">
              <Col span={6}>
                <Form.Item
                  label="Esconder desktop"
                  name="desktopHide"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch data-testid="homeAddBannerBlockDesktopHide" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label="Esconder mobile"
                  name="mobileHide"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Switch data-testid="homeAddBannerBlockMobileHide" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} type="flex" justify="end" align="middle">
              <Col>
                <Button
                  type="success"
                  htmlType="submit"
                  icon={<PlusOutlined />}
                  spacing
                  data-testid="homeAddBannerItemBtn"
                  style={{ margin: 0 }}
                  loading={loadForm}
                  disabled={isDisable}
                >
                  {buttonTextDisplayed()}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

FormBanner.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.number,
    kind: PropTypes.string,
  }).isRequired,
  navigationCreateBlockBanner: PropTypes.func.isRequired,
  navigationUpdateBlockBanner: PropTypes.func.isRequired,
  blockBannerItem: PropTypes.shape({
    banner: PropTypes.string,
    id: PropTypes.number,
    alt: PropTypes.string,
    desktopSize: PropTypes.number,
    url: PropTypes.string,
    mobileSize: PropTypes.number,
    mobileHide: PropTypes.bool,
    desktopHide: PropTypes.bool,
    buttonText: PropTypes.string,
    buttonTextColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    navigationBackgroundColor: PropTypes.string,
  }).isRequired,
  setBlockBannerItem: PropTypes.func.isRequired,
};

export default FormBanner;
