import React from 'react';
import { Modal, Select, Typography, notification } from 'antd';
import history from '../../../../services/history';

const { confirm } = Modal;
const { Option } = Select;
const { Text } = Typography;

export function deleteItem(key, { deleteCategory, setTable, newBrandId }) {
  deleteCategory({
    variables: {
      input: {
        id: key,
        newBrandId,
      },
    },
  })
    .then(() => {
      setTable(table => {
        return {
          ...table,
          loading: true,
        };
      });

      notification.success({
        message: `Categoria deletada`,
        description: 'Tabela atualizada com sucesso',
        placement: 'topRight',
      });
    })
    .catch(errorDelete => {
      notification.error({
        message: `Erro ao deletar a categoria.`,
        description: errorDelete.errors
          ? errorDelete.errors.map(e => e.message).join(';')
          : errorDelete.message,
        placement: 'topRight',
      });
    });
}

function handleChangeNewCategory(value, { setNewCategoryId }) {
  setNewCategoryId(value);
}

export function handleDelete(
  key,
  { category, newCategoryId, setNewCategoryId, ...restOfHooks }
) {
  confirm({
    title: 'Você tem certeza que deseja excluir este item?',
    content:
      category.length > 1 ? (
        <>
          <Text>
            Selecione uma nova marca para onde os produtos serão movidos.
          </Text>
          <Select
            style={{ width: '100%' }}
            defaultValue={
              category[0].id === key ? category[1].name : category[0].name
            }
            onChange={value =>
              handleChangeNewCategory(value, { setNewCategoryId })
            }
          >
            {category.map(
              item =>
                item.id !== key && (
                  <Option key={item.id} value={`${item.id}-${item.name}`}>
                    {item.name}
                  </Option>
                )
            )}
          </Select>
        </>
      ) : (
        'É necessário ter mais de uma categoria pra deletar!'
      ),
    okButtonProps: { disabled: category.length <= 1 },
    okText: 'Excluir',
    okType: 'danger',
    cancelText: 'Cancelar',
    onOk() {
      if (category.length > 1) {
        deleteItem(key, {
          category,
          newCategoryId,
          setNewCategoryId,
          ...restOfHooks,
        });
      }
    },
    onCancel() {
      // Cancel
    },
  });
}

export function handleSearch(searchText, { setTable }) {
  setTable(table => {
    return {
      ...table,
      currentPage: 1,
      skip: 0,
      totalCount: 0,
      searchText,
      loading: true,
    };
  });
}

export function handleAddCategory(id) {
  if (typeof id === 'undefined') {
    history.push('/manage/content/category/create/');
  } else {
    history.push(`/manage/content/category/create/${id}`);
  }
}
