import gql from 'graphql-tag';

export const LIST_USERS = gql`
  query listUsers(
    $size: Int
    $kind: Kind
    $skip: Int
    $email: String
    $document: String
    $status: String
  ) {
    userSearchUsers(
      size: $size
      kind: $kind
      skip: $skip
      email: $email
      document: $document
      status: $status
    ) {
      nodes {
        id
        email
        firstName
        lastName
        slug
        mobilePhone
        document
        status
        createdAt
        ... on UserCustomer {
          currentLastBalance
          bagsGeneratedCount
          dateLastLogin
          ordersCount
          sellingCount
          purchasedCount
          soldCount
        }
        ... on UserOng {
          amountTransferred
          dateLastLogin
          responsible
          sellingCount
          soldCount
        }
        ... on UserPartner {
          responsible
          bagsGeneratedCount
          bagsIdentifiedCount
          vouchersGeneratedCount
        }
      }
      totalCount
    }
  }
`;

export const QUERY_USER_BY_ID = gql`
  query listUsersById($size: Int, $kind: Kind, $skip: Int, $id: Int) {
    userSearchUsers(size: $size, kind: $kind, skip: $skip, id: $id) {
      nodes {
        id
        email
        firstName
        lastName
        slug
        mobilePhone
        document
        status
        createdAt
        bankingAccount {
          account
          accountType
          agency
          code
          id
          name
        }
        addresses {
          address1
          address2
          city
          id
          kind
          lat
          lng
          neighborhood
          number
          state {
            id
            country
            code
            abbr
            name
          }
          userId
          zipcode
        }
        ... on UserCustomer {
          createdAt
          miniBio
          ordersCount
          sellingCount
          purchasedCount
          soldCount
          gender
        }
        ... on UserOng {
          amountTransferred
          responsible
          sellingCount
          soldCount
          miniBio
          website
        }
        ... on UserPartner {
          responsible
          bagsGeneratedCount
          bagsIdentifiedCount
          vouchersGeneratedCount
        }
      }
      totalCount
    }
  }
`;
export const CREATE_ADDRESS = gql`
  mutation createAddress($input: CreateAddressInput!) {
    userCreateAddress(input: $input) {
      id
      address1
      address2
      city
      kind
      lat
      lng
      neighborhood
      number
      state {
        id
        name
        country
        code
        abbr
      }
      userId
      zipcode
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($input: UpdateAddressInput!) {
    userUpdateAddress(input: $input) {
      id
      address1
      address2
      city
      kind
      lat
      lng
      neighborhood
      number
      state {
        id
        name
        country
        code
        abbr
      }
      userId
      zipcode
    }
  }
`;

export const SET_BANK_ACCOUNT = gql`
  mutation setBankAccount($input: UpsertBankingAccountInput!) {
    userSetBankingAccount(input: $input) {
      account
      accountType
      agency
      code
      id
      name
      userId
    }
  }
`;
