import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const UPDATE_PARTNER = gql`
  mutation useUpdatePartner($input: UpdatePartnerInput!) {
    userUpdatePartner(input: $input) {
      ... on UserPartner {
        id
        email
        firstName
        lastName
        slug
        mobilePhone
        document
        responsible
      }
    }
  }
`;

function useUpdatePartner(options = {}) {
  const [updatePartner, { loading, data, error }] = useMutation(
    UPDATE_PARTNER,
    {
      ...options,
    }
  );

  return [
    ({
      partnerId = undefined,
      document = undefined,
      firstName = undefined,
      lastName = undefined,
      ddd = undefined,
      mobilePhone = undefined,
      responsible = undefined,
    } = {}) => {
      updatePartner({
        variables: {
          input: {
            userId: partnerId,
            document: document ? document.replace(/[^\d]+/gi, '') : undefined,
            firstName,
            lastName,
            mobilePhone:
              ddd && mobilePhone
                ? `${ddd}${mobilePhone.replace(/[^\d]+/gi, '')}`
                : '',
            responsible,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useUpdatePartner;
