import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { Row, Col, Input, DatePicker, Select, notification } from 'antd';
import { Option } from 'antd/lib/mentions';
import { FilterOutlined } from '@ant-design/icons';
import Button from '~/components/Button';

import { DATE_FORMAT_SIMPLE } from '~/constants/Global';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_CANCELED,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
  SHIPMENT_DISPATCH_STATUS,
} from '~/constants/ShipmentDispatch';
import {
  SHIPMENT_COMPLETED,
  SHIPMENT_DELIVERED,
  SHIPMENT_DELIVERY_NOT_MADE,
  SHIPMENT_ON_ROUTE,
  SHIPMENT_SEND,
  SHIPMENT_STATUSES,
  SHIPMENT_WAITING_SENT,
} from '~/constants/Shipment';
import {
  getPaymentStatus,
  getShipmentDispatchStatus,
  getShipmentStatus,
} from '~/util/statusConverter';
import {
  PAYMENT_STATUSES,
  STATUS_CREATED,
  STATUS_EXPIRED,
  STATUS_IN_ANALYSIS,
  STATUS_PAID,
  STATUS_REFUNDED,
  STATUS_REVERSED,
  STATUS_WAITING,
} from '~/constants/Payment';
import {
  STATUS_CANCELED,
  ORDER_LIST_REPURCHASE,
  ORDER_LIST_WITHOUT_REPURCHASE,
} from '~/constants/Order';

const { RangePicker } = DatePicker;

function Filter({ onSubmit, onReset, onChange, currentFilters }) {
  const handleSubmit = () => {
    onSubmit();
  };

  const resetFields = () => {
    onReset();
  };

  // TO DO: Adicionar novamente range de data, depois de decisão de negócio
  // const [api, contextHolder] = notification.useNotification();
  // const [metaInfoDate, setMetaInfoDate] = useState(null);

  // const disabledDate = current => {
  //   if (!metaInfoDate) return false;
  //   if (dates.length) {
  //     if (metaInfoDate === 'start') {
  //       return current.isSameOrAfter(moment(dates[0]).add(31, 'days'));
  //     }

  //     if (metaInfoDate === 'end') {
  //       return current.isSameOrBefore(
  //         moment(dates[1]).subtract(31, 'days').format('YYYY-MM-DD')
  //       );
  //     }
  //   }
  //   return false;
  // };

  const handleCalendarChange = value => {
    if (!value[0] || !value[1]) {
      notification.error({
        description: 'Selecione a data inicial e final',
        duration: 2,
        placement: 'topRight',
      });
    }

    onChange({
      filters: {
        ...currentFilters,
        startDate: value[0]?.format(DATE_FORMAT_SIMPLE),
        endDate: value[1]?.format(DATE_FORMAT_SIMPLE),
      },
    });
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xl={8} lg={24} md={24} xs={24}>
          <Input
            data-testid="inputFilter"
            placeholder="Buscar por id do pedido ou e-mail"
            value={currentFilters?.searchText}
            onChange={e => {
              onChange({
                filters: {
                  ...currentFilters,
                  searchText: e?.target?.value?.length
                    ? e.target.value
                    : undefined,
                },
              });
            }}
          />
        </Col>

        <Col xl={4} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterKind"
            value={currentFilters?.kind}
            placeholder="Tipo de pedido"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  kind: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option />
            <Option value="original">Compra</Option>
            <Option value="devolution">Devolução</Option>
          </Select>
        </Col>

        <Col xl={4} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterRepurchase"
            value={currentFilters?.repurchase}
            placeholder="Recompra"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  repurchase: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option>Todos</Option>
            <Option value={ORDER_LIST_REPURCHASE}>Sim</Option>
            <Option value={ORDER_LIST_WITHOUT_REPURCHASE}>Não</Option>
          </Select>
        </Col>

        <Col xl={8} lg={24} md={24} xs={24} data-testid="rangePicker">
          <RangePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            value={[
              currentFilters?.startDate
                ? moment(currentFilters?.startDate)
                : undefined,
              currentFilters?.endDate
                ? moment(currentFilters?.endDate)
                : undefined,
            ]}
            inputReadOnly
            allowClear
            ranges={{
              Dia: [moment(), moment()],
              Semana: [moment().day(0), moment().day(6)],
              Mês: [moment().startOf('month'), moment().endOf('month')],
            }}
            onCalendarChange={handleCalendarChange}
          />
          {/*
          <RangePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            value={dates}
            inputReadOnly
            allowClear={false}
            disabledDate={disabledDate}
            ranges={{
              Dia: [moment(), moment()],
              Semana: [moment().day(0), moment().day(6)],
              Mês: [moment().startOf('month'), moment().endOf('month')],
            }}
            onOpenChange={open => {
              setMetaInfoDate(open);
            }}
            onCalendarChange={(value, _, meta) => {
              setMetaInfoDate(meta.range);
              const daysRange = (value[1] - value[0]) / 1000 / 60 / 60 / 24;
              if (!value[0] || !value[1]) {
                api.error({
                  description: 'Selecione a data inicial e final',
                  duration: 2,
                });
              } else if (daysRange >= 31) {
                api.error({
                  description: 'Máximo 31 dias',
                  duration: 2,
                });
              } else {
                setDates([value[0], value[1]]);
              }
            }}
          /> */}
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xl={6} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterStatus"
            value={currentFilters?.status}
            placeholder="Status do pedido"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  status: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option />
            <Option value="pending">Aguardando</Option>
            <Option value="completed">Finalizado</Option>
            <Option value="canceled">Cancelado</Option>
          </Select>
        </Col>

        <Col xl={6} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterCurrentPaymentStatus"
            value={currentFilters?.currentPaymentStatus}
            placeholder="Status do pagamento"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  currentPaymentStatus: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option />
            <Option value={STATUS_CREATED}>
              {getPaymentStatus(STATUS_CREATED)}
            </Option>
            <Option value={STATUS_WAITING}>
              {getPaymentStatus(STATUS_WAITING)}
            </Option>
            <Option value={STATUS_IN_ANALYSIS}>
              {getPaymentStatus(STATUS_IN_ANALYSIS)}
            </Option>
            <Option value={STATUS_CANCELED}>
              {getPaymentStatus(STATUS_CANCELED)}
            </Option>
            <Option value={STATUS_REFUNDED}>
              {getPaymentStatus(STATUS_REFUNDED)}
            </Option>
            <Option value={STATUS_REVERSED}>
              {getPaymentStatus(STATUS_REVERSED)}
            </Option>
            <Option value={STATUS_PAID}>{getPaymentStatus(STATUS_PAID)}</Option>
            <Option value={STATUS_EXPIRED}>
              {getPaymentStatus(STATUS_EXPIRED)}
            </Option>
          </Select>
        </Col>

        <Col xl={6} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterCurrentShipmentStatus"
            value={currentFilters?.currentShipmentDispatchStatus}
            placeholder="Status de expedição"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  currentShipmentDispatchStatus: value?.length
                    ? value
                    : undefined,
                },
              });
            }}
          >
            <Option />
            <Option value={SHIPMENT_DISPATCH_AWAITING}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_AWAITING)}
            </Option>
            <Option value={SHIPMENT_DISPATCH_PROCESSING}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_PROCESSING)}
            </Option>
            <Option value={SHIPMENT_DISPATCH_PROCESSED}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_PROCESSED)}
            </Option>
            <Option value={SHIPMENT_DISPATCH_CANCELED}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_CANCELED)}
            </Option>
          </Select>
        </Col>

        <Col xl={6} lg={24} md={24} xs={24}>
          <Select
            data-testid="selectFilterCurrentShipmentStatus"
            value={currentFilters?.currentShipmentStatus}
            placeholder="Status do envio"
            style={{ width: '100%' }}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  currentShipmentStatus: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option />
            <Option value={SHIPMENT_WAITING_SENT}>
              {getShipmentStatus(SHIPMENT_WAITING_SENT)}
            </Option>
            <Option value={SHIPMENT_SEND}>
              {getShipmentStatus(SHIPMENT_SEND)}
            </Option>
            <Option value={SHIPMENT_ON_ROUTE}>
              {getShipmentStatus(SHIPMENT_ON_ROUTE)}
            </Option>
            <Option value={SHIPMENT_DELIVERED}>
              {getShipmentStatus(SHIPMENT_DELIVERED)}
            </Option>
            <Option value={SHIPMENT_COMPLETED}>
              {getShipmentStatus(SHIPMENT_COMPLETED)}
            </Option>
            <Option value={SHIPMENT_DELIVERY_NOT_MADE}>
              {getShipmentStatus(SHIPMENT_DELIVERY_NOT_MADE)}
            </Option>
          </Select>
        </Col>
      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Button
            data-testid="buttonForm"
            type="success"
            style={{ width: '100%' }}
            full="100"
            icon={<FilterOutlined />}
            onClick={handleSubmit}
          >
            FILTRAR
          </Button>
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Button
            data-testid="buttonClear"
            type="default"
            spacing={false}
            style={{ width: '100%' }}
            onClick={() => resetFields()}
          >
            LIMPAR BUSCA
          </Button>
        </Col>
      </Row>
    </>
  );
}

Filter.defaultProps = {
  currentFilters: {
    searchText: undefined,
    status: undefined,
    currentPaymentStatus: undefined,
    currentShipmentStatus: undefined,
    currentShipmentDispatchStatus: undefined,
    startDate: undefined,
    endDate: undefined,
    kind: undefined,
    repurchase: undefined,
  },
  onSubmit: () => {},
  onReset: () => {},
};

Filter.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape({
    searchText: PropTypes.string,
    status: PropTypes.oneOf(['pending', 'canceled', 'completed']),
    currentPaymentStatus: PropTypes.oneOf(PAYMENT_STATUSES),
    currentShipmentStatus: PropTypes.oneOf(SHIPMENT_STATUSES),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    kind: PropTypes.oneOf(['original', 'devolution']),
    currentShipmentDispatchStatus: PropTypes.oneOf(SHIPMENT_DISPATCH_STATUS),
    repurchase: PropTypes.string,
  }),
};

export default Filter;
