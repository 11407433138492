import React from 'react';
import { notification } from 'antd';

export function handleSubmit(
  subjectId,
  values,
  { isEdit, faqQuestion, setFaqQuestion, createFaqQuestion, updateFaqQuestion }
) {
  if (!isEdit) {
    createFaqQuestion({
      variables: {
        input: {
          faqSubjectId: subjectId,
          weightPosition: 0,
          question: values.question,
          answer: values.answer,
          bagQuestion: values.bagQuestion,
          anchor: values.anchor,
        },
      },
    })
      .then(successUpt => {
        setFaqQuestion(successUpt.data.faqCreateFaqQuestion);
        notification.success({
          message: `Pergunta criada`,
          description: <div>A pergunta foi criada com sucesso!</div>,
          placement: 'topRight',
        });
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao criar pergunta`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  } else {
    updateFaqQuestion({
      variables: {
        input: {
          id: faqQuestion.id,
          weightPosition: faqQuestion.weightPosition,
          question: values.question,
          answer: values.answer,
          bagQuestion: values.bagQuestion,
          anchor: values.anchor,
        },
      },
    })
      .then(successUpt => {
        setFaqQuestion(successUpt.data.faqUpdateFaqQuestion);
        notification.success({
          message: `Pergunta atualizada`,
          description: <div>A pergunta foi atualizada com sucesso!</div>,
          placement: 'topRight',
        });
      })
      .catch(errorUpt => {
        notification.error({
          message: `Erro ao atualizar pergunta`,
          description: errorUpt.errors
            ? errorUpt.errors.map(obj => obj.message).join(';')
            : errorUpt.message,
          placement: 'topRight',
        });
      });
  }
}

export const loadFaqQuestions = ({ data, setFaqQuestion, setLoading }) => {
  if (data) {
    const {
      faqSearchFaqQuestions: { nodes, totalCount },
    } = data;
    if (totalCount > 0) {
      const [faqQuestion] = nodes;
      setFaqQuestion(prevState => {
        return {
          ...prevState,
          id: faqQuestion.id,
          weightPosition: faqQuestion.weightPosition,
          question: faqQuestion.question,
          answer: faqQuestion.answer,
          bagQuestion: faqQuestion.bagQuestion,
          anchor: faqQuestion.anchor,
        };
      });
    }
    setLoading(false);
  }
};
