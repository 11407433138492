import gql from 'graphql-tag';

export const QUERY_RETURN = gql`
  query (
    $id: Int
    $productId: Int
    $customerName: String
    $customerEmail: String
    $sellerEmail: String
    $reverseCode: String
    $trackingCode: String
    $requestDate: String
    $orderItemStatus: DevolutionOrderItemStatuses
    $currentShipmentStatus: Int
  ) {
    ordersOrderListOrders(
      id: $id
      productId: $productId
      customerName: $customerName
      customerEmail: $customerEmail
      sellerEmail: $sellerEmail
      reverseCode: $reverseCode
      trackingCode: $trackingCode
      requestDate: $requestDate
      orderItemStatus: $orderItemStatus
      currentShipmentStatus: $currentShipmentStatus
    ) {
      nodes {
        id
        createdAt
        currentStatus
        shippingName
        currentShipmentOrderSubType
        currentShipmentStatus
        user {
          id
          fullname
          email
        }
        orderItems {
          id
          status
          reverseCode
          trackingCode
          itemName
          itemId
          thumbnail
          sellerEmail
          orderItemReturn {
            reason
            comment
            anotherReason
            requestedAt
            retunerdAt
          }
        }
      }
    }
  }
`;

export const MUTATION_APPROVE_RETURN = gql`
  mutation confirmOrdersItemReturnMutation(
    $productId: Int!
    $orderId: Int!
    $reverseCode: String
  ) {
    confirmOrdersItemReturn(
      input: {
        productId: $productId
        orderId: $orderId
        reverseCode: $reverseCode
      }
    ) {
      id
      status
      product {
        id
        name
      }
      orderItemReturn {
        retunerdAt
      }
    }
  }
`;
