import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

import { notifyError, notifySuccess } from '~/util/Functions';

export const PROMOTION_ANTICIPATE = gql`
  mutation useAnticipatePromotion($input: AnticipateSaleInput!) {
    salesSaleAnticipateSale(input: $input) {
      id
      name
      sellerNotifiedAt
    }
  }
`;

export function useAnticipatePromotion() {
  const [anticipatePromotion] = useMutation(PROMOTION_ANTICIPATE, {
    onCompleted: ({ salesSaleAnticipateSale: anticipatedPromotionData }) => {
      notifySuccess(
        'Promoção antecipada com sucesso',
        `A data de opt-in da promoção "${anticipatedPromotionData.name}" foi antecipada com sucesso!`
      );
    },
    onError: err => {
      notifyError('Houve um erro ao antecipar a promoção', err);
    },
    refetchQueries: ['listPromotions'],
    awaitRefetchQueries: true,
  });

  return [
    ({ id }) => {
      anticipatePromotion({ variables: { input: { id } } });
    },
  ];
}
