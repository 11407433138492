import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ArrowLeftOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Card, Tooltip } from 'antd';
import { Redirect } from 'react-router-dom';
import history from '~/services/history';

import {
  MUTATION_CREATE_FAQ_CATEGORY,
  QUERY_FAQ_CATEGORY_BY_ID,
  MUTATION_UPDATE_FAQ_CATEGORY,
  QUERY_FAQ_CATEGORIES,
} from '~/apollo/content/faq';

import LoadingBar from '~/components/LoadingBar';
import FeedbackMessage from '~/components/FeedbackMessage';
import Button from '~/components/Button';
import DefaultForm from './DefaultForm';
import { handleSubmit, loadFaqCayegory } from './actions';

function CategoryForm({ match }) {
  const [isEdit, setIsEdit] = useState(match.url.includes('edit'));
  const [redirectToEdit, setRedirectToEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(null);
  const [faqCategory, setFaqCategory] = useState({
    id: '',
    subject: '',
    icon: '',
    weightPosition: 0,
  });

  const [queryFaqCategoryById, { data }] = useLazyQuery(
    QUERY_FAQ_CATEGORY_BY_ID,
    {
      context: {
        newBackend: true,
      },
      variables: {
        id: Number(match.params.id),
      },
      fetchPolicy: 'network-only',
      onCompleted: result => {
        const {
          faqSearchFaqSubjects: { nodes: faqCategories },
        } = result;

        if (faqCategories.length > 0) {
          setFaqCategory(faqCategories[0]);
        } else {
          setNotFound(true);
        }
      },
      onError: errorSearch => {
        setError(errorSearch);
        setLoading(false);
      },
    }
  );

  const [createFaqCategory] = useMutation(MUTATION_CREATE_FAQ_CATEGORY, {
    context: {
      newBackend: true,
    },
    update(cache, { data: { faqCreateFaqSubject: addSubject } }) {
      try {
        const { faqSearchFaqSubjects } = cache.readQuery({
          query: QUERY_FAQ_CATEGORIES,
        });

        faqSearchFaqSubjects.nodes = faqSearchFaqSubjects.nodes.concat([
          addSubject,
        ]);

        cache.writeQuery({
          query: QUERY_FAQ_CATEGORIES,
          data: {
            faqSearchFaqSubjects,
          },
        });
      } catch {
        // Cache doesn't exists, do nothing
      }
    },
  });

  const [updateFaqCategory] = useMutation(MUTATION_UPDATE_FAQ_CATEGORY, {
    context: {
      newBackend: true,
    },
  });

  const callSubmit = values => {
    handleSubmit(values, {
      isEdit,
      faqCategory,
      setFaqCategory,
      createFaqCategory,
      updateFaqCategory,
      setRedirectToEdit,
    });
  };

  useEffect(() => {
    if (isEdit) {
      queryFaqCategoryById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  useEffect(() => {
    loadFaqCayegory({ data, setFaqCategory, setLoading });
  }, [data]);

  useEffect(() => {
    if (redirectToEdit) {
      setIsEdit(true);
      setRedirectToEdit(false);
    }
  }, [redirectToEdit]);

  if (isEdit && loading) return <LoadingBar />;
  if (isEdit && error) return `Error! ${error.message}`;

  if (redirectToEdit) {
    return (
      <Redirect to={`/manage/content/faq/category/edit/${faqCategory.id}`} />
    );
  }

  if (notFound)
    return (
      <Card>
        <FeedbackMessage
          icon={<CloseCircleOutlined />}
          title="Categoria do FAQ não encontrada"
          description={
            <center>
              <small>(ID: {match.params.id})</small>
              <p>Categoria do FAQ especificado não foi encontrado</p>
            </center>
          }
          bottom={
            <Button
              type="default"
              htmlType="submit"
              data-testid="faqAddCategoryBtn"
              onClick={() => {
                history.push('/manage/content/faq/category');
              }}
            >
              Ir para listagem
            </Button>
          }
        />
      </Card>
    );

  return (
    <Card
      data-testid="faqCategoryCardForm"
      title={
        <>
          <Tooltip placement="bottom" title="Voltar para categorias">
            <ArrowLeftOutlined
              onClick={history.goBack}
              data-testid="faqBackToCategoryBtn"
            />
          </Tooltip>
          <span style={{ marginLeft: '10px' }}>
            FAQ - {isEdit ? 'Editar categoria' : 'Adicionar categoria'}
          </span>
        </>
      }
    >
      <DefaultForm
        handleSubmit={callSubmit}
        initialData={faqCategory}
        isEdit={isEdit}
      />
    </Card>
  );
}

CategoryForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape(),
  }).isRequired,
};

export default CategoryForm;
