import gql from 'graphql-tag';

// New Backend
export const QUERY_FOOTER_CATEGORIES_CONTENTS = gql`
  query footerListCategories {
    contentDynamicContentsSearchFooterContents(kind: footer_categories) {
      nodes {
        id
        dynamicContentable {
          ... on Category {
            id
            name
            permalink
          }
        }
      }
    }
  }
`;

export const QUERY_FOOTER_BRANDS_CONTENTS = gql`
  query footerListBrands {
    contentDynamicContentsSearchFooterContents(kind: footer_brands) {
      nodes {
        id
        dynamicContentable {
          ... on Brand {
            id
            name
          }
        }
      }
    }
  }
`;

// New Backend
export const FOOTER_CATEGORIES_SEARCH = gql`
  query footerSearchCategories($first: Int, $name: String) {
    categoriesProductSearchCategories(first: $first, name: $name) {
      nodes {
        id
        name
        parentId
        metaTitle
        permalink
      }
    }
  }
`;

// New Backend
export const FOOTER_BRANDS_SEARCH = gql`
  query footerSearchBrands($first: Int, $name: String) {
    productsProductSearchBrands(first: $first, name: $name) {
      nodes {
        id
        name
        productCount
        active
      }
    }
  }
`;

// New Backend
export const MUTATION_UPSERT_FOOTER_BRANDS = gql`
  mutation footerUpsertBrands($input: UpsertFooterContentsInput!) {
    contentDynamicContentsUpsertFooterContents(input: $input) {
      id
      dynamicContentable {
        ... on Brand {
          id
          name
        }
      }
    }
  }
`;

export const MUTATION_UPSERT_FOOTER_CATEGORIES = gql`
  mutation footerUpsertCategories($input: UpsertFooterContentsInput!) {
    contentDynamicContentsUpsertFooterContents(input: $input) {
      id
      dynamicContentable {
        ... on Category {
          id
          name
        }
      }
    }
  }
`;
