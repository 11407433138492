import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, TreeSelect, Select, Modal, Spin } from 'antd';
import Button from '~/components/Button';
import ColorsModal from './ColorsModal';
import {
  ACTION_ADD_TABLE,
  ACTION_ADD_MULTROW,
  TABLE_TYPE_CATEGORY,
  ACTION_ADD_MULTCOLOR,
} from '../../RulesReducer/constants';

import { notifyError } from '~/util/Functions';

import { initialTableState } from '../../RulesReducer/actions';
import useCategory from './useCategory';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

function Category({
  form,
  promotion,
  dispatch,
  tables,
  selectedTypeRule,
  addColor,
  setAddColor,
}) {
  const [selectedGenre, setSelectedGenre] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [arrGenres, setArrGenres] = useState([]);
  const [newColors, setNewColors] = useState([]);

  const [getGenres, { loading: loadingGenres }] = useCategory({
    getter: arrGenres,
    setter: setArrGenres,
    onError: errorSearch => {
      notifyError('Erro ao buscar generos', errorSearch);
      setArrGenres([]);
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      genre: '',
      categories: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypeRule]);

  useEffect(() => {
    getGenres({
      parentId: null,
      orderBy: 'NAME_ASC',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getCategories] = useCategory({
    getter: selectedCategory,
    setter: setSelectedCategory,
    onError: errorSearch => {
      notifyError('Erro ao buscar categorias', errorSearch);
      setSelectedCategory([]);
    },
  });

  const [getCategoriesChildrens] = useCategory({
    getter: selectedCategory,
    setter: setSelectedCategory,
    onError: errorSearch => {
      notifyError('Erro ao buscar categorias', errorSearch);
      setSelectedCategory([]);
    },
  });

  const handleTreeExpand = expandedKeys => {
    expandedKeys.forEach(key => {
      getCategoriesChildrens(
        {
          parentId: key,
          orderBy: 'NAME_ASC',
        },
        key
      );
    });
  };

  const handleTreeSelect = value => {
    getCategoriesChildrens(
      {
        parentId: value,
        orderBy: 'NAME_ASC',
      },
      value
    );
  };

  const handleOk = () => {
    dispatch({
      type: ACTION_ADD_MULTCOLOR,
      payload: {
        tableKey: addColor.tableKey,
        rowKey: addColor.record.key,
        newColors,
      },
    });
    setNewColors([]);
    setAddColor({ visible: false, record: {} });
  };

  const handleCancel = () => {
    setAddColor({ visible: false });
  };

  const handleChangeGenre = value => {
    const Genres = arrGenres.filter(item => item.value === value);
    setSelectedGenre(Genres[0]);

    getCategories({
      parentId: value,
      orderBy: 'NAME_ASC',
    });

    form.setFieldsValue({
      genre: value,
    });
  };

  const handleChangeCategory = values => {
    form.setFieldsValue({
      categories: values,
    });
  };

  const selectCategories = selectedCategories => {
    const arr = [];
    selectedCategory.forEach(treeItem => {
      if (selectedCategories.includes(treeItem.key)) {
        arr.push(treeItem);
      }
      treeItem.children.forEach(children => {
        if (selectedCategories.includes(children.key)) {
          arr.push({
            ...children,
            parentName: treeItem.title,
          });
        }
      });
    });
    return arr;
  };

  const handleAddTable = selectedItems => {
    form.setFieldsValue({
      categories: [],
    });
    const exists =
      tables.filter(table => {
        return table.key === selectedGenre.id;
      }).length > 0;

    if (exists) {
      dispatch({
        type: ACTION_ADD_MULTROW,
        payload: {
          tableKey: selectedGenre.id,
          newRows: selectedItems,
        },
      });
    } else {
      dispatch({
        type: ACTION_ADD_TABLE,
        payload: initialTableState({
          key: selectedGenre.id,
          type: TABLE_TYPE_CATEGORY,
          title: selectedGenre.title,
          dispatch,
          setAddColor,
          data: selectedItems,
        }),
      });
    }
  };

  const handleAdd = () => {
    const categories = form.getFieldValue('categories');
    handleAddTable(selectCategories(categories));
  };

  return (
    <>
      <Col span={24}>
        <Form.Item label="Generos" name="genre" initialValue={promotion.genre}>
          <Select
            data-testid="genre"
            style={{ width: '100%' }}
            onChange={handleChangeGenre}
            notFoundContent={loadingGenres ? <Spin size="small" /> : null}
            disabled={!selectedTypeRule}
          >
            <Option value="" disabled selected="selected">
              Selecione um gênero
            </Option>
            {arrGenres.map(item => (
              <Option key={item.id} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Categorias"
          data-testid="categories"
          name="categories"
          initialValue={[]}
        >
          <TreeSelect
            treeData={selectedCategory}
            onTreeExpand={handleTreeExpand}
            onChange={handleChangeCategory}
            onSelect={handleTreeSelect}
            treeCheckable
            showCheckedStrategy={SHOW_PARENT}
            placeholder="Selecione uma Categoria"
            style={{ width: '100%' }}
            disabled={form.getFieldValue('genre') === ''}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item label="Categorias">
          <Button
            data-testid="addCategoryRuleBtn"
            type="primary"
            onClick={handleAdd}
          >
            Adicionar
          </Button>
        </Form.Item>
      </Col>
      <Modal
        title="Adicionar cor"
        visible={addColor.visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ColorsModal newColors={newColors} setNewColors={setNewColors} />
      </Modal>
    </>
  );
}

Category.propTypes = {
  form: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedTypeRule: PropTypes.string,
  promotion: PropTypes.shape({
    genre: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
        key: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            key: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  addColor: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    record: PropTypes.shape().isRequired,
    tableKey: PropTypes.string,
  }).isRequired,
  setAddColor: PropTypes.func.isRequired,
};

Category.defaultProps = {
  selectedTypeRule: '',
};

export default Category;
