import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment';
import Button from '~/components/Button';
import { triggerErrorNotification } from '~/util/Functions';
import DetailsPromotion from './DetailsPromotion';
import RulesPromotion from './RulesPromotion';

const PromotionForm = ({
  handleSubmitForm,
  promotion,
  tables,
  dispatch,
  addColor,
  setAddColor,
  isEdit,
}) => {
  const [form] = Form.useForm();

  const formatDate = date => moment(date).format('YYYY-MM-DD');

  const checkIsSameOrBefore = ({ prevValue, targetValue, key, message }) => {
    if (moment(prevValue).isSameOrBefore(targetValue)) {
      form.setFields([{ name: key, errors: [message] }]);
    }
  };

  const validateDateFields = values => {
    const { sellerNotifiedAt, startedAt, expiredAt } = values;
    const dates = {
      startedAt: {
        prevValue: formatDate(startedAt),
        targetValue: formatDate(sellerNotifiedAt),
        message: 'Publicação não pode ser anterior ou igual a opt-in',
      },
      expiredAt: {
        prevValue: formatDate(expiredAt),
        targetValue: formatDate(startedAt),
        message: 'Data Fim não pode ser anterior ou igual a publicação',
      },
    };

    Object.entries(dates).forEach(([key, value]) => {
      checkIsSameOrBefore({ ...value, key });
    });
  };

  const onFinish = async values => {
    validateDateFields(values);

    const hasError = form.getFieldsError().filter(error => error.errors.length);

    if (hasError.length) return;

    handleSubmitForm({ ...values, tables });
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    triggerErrorNotification(
      'Erro ao criar categoria',
      values,
      errorFields,
      outOfDate
    );
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      data-testid="brand-form"
      layout="vertical"
    >
      <Row gutter={50}>
        <Col xl={12} lg={24} sm={24} xs={24}>
          <DetailsPromotion
            form={form}
            promotion={promotion}
            isDisabled={isEdit}
          />
        </Col>
        <Col xl={12} lg={24} sm={24} xs={24}>
          <RulesPromotion
            form={form}
            promotion={promotion}
            tables={tables}
            dispatch={dispatch}
            addColor={addColor}
            setAddColor={setAddColor}
            isDisabled={isEdit}
          />
        </Col>
      </Row>

      <Row type="flex" justify="end" align="middle">
        <Col>
          <Form.Item>
            <Button
              data-testid="addPromotionSubmitBtn"
              type="success"
              htmlType="submit"
              icon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

function DefaultForm({
  handleSubmitForm,
  promotion,
  tables,
  dispatch,
  addColor,
  setAddColor,
  isEdit,
}) {
  return (
    <PromotionForm
      handleSubmitForm={handleSubmitForm}
      promotion={promotion}
      tables={tables}
      dispatch={dispatch}
      addColor={addColor}
      setAddColor={setAddColor}
      isEdit={isEdit}
    />
  );
}

DefaultForm.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  promotion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    sellerDescription: PropTypes.string,
    buyerDescription: PropTypes.string,
    discount: PropTypes.number,
    ruleType: PropTypes.string,
    genre: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
        key: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            key: PropTypes.string,
          })
        ),
      })
    ),
    property: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string,
        option: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
    gte: PropTypes.number,
    lte: PropTypes.number,
    timer: PropTypes.string,
  }).isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  addColor: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    tableKey: PropTypes.string,
  }).isRequired,
  setAddColor: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

PromotionForm.propTypes = {
  handleSubmitForm: PropTypes.func.isRequired,
  promotion: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    sellerDescription: PropTypes.string,
    buyerDescription: PropTypes.string,
    discount: PropTypes.number,
    ruleType: PropTypes.string,
    genre: PropTypes.string,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.string,
        key: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.string,
            key: PropTypes.string,
          })
        ),
      })
    ),
    property: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        key: PropTypes.string,
        option: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
    priceMax: PropTypes.number,
    priceMin: PropTypes.number,
    timer: PropTypes.string,
  }).isRequired,
  tables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  addColor: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    tableKey: PropTypes.string,
  }).isRequired,
  setAddColor: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

export default DefaultForm;
