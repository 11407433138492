import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Button from '../../../../components/Button';
import LoadingBar from '../../../../components/LoadingBar';

import Filter from './Filter';
import TableBrand from './TableBrand';

import { handleAddBrand } from './actions';

import { getTodayISODate } from '../../../../util/Functions';
import { BRANDS_LIST, BRAND_DELETE } from '../../../../apollo/bag/brand';

export default function BrandList() {
  const [brand, setBrand] = useState([]);
  const [newBrandId, setNewBrandId] = useState('');
  const [table, setTable] = useState({
    pageSize: 10,
    currentPage: 1,
    skip: 0,
    totalCount: 0,
    searchText: '',
    searchDate: getTodayISODate({
      seconds: false,
      minutes: false,
      hours: false,
    }),
    searchStatus: 'pending',
    loading: false,
    refetch: false,
  });
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState({
    flag: false,
    message: '',
    obj: {},
  });

  function updateHooks(searchBrands, totalCount) {
    setBrand(searchBrands);
    setTable({
      ...table,
      loading: false,
      refetch: false,
      totalCount,
    });
    setLoading(false);
  }

  const [listBrands] = useLazyQuery(BRANDS_LIST, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    variables: {
      first: table.pageSize,
      skip: table.skip,
      name: table.searchText,
    },
    onCompleted: ({
      productsProductSearchBrands: { nodes: searchBrands, totalCount },
    }) => {
      updateHooks(searchBrands, totalCount); /* totalCount */
    },
    onError: err => {
      setError({
        flag: true,
        message: 'Erro ao buscar brands',
        obj: err,
      });
      setLoading(false);
    },
  });

  const [deleteBrand] = useMutation(BRAND_DELETE, {
    context: {
      newBackend: true,
    },
    refetchQueries: ['listBrands'],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    listBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.loading]);

  if (loading) return <LoadingBar />;
  if (error.flag) return `Error! ${error.message}`;

  return (
    <Card
      data-testid="brandCard"
      title="MARCA"
      extra={
        <Button
          data-testid="addBtn"
          onClick={handleAddBrand}
          style={{ marginBottom: 0 }}
          type="primary"
          icon={<PlusOutlined />}
        >
          Adicionar
        </Button>
      }
    >
      <Card.Grid style={{ width: '100%', textAlign: 'left' }}>
        <Filter setTable={setTable} />
      </Card.Grid>
      <Card.Grid style={{ width: '100%' }}>
        <TableBrand
          brand={brand}
          deleteBrand={deleteBrand}
          newBrandId={newBrandId}
          setNewBrandId={setNewBrandId}
          table={table}
          setTable={setTable}
        />
      </Card.Grid>
    </Card>
  );
}
