import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

export const CREATE_ONG = gql`
  mutation useCreateOng($input: CreateOngInput!) {
    userCreateOng(input: $input) {
      user {
        id
        firstName
        lastName
        email
        slug
        document
        mobilePhone
        ... on UserOng {
          responsible
          miniBio
          website
        }
      }
    }
  }
`;

function useCreateOng(options = {}) {
  const [createPartner, { loading, data, error }] = useMutation(CREATE_ONG, {
    ...options,
  });

  return [
    ({
      document = undefined,
      email = undefined,
      firstName = undefined,
      lastName = undefined,
      ddd = undefined,
      mobilePhone = undefined,
      password = undefined,
      passwordConfirmation = undefined,
      responsible = undefined,
      miniBio = undefined,
      website = undefined,
    } = {}) => {
      createPartner({
        variables: {
          input: {
            document: document ? document.replace(/[^\d]+/gi, '') : undefined,
            email,
            firstName,
            lastName,
            mobilePhone:
              ddd && mobilePhone
                ? `${ddd}${mobilePhone.replace(/[^\d]+/gi, '')}`
                : undefined,
            password,
            passwordConfirmation,
            responsible,
            miniBio,
            website,
          },
        },
      });
    },
    {
      loading,
      data,
      error,
    },
  ];
}

export default useCreateOng;
