import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, notification } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import UppyUpload from '~/components/Upload/uppy';
import { useImportZipfileNfse } from '~/hooks/financial/useImportZipfileNfse';
import history from '~/services/history';
import {
  ButtonWrapper,
  StatusMessage,
  UploadIcon,
  UploadObs,
  UploadText,
} from './styles';

const FILE_SIZE = 10;

export default function SendFilesConciliation() {
  const [cachedFile, uploadCache, error] = UppyUpload('ZipfileNFSe');

  const [sendStatus, setSendStatus] = useState(null);
  const [fileList, setFileList] = useState([]);
  const doneFiles = fileList.filter(file => file.status === 'done');

  const [importZipfileNfse, { loading: loadingImport }] = useImportZipfileNfse({
    onCompleted: () => {
      setSendStatus({ status: 'success' });
    },
    onError: () => {
      setSendStatus({ status: 'error' });
    },
  });

  useEffect(() => {
    if (cachedFile) {
      setFileList(prevFileList => {
        return prevFileList.map(file => {
          if (file.name === JSON.parse(cachedFile).metadata.filename) {
            return { ...file, status: 'done', zipfile: cachedFile };
          }
          return file;
        });
      });
    }
  }, [cachedFile]);

  useEffect(() => {
    if (error && error?.length > 1) {
      setFileList(prevFileList => {
        return prevFileList.map(file => {
          if (file.name === error?.[0]?.name) {
            // eslint-disable-next-line no-console
            console.log('file error:', error);
            return { ...file, status: 'error' };
          }
          return file;
        });
      });
    }
  }, [error]);

  const resetUpload = () => {
    setSendStatus(null);
    setFileList([]);
  };

  const beforeUpload = async file => {
    const isLimitMb = file.size / 1024 / 1024 < FILE_SIZE;

    if (!file.type.includes('zip')) {
      notification.info({
        message: 'Somente serão aceitos arquivos compactados no formato .zip',
        duration: 5,
        placement: 'topRight',
      });
      return false;
    }

    if (!isLimitMb) {
      notification.info({
        message: `Somente serão aceitos arquivos de no máximo ${FILE_SIZE}MB`,
        duration: 5,
        placement: 'topRight',
      });
      return false;
    }

    if (fileList.find(f => f.name === file.name)) {
      notification.info({
        message: 'O arquivo já foi adicionado',
        duration: 5,
        placement: 'topRight',
      });
      return false;
    }

    setFileList(prev => [
      ...prev,
      {
        uid: file.uid,
        name: file.name,
        status: 'uploading',
      },
    ]);
    // é necessário o await abaixo para evitar um erro no @uppy/aws-s3
    await uploadCache(file);

    return false;
  };

  const onRemove = file => {
    if (!loadingImport) {
      setFileList(prev => prev.filter(item => item.uid !== file.uid));
    }
  };

  const handleConciliation = () => {
    importZipfileNfse({
      zipfile: doneFiles.map(file => file.zipfile),
    });
  };

  const handleConciliationStatus = () =>
    history.push('/manage/financial/conciliation-nfse/status');

  return (
    <Card title="Enviar Arquivos para Conciliação">
      <Row gutter={20} justify="center" align="top">
        <Col md={20}>
          {sendStatus?.status ? (
            <StatusMessage success={sendStatus?.status === 'success'}>
              {sendStatus?.status === 'success' ? (
                <>
                  <CheckCircleOutlined />
                  <h3>Solicitação de conciliação realizada com sucesso.</h3>
                  <ButtonWrapper>
                    <Button
                      type="default"
                      htmlType="button"
                      onClick={resetUpload}
                    >
                      Enviar mais arquivos
                    </Button>
                    <Button
                      type="success"
                      htmlType="button"
                      onClick={handleConciliationStatus}
                    >
                      Status da Conciliação
                    </Button>
                  </ButtonWrapper>
                </>
              ) : (
                <>
                  <CloseCircleOutlined />
                  <h3>
                    Erro na solicitação de conciliação, por favor tente
                    novamente.
                  </h3>

                  <ButtonWrapper>
                    <Button
                      type="success"
                      htmlType="button"
                      onClick={resetUpload}
                    >
                      Enviar arquivos novamente
                    </Button>
                  </ButtonWrapper>
                </>
              )}
            </StatusMessage>
          ) : (
            <>
              <Dragger
                name="file"
                listType="picture"
                defaultFileList={fileList}
                onRemove={onRemove}
                beforeUpload={beforeUpload}
                fileList={fileList}
                accept=".zip"
                multiple
              >
                <UploadIcon>
                  <FileZipOutlined color="#707486" />
                </UploadIcon>
                <UploadText>
                  Click ou arraste os arquivos para fazer o upload
                </UploadText>
                <UploadObs>
                  Obs.: Somente serão aceitos arquivos compactados no formato
                  .zip e de no máximo {FILE_SIZE}MB
                </UploadObs>
              </Dragger>

              <ButtonWrapper>
                <Button
                  type="success"
                  htmlType="button"
                  disabled={!doneFiles.length}
                  onClick={handleConciliation}
                  loading={loadingImport}
                >
                  Conciliar NFS-e
                </Button>
                <Button
                  type="success"
                  htmlType="button"
                  disabled={!fileList.length || loadingImport}
                  onClick={resetUpload}
                >
                  Cancelar
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}
