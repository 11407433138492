import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BlockCarousel } from '~/pages/marketing/home/BlockBanner/Carousel';
import { oneOfAzureGroups } from '~/util/Auth';
import NotFound from '~/containers/NotFound';
import GroupRoute from '../../../components/GroupRoute';

import CouponList from '../../../pages/marketing/coupon/CouponList';
import CouponForm from '../../../pages/marketing/coupon/CouponForm';
import PromotionList from '../../../pages/marketing/promotion/PromotionList';
import PromotionForm from '../../../pages/marketing/promotion/PromotionForm';

import BlocksList from '../../../pages/marketing/home/BlocksList';
import { BlockBanner } from '../../../pages/marketing/home/BlockBanner';
import ProductForm from '../../../pages/marketing/home/BlockProduct';
import EditBlock from '../../../pages/marketing/home/BlockEdit';

import {
  TECNOLOGIA,
  MARKETING,
  ATENDIMENTO,
} from '../../../constants/UserGroups';

const Marketing = ({ match, groups }) => {
  return (
    <Switch>
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING, ATENDIMENTO]}
        exact
        path={`${match.url}/coupons`}
        component={CouponList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING, ATENDIMENTO]}
        exact
        path={`${match.url}/coupons/create`}
        component={CouponForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING, ATENDIMENTO]}
        exact
        path={`${match.url}/coupons/edit/:id`}
        component={CouponForm}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home`}
        component={BlocksList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/banner/edit/:id`}
        component={EditBlock}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/banner_carousel/edit/:id`}
        component={EditBlock}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/create/banner`}
        component={BlockBanner}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/create/carousel`}
        component={BlockCarousel}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/product/create`}
        component={ProductForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/home/product/edit/:id`}
        component={ProductForm}
      />

      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/promotion`}
        component={PromotionList}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/promotion/create`}
        component={PromotionForm}
      />
      <GroupRoute
        currentGroups={groups}
        routeGroups={[TECNOLOGIA, MARKETING]}
        exact
        path={`${match.url}/promotion/edit/:id`}
        component={PromotionForm}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

Marketing.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  groups: oneOfAzureGroups,
};

Marketing.defaultProps = {
  groups: [],
};

const mapStateToProps = ({ auth }) => {
  return {
    groups: auth.loggedIn ? auth.account.groups : [],
  };
};

export default connect(mapStateToProps)(Marketing);
