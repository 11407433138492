import React from 'react';

import { Link } from 'react-router-dom';
import { Tooltip, Typography } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';

import Button from '~/components/Button';

import { convertDate, toCurrencyFormat } from '~/util/Functions';
import {
  getOrderStatus,
  getPaymentStatus,
  getProductOrderKind,
  getShipmentDispatchStatus,
  getShipmentStatus,
} from '~/util/statusConverter';
import {
  ORDER_KIND_ORIGINAL,
  ORDER_ORIGIN_CLIENT_LISTING,
} from '~/constants/Order';

const { Text } = Typography;

export function generateColumns(userId) {
  const data = [
    {
      title: 'ID do pedido',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      render: (_, { id, number }) => (
        <Text>
          #{id}{' '}
          <small>
            <Text type="secondary">({number})</Text>
          </small>
        </Text>
      ),
    },
    {
      title: 'Data do Pedido',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (_, { orderDate }) => convertDate(orderDate),
    },
    {
      title: 'Valor do Pedido',
      dataIndex: 'total',
      key: 'total',
      render: (_, { total }) => toCurrencyFormat(total),
    },
    {
      title: 'Tipo do pedido',
      dataIndex: 'kind',
      key: 'kind',
      width: 150,
      render: (_, record) => getProductOrderKind(record?.kind),
    },
    {
      title: 'Status do pedido',
      dataIndex: 'currentStatus',
      key: 'currentStatus',
      render: (_, { currentStatus }) => getOrderStatus(currentStatus),
    },
    {
      title: 'Status do pagamento',
      dataIndex: 'currentPaymentStatus',
      key: 'currentPaymentStatus',
      render: (_, { currentPaymentStatus }) =>
        getPaymentStatus(currentPaymentStatus),
    },
    {
      title: 'Status do envio',
      dataIndex: 'getShipmentStatus',
      key: 'getShipmentStatus',
      render: (_, { currentShipmentStatus, kind }) =>
        getShipmentStatus(currentShipmentStatus, kind || ORDER_KIND_ORIGINAL),
    },
    {
      title: 'Status de expedição ',
      dataIndex: 'currentShipmentDispatchStatus',
      key: 'currentShipmentDispatchStatus',
      render: (_, { currentShipmentDispatchStatus }) =>
        getShipmentDispatchStatus(currentShipmentDispatchStatus),
    },
    {
      title: 'Transportadora',
      dataIndex: 'shipmentMethodName',
      key: 'shipmentMethodName',
      render: (_, { shippingAddress }) => {
        return shippingAddress?.shipmentMethod?.shipment_name || '-';
      },
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 80,
      render: (_, record) => (
        <Tooltip placement="bottom" title="Visualizar">
          <Link
            to={`/manage/orders/products/edit/${record.id}?from=${ORDER_ORIGIN_CLIENT_LISTING}&userId=${userId}`}
          >
            <Button
              data-testid="viewBtn"
              type="link"
              icon={<FileSearchOutlined />}
              size="small"
              style={{ margin: 0 }}
            />
          </Link>
        </Tooltip>
      ),
    },
  ];

  return data;
}
