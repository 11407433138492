import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { useLazyQuery } from '@apollo/react-hooks';

import { Card, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import TableCoupon from './TableCoupon';
import Button from '~/components/Button';
import Filter from './Filter';
import LoadingBar from '~/components/LoadingBar';

import { LIST_COUPONS } from '~/apollo/marketing/coupon';

function CouponList() {
  const [coupons, setCoupons] = useState([]);

  const [error, setError] = useState({
    flag: false,
    message: '',
    obj: {},
  });

  const [loadingComponent, setLoadingComponent] = useState(true);

  const [table, setTable] = useState({
    pageSize: 10,
    currentPage: 1,
    skip: 0,
    totalCount: 0,
    searchText: undefined,
    category: undefined,
    kind: undefined,
    loading: true,
    refetch: false,
  });

  const updateHooks = useCallback((searchCoupons, totalCount) => {
    setCoupons(searchCoupons);
    setTable(prevState => ({
      ...prevState,
      loading: false,
      refetch: false,
      totalCount,
    }));
    setLoadingComponent(false);
  }, []);

  const [listCoupons, { data }] = useLazyQuery(LIST_COUPONS, {
    context: {
      newBackend: true,
    },
    fetchPolicy: 'network-only',
    onError: err => {
      setError({
        flag: true,
        message: 'Erro ao buscar cupons',
        obj: err,
      });
      setLoadingComponent(false);
    },
  });

  useEffect(() => {
    if (data && !data?.errors) {
      updateHooks(
        data.couponSearchCoupons.nodes,
        data.couponSearchCoupons.totalCount
      );
    }
  }, [data, updateHooks]);

  useEffect(() => {
    if (table.loading) {
      listCoupons({
        variables: {
          first: table.pageSize,
          skip: table.skip,
          couponFilter: table.searchText,
          kind: table.kind,
          category: table.category,
        },
      });
    }
  }, [table, listCoupons]);

  if (loadingComponent) return <LoadingBar />;
  if (error.flag) return `Error! ${error.message}`;

  return (
    <Card
      data-testid="CouponList"
      title="Cupons"
      extra={
        <>
          <Link to="/manage/marketing/coupons/create">
            <Tooltip placement="bottom" title="Adicionar novo cupom">
              <Button
                data-testid="addCouponBtn"
                type="primary"
                icon={<PlusOutlined />}
                size="default"
                style={{ marginBottom: 0 }}
              >
                Adicionar
              </Button>
            </Tooltip>
          </Link>
        </>
      }
    >
      <Filter setTable={setTable} />
      <TableCoupon
        coupons={coupons}
        setCoupons={setCoupons}
        table={table}
        setTable={setTable}
      />
    </Card>
  );
}

export default CouponList;
