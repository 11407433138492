import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

export const CART_FRAGMENT = gql`
  fragment CartFragment on Cart {
    adjustmentTotal
    bonusTotal
    balanceTotal
    cartAdjustments {
      id
      label
      value
    }
    cartItems {
      id
      kind
      itemId
      total
      adjustmentTotal
      itemable {
        id
        ... on ElasticProduct {
          name
          categories {
            id
            name
            permalink
          }
          images {
            imageUrl
          }
          price
          costPrice
          slug
          details
          brand {
            id
            name
          }
          userId
        }
      }
    }
    cartTotal
    couponCode
    expiresAt
    expirationCounter
    id
    installments
    interestRate
    itemTotal
    paymentTermIds
    paymentType
    token
    shipmentMethodId
    finalShipmentTotal
    shipmentTotal
    purchaseDocument
    shipping {
      address1
      address2
      city
      neighborhood
      number
      state {
        abbr
        code
        name
        country
        id
      }
      zipcode
    }
    status
    subsidyTotal
    userId
    useBalance
    subsidyTotal
    bankSlipCode
    invoiceDueDate
    invoiceUrl
    paymentFullBalance
    totalMoneySaved
  }
`;

export const QUERY_CART = gql`
  query useFindCart($cartToken: String!) {
    cartFindCart(cartToken: $cartToken) {
      ...CartFragment
    }
  }
  ${CART_FRAGMENT}
`;

function useFindCart(options = {}) {
  const [loadCart, { data, error, called, loading, refetch }] = useLazyQuery(
    QUERY_CART,
    {
      ...options,
    }
  );

  return [
    ({ cartToken }) => {
      loadCart({
        variables: {
          cartToken,
        },
      });
    },
    {
      cart: data?.cartFindCart,
      loading,
      called,
      error,
      refetch,
    },
  ];
}

export default useFindCart;
