import gql from 'graphql-tag';

// New Backend
export const UPDATE_CONTENT_HEADER = gql`
  mutation ($input: UpdateHeaderContentInput!) {
    contentDynamicContentsContentUpdateHeaderContent(input: $input) {
      id
      kind
      title
      url
    }
  }
`;

// New Backend
export const QUERY_CONTENT_HEADER_ALL = gql`
  query {
    contentDynamicContentsSearchHeaderContents {
      nodes {
        id
        kind
        title
        url
      }
    }
  }
`;
